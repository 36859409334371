import { RefObject, useEffect } from 'react';

export const UseCheckOutsideElement = (refs: Array<RefObject<HTMLElement>>, clickOutside: () => void) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (refs.every((ref) => ref.current && !ref.current.contains(event.target))) {
        clickOutside();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, clickOutside]);
};
