import { useCallback, memo } from 'react';
import InputOTP from './InputOTP';
import { useMutation } from 'react-query';

interface IVerifyOTP {
  handleChange: any;
  isErrorOTP: boolean;
  isConfirmRegister?: boolean;
  otp: Array<any>;
}

const VerifyOTP = ({ handleChange, isErrorOTP, isConfirmRegister, otp }: IVerifyOTP) => {
  const inputFocus = useCallback((event: any) => {
    const next = event.target.tabIndex;
    if (next < 6) {
      event.target.form.elements[next].focus();
    }
  }, []);

  const onKeyUp = useCallback(
    (event: any, index: number) => {
      if (event.key === 'Delete' || event.key === 'Backspace') {
        const next = event.target.tabIndex - 2;
        if (next > -1) {
          event.target.form.elements[next].focus();
        }
        handleChange('', index);
        return;
      }
      handleChange(event.key, index);
      inputFocus(event);
    },
    [handleChange, inputFocus]
  );

  return (
    <form id="otp" className="flex flex-row justify-center px-2 text-center">
      {otp.map((_: any, index: number) => (
        <InputOTP
          key={index}
          index={index}
          value={_}
          inputFocus={inputFocus}
          onKeyUp={(e: any) => onKeyUp(e, index)}
          isErrorOTP={isErrorOTP}
          isConfirmRegister={isConfirmRegister}
          handleChange={handleChange}
        />
      ))}
    </form>
  );
};

export default memo(VerifyOTP);
