import { FooterMobi } from 'components/footer/FooterMobi';
import { HeaderCMSCommon } from '../../components/header/HeaderCMSCommon';
import { default as Image1 } from './image1.png';
import { default as Image2 } from './image2.png';
import ScrollToTop from 'components/common/ScrollToTop';
import { classNames } from 'utils/utils';
import useCheckDeviceMobi from 'hooks/useCheckDeviceMobi';

export const PlanPage = () => {
  const isMobile = useCheckDeviceMobi();

  return (
    <>
      <ScrollToTop />
      <HeaderCMSCommon />
      <div className={'w-full bg-[#F8F8F8] pt-24'}>
        <div className={'mx-auto w-full max-w-[85rem] px-4'}>
          <div className={'mx-auto w-full max-w-[62rem] py-16 pt-4'}>
            <div
              className={classNames(
                'mx-auto w-full max-w-[62rem] rounded-[4px] bg-white shadow-[0_0_20px_0_rgba(0,0,0,0.05)]',
                isMobile ? 'p-4' : 'p-8'
              )}
            >
              <h1 className={'mb-4 text-center text-[32px] font-extrabold uppercase leading-[32px] text-[#F15836]'}>
                Giới thiệu nền tảng Shop360
              </h1>
              <div className={'flex-2 mt-6 flex space-x-4 [&>*]:leading-[25px]'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-justify text-sm font-medium text-baseColor'}>
                  Bạn đang quan tâm đến làm tiếp thị liên kết trên Shop360 nhưng chưa hiểu rõ về mô hình kinh doanh, sản phẩm
                  kinh doanh cũng như quyền lợi dành cho cộng tác viên khi đăng ký tham gia. Vậy cùng tìm hiểu chi tiết
                  ngay sau đây để biết Shop360 là gì và liệu nền tảng này có uy tín, đáng tin cậy không nhé!
                </span>
              </div>

              <h2 className={'mt-6 text-[18px] font-bold leading-[28px] text-[#F15836] [&>*]:leading-[25px]'}>
                Tiếp thị liên kết là gì?
              </h2>
              <div className={'flex-2 mt-6 flex space-x-4 [&>*]:leading-[25px]'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-justify text-sm font-medium text-baseColor'}>
                  Tiếp thị liên kết (Affiliate Marketing) là một hệ thống kinh doanh sản phẩm/ dịch vụ dựa trên nền tảng
                  internet. Theo đó, người bán hàng nhận về hoa hồng từ nhà cung cấp sau khi hoàn thành một hành động cụ
                  thể quy định trong điều khoản hợp tác, chẳng hạn như: điền thông tin biểu mẫu, lượt click đăng ký hay
                  mua hàng thành công…
                </span>
              </div>
              <img src={Image1} alt={'image1'} className={'mx-auto my-6'} />
              <div className={'flex-2 mt-6 flex space-x-4 [&>*]:leading-[25px]'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-justify text-sm font-medium text-baseColor'}>
                  Hiểu một cách đơn giản, người bán hàng sẽ giới thiệu, quảng cáo sản phẩm/ dịch vụ từ nhà cung cấp để
                  thu hút người mua sử dụng sản phẩm/ dịch vụ này và nhận về hoa hồng khi hoàn thành hành động.
                </span>
              </div>
              <div className={'flex-2 mt-6 flex space-x-4 [&>*]:leading-[25px]'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-justify text-sm font-medium text-baseColor'}>
                  Tiếp thị liên kết cũng giống như mô hình cộng tác viên (CTV) thường thấy tại nhiều shop bán hàng
                  online. Tuy nhiên, tiếp thị liên kết có sự tham gia của nền tảng tiếp thị (affiliate network), không
                  chỉ là cấu nối giữa nhà cung cấp và CTV mà còn cung cấp nhiều công cụ quản lý minh bạch, đảm bảo quyền
                  lợi cho các bên.
                </span>
              </div>
              <div className={'flex-2 mt-6 flex space-x-4 [&>*]:leading-[25px]'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <div className={'flex flex-col'}>
                  <p className={'text-sm font-medium text-baseColor'}>
                    Hiện nay, các thành phần tham gia vào quá trình làm tiếp thị liên kết thường bao gồm:
                  </p>
                  <p className={'mt-4 text-justify text-sm font-medium text-baseColor'}>- Nhà cung cấp.</p>
                  <p className={'mt-4 text-justify text-sm font-medium text-baseColor'}>
                    - Nhà tiếp thị/ Cộng tác viên.
                  </p>
                  <p className={'mt-4 text-justify text-sm font-medium text-baseColor'}>-Nền tảng tiếp thị liên kết.</p>
                  <p className={'mt-4 text-justify text-sm font-medium text-baseColor'}>- Khách hàng.</p>
                </div>
              </div>
              <h2 className={'mt-6 text-[18px] font-bold leading-[28px] text-[#F15836]'}>
                Shop360 - Nền tảng tiếp thị liên kết uy tín hàng đầu hiện nay
              </h2>
              <div className={'flex-2 mt-6 flex space-x-4 [&>*]:leading-[25px]'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-justify text-sm font-medium text-baseColor'}>
                  Ra đời với sứ mệnh đem đến công cụ kinh doanh đơn giản, dễ sử dụng nhưng vẫn đảm bảo an toàn, minh
                  bạch và mang lại hiệu quả cao, giúp mọi CTV gia tăng thu nhập. Bởi vậy, Shop360 đang trở thành nền
                  tảng tiếp thị liên kết được nhiều CTV lựa chọn khi tìm kiếm việc làm online.
                </span>
              </div>
              <img src={Image2} alt={'image2'} className={'mx-auto my-6'} />

              <h2 className={'mt-6 text-[18px] font-bold leading-[28px] text-[#F15836]'}>Shop360 là gì?</h2>
              <div className={'flex-2 mt-6 flex space-x-4 [&>*]:leading-[25px]'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-justify text-sm font-medium text-baseColor'}>
                  Shop360 là nền tảng tiếp thị liên kết với đa dạng sản phẩm kinh doanh về viễn thông và dịch vụ số.
                  Trong đó các sản phẩm kinh doanh nổi bật của Shop360 bao gồm: sim số, gói cước, sách, vietlott, bảo
                  hiểm, tài chính…
                </span>
              </div>
              <div className={'flex-2 mt-6 flex space-x-4 [&>*]:leading-[25px]'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-justify text-sm font-medium text-baseColor'}>
                  Shop360 không chỉ giúp CTV gia tăng thu nhập mà còn kết nối nhiều nhà cung cấp uy tín trên thị trường.
                </span>
              </div>

              {/* <h2 className={'mt-6 text-[18px] font-bold leading-[28px] text-[#F15836]'}>
                Các đối tác kinh doanh trên Shop360
              </h2>
              <div className={'flex-2 mt-6 flex space-x-4 [&>*]:leading-[25px]'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-justify text-sm font-medium text-baseColor'}>
                  Sở hữu mạng lưới với hàng ngàn đối tác kinh doanh uy tín trên nhiều lĩnh vực về dịch vụ số và viễn
                  thông trên cả nước như: MobiFone, VinaPhone, Viettel, Wintel, Vietlott, PVIRe… Shop360 đảm bảo các sản
                  phẩm CTV kinh doanh luôn đạt chất lượng cao, uy tín, đáng tin cậy.
                </span>
              </div>
              <div className={'flex-2 mt-6 flex space-x-4 [&>*]:leading-[25px]'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-justify text-sm font-medium text-baseColor'}>
                  Tất cả các đối tác kinh doanh trên nền tảng đều được đánh giá, lựa chọn kỹ lưỡng từ các chuyên gia
                  hàng đầu tại Shop360.
                </span>
              </div> */}

              <h2 className={'mt-6 text-[18px] font-bold leading-[28px] text-[#F15836]'}>
                Mô hình kinh doanh Shop360 hiện nay
              </h2>
              <div className={'flex-2 mt-6 flex space-x-4 [&>*]:leading-[25px]'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-justify text-sm font-medium text-baseColor'}>
                  Áp dụng mô hình C2C - Cộng tác viên khi đăng ký tham gia vào hệ thống tiếp thị liên kết trên Shop360
                  sẽ được cung cấp 01 liên kết giới thiệu để ghi nhận doanh thu bán hàng.
                </span>
              </div>
              <div className={'flex-2 mt-6 flex space-x-4 [&>*]:leading-[25px]'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <div className={'flex flex-col [&>*]:leading-[25px]'}>
                  <p className={'text-sm font-medium text-baseColor'}>Hệ thống bao gồm:</p>
                  <p className={'mt-4 text-justify text-sm font-medium text-baseColor'}>
                    - Trang Website Affiliate dành cho Người quản trị: CTV, cấp Tổng công ty, cấp công ty khu vực,
                    MobiFone tỉnh và người quản lý vận hành - Admin.
                  </p>
                  <p className={'mt-4 text-justify text-sm font-medium text-baseColor'}>
                    - Trang Site bán hàng (Landing page) dành cho Khách hàng - người được giới thiệu.
                  </p>
                </div>
              </div>

              <h2 className={'mt-6 text-[18px] font-bold leading-[28px] text-[#F15836]'}>
                Chính sách hoa hồng và thanh toán tới CTV
              </h2>
              <div className={'flex-2 mt-6 flex space-x-4 [&>*]:leading-[25px]'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <div className={'flex flex-col'}>
                  <p className={'text-sm font-medium text-baseColor'}>Về chính sách hoa hồng:</p>
                  <p className={'mt-4 text-justify text-sm font-medium text-baseColor'}>
                    - Hoa hồng trên Shop360 lên tới 70% và khác nhau tùy theo từng sản phẩm/dịch vụ kinh doanh. Bên cạnh
                    đó, CTV/ người bán hàng có thể nhận thêm nhiều khoản hoa hồng khác nhau tùy theo từng chiến dịch
                    kinh doanh, được thông báo chi tiết khi đăng ký trở thành CTV.
                  </p>
                  <p className={'mt-4 text-justify text-sm font-medium text-baseColor'}>
                    - Chính sách hoa hồng tại Shop360 đảm bảo minh bạch, rõ ràng. Khi đăng nhập vào hệ thống, các bạn
                    CTV sẽ có thể theo dõi chính xác tổng hoa hồng thực nhận của mình.
                  </p>
                </div>
              </div>
              <div className={'flex-2 mt-6 flex space-x-4 [&>*]:leading-[25px]'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <div className={'flex flex-col'}>
                  <p className={'text-sm font-medium text-baseColor'}>Về chính sách thanh toán:</p>
                  <p className={'mt-4 text-justify text-sm font-medium text-baseColor'}>
                    - Shop360 thực hiện thanh toán hoa hồng cho các bạn CTV nhanh chóng ngay trong 24h kể từ khi đơn
                    hàng thành công, không giam giữ, không chờ đối soát cuối tháng.
                  </p>
                </div>
              </div>

              <h2 className={'mt-6 text-[18px] font-bold leading-[28px] text-[#F15836]'}>
                Điều kiện để trở thành CTV Shop360
              </h2>
              <div className={'flex-2 mt-6 flex space-x-4 [&>*]:leading-[25px]'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-justify text-sm font-medium text-baseColor'}>
                  Vậy trở thành CTV Shop360 có yêu cầu gì và làm như thế nào? <br />
                  Shop360 là nền tảng tiếp thị liên kết dành cho tất cả mọi người. Dù bạn là ai: sinh viên, người đi làm
                  hay người nội trợ, mẹ bỉm sữa… đều có thể trở thành CTV để bắt đầu kinh doanh. Tham gia làm việc trên
                  Shop360 bạn sẽ không cần đóng bất kỳ khoản chi phí nào. Đối với CTV, bạn sẽ được hỗ trợ từ chăm sóc
                  khách hàng của nền tảng và được đào tạo hoàn toàn miễn phí các kiến thức về sản phẩm, nghiệp vụ kinh
                  doanh.
                </span>
              </div>
              <div className={'flex-2 mt-6 flex space-x-4 [&>*]:leading-[25px]'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-justify text-sm font-medium text-baseColor'}>
                  Đặc biệt, mọi thông tin, hoạt động của CTV trên Shop360 đều được bảo mật và đảm bảo quyền riêng tư. Do
                  đó, bạn hoàn toàn có thể yên tâm khi lựa chọn và làm tiếp thị liên kết cùng Shop360.
                </span>
              </div>
              <div className={'flex-2 mt-6 flex space-x-4 [&>*]:leading-[25px]'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                {/* <span className="text-[13px]">&#62;&#62; </span> */}
                <span className={'text-justify text-sm font-medium text-baseColor'}>
                  Xem thêm thông tin chi tiết cách đăng ký trở thành CTV Shop360 đơn giản - dễ dàng tại:
                  <a href="https://shop360.vn/instructions" className="ml-2 text-[#0c8ce9] underline">
                    https://shop360.vn/instructions
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterMobi />
    </>
  );
};
