import { ButtonGradient } from 'components/common/button/ButtonGradient';
import { SUPPLIER } from 'constant';
import { useNavigate } from 'react-router-dom';

export const RegisterPackageError = ({ packageName, onClose, supplier, phoneNumber, otpError }: any) => {
  const navigate = useNavigate();
  return (
    <div className={'mt-2 flex w-full flex-col items-center justify-center text-center'}>
      <span className="text-sm">
        {supplier === SUPPLIER.VINAPHONE
          ? otpError ||
            'Số điện thoại của bạn không đủ điều kiện đăng ký gói cước này. Vui lòng thử lại hoặc xem gợi ý một số gói cước cho thuê bao của bạn.'
          : 'Số điện thoại của bạn không đủ điều kiện đăng ký gói cước này. Vui lòng thử lại với số điện thoại khác'}
      </span>
      <div className={'flex w-full items-center justify-between space-x-2'}>
        <ButtonGradient className={'mt-6 h-[40px] w-full'} background={'bg-white'} onClick={() => onClose()}>
          <span className={'text-sm font-semibold text-[#F15836]'}>Đóng</span>
        </ButtonGradient>
        {/* {supplier === "Vinaphone" ?
          <button
            className={'w-full rounded-t-[8px] rounded-bl-[8px] bg-gradient-to-l from-[#F15836] to-[#FF924F] p-2' +
              ' mt-6 h-[40px] text-sm font-extrabold text-white'}
            onClick={() => navigate('/products/service-plan?suggest=true&packType=vinaphone&phoneNumber=' + (phoneNumber || ""))}
          >
            Xem gợi ý
          </button> : <></>} */}
      </div>
    </div>
  );
};
