export const RadioFilter = ({ name, label, group, value, onChange }: any) => {
  return (
    <div className={'flex w-full flex-row justify-between'}>
      <label className={'ml-1 cursor-pointer text-sm font-medium text-baseColor'} htmlFor={`${label}-${name}-radio`}>
        {label}
      </label>
      <input
        type={'radio'}
        name={group}
        checked={value}
        className={
          'hover:border-[#15836] form-radio h-[20px] w-[20px] cursor-pointer border border-[#A9A9A9] checked:h-[20px] checked:w-[20px] checked:bg-transparent'
        }
        onChange={(e) => onChange(e.target.checked)}
        id={`${label}-${name}-radio`}
      />
    </div>
  );
};
