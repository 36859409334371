const pathAPI = `${process.env.REACT_APP_SERVICE_PATH_OSP}/api/app-package`;
const pathAPIOTP = `${process.env.REACT_APP_SERVICE_PATH_OSP}/api/package`;
const path_VNP_API = `${process.env.REACT_APP_SERVICE_PATH_OSP}/api/v2/vnp`;

export const apiServicePlan = {
  searchServicePlan: `${pathAPI}/search`,
  detailServicePlan: `${process.env.REACT_APP_SERVICE_PATH_OSP}/api/package/detail`,
  getOtp: `${pathAPIOTP}/getOtp`,
  confirmOtp: `${pathAPIOTP}/confirmOtp`,
  getSuggest: `${pathAPI}/getSuggestV2`
};

export const apiVNPServicePlan = {
  searchServicePlan: `${path_VNP_API}/search`,
  detailServicePlan: `${path_VNP_API}/detail`,
  getOtp: `${path_VNP_API}/otp/get`,
  confirmOtp: `${path_VNP_API}/otp/confirm`,
  searchPhoneServicePlan: `${path_VNP_API}/packageOfMsisdn`,
};
