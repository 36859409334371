import {useState} from "react";
import {classNames} from "../../../../utils/utils";


export const StepperConfirm = ({activeStepIndex}: any) => {

	return (
		<div className="mb-3 grid w-full grid-cols-2 items-center justify-start">
			<div className={"flex flex-col w-full h-fit"}>
				<div
					className={classNames(
						'flex flex-row items-end space-x-1 pr-4 pb-2',
						activeStepIndex === 0 ? '' : 'opacity-40'
					)}
				>
					<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="7.90234" cy="11.5" r="7.5" fill="url(#paint0_linear_572_27256)"/>
						<path
							d="M9.95531 13.2V14H6.36531V13.2H7.78531V9.3C7.72531 9.38 7.63198 9.46667 7.50531 9.56C7.37865 9.64667 7.23531 9.73 7.07531 9.81C6.91531 9.89 6.75531 9.95667 6.59531 10.01C6.43531 10.0567 6.28865 10.08 6.15531 10.08V9.24C6.30198 9.24 6.46198 9.2 6.63531 9.12C6.81531 9.03333 6.98865 8.93333 7.15531 8.82C7.32198 8.70667 7.46198 8.6 7.57531 8.5C7.69531 8.4 7.76531 8.33 7.78531 8.29H8.68531V13.2H9.95531Z"
							fill="white"/>
						<defs>
							<linearGradient id="paint0_linear_572_27256" x1="0.402344" y1="19" x2="0.402342" y2="4"
							                gradientUnits="userSpaceOnUse">
								<stop stopColor="#F15836"/>
								<stop offset="1" stopColor="#FF924F"/>
							</linearGradient>
						</defs>
					</svg>
					<span className={'text-sm font-medium text-baseColor'}>Nhập số điện thoại</span>
				</div>
				<div
					className={classNames("w-full h-[4px] bg-gradient-to-r from-[#F15836] to-[#FF924F]", activeStepIndex === 0 ? "" : "opacity-40")}/>
			</div>
			<div className={"flex flex-col w-full h-fit"}>
				<div
					className={classNames(
						'flex flex-row items-end space-x-1 pr-4 pb-2',
						activeStepIndex === 1 ? '' : 'opacity-40'
					)}
				>
					<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="7.65234" cy="11.5" r="7.5" fill="url(#paint0_linear_572_27380)"/>
						<path
							d="M5.42219 14C5.42219 13.7333 5.44219 13.4833 5.48219 13.25C5.52885 13.0167 5.60885 12.7933 5.72219 12.58C5.84219 12.3667 6.00885 12.1633 6.22219 11.97C6.43552 11.77 6.71219 11.5767 7.05219 11.39C7.25219 11.2767 7.45219 11.17 7.65219 11.07C7.85885 10.97 8.04885 10.8633 8.22219 10.75C8.40219 10.6367 8.54552 10.5133 8.65219 10.38C8.75885 10.24 8.81219 10.08 8.81219 9.9C8.81219 9.74667 8.76552 9.6 8.67219 9.46C8.58552 9.31333 8.45219 9.19667 8.27219 9.11C8.09885 9.01667 7.88219 8.97 7.62219 8.97C7.42219 8.97 7.23885 8.99667 7.07219 9.05C6.91219 9.10333 6.76552 9.17333 6.63219 9.26C6.50552 9.34 6.39219 9.42667 6.29219 9.52C6.19885 9.60667 6.11885 9.68667 6.05219 9.76L5.50219 9.14C5.55552 9.08 5.64552 9 5.77219 8.9C5.89885 8.79333 6.05885 8.68667 6.25219 8.58C6.44552 8.47333 6.66885 8.38333 6.92219 8.31C7.18219 8.23 7.46552 8.19 7.77219 8.19C8.18552 8.19 8.53885 8.26333 8.83219 8.41C9.13219 8.55 9.36219 8.74333 9.52219 8.99C9.68219 9.23 9.76219 9.5 9.76219 9.8C9.76219 10.06 9.70552 10.2867 9.59219 10.48C9.48552 10.6733 9.34552 10.8433 9.17219 10.99C9.00552 11.1367 8.82885 11.2633 8.64219 11.37C8.46219 11.47 8.29885 11.5567 8.15219 11.63C7.85219 11.7767 7.59552 11.91 7.38219 12.03C7.16885 12.15 6.99219 12.27 6.85219 12.39C6.71885 12.5033 6.60885 12.6233 6.52219 12.75C6.44219 12.8767 6.38219 13.0267 6.34219 13.2H9.87219V14H5.42219Z"
							fill="white"/>
						<defs>
							<linearGradient id="paint0_linear_572_27380" x1="0.152344" y1="19" x2="0.152342" y2="4"
							                gradientUnits="userSpaceOnUse">
								<stop stopColor="#F15836"/>
								<stop offset="1" stopColor="#FF924F"/>
							</linearGradient>
						</defs>
					</svg>
					<span className={' text-sm font-medium text-baseColor'}>Xác minh mã OTP</span>
				</div>
				<div
					className={classNames("w-full h-[4px] bg-gradient-to-r from-[#F15836] to-[#FF924F]", activeStepIndex === 1 ? "" : "opacity-40")}/>
			</div>
		</div>
	);
};
