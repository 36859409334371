import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useMemo, useState } from 'react';
import { ConfirmPhoneBody } from './ConfirmPhoneBody';
import { StepperConfirm } from './StepperConfirm';
import { ConfirmOTPBody } from './ConfirmOTPBody';
import { ConfirmPackagePopupContext } from './ConfirmPackagePopupContext';
import { RegisterPackageSuccess } from './RegisterPackageSuccess';
import { RegisterPackageError } from './RegisterPackageError';

export const ConfirmPackagePopup = ({ onClose, packageName, item }: any) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [responseGetOTP, setResponseGetOTP] = useState<any>({});
  const [dataForm, setDataForm] = useState<any>(undefined);
  const [successTransCode, setSuccessTransCode] = useState<string>('');
  const [otpError, setOtpError] = useState<string>('');
  const renderBody = useMemo(() => {
    if (activeStepIndex === 0) {
      return <ConfirmPhoneBody onSuccess={(msisdn: string) => setResponseGetOTP(msisdn)} />;
    } else if (activeStepIndex === 1) {
      return <ConfirmOTPBody responseGetOTP={responseGetOTP} setSuccessTransCode={setSuccessTransCode} setOtpError={setOtpError} />;
    } else if (activeStepIndex === 2) {
      // TODO: Đổi id sau khi nhận được response từ API
      return <RegisterPackageSuccess packageName={packageName} onClose={onClose} supplier={item?.supplier || ""} id={successTransCode || ''} />;
    }
    return <RegisterPackageError
      packageName={packageName}
      onClose={onClose}
      supplier={item?.supplier || ""}
      phoneNumber={dataForm?.phoneNumber || ""}
      otpError={otpError} />;
  }, [activeStepIndex, responseGetOTP, packageName, onClose, item?.supplier, dataForm?.phoneNumber, successTransCode, otpError]);

  return (
    <Transition show={true} as={Fragment}>
      <Dialog
        open={true}
        onClose={() => { }}
        className="fixed inset-0 z-50 flex h-full min-h-screen w-screen flex-row items-center justify-center overflow-y-auto bg-[#000000]/60"
      >
        {activeStepIndex < 2 ? (
          <Dialog.Panel
            className={
              'min-h-[382px] w-[400px] rounded-t-[16px] rounded-bl-[16px] border border-gray-200' +
              ' bg-[#F8F8F8] shadow-base'
            }
          >
            <Dialog.Title className={'mb-2 flex h-fit flex-row items-start justify-between px-6 pt-6'}>
              <div></div>
              <svg width="81" height="80" viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_572_27233)">
                  <path
                    d="M33.1519 15.2227C29.1616 15.2227 25.8968 18.4875 25.8968 22.4778V73.2636L40.407 58.7534H73.0551C77.0454 58.7534 80.3102 55.4885 80.3102 51.4982V22.4778C80.3102 18.4875 77.0454 15.2227 73.0551 15.2227H33.1519ZM11.3866 22.4778C9.21004 22.4778 7.75902 23.9288 7.75902 26.1053C7.75902 28.2819 9.21004 29.7329 11.3866 29.7329H18.6417V22.4778H11.3866ZM40.407 26.1053H69.4275V33.3604H40.407V26.1053ZM7.75902 36.988C5.58249 36.988 4.13146 38.439 4.13146 40.6156C4.13146 42.7921 5.58249 44.2431 7.75902 44.2431H18.6417V36.988H7.75902ZM40.407 40.6156H58.5448V47.8707H40.407V40.6156ZM4.13146 51.4982C1.95493 51.4982 0.503906 52.9493 0.503906 55.1258C0.503906 57.3023 1.95493 58.7534 4.13146 58.7534H18.6417V51.4982H4.13146Z"
                    fill="url(#paint0_linear_572_27233)"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_572_27233"
                    x1="0.503906"
                    y1="44.2431"
                    x2="80.3102"
                    y2="44.2431"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F15836" />
                    <stop offset="1" stopColor="#FF924F" />
                  </linearGradient>
                  <clipPath id="clip0_572_27233">
                    <rect width="80" height="80" fill="white" transform="translate(0.402344)" />
                  </clipPath>
                </defs>
              </svg>
              <button type={'button'} onClick={() => onClose()}>
                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_573_26413)">
                    <path
                      d="M0.314708 0.701427C0.414214 0.601669 0.532424 0.522521 0.662566 0.468518C0.792707 0.414515 0.932225 0.386719 1.07313 0.386719C1.21403 0.386719 1.35354 0.414515 1.48369 0.468518C1.61383 0.522521 1.73204 0.601669 1.83154 0.701427L7.5004 6.37242L13.1692 0.701427C13.2688 0.60183 13.3871 0.522825 13.5172 0.468924C13.6473 0.415022 13.7868 0.38728 13.9277 0.38728C14.0685 0.38728 14.208 0.415022 14.3381 0.468924C14.4682 0.522825 14.5865 0.60183 14.6861 0.701427C14.7857 0.801024 14.8647 0.919262 14.9186 1.04939C14.9725 1.17952 15.0002 1.31899 15.0002 1.45984C15.0002 1.6007 14.9725 1.74017 14.9186 1.8703C14.8647 2.00043 14.7857 2.11867 14.6861 2.21826L9.01509 7.88711L14.6861 13.556C14.7857 13.6556 14.8647 13.7738 14.9186 13.9039C14.9725 14.0341 15.0002 14.1735 15.0002 14.3144C15.0002 14.4552 14.9725 14.5947 14.9186 14.7248C14.8647 14.855 14.7857 14.9732 14.6861 15.0728C14.5865 15.1724 14.4682 15.2514 14.3381 15.3053C14.208 15.3592 14.0685 15.3869 13.9277 15.3869C13.7868 15.3869 13.6473 15.3592 13.5172 15.3053C13.3871 15.2514 13.2688 15.1724 13.1692 15.0728L7.5004 9.40181L1.83154 15.0728C1.73195 15.1724 1.61371 15.2514 1.48358 15.3053C1.35345 15.3592 1.21398 15.3869 1.07313 15.3869C0.932275 15.3869 0.792802 15.3592 0.662673 15.3053C0.532543 15.2514 0.414305 15.1724 0.314708 15.0728C0.215111 14.9732 0.136106 14.855 0.0822051 14.7248C0.0283036 14.5947 0.000560851 14.4552 0.000560851 14.3144C0.000560851 14.1735 0.0283036 14.0341 0.0822051 13.9039C0.136106 13.7738 0.215111 13.6556 0.314708 13.556L5.9857 7.88711L0.314708 2.21826C0.21495 2.11876 0.135802 2.00055 0.0817995 1.87041C0.0277966 1.74026 0 1.60075 0 1.45984C0 1.31894 0.0277966 1.17943 0.0817995 1.04928C0.135802 0.919142 0.21495 0.800933 0.314708 0.701427Z"
                      fill="#A9A9A9"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_573_26413">
                      <rect width="15" height="15" fill="white" transform="translate(0 0.386719)" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </Dialog.Title>
            <Dialog.Description className={'flex h-full w-full flex-col items-center px-6 pb-6'}>
              <ConfirmPackagePopupContext.Provider
                value={{ activeStepIndex, setActiveStepIndex, item, dataForm, setDataForm }}
              >
                <h2 className={'mb-4 text-[22px] font-extrabold leading-[26px] text-baseColor'}>
                  Đăng ký gói cước {packageName}
                </h2>
                <StepperConfirm activeStepIndex={activeStepIndex} />
                {renderBody}
              </ConfirmPackagePopupContext.Provider>
            </Dialog.Description>
          </Dialog.Panel>
        ) : (
          <Dialog.Panel
            className={
              'min-h-[306px] w-[400px] rounded-t-[16px] rounded-bl-[16px] border border-gray-200' +
              ' bg-[#F8F8F8] shadow-base'
            }
          >
            <Dialog.Title className={'mb-2 flex h-fit flex-row items-start justify-between px-6 pt-6'}>
              <div></div>
              {activeStepIndex === 2 ? (
                <svg width="71" height="69" viewBox="0 0 71 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_572_27451)">
                    <path
                      d="M34.6497 67.9994C35.8473 67.9676 36.9966 67.4483 37.8942 66.5334C38.7919 65.6185 39.38 64.3669 39.5547 63H29.5547C29.7343 64.404 30.35 65.685 31.2872 66.6046C32.2244 67.5243 33.4194 68.0199 34.6497 67.9994Z"
                      fill="#F15836"
                    />
                    <path
                      d="M64.5547 55.3938L63.8701 54.7974C61.928 53.0889 60.2282 51.1297 58.8164 48.9726C57.2746 45.9956 56.3505 42.7446 56.0983 39.4103V29.5895C56.0901 28.3966 55.9824 27.2064 55.7762 26.031C52.3657 25.3389 49.3022 23.5043 47.1044 20.8379C44.9066 18.1715 43.7095 14.8372 43.7158 11.3993V10.1469C41.6136 9.12541 39.355 8.45371 37.0312 8.15887V5.65398C37.0312 4.9501 36.748 4.27505 36.2439 3.77733C35.7398 3.27962 35.0562 3 34.3433 3C33.6304 3 32.9467 3.27962 32.4426 3.77733C31.9386 4.27505 31.6554 4.9501 31.6554 5.65398V8.25827C26.4521 8.983 21.6901 11.5419 18.2484 15.4627C14.8068 19.3834 12.9179 24.4011 12.9305 29.5895V39.4103C12.6784 42.7446 11.7542 45.9956 10.2124 48.9726C8.82492 51.1243 7.1524 53.0833 5.23925 54.7974L4.55469 55.3938V61H64.5547V55.3938Z"
                      fill="url(#paint0_linear_572_27451)"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M58.5 1.875C53.1844 1.875 48.875 6.18438 48.875 11.5C48.875 16.8156 53.1844 21.125 58.5 21.125C63.8156 21.125 68.125 16.8156 68.125 11.5C68.125 6.18438 63.8156 1.875 58.5 1.875ZM62.672 9.8725C62.7488 9.78469 62.8073 9.6824 62.844 9.57165C62.8807 9.4609 62.8949 9.34392 62.8857 9.22761C62.8766 9.1113 62.8442 8.99799 62.7906 8.89436C62.737 8.79073 62.6632 8.69887 62.5736 8.62417C62.484 8.54948 62.3803 8.49347 62.2687 8.45943C62.1571 8.42539 62.0398 8.41402 61.9238 8.42597C61.8077 8.43793 61.6952 8.47298 61.5929 8.52905C61.4906 8.58513 61.4005 8.6611 61.328 8.7525L57.5655 13.2666L55.6186 11.3189C55.4536 11.1595 55.2326 11.0713 55.0031 11.0733C54.7737 11.0753 54.5543 11.1673 54.392 11.3295C54.2298 11.4918 54.1378 11.7112 54.1358 11.9406C54.1338 12.1701 54.222 12.3911 54.3814 12.5561L57.0064 15.1811C57.0924 15.267 57.1953 15.3341 57.3086 15.3781C57.4219 15.422 57.5432 15.4418 57.6646 15.4363C57.786 15.4308 57.905 15.4 58.0139 15.346C58.1227 15.292 58.2192 15.2159 58.297 15.1225L62.672 9.8725Z"
                      fill="#29CC6A"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_572_27451"
                      x1="4.55469"
                      y1="61"
                      x2="4.55468"
                      y2="3"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F15836" />
                      <stop offset="1" stopColor="#FF924F" />
                    </linearGradient>
                    <clipPath id="clip0_572_27451">
                      <rect width="70" height="70" fill="white" transform="translate(0.554688)" />
                    </clipPath>
                  </defs>
                </svg>
              ) : (
                <svg width="71" height="69" viewBox="0 0 71 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_572_27633)">
                    <path
                      d="M34.6497 67.9994C35.8473 67.9676 36.9966 67.4483 37.8942 66.5334C38.7919 65.6185 39.38 64.3669 39.5547 63H29.5547C29.7343 64.404 30.35 65.685 31.2872 66.6046C32.2244 67.5243 33.4194 68.0199 34.6497 67.9994Z"
                      fill="#A9A9A9"
                    />
                    <path
                      d="M64.5547 55.3938L63.8701 54.7974C61.928 53.0889 60.2282 51.1297 58.8164 48.9726C57.2746 45.9956 56.3505 42.7446 56.0983 39.4103V29.5895C56.0901 28.3966 55.9824 27.2064 55.7762 26.031C52.3657 25.3389 49.3022 23.5043 47.1044 20.8379C44.9066 18.1715 43.7095 14.8372 43.7158 11.3993V10.1469C41.6136 9.12541 39.355 8.45371 37.0312 8.15887V5.65398C37.0312 4.9501 36.748 4.27505 36.2439 3.77733C35.7398 3.27962 35.0562 3 34.3433 3C33.6304 3 32.9467 3.27962 32.4426 3.77733C31.9386 4.27505 31.6554 4.9501 31.6554 5.65398V8.25827C26.4521 8.983 21.6901 11.5419 18.2484 15.4627C14.8068 19.3834 12.9179 24.4011 12.9305 29.5895V39.4103C12.6784 42.7446 11.7542 45.9956 10.2124 48.9726C8.82492 51.1243 7.1524 53.0833 5.23925 54.7974L4.55469 55.3938V61H64.5547V55.3938Z"
                      fill="#A9A9A9"
                    />
                    <g clipPath="url(#clip1_572_27633)">
                      <path
                        d="M58.5 1.31641C52.6935 1.31641 48 6.00991 48 11.8164C48 17.6229 52.6935 22.3164 58.5 22.3164C64.3065 22.3164 69 17.6229 69 11.8164C69 6.00991 64.3065 1.31641 58.5 1.31641ZM63.015 16.3314C62.9179 16.4287 62.8025 16.506 62.6755 16.5587C62.5484 16.6113 62.4123 16.6385 62.2747 16.6385C62.1372 16.6385 62.0011 16.6113 61.874 16.5587C61.747 16.506 61.6316 16.4287 61.5345 16.3314L58.5 13.2969L55.4655 16.3314C55.2692 16.5277 55.0029 16.638 54.7252 16.638C54.4476 16.638 54.1813 16.5277 53.985 16.3314C53.7887 16.1351 53.6784 15.8688 53.6784 15.5912C53.6784 15.4537 53.7055 15.3175 53.7581 15.1905C53.8107 15.0635 53.8878 14.9481 53.985 14.8509L57.0195 11.8164L53.985 8.78191C53.7887 8.58558 53.6784 8.3193 53.6784 8.04166C53.6784 7.76401 53.7887 7.49773 53.985 7.30141C54.1813 7.10508 54.4476 6.99478 54.7252 6.99478C55.0029 6.99478 55.2692 7.10508 55.4655 7.30141L58.5 10.3359L61.5345 7.30141C61.6317 7.20419 61.7471 7.12708 61.8741 7.07447C62.0011 7.02186 62.1373 6.99478 62.2747 6.99478C62.4122 6.99478 62.5484 7.02186 62.6754 7.07447C62.8024 7.12708 62.9178 7.20419 63.015 7.30141C63.1122 7.39862 63.1893 7.51402 63.2419 7.64104C63.2945 7.76805 63.3216 7.90418 63.3216 8.04166C63.3216 8.17913 63.2945 8.31526 63.2419 8.44228C63.1893 8.56929 63.1122 8.68469 63.015 8.78191L59.9805 11.8164L63.015 14.8509C63.414 15.2499 63.414 15.9219 63.015 16.3314Z"
                        fill="url(#paint0_linear_572_27633)"
                      />
                    </g>
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_572_27633"
                      x1="58.5"
                      y1="1.31641"
                      x2="58.5"
                      y2="22.3164"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FF3F3F" />
                      <stop offset="1" stopColor="#E01D1D" />
                    </linearGradient>
                    <clipPath id="clip0_572_27633">
                      <rect width="70" height="70" fill="white" transform="translate(0.554688)" />
                    </clipPath>
                    <clipPath id="clip1_572_27633">
                      <rect width="21" height="21" fill="white" transform="translate(48 1.31641)" />
                    </clipPath>
                  </defs>
                </svg>
              )}
              <button type={'button'} onClick={() => onClose()}>
                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_573_26413)">
                    <path
                      d="M0.314708 0.701427C0.414214 0.601669 0.532424 0.522521 0.662566 0.468518C0.792707 0.414515 0.932225 0.386719 1.07313 0.386719C1.21403 0.386719 1.35354 0.414515 1.48369 0.468518C1.61383 0.522521 1.73204 0.601669 1.83154 0.701427L7.5004 6.37242L13.1692 0.701427C13.2688 0.60183 13.3871 0.522825 13.5172 0.468924C13.6473 0.415022 13.7868 0.38728 13.9277 0.38728C14.0685 0.38728 14.208 0.415022 14.3381 0.468924C14.4682 0.522825 14.5865 0.60183 14.6861 0.701427C14.7857 0.801024 14.8647 0.919262 14.9186 1.04939C14.9725 1.17952 15.0002 1.31899 15.0002 1.45984C15.0002 1.6007 14.9725 1.74017 14.9186 1.8703C14.8647 2.00043 14.7857 2.11867 14.6861 2.21826L9.01509 7.88711L14.6861 13.556C14.7857 13.6556 14.8647 13.7738 14.9186 13.9039C14.9725 14.0341 15.0002 14.1735 15.0002 14.3144C15.0002 14.4552 14.9725 14.5947 14.9186 14.7248C14.8647 14.855 14.7857 14.9732 14.6861 15.0728C14.5865 15.1724 14.4682 15.2514 14.3381 15.3053C14.208 15.3592 14.0685 15.3869 13.9277 15.3869C13.7868 15.3869 13.6473 15.3592 13.5172 15.3053C13.3871 15.2514 13.2688 15.1724 13.1692 15.0728L7.5004 9.40181L1.83154 15.0728C1.73195 15.1724 1.61371 15.2514 1.48358 15.3053C1.35345 15.3592 1.21398 15.3869 1.07313 15.3869C0.932275 15.3869 0.792802 15.3592 0.662673 15.3053C0.532543 15.2514 0.414305 15.1724 0.314708 15.0728C0.215111 14.9732 0.136106 14.855 0.0822051 14.7248C0.0283036 14.5947 0.000560851 14.4552 0.000560851 14.3144C0.000560851 14.1735 0.0283036 14.0341 0.0822051 13.9039C0.136106 13.7738 0.215111 13.6556 0.314708 13.556L5.9857 7.88711L0.314708 2.21826C0.21495 2.11876 0.135802 2.00055 0.0817995 1.87041C0.0277966 1.74026 0 1.60075 0 1.45984C0 1.31894 0.0277966 1.17943 0.0817995 1.04928C0.135802 0.919142 0.21495 0.800933 0.314708 0.701427Z"
                      fill="#A9A9A9"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_573_26413">
                      <rect width="15" height="15" fill="white" transform="translate(0 0.386719)" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </Dialog.Title>
            <Dialog.Description className={'mt-6 flex h-full w-full flex-col items-center px-6 pb-6'}>
              <h2 className={'mb-4 text-[22px] font-extrabold leading-[26px] text-baseColor'}>Thông báo</h2>
              {renderBody}
            </Dialog.Description>
          </Dialog.Panel>
        )}
      </Dialog>
    </Transition>
  );
};
