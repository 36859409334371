import { sendPostRequest } from './index';
import { apiOTP } from './api/otp';

export interface getOPTParam {
    msisdn: string, // số điện thoại
    type: number, // Loại giao dịch (0: đăng kí, 1: quên mật khẩu, 2: cập nhật thông tin thanh toán)
}

export interface confirmOPTParam{
    msisdn: string, // số điện thoại
    type: number, // Loại giao dịch (0: đăng kí, 1: quên mật khẩu, 2: cập nhật thông tin thanh toán)
    otp: string 
}

export const getOPT = (param: getOPTParam) => sendPostRequest(apiOTP.getOTP, param);

export const confirmOPT = (param: confirmOPTParam ) => sendPostRequest(apiOTP.confirmOTP,param );
