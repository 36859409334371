import { ButtonGradient } from 'components/common/button/ButtonGradient';
import { SUPPLIER } from 'constant';
import { useNavigate } from 'react-router-dom';

export const RegisterPackageSuccess = ({ packageName, onClose, supplier, id }: any) => {
  const navigate = useNavigate();
  return (
    <div className={'mt-2 flex w-full flex-col items-center justify-center'}>
      <span>Quý khách đã đăng ký thành công gói cước {packageName}</span>

      {supplier === SUPPLIER.VINAPHONE ? (
        <ButtonGradient
          className={'mt-6 h-[40px] w-full'}
          background={'bg-white'}
          onClick={() => navigate('/statistic/transaction/info/' + id)}
        >
          <span className={'text-sm font-semibold text-[#F15836]'}>Xem chi tiết</span>
        </ButtonGradient>
      ) : (
        <ButtonGradient className={'mt-6 h-[40px] w-full'} background={'bg-white'} onClick={() => onClose()}>
          <span className={'text-sm font-semibold text-[#F15836]'}>Đóng</span>
        </ButtonGradient>
      )}
    </div>
  );
};
