import { classNames } from '../../../utils/utils';

export const CheckBoxLabel = ({ label, value, onChange, values }: any) => {
  return (
    <>
      <input
        type={'checkbox'}
        className={'hidden'}
        id={`check-label-${label}-${value}`}
        checked={values.includes(value)}
        onChange={(e) => {
          if (e.target.checked) {
            onChange([...values, value]);
          } else {
            onChange(values.filter((item: any) => item !== value));
          }
        }}
      />
      <label
        className={classNames(
          'align-center flex h-[35px] w-fit items-center justify-center justify-center rounded-t-[12px]' +
            ' cursor-pointer select-none rounded-bl-[12px] py-1' +
            ' px-3 text-center text-sm font-medium',
          values.includes(value) ? '!bg-[#FFE3D6] !text-[#F15836]' : 'bg-[#EFEFEF] text-baseColor'
        )}
        htmlFor={`check-label-${label}-${value}`}
      >
        {label}
      </label>
    </>
  );
};
