import { FacebookIcon, ZaloIcon } from 'assets/images/icon';
import useCheckDeviceMobi from 'hooks/useCheckDeviceMobi';
import { NavLink } from 'react-router-dom';
import { FooterMobi } from './FooterMobi';
import { BCTIcon } from './image/index';

export const Footer = () => {
  const isMobile = useCheckDeviceMobi();
  return (
    <>
      {isMobile ? (
        <FooterMobi />
      ) : (
        <footer className={'bottom-0'}>
          <div className="flex w-full flex-col items-center justify-center bg-white">
            <div className="w-full px-10 py-5 md:flex md:justify-center">
              <div className="flex w-full max-w-[85rem] justify-between">
                <div className="items-left flex flex-col">
                  {/* <img className="w-[50px] object-contain" src={Logo} alt="Logo" />*/}
                  <span className="mb-4 text-[16px] font-bold uppercase leading-[24px] text-[#EF4223]">
                    CÔNG TY CỔ PHẦN CÔNG NGHỆ PHẦN MỀM VÀ NỘI DUNG SỐ OSP
                  </span>
                  {/* <p className={'mt-[16px] text-[13.3px] uppercase leading-[24px] text-[#333333]'}>
                    Một sản phẩm của Công ty CP công nghệ phần mềm và nội dung số OSP
                  </p> */}
                  <p className={'text-[13.3px] leading-[24px] text-[#333333]'}>
                    <span className={'font-medium'}>MST</span>: 0102886901 do Sở kế hoạch và Đầu tư thành phố Hà Nội cấp
                    lần đầu ngày 22/08/2008.
                  </p>
                  <p className={'text-[13.3px] leading-[24px] text-[#333333]'}>
                    <span className={'font-medium'}>Địa chỉ</span>: Tầng 4, Tòa nhà The Garden Hill, số 99 Trần Bình,
                    phường Mỹ Đình 2, quận Nam Từ Liêm, Thành phố Hà Nội, Việt Nam
                  </p>
                  <p className={'text-[13.3px] leading-[24px] text-[#333333]'}>
                    <span className={'font-medium'}>Hotline</span>: 0896.686.686
                  </p>
                  <p className={'text-[13.3px] leading-[24px] text-[#333333]'}>
                    <span className={'font-medium'}>Email</span>:{' '}
                    <a href="mailto:shop360.cskh@osp.vn" className="hover:text-blue-500">
                      shop360.cskh@osp.vn
                    </a>
                  </p>
                  <p className={'mt-4 text-[12px] leading-[24px] text-[#333333]'}>
                    Copyright © CÔNG TY CỔ PHẦN CÔNG NGHỆ PHẦN MỀM VÀ NỘI DUNG SỐ OSP
                  </p>
                </div>
                <div className="flex max-w-[30rem] flex-grow flex-row justify-between">
                  <div>
                    <h2 className="mb-3 text-[18px] font-bold text-[#404040] dark:text-white">Thông tin</h2>
                    <ul className="text-base font-normal text-black dark:text-gray-400">
                      <li>
                        <NavLink to={'/plan'} className="text-sm text-[#818181] hover:underline">
                          Giới thiệu
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={'/hdsd'} className="text-sm text-[#818181] hover:underline">
                          Hướng dẫn sử dụng
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={'/instructions'} className="text-sm text-[#818181] hover:underline">
                          Hướng dẫn tham gia
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={'/transaction-terms'} className="text-sm text-[#818181] hover:underline">
                          Điều kiện giao dịch chung
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={'/delivery-policy'} className="text-sm text-[#818181] hover:underline">
                          Chính sách vận chuyển
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={'/payment-policy'} className="text-sm text-[#818181] hover:underline">
                          Chính sách thanh toán
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={'/privacy'} className="text-sm text-[#818181] hover:underline">
                          Chính sách bảo mật
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to={'/questions'} className="text-sm text-[#818181] hover:underline">
                          Các câu hỏi thường gặp
                        </NavLink>
                      </li>
                      <li>
                        <a href={'https://shop360.vn/product'} className="text-sm text-[#818181] hover:underline">
                          Sản phẩm
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="mb-3 text-[18px] font-bold text-[#404040] dark:text-white">Liên hệ</h2>
                    <ul className="text-base font-normal text-black dark:text-gray-400">
                      <li>
                        <a href={'https://shop360.vn'} className="mt-2 text-sm text-[#818181] hover:underline">
                          Website: shop360.vn
                        </a>
                      </li>
                      <li>
                        <a href="tel:+84912345678" className="mt-2 text-sm text-[#818181] hover:underline">
                          Hotline: 0896.686.686
                        </a>
                      </li>
                      <li>
                        <a href="mailto:shop360.cskh@osp.vn" className="mt-2 text-sm text-[#818181] hover:underline">
                          Email: shop360.cskh@osp.vn
                        </a>
                      </li>
                      <li className={'mt-3.5 flex flex-row justify-start space-x-2'}>
                        <a href="https://www.facebook.com/fanpageshop360" target="_blank" rel="noreferrer">
                          <img src={FacebookIcon} alt={'Facebook'} className={'h-8 w-8'} />
                        </a>
                        <a href="https://zalo.me/g/czjvyl860" target="_blank" rel="noreferrer">
                          <img src={ZaloIcon} alt={'Zalo'} className={'h-8 w-8'} />
                        </a>
                        {/* <a href="https://www.tiktok.com/@phapsukinhdoanh?is_from_webapp=1&sender_device=pc" target="_blank" >
                      <img src={TiktokIcon} alt={'Tiktok'} className={'h-8 w-8'} />
                    </a> */}
                      </li>
                      <li className={`mb-4 mt-4 flex flex-row space-x-1 ${isMobile ? 'justify-start' : 'justify-end'}`}>
                        <a href="http://online.gov.vn/Home/WebDetails/114671">
                          <img src={BCTIcon} alt={'BCT'} className={'h-auto w-[170px]'} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div>
                  <h2 className="mb-3 text-[18px] font-bold text-[#404040] dark:text-white">Ứng dụng di động</h2>
                  <ul className="text-base font-normal text-black dark:text-gray-400">
                    <img src={AppIcon} alt={'Ứng dụng di động'} />
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};
