import { FooterMobi } from 'components/footer/FooterMobi';
import { HeaderCMSCommon } from '../../components/header/HeaderCMSCommon';
import { default as Register1 } from '../instructions/Register1.png';
import { default as Register2 } from '../instructions/Register2.png';
import { default as Register3 } from '../instructions/Register3.png';
import { default as Register4 } from '../instructions/Register4.png';
import { default as Register5 } from '../instructions/Register5.png';
import { default as Login1 } from '../instructions/login1.png';
import { default as Login2 } from '../instructions/login2.png';
import { default as Login3 } from '../instructions/login3.png';
import { default as Display1 } from '../instructions/display1.png';
import useCheckDeviceMobi from 'hooks/useCheckDeviceMobi';
import { classNames } from 'utils/utils';

export const InstructionsPage = () => {
  const isMobile = useCheckDeviceMobi();
  return (
    <>
      <HeaderCMSCommon />
      <div className={'w-full bg-[#F8F8F8] pt-5'}>
        <div className={'mx-auto w-full max-w-[85rem] px-4'}>
          {/* <div className={'relative h-52 w-full rounded-[16px] bg-[#D9D9D9] md:h-[450px]'}>
            <div className={'absolute bottom-0 mx-auto hidden w-full flex-row justify-center space-x-4 pb-4 md:flex'}>
              {Array.from({ length: 3 }).map((item, index) => {
                if (index === indexDataCarousel) {
                  return (
                    <svg
                      className={'cursor-pointer'}
                      width="38"
                      height="16"
                      viewBox="0 0 38 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect x="0.828125" y="0.163574" width="36.3507" height="15" rx="7.5" fill="#F15836" />
                    </svg>
                  );
                }
                return (
                  <svg
                    onClick={() => setIndexDataCarousel(index)}
                    className={'cursor-pointer'}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="8" cy="8" r="8" fill="#818181" />
                  </svg>
                );
              })}
            </div>
          </div> */}
          <div className={'mx-auto w-full max-w-[62rem] py-16'}>
            <div
              className={classNames(
                'mx-auto w-full max-w-[62rem] rounded-[4px] bg-white shadow-[0_0_20px_0_rgba(0,0,0,0.05)]',
                isMobile ? 'p-4' : 'p-8'
              )}
            >
              <h1 className={'text-center text-[32px] font-extrabold leading-[26px] text-[#F15836]'}>
                HƯỚNG DẪN THAM GIA
              </h1>
              <h2 className={'mt-6 text-[18px] font-bold leading-[28px] text-[#F15836]'}>
                1. Hướng dẫn đăng ký tài khoản
              </h2>
              <div className={'flex-2 mt-6 flex space-x-4'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-sm font-medium text-baseColor'}>
                  Bước 1: Truy cập Website <a href={'https://shop360.vn'}>https://shop360.vn</a>
                </span>
              </div>
              <div className={'flex-2 mt-6 flex space-x-4'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-sm font-medium text-baseColor'}>
                  Bước 2: Click vào nút <span className={'font-extrabold text-[#4568DA]'}>Đăng ký</span> trên thanh
                  menu.
                </span>
              </div>
              <div className={'flex-2 mt-6 flex space-x-4'}>
                <div className={classNames('mx-auto w-[624px] ', isMobile ? 'h-[160px]' : 'h-[248px]')}>
                  <img src={Register1} alt="" />
                </div>
                <div className={classNames('mx-auto w-[624px] ', isMobile ? 'h-[160px]' : 'h-[248px]')}>
                  <img src={Register2} alt="" />
                </div>
              </div>

              <div className={'flex-2 mt-6 flex space-x-4'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-sm font-medium text-baseColor'}>Bước 3: Nhập số điện thoại</span>
              </div>
              <div className={'flex-2 mt-6 flex space-x-4'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-sm font-medium text-baseColor'}>Bước 4: Xác minh mã OTP và SĐT</span>
              </div>
              <div className={'flex-2 mt-6 flex space-x-4'}>
                <div className={classNames('mx-auto w-[624px] ', isMobile ? 'h-[220px]' : 'h-[380px]')}>
                  <img src={Register3} alt="" />
                </div>
              </div>

              <div className={'flex-2 mt-6 flex space-x-4'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-sm font-medium text-baseColor'}>Bước 5: Thiết lập mật khẩu</span>
              </div>
              <div className={'flex-2 mt-6 flex space-x-4'}>
                <div className={classNames('mx-auto w-[624px] ', isMobile ? 'h-[220px]' : 'h-[380px]')}>
                  <img src={Register4} alt="" />
                </div>
              </div>

              <div className={'flex-2 mt-6 flex space-x-4'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-sm font-medium text-baseColor'}>Giao diện đăng ký thành công</span>
              </div>
              <div className={'flex-2 mt-6 flex space-x-4'}>
                <div className={classNames('mx-auto w-[624px] ', isMobile ? 'h-[220px]' : 'h-[380px]')}>
                  <img src={Register5} alt="" />
                </div>
              </div>
              <h2 className={'mt-6 text-[18px] font-bold leading-[28px] text-[#F15836]'}>
                2. Hướng dẫn đăng nhập tài khoản
              </h2>

              <div className={'flex-2 mt-6 flex space-x-4'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-sm font-medium text-baseColor'}>
                  Bước 1: Truy cập Website <a href={'https://shop360.vn'}>https://shop360.vn</a>
                </span>
              </div>
              <div className={'flex-2 mt-6 flex space-x-4'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-sm font-medium text-baseColor'}>Bước 2: Nhấp vào phần đăng nhập</span>
              </div>
              <div className={`flex-2 mt-6 flex space-x-4`}>
                <div className={classNames('mx-auto w-[624px] ', isMobile ? 'h-[160px]' : 'h-[240px]')}>
                  <img src={Login1} alt="" />
                </div>
                <div className={classNames('mx-auto w-[624px] ', isMobile ? 'h-[160px]' : 'h-[240px]')}>
                  <img src={Login2} alt="" />
                </div>
              </div>

              <div className={'flex-2 mt-6 flex space-x-4'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-sm font-medium text-baseColor'}>Bước 3: Nhập số điện thoại và mật khẩu</span>
              </div>
              <div className={'flex-2 mt-6 flex space-x-4'}>
                <svg
                  className={'min-h-[20px] min-w-[20px]'}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="20" height="20" rx="10" fill="#F15836" />
                  <path
                    d="M13.4937 6.91074C13.8383 6.5853 14.397 6.5853 14.7416 6.91074C15.0861 7.23618 15.0861 7.76382 14.7416 8.08925L9.44745 13.0893C9.10287 13.4147 8.54419 13.4147 8.19961 13.0893L5.25844 10.3115C4.91385 9.98604 4.91385 9.4584 5.25844 9.13296C5.60302 8.80753 6.16169 8.80753 6.50627 9.13296L8.82353 11.3215L13.4937 6.91074Z"
                    fill="white"
                  />
                </svg>
                <span className={'text-sm font-medium text-baseColor'}>Bước 4 Đăng nhập vào hệ thống</span>
              </div>
              <div className={'flex-2 mt-6 flex space-x-4'}>
                <div className={classNames('mx-auto w-[624px] ', isMobile ? 'h-[220px]' : 'h-[380px]')}>
                  <img src={Login3} alt="" />
                </div>
              </div>

              <h2 className={'mt-6 text-[18px] font-bold leading-[28px] text-[#F15836]'}>
                3. Giao diện kinh doanh của Shop360
              </h2>
              <p className={'text-base font-medium text-baseColor'}>Các thông tin trong giao diện bao gồm:</p>
              <p className={'text-sm font-bold'}>A. Chính</p>
              <p className={'text-sm font-medium text-baseColor'}>1. Bảng báo cáo</p>
              <p className={'text-sm font-medium text-baseColor'}>2. Tài khoản</p>
              <p className={'text-sm font-medium text-baseColor'}>3. Hỗ trợ</p>
              <p className={'text-sm font-bold'}>B. Sản phẩm</p>
              <p className={'text-sm font-medium text-baseColor'}>1. Danh mục tổng hợp sản phẩm</p>
              <p className={'text-sm font-medium text-baseColor'}>2. Sim số</p>
              <p className={'text-sm font-medium text-baseColor'}>3. Gói cước</p>
              <p className={'text-sm font-bold'}>C. Báo cáo thống kê</p>
              <p className={'text-sm font-medium text-baseColor'}>1. Danh sách giao dịch</p>
              <div className={'flex-2 mt-6 flex space-x-4'}>
                <div className={classNames('mx-auto w-[624px] ', isMobile ? 'h-[250px]' : 'h-[480px]')}>
                  <img src={Display1} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterMobi />
    </>
  );
};
