import { DetailedHTMLProps, InputHTMLAttributes, useState } from 'react';
import { classNames } from '../../../utils/utils';

interface ITextForm extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  name?: string;
  label?: string;
  placeholder: string;
  isRequired?: boolean;
  value?: string;
  setValue?: (value: string) => void;
  register?: any;
  required?: any;
  control?: any;
}

export const TextForm = ({
  name,
  placeholder,
  className,
  label,
  register,
  control,
  required,
  isRequired,
  value,
  setValue,
  ...otherProps
}: ITextForm) => {
  const [isFocus, setIsFocus] = useState<boolean>(false);

  return (
    <div className={'flex flex-col space-y-2'}>
      {label && (
        <label className={'text-sm font-bold text-baseColor'}>
          {label}
          {isRequired && <label className={'ml-1 text-[15px] font-semibold leading-[20px] text-[#FC5555]'}>*</label>}
        </label>
      )}
      <input
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue && setValue(e.target.value)}
        className={classNames(
          className,
          'rounded-t-[10px] rounded-bl-[10px] border border-[#EFEFEF] bg-white p-2 text-sm font-normal shadow-base' +
            ' placeholder:text-[#A9A9A9] focus:outline-none',
          isFocus ? ' !border-[#4568DA]' : '!border-[#E5E7EB]'
        )}
        {...(register && { ...register(name, { ...required }) })}
        {...otherProps}
        onFocus={() => setIsFocus(true)}
        onBlur={() => {
          setTimeout(() => setIsFocus(false), 100);
        }}
      />
    </div>
  );
};
