export const FilterItem = ({ label, onClear }: any) => {
  return (
    <div
      className={
        'h-[35px] w-fit rounded-t-[12px] rounded-bl-[12px] border-[2px] border-[#FF9E6E] bg-[#FFF3EC] py-1 px-3' +
        ' flex flex-row items-center space-x-4 text-sm font-medium text-baseColor'
      }
    >
      {label}
      <svg
        onClick={() => onClear()}
        className={'ml-2 cursor-pointer'}
        width="15"
        height="16"
        viewBox="0 0 15 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_2500_5658)">
          <path
            d="M0.314708 0.916271C0.414214 0.816512 0.532424 0.737365 0.662566 0.683362C0.792707 0.629359 0.932225 0.601562 1.07313 0.601562C1.21403 0.601562 1.35354 0.629359 1.48369 0.683362C1.61383 0.737365 1.73204 0.816512 1.83154 0.916271L7.5004 6.58727L13.1692 0.916271C13.2688 0.816674 13.3871 0.737669 13.5172 0.683768C13.6473 0.629866 13.7868 0.602123 13.9277 0.602123C14.0685 0.602123 14.208 0.629866 14.3381 0.683768C14.4682 0.737669 14.5865 0.816674 14.6861 0.916271C14.7857 1.01587 14.8647 1.13411 14.9186 1.26424C14.9725 1.39436 15.0002 1.53384 15.0002 1.67469C15.0002 1.81554 14.9725 1.95501 14.9186 2.08514C14.8647 2.21527 14.7857 2.33351 14.6861 2.43311L9.01509 8.10196L14.6861 13.7708C14.7857 13.8704 14.8647 13.9886 14.9186 14.1188C14.9725 14.2489 15.0002 14.3884 15.0002 14.5292C15.0002 14.6701 14.9725 14.8096 14.9186 14.9397C14.8647 15.0698 14.7857 15.188 14.6861 15.2876C14.5865 15.3872 14.4682 15.4662 14.3381 15.5201C14.208 15.574 14.0685 15.6018 13.9277 15.6018C13.7868 15.6018 13.6473 15.574 13.5172 15.5201C13.3871 15.4662 13.2688 15.3872 13.1692 15.2876L7.5004 9.61665L1.83154 15.2876C1.73195 15.3872 1.61371 15.4662 1.48358 15.5201C1.35345 15.574 1.21398 15.6018 1.07313 15.6018C0.932275 15.6018 0.792802 15.574 0.662673 15.5201C0.532543 15.4662 0.414305 15.3872 0.314708 15.2876C0.215111 15.188 0.136106 15.0698 0.0822051 14.9397C0.0283036 14.8096 0.000560851 14.6701 0.000560851 14.5292C0.000560851 14.3884 0.0283036 14.2489 0.0822051 14.1188C0.136106 13.9886 0.215111 13.8704 0.314708 13.7708L5.9857 8.10196L0.314708 2.43311C0.21495 2.3336 0.135802 2.21539 0.0817995 2.08525C0.0277966 1.95511 0 1.81559 0 1.67469C0 1.53379 0.0277966 1.39427 0.0817995 1.26413C0.135802 1.13399 0.21495 1.01578 0.314708 0.916271Z"
            fill="#A9A9A9"
          />
        </g>
        <defs>
          <clipPath id="clip0_2500_5658">
            <rect width="15" height="15" fill="white" transform="translate(0 0.601562)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
