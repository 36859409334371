import { Disclosure } from '@headlessui/react';
import { LogoHorizontal, LogoWhite } from 'assets/images';
import { Link, NavLink } from 'react-router-dom';
import { FacebookIcon, TiktokIcon, ZaloIcon } from '../../assets/images/main';
import { BCTIcon } from './image/index';
import useCheckDeviceMobi from 'hooks/useCheckDeviceMobi';

export const FooterMobi = () => {
  const isMobile = useCheckDeviceMobi();

  return (
    <footer className={'t-0 relative w-full overflow-hidden md:h-[500px]'}>
      <div
        className={
          "h-full w-full bg-[#f8f8f8] bg-gradient-to-r from-[#F15836] to-[#FF924F] md:bg-[url('assets/images/main/footer.svg')] md:bg-cover" +
          ' md:bg-center md:bg-no-repeat'
        }
      >
        <div className={'mx-auto flex h-full max-w-[85rem] flex-col md:flex-row md:justify-center md:pt-20'}>
          <div className={'flex flex-col justify-start p-4 md:w-1/3 '}>
            {/*<img className="mb-2 h-[95px] w-[95px] object-contain" src={LogoWhite} alt="Logo" />*/}
            <h2 className={'text-sm font-bold uppercase text-white'}>
              CÔNG TY CỔ PHẦN CÔNG NGHỆ PHẦN MỀM VÀ NỘI DUNG SỐ OSP
            </h2>
            {/* <h3 className={'text-sm font-bold text-white'}>Một sản phẩm của Công ty CP công nghệ phần mềm và nội dung số OSP</h3> */}
            <ul className="list-disc pl-4 text-white">
              <li>
                {' '}
                <p className={'mt-2 text-sm font-normal text-white'}>
                  {' '}
                  <span className={'font-medium'}>MST</span>: 0102886901 do Sở kế hoạch và Đầu tư thành phố Hà Nội cấp
                  lần đầu ngày 22/08/2008.
                </p>
              </li>
              <li>
                <p className={'mt-2 text-sm font-normal text-white'}>
                  <span className={'font-medium'}>Địa chỉ</span>: Tầng 4, Tòa nhà The Garden Hill, số 99 Trần Bình,
                  phường Mỹ Đình 2, quận Nam Từ Liêm, Thành phố Hà Nội, Việt Nam.
                </p>
              </li>
              <li>
                {' '}
                <p className={'mt-2 text-sm font-normal text-white'}>
                  {' '}
                  <span className={'font-medium'}>Hotline</span>: 0896.686.686{' '}
                </p>
              </li>
              <li>
                {' '}
                <p className={'mt-2 text-sm font-normal text-white'}>
                  {' '}
                  <span className={'font-medium'}>Email</span>:{' '}
                  <a href="mailto:shop360.cskh@osp.vn" className="hover:text-blue-500">
                    shop360.cskh@osp.vn
                  </a>{' '}
                </p>
              </li>
            </ul>

            {/* <p className={'text-[13.3px] leading-[24px] text-[#333333]'}>
                    <span className={'font-medium'}>MST</span>: 0102886901
                  </p>
                  <p className={'text-[13.3px] leading-[24px] text-[#333333]'}>
                  <span className={'font-medium'}>Địa chỉ</span>: Tầng 4, Toà Garden Hill, số 99, Trần Bình, Nam Từ Liêm, Hà Nội.
                  </p>
                  <p className={'text-[13.3px] leading-[24px] text-[#333333]'}>
                    <span className={'font-medium'}>Hotline</span>: 0896.686.686
                  </p>
                  <p className={'mt-4 text-[12px] leading-[24px] text-[#333333]'}>
                    Copyright © Tập Đoàn công nghệ OSP
                  </p> */}
            <p className={'mt-4 text-sm font-bold text-white'}>
              Copyright © CÔNG TY CỔ PHẦN CÔNG NGHỆ PHẦN MỀM VÀ NỘI DUNG SỐ OSP
            </p>
          </div>
          <div className={'flex flex-col p-4 md:w-1/3 md:items-center '}>
            <h1 className={'text-xl font-bold uppercase leading-[24px] text-white'}>Thông tin</h1>
            <ul className="list-disc pl-4 text-white">
              <li>
                <p className={'mt-4 text-sm font-normal text-white hover:underline'}>
                  <Link to={'/plan'}>Giới thiệu</Link>
                </p>
              </li>
              <li>
                <Link to={'/hdsd'} className={'mt-4 text-sm font-normal text-white hover:underline'}>
                  Hướng dẫn sử dụng
                </Link>
              </li>
              <li>
                <Link to={'/instructions'} className={'mt-4 text-sm font-normal text-white hover:underline'}>
                  Hướng dẫn tham gia
                </Link>
              </li>
              <li>
                <Link to={'/transaction-terms'} className={'mt-4 text-sm font-normal text-white hover:underline'}>
                  Điều kiện giao dịch chung
                </Link>
              </li>
              <li>
                <Link to={'/delivery-policy'} className={'mt-4 text-sm font-normal text-white hover:underline'}>
                  Chính sách vận chuyển
                </Link>
              </li>
              <li>
                <Link to={'/payment-policy'} className={'mt-4 text-sm font-normal text-white hover:underline'}>
                  Chính sách thanh toán
                </Link>
              </li>
              <li>
                <Link to={'/privacy'} className={'mt-4 text-sm font-normal text-white hover:underline'}>
                  Chính sách bảo mật
                </Link>
              </li>
              <li>
                <Link to={'/questions'} className="mt-4 text-sm text-white hover:underline">
                  Câu hỏi thường gặp
                </Link>
              </li>
              <li>
                <Link to={'/product'} className="mt-4 text-sm text-white hover:underline">
                  Sản phẩm
                </Link>
              </li>
            </ul>
          </div>
          <div className={'flex flex-col items-start justify-start p-4 md:relative md:w-1/3 md:items-center'}>
            <div className={' h-fit'}>
              <h1 className={'text-xl font-bold uppercase leading-[24px] text-white'}>Liên hệ</h1>
              <ul className="list-disc pl-4 text-white">
                <li>
                  <p className={'mt-2 text-sm font-normal text-white'}>Website: Shop360.vn</p>
                </li>
                <li>
                  <p className={'mt-2 text-sm font-normal text-white'}>Hotline: 0896.686.686</p>
                </li>
                <li>
                  <p className={'mt-2 text-sm font-normal text-white'}>Email: shop360.cskh@osp.vn </p>
                </li>
              </ul>

              <div className={'mt-4 flex flex-row justify-start space-x-1'}>
                <a href="https://www.facebook.com/fanpageshop360" target="_blank" rel="noreferrer">
                  <img
                    src={FacebookIcon}
                    alt={'Facebook'}
                    className={`h-8 w-8 ${isMobile ? 'rounded-[10px] bg-white' : ''}`}
                  />
                </a>
                <a href="https://zalo.me/g/czjvyl860" target="_blank" rel="noreferrer">
                  <img src={ZaloIcon} alt={'Zalo'} className={`h-8 w-8 ${isMobile ? 'rounded-[10px] bg-white' : ''}`} />
                </a>
                {/* <a href="https://www.tiktok.com/@phapsukinhdoanh?is_from_webapp=1&sender_device=pc" target="_blank" >
                <img src={TiktokIcon} alt={'Tiktok'} className={'h-8 w-8'} />
              </a> */}
              </div>
              <div className={`mb-4 mt-4 flex flex-row space-x-1 ${isMobile ? 'justify-start' : 'justify-end'}`}>
                <a href="http://online.gov.vn/Home/WebDetails/114671">
                  <img src={BCTIcon} alt={'BCT'} className={'h-auto w-[170px]'} />
                </a>
              </div>
            </div>
            <div className={'col-span-2 h-fit'}>
              {/* <h2 className="mb-3 text-[18px] font-bold text-white">Ứng dụng di động</h2>
              <img src={AppIcon} alt={'Ứng dụng di động'} /> */}
            </div>
            <div className={'md:absolute md:bottom-4 md:left-0'}>
              <div
                className="fb-page"
                data-href="https://www.facebook.com/fanpageshop360"
                data-tabs="timeline"
                data-width=""
                data-height="130"
                data-small-header="false"
                data-adapt-container-width="true"
                data-hide-cover="false"
                data-show-facepile="true"
              >
                <blockquote cite="https://www.facebook.com/fanpageshop360" className="fb-xfbml-parse-ignore">
                  <a href="https://www.facebook.com/fanpageshop360"></a>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
