import { sendPostRequest } from './index';
import { apiDashboard } from './api/dashboard';

export const getDataDashboard = ({ username = 'admin', fromDate = '', toDate = '' }) => {
  return sendPostRequest(apiDashboard.getDataDashBoard, {
    username,
    fromDate,
    toDate,
  });
};
