import { classNames } from '../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { PATH_PAGE, QUERY_KEY } from '../../constant';
import { Fragment, useCallback, useState } from 'react';
import { checkReqPayment } from 'services/payment';
import toast from 'react-hot-toast';
import { Transition, Dialog } from '@headlessui/react';

export const PaymentCardInfo = ({ isLeftFuncRequest, accBalance }: any) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [messageError, setMessageError] = useState<{
    title: string;
    message: string;
    labelButton: string;
    href?: string;
  }>();
  const mutationCheckReqPayment = useMutation(QUERY_KEY.REQ_PAYMENT, () => checkReqPayment(), {
    onSuccess: (response) => {
      queryClient.setQueryData(QUERY_KEY.REQ_PAYMENT, response?.data?.data);
      if (response?.data?.code !== 1) {
        // toast.error(response?.data?.message);
        // 161 =  tài khoản chưa xác thực
        // 162 =  số dư chưa đủ
        // 163 =  giới hạn 1 lần 1 ngày
        // 164 =  thiếu thông tin ngân hàng
        if (response?.data?.code === 161) {
          setMessageError({
            title: 'Tài khoản chưa xác thực',
            message: 'Vui lòng bổ sung thông tin xác thực tài khoản để bắt đầu kinh doanh trên nền tảng Shop360!',
            labelButton: 'Xác thực tài khoản',
            href: PATH_PAGE.PROFILE,
          });
          return;
        } else if (response?.data?.code === 162) {
          toast.error('Số dư tài khoản của bạn chưa đủ giá trị tối thiểu 200.000 VNĐ để tạo yêu cầu rút tiền!');
          // setMessageError({
          //   title: 'Tài khoản chưa xác thực',
          //   message: response?.data?.message,
          //   labelButton: 'Đóng',
          // });
          return;
        } else if (response?.data?.code === 163) {
          setMessageError({
            title: 'Thông báo',
            message: 'Bạn không thể tạo yêu cầu thanh toán do giới hạn rút 1 lần/ngày. Vui lòng thử lại sau!',
            labelButton: 'Đóng',
          });
          return;
        } else if (response?.data?.code === 164) {
          setMessageError({
            title: 'Tài khoản chưa xác thực',
            message: response?.data?.message,
            labelButton: 'Đóng',
          });
          return;
        } else if (response?.data?.code === 165) {
          setMessageError({
            title: 'Thông báo',
            message: response?.data?.message,
            labelButton: 'Đóng',
          });
          return;
        }
        setMessageError({ title: 'Thông báo', message: response?.data?.message, labelButton: 'Đóng' });
        return;
      }
      navigate(PATH_PAGE.CREATE_PAYMENT);
    },
    onError: (error: any) => {
      toast.error(error.message);
    },
  });

  const onCheckReqPayment = useCallback(() => {
    mutationCheckReqPayment.mutate();
  }, []);

  return (
    <div
      className={classNames(
        'h-full w-full bg-gradient-to-r from-[#F15836] to-[#FF924F] shadow-base',
        isLeftFuncRequest ? 'rounded-t-[15px] rounded-bl-[15px]' : 'rounded-[15px]'
      )}
    >
      <div
        className={classNames(
          "relative flex flex-col justify-between rounded-[15px] bg-[url('assets/images/background-card-icon.svg')] bg-cover p-6",
          isLeftFuncRequest ? 'h-[148px]' : 'h-[200px] '
        )}
      >
        <label className={'text-base font-medium text-white'}>Số dư tài khoản</label>
        <div className={classNames('flex flex-row justify-between', isLeftFuncRequest ? '' : 'mt-9')}>
          <span className={classNames(' font-bold text-white', isLeftFuncRequest ? 'text-[26px]' : 'text-[35px]')}>
            {accBalance ? accBalance.toLocaleString('vi') : 0}
            <span className={' text-sm font-extrabold text-white'}> VNĐ</span>
          </span>
        </div>
        {/* <div className={classNames('flex h-fit w-full flex-row', isLeftFuncRequest ? 'justify-start' : 'justify-end')}>
          <button
            className={
              'flex h-[40px] w-[158px] flex-row items-center justify-center space-x-1 rounded-t-[12px] rounded-bl-[12px] border-2 border-white bg-transparent'
            }
            onClick={onCheckReqPayment}
          >
            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.09375 0H14.0938C17.4028 0 20.0938 2.691 20.0938 6V19C20.0938 19.2652 19.9884 19.5196 19.8009 19.7071C19.6133 19.8946 19.359 20 19.0938 20H6.09375C2.78475 20 0.09375 17.309 0.09375 14V6C0.09375 2.691 2.78475 0 6.09375 0ZM5.09375 11H9.09375V15H11.0938V11H15.0938V9H11.0938V5H9.09375V9H5.09375V11Z"
                fill="white"
              />
            </svg>
            <span className={'text-sm font-extrabold text-white'}>Yêu cầu rút tiền</span>
          </button>
        </div> */}
        <div className={'absolute right-6 top-7'}>
          <svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_634_33212)">
              <g clipPath="url(#clip1_634_33212)">
                <path
                  d="M0.929688 6.96457C0.929688 6.16433 1.24758 5.39687 1.81344 4.83101C2.37929 4.26516 3.14676 3.94727 3.947 3.94727H22.0508C22.8511 3.94727 23.6185 4.26516 24.1844 4.83101C24.7503 5.39687 25.0681 6.16433 25.0681 6.96457V14.5078H0.929688V6.96457ZM18.2792 8.47323C18.0791 8.47323 17.8873 8.5527 17.7458 8.69416C17.6044 8.83563 17.5249 9.02749 17.5249 9.22755V10.7362C17.5249 10.9363 17.6044 11.1281 17.7458 11.2696C17.8873 11.4111 18.0791 11.4905 18.2792 11.4905H21.2965C21.4966 11.4905 21.6884 11.4111 21.8299 11.2696C21.9714 11.1281 22.0508 10.9363 22.0508 10.7362V9.22755C22.0508 9.02749 21.9714 8.83563 21.8299 8.69416C21.6884 8.5527 21.4966 8.47323 21.2965 8.47323H18.2792ZM0.929688 17.5251V19.0338C0.929688 19.834 1.24758 20.6015 1.81344 21.1674C2.37929 21.7332 3.14676 22.0511 3.947 22.0511H22.0508C22.8511 22.0511 23.6185 21.7332 24.1844 21.1674C24.7503 20.6015 25.0681 19.834 25.0681 19.0338V17.5251H0.929688Z"
                  fill="white"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_634_33212">
                <rect width="25" height="26" fill="white" transform="translate(0.0664062 0.25)" />
              </clipPath>
              <clipPath id="clip1_634_33212">
                <rect width="25" height="26" fill="white" transform="translate(0 -0.101562)" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <Transition show={!!messageError} as={Fragment}>
        <Dialog
          open={!!messageError}
          onClose={() => {}}
          className="fixed inset-0 z-50 flex h-full min-h-screen w-screen flex-row items-center justify-center overflow-y-auto bg-[#000000]/60"
        >
          <Dialog.Panel
            className={
              'h-[324px] min-h-[324px] w-[400px] rounded-t-[16px] rounded-bl-[16px] border border-gray-200' +
              ' bg-[#F8F8F8] shadow-base'
            }
          >
            <Dialog.Title className={'mb-2 flex h-fit flex-col items-start px-6 pt-6'}>
              <div className={'flex h-fit w-full flex-row justify-end'}>
                <button type={'button'} onClick={() => setMessageError(undefined)}>
                  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_573_26413)">
                      <path
                        d="M0.314708 0.701427C0.414214 0.601669 0.532424 0.522521 0.662566 0.468518C0.792707 0.414515 0.932225 0.386719 1.07313 0.386719C1.21403 0.386719 1.35354 0.414515 1.48369 0.468518C1.61383 0.522521 1.73204 0.601669 1.83154 0.701427L7.5004 6.37242L13.1692 0.701427C13.2688 0.60183 13.3871 0.522825 13.5172 0.468924C13.6473 0.415022 13.7868 0.38728 13.9277 0.38728C14.0685 0.38728 14.208 0.415022 14.3381 0.468924C14.4682 0.522825 14.5865 0.60183 14.6861 0.701427C14.7857 0.801024 14.8647 0.919262 14.9186 1.04939C14.9725 1.17952 15.0002 1.31899 15.0002 1.45984C15.0002 1.6007 14.9725 1.74017 14.9186 1.8703C14.8647 2.00043 14.7857 2.11867 14.6861 2.21826L9.01509 7.88711L14.6861 13.556C14.7857 13.6556 14.8647 13.7738 14.9186 13.9039C14.9725 14.0341 15.0002 14.1735 15.0002 14.3144C15.0002 14.4552 14.9725 14.5947 14.9186 14.7248C14.8647 14.855 14.7857 14.9732 14.6861 15.0728C14.5865 15.1724 14.4682 15.2514 14.3381 15.3053C14.208 15.3592 14.0685 15.3869 13.9277 15.3869C13.7868 15.3869 13.6473 15.3592 13.5172 15.3053C13.3871 15.2514 13.2688 15.1724 13.1692 15.0728L7.5004 9.40181L1.83154 15.0728C1.73195 15.1724 1.61371 15.2514 1.48358 15.3053C1.35345 15.3592 1.21398 15.3869 1.07313 15.3869C0.932275 15.3869 0.792802 15.3592 0.662673 15.3053C0.532543 15.2514 0.414305 15.1724 0.314708 15.0728C0.215111 14.9732 0.136106 14.855 0.0822051 14.7248C0.0283036 14.5947 0.000560851 14.4552 0.000560851 14.3144C0.000560851 14.1735 0.0283036 14.0341 0.0822051 13.9039C0.136106 13.7738 0.215111 13.6556 0.314708 13.556L5.9857 7.88711L0.314708 2.21826C0.21495 2.11876 0.135802 2.00055 0.0817995 1.87041C0.0277966 1.74026 0 1.60075 0 1.45984C0 1.31894 0.0277966 1.17943 0.0817995 1.04928C0.135802 0.919142 0.21495 0.800933 0.314708 0.701427Z"
                        fill="#A9A9A9"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_573_26413">
                        <rect width="15" height="15" fill="white" transform="translate(0 0.386719)" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </div>
              <div className={'mb-4 flex h-fit w-full flex-1 items-center justify-center'}>
                <svg
                  className={'text-center'}
                  width="70"
                  height="69"
                  viewBox="0 0 70 69"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_692_11251)">
                    <path
                      d="M34.0951 67.9994C35.2927 67.9676 36.4419 67.4483 37.3395 66.5334C38.2372 65.6185 38.8253 64.3669 39 63H29C29.1796 64.404 29.7953 65.685 30.7325 66.6046C31.6697 67.5243 32.8647 68.0199 34.0951 67.9994Z"
                      fill="#A9A9A9"
                    />
                    <path
                      d="M64 55.3938L63.3154 54.7974C61.3734 53.0889 59.6735 51.1297 58.2617 48.9726C56.7199 45.9956 55.7958 42.7446 55.5436 39.4103V29.5895C55.5354 28.3966 55.4277 27.2064 55.2215 26.031C51.811 25.3389 48.7475 23.5043 46.5497 20.8379C44.3519 18.1715 43.1548 14.8372 43.1611 11.3993V10.1469C41.0589 9.12541 38.8003 8.45371 36.4765 8.15887V5.65398C36.4765 4.9501 36.1933 4.27505 35.6892 3.77733C35.1852 3.27962 34.5015 3 33.7886 3C33.0757 3 32.392 3.27962 31.8879 3.77733C31.3839 4.27505 31.1007 4.9501 31.1007 5.65398V8.25827C25.8974 8.983 21.1354 11.5419 17.6937 15.4627C14.2521 19.3834 12.3632 24.4011 12.3758 29.5895V39.4103C12.1237 42.7446 11.1996 45.9956 9.65772 48.9726C8.27023 51.1243 6.59771 53.0833 4.68456 54.7974L4 55.3938V61H64V55.3938Z"
                      fill="#A9A9A9"
                    />
                    <path
                      d="M57.4987 1.08203C51.7456 1.08203 47.082 5.74557 47.082 11.4987C47.082 17.2518 51.7456 21.9154 57.4987 21.9154C63.2518 21.9154 67.9154 17.2518 67.9154 11.4987C67.9154 5.74557 63.2518 1.08203 57.4987 1.08203ZM58.5404 16.707H56.457V14.6237H58.5404V16.707ZM58.5404 12.5404H56.457L55.9362 6.29037H59.0612L58.5404 12.5404Z"
                      fill="url(#paint0_linear_692_11251)"
                    />
                  </g>
                  <defs>
                    <linearGradient
                      id="paint0_linear_692_11251"
                      x1="47.082"
                      y1="11.4987"
                      x2="67.9154"
                      y2="11.4987"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#F15836" />
                      <stop offset="1" stopColor="#FF924F" />
                    </linearGradient>
                    <clipPath id="clip0_692_11251">
                      <rect width="70" height="70" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </Dialog.Title>
            <Dialog.Description className={'flex h-full w-full flex-col items-center px-6 pb-6'}>
              <h2 className={'mb-4 text-[22px] font-extrabold leading-[26px] text-baseColor'}>{messageError?.title}</h2>
              <p className={'text-center text-sm font-medium text-[#818181]'}>{messageError?.message}</p>
              <div
                className={
                  'h-[40px] w-full rounded-t-[8px] rounded-bl-[8px] bg-gradient-to-r from-[#F15836]' +
                  ' mt-6 to-[#FF924F] p-[2px]'
                }
              >
                <button
                  // className={'h-full w-full rounded-t-[6px] rounded-bl-[6px] bg-white'}
                  className={'h-full w-full rounded-t-[6px] rounded-bl-[6px]'}
                  onClick={() => {
                    if (messageError?.href) {
                      navigate(messageError.href);
                      return;
                    }
                    setMessageError(undefined);
                  }}
                >
                  {/* <span
                    className={
                      'bg-gradient-to-r from-[#F15836]' +
                      ' to-[#FF924F] bg-clip-text text-sm font-extrabold text-transparent'
                    }
                  > */}
                  <span className={'text-sm font-extrabold text-white'}>{messageError?.labelButton}</span>
                </button>
              </div>
            </Dialog.Description>
          </Dialog.Panel>
        </Dialog>
      </Transition>
    </div>
  );
};
