import logo from '../../assets/images/header/Logo.png';

export const FooterProducts = () => {
  return (
    <>
      <footer
        className={
          'hidden h-[200px] flex-row items-center justify-start bg-gradient-to-r from-[#F15836] md:flex' +
          ' to-[#FF924F]' +
          ' sm:mt-auto'
        }
      >
        <div className={'mx-auto flex w-[61rem] flex-row items-center justify-start'}>
          <img className="h-[56px] w-[56px] object-contain" src={logo} alt="Logo" />
          <div className="ml-[100px] flex h-[162px] w-fit flex-col items-start justify-center py-4">
            <p className={'text-base font-bold text-white'}>
              Một sản phẩm của Công ty CP công nghệ phần mềm và nội dung số OSP
            </p>
            <p className={'mt-4 text-sm font-medium text-white'}>
              Địa chỉ: Tầng 4, Tòa nhà The Garden Hill, 99 Trần Bình, Mỹ Đình 2, Nam Từ Liêm, Hà Nội.
            </p>
            <p className={'mt-2 text-sm font-medium text-white'}>Hotline: 0896.686.686</p>
            <p className={'mt-4 text-sm font-medium text-white'}>
              Copyright © CÔNG TY CỔ PHẦN CÔNG NGHỆ PHẦN MỀM VÀ NỘI DUNG SỐ OSP
            </p>
          </div>
        </div>
      </footer>
      <footer
        className={
          'flex flex-col items-center justify-center bg-gradient-to-r from-[#F15836] to-[#FF924F] md:hidden' +
          ' sm:mt-auto'
        }
      >
        <div className="flex h-[210px] w-full max-w-[85rem] flex-col items-center justify-center px-3 py-3 md:h-[162px] md:px-0 md:py-4">
          <p className={'text-center text-sm font-bold text-white md:text-base'}>
            Một sản phẩm của Công ty CP công nghệ phần mềm và nội dung số OSP
          </p>
          <p className={'mt-5 text-center text-sm font-medium text-white'}>
            Địa chỉ: The Garden Hill, số 99 Trần Bình, Nam Từ Liêm, Hà Nội
          </p>
          <p className={'text-center text-sm font-medium text-white md:mt-2'}>Hotline: 0896.686.686</p>
          <p className={'mt-2 text-center text-sm font-medium text-white md:mt-4'}>
            Copyright © CÔNG TY CỔ PHẦN CÔNG NGHỆ PHẦN MỀM VÀ NỘI DUNG SỐ OSP
          </p>
        </div>
      </footer>
    </>
  );
};
