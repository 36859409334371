import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { classNames } from '../../utils/utils';
import { PATH_PAGE } from '../../constant';
import { HeaderCommon } from '../../components/header';
import Scrollbars from 'react-custom-scrollbars';
import { FooterProducts } from '../../components/footer/FooterProducts';
import useCheckDeviceMobi from 'hooks/useCheckDeviceMobi';

export const Products = ({ children, name }: any) => {
  const navigate = useNavigate();
  const isMobile = useCheckDeviceMobi();
  const [params, setParams] = useSearchParams();
  const isSuggesting = params.get('suggest') === 'true';
  return (
    <>
      <div className={'h-fit w-full bg-[#F8F8F8] pb-6 pl-6 pt-6 md:h-full md:rounded-[20px]'}>
        <HeaderCommon
          children={
            <div className={'flex h-full flex-col -space-y-2 ' + isSuggesting ? 'justify-center' : 'justify-end'}>
              <span className={'text-[22px] font-extrabold text-[#404040] max-[896px]:hidden'}>
                {isSuggesting ? 'Danh sách gói cước gợi ý' : 'Danh sách sản phẩm'}
              </span>
              {!isSuggesting ? (
                <>
                  <ul className="flex flex-wrap space-x-6 text-center text-base font-bold text-white max-[896px]:justify-center max-[896px]:space-x-3 max-[896px]:pb-2">
                    {/* <li className={'cursor-pointer'} onClick={() => navigate(PATH_PAGE.PRODUCTS_CATEGORY)}>
                      <span
                        className={classNames(
                          name === PATH_PAGE.PRODUCTS_CATEGORY
                            ? 'border-b-4 border-[#F15836] font-bold text-[#F15836]'
                            : 'font-medium text-[#818181]',
                          'mt-2.5 inline-block rounded-t-[10px] py-1 pb-2 text-sm hover:text-[#F15836]'
                        )}
                      >
                        Danh mục
                      </span>
                    </li>
                    <li className={'cursor-pointer'} onClick={() => navigate(PATH_PAGE.PRODUCTS_SIM)}>
                      <span
                        className={classNames(
                          name === PATH_PAGE.PRODUCTS_SIM
                            ? 'border-b-4 border-[#F15836] font-bold text-[#F15836]'
                            : 'font-medium text-[#818181]',
                          'mt-2.5 inline-block rounded-t-[10px] py-1 pb-2 text-sm hover:text-[#F15836]'
                        )}
                      >
                        Sim số
                      </span>
                    </li> */}
                    <li className={'cursor-pointer'} onClick={() => navigate(PATH_PAGE.PRODUCTS_SERVICE_PLAN)}>
                      <span
                        className={classNames(
                          name === PATH_PAGE.PRODUCTS_SERVICE_PLAN
                            ? 'border-b-4 border-[#F15836] font-bold text-[#F15836]'
                            : 'font-medium text-[#818181]',
                          'mt-2.5 inline-block rounded-t-[10px] py-1 pb-2 text-sm hover:text-[#F15836]'
                        )}
                      >
                        Gói cước
                      </span>
                    </li>
                  </ul>
                </>
              ) : (
                <></>
              )}
            </div>
          }
        />
        {!isMobile && (
          <>
            <div className={'h-[calc(100%-90px)] w-full'}>
              <Scrollbars className={'relative h-full w-full'}>
                <div className={'flex h-full flex-col pb-4 pr-7'}>{children}</div>
              </Scrollbars>
            </div>
          </>
        )}

        {isMobile && (
          <>
            <div className={'mt-[40px] pt-3'}>
              <div
                className={
                  'mb-2 flex cursor-pointer flex-row space-x-2 text-[12px] font-medium leading-[16px] text-[#71717A]' +
                  ' md:hidden'
                }
              >
                <NavLink to={PATH_PAGE.DASHBOARD}>Trang chủ</NavLink>
                <span>|</span>
                <span>Sản phẩm</span>
                <span>|</span>
                <span>
                  {name === PATH_PAGE.PRODUCTS_CATEGORY
                    ? 'Danh mục'
                    : name === PATH_PAGE.PRODUCTS_SIM
                    ? 'Sim số'
                    : 'Gói cước'}
                </span>
              </div>
              <div className={'flex h-full flex-col justify-end space-y-2'}>
                <span className={'text-[18px] font-bold text-[#404040]'}>Danh sách sản phẩm</span>
                <ul className="grid grid-cols-3 pr-4 text-center text-base font-bold text-white">
                  {/* <li className={'w-full cursor-pointer'} onClick={() => navigate(PATH_PAGE.PRODUCTS_CATEGORY)}>
                    <span
                      className={classNames(
                        name === PATH_PAGE.PRODUCTS_CATEGORY
                          ? 'border-[#F15836] font-bold text-[#F15836]'
                          : 'border-[#D6D5D5] font-medium text-[#818181]',
                        'font-base inline-block w-full rounded-t-[10px] border-b-4 py-1 pb-2 hover:text-[#F15836]'
                      )}
                    >
                      Danh mục
                    </span>
                  </li>
                  <li className={'w-full cursor-pointer'} onClick={() => navigate(PATH_PAGE.PRODUCTS_SIM)}>
                    <span
                      className={classNames(
                        name === PATH_PAGE.PRODUCTS_SIM
                          ? 'border-[#F15836] font-bold text-[#F15836]'
                          : 'border-[#D6D5D5] font-medium text-[#818181]',
                        'font-base inline-block w-full rounded-t-[10px] border-b-4 py-1 pb-2 hover:text-[#F15836]'
                      )}
                    >
                      Sim số
                    </span>
                  </li> */}
                  <li className={'w-full cursor-pointer'} onClick={() => navigate(PATH_PAGE.PRODUCTS_SERVICE_PLAN)}>
                    <span
                      className={classNames(
                        name === PATH_PAGE.PRODUCTS_SERVICE_PLAN
                          ? 'border-[#F15836] font-bold text-[#F15836]'
                          : 'border-[#D6D5D5] font-medium text-[#818181]',
                        'font-base inline-block w-full rounded-t-[10px] border-b-4 py-1 pb-2 hover:text-[#F15836]'
                      )}
                    >
                      Gói cước
                    </span>
                  </li>
                </ul>
              </div>

              <span
                className={
                  'hidden cursor-pointer flex-row text-[22px] font-extrabold leading-[26px] text-baseColor md:flex'
                }
                onClick={() => navigate('/')}
              >
                <svg
                  className={'mr-2'}
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.82103 11.3955L13.9985 4.2165C14.2889 3.92609 14.6828 3.76294 15.0935 3.76294C15.5042 3.76294 15.8981 3.92609 16.1885 4.2165C16.4789 4.50692 16.6421 4.9008 16.6421 5.3115C16.6421 5.72221 16.4789 6.11609 16.1885 6.4065L10.091 12.501L16.1885 18.594C16.4787 18.8844 16.6417 19.2782 16.6416 19.6888C16.6415 19.8921 16.6014 20.0934 16.5235 20.2812C16.4457 20.4689 16.3316 20.6396 16.1878 20.7833C16.044 20.927 15.8733 21.0409 15.6854 21.1187C15.4976 21.1964 15.2963 21.2364 15.093 21.2363C14.6824 21.2361 14.2887 21.0729 13.9985 20.7825L6.82103 13.605C6.52819 13.3119 6.36369 12.9146 6.36369 12.5003C6.36369 12.0859 6.52819 11.6886 6.82103 11.3955Z"
                    fill="#6A6A6A"
                  />
                </svg>
                Danh sách sim số
              </span>
            </div>
            <div className={'pb-12 pr-7 md:hidden'}>{children}</div>
            <div className={'-ml-7 md:hidden'}>
              <FooterProducts />
            </div>
          </>
        )}
      </div>
    </>
  );
};
