import { useContext, useEffect, useState } from 'react';
import ArrowBack from 'assets/images/arrow-back-ios.svg';
import { TIME_COUNTDOWN_RESEND, TIME_COUNTDOWN_VERIFY } from '../../constant';
import { classNames, generateRecaptcha, minTwoDigits, secondsToHms } from 'utils/utils';
import { RegisterContext } from 'pages/resigter';
import VerifyOTP from 'components/common/otp/VerifyOTP';
import { ButtonCustom } from 'components/common/ButtonCustom';
import { signInWithPhoneNumber } from 'firebase/auth';
import { authenticationFirebase } from '../../configs/firebase';
import { Stepper } from '../common/Stepper';
import useCheckDeviceMobi from '../../hooks/useCheckDeviceMobi';
import { ArrowBackMobile } from '../../assets/images';
import { useMutation } from 'react-query';
import { confirmOPT, confirmOPTParam, getOPT, getOPTParam } from 'services/otp';
import toast from 'react-hot-toast';

export const StepVerifyOTP = () => {
  const { activeStepIndex, setActiveStepIndex, phoneNumber, confirmationResult, setConfirmationResult } =
    useContext(RegisterContext);

  const isMobile = useCheckDeviceMobi();
  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [countdown, setCountdown] = useState<number>(TIME_COUNTDOWN_RESEND);
  const [countdownVerify, setCountdownVerify] = useState<number>(TIME_COUNTDOWN_VERIFY);
  const [isErrorOTP, setIsErrorOTP] = useState<boolean>(false);

  useEffect(() => {
    if (!countdown) {
      return;
    }
    const intervalId = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [countdown, setCountdown]);

  useEffect(() => {
    if (!countdownVerify) {
      return;
    }
    const intervalId = setInterval(() => {
      setCountdownVerify(countdownVerify - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [countdownVerify, setCountdownVerify]);

  const handleChange = (_value: any, index: number) => {
    setOtp([...otp.map((_item: any, idx: number) => (idx === index ? _value : _item))]);
    isErrorOTP && setIsErrorOTP(false);
  };

  const sendOTP = useMutation((param: getOPTParam) => getOPT(param), {
    onSuccess: (response) => {
      if (response.data?.code===1) {
        toast.success(response?.data?.message || 'Gửi OTP thành công');
        return;
      }
      toast.error(response?.data?.message || 'Gửi OTP thất bại');
    },
    onError: (error:any) => {
      toast.error(error?.message || 'Gửi OTP thất bại');
    },
  });

  const confirmOPTFunc = useMutation((param: confirmOPTParam) => confirmOPT(param), {
    onSuccess: (response) => {
      if (response.data?.code===1) {
        setActiveStepIndex(activeStepIndex + 1);
        return;
      }
      setIsErrorOTP(true);
    },
    onError: (error:any) => {
      setIsErrorOTP(true);
    },
  });

  const submitData = () => {
    // confirmationResult
    //   .confirm(otp.join(''))
    //   .then((result: any) => {
    //     setActiveStepIndex(activeStepIndex + 1);
    //   })
    //   .catch((error: any) => {
    //     setIsErrorOTP(true);
    //   });
    confirmOPTFunc.mutate({
      msisdn: phoneNumber ? phoneNumber?.toString() : '',
      type: 2,
      otp: otp.join(''),
    })
  };

  const resendOTP = () => {
    setOtp(new Array(6).fill(''));
    setCountdownVerify(TIME_COUNTDOWN_VERIFY);
    setCountdown(TIME_COUNTDOWN_RESEND);
    // const verify = generateRecaptcha();
    // signInWithPhoneNumber(authenticationFirebase, `+84${phoneNumber}`, verify)
    //   .then((result: any) => {
    //     setConfirmationResult(result);
    //   })
    //   .catch((error) => {
    //     setIsErrorOTP(true);
    //   });
    sendOTP.mutate({
      msisdn: phoneNumber ? phoneNumber?.toString() : '',
      type: 2
    });
  };

  return (
    <>
      {!isMobile ? (
        <div className={'mt-16 flex w-full flex-col sm:flex-row sm:space-x-4 sm:px-10'}>
          <div className={'w-full sm:w-6/12'}>
            <Stepper />
            <div className={'flex items-center'}>
              <a className={'mr-[24px]'} href={'/register'}>
                <img className="h-[24px] w-[24px]" src={ArrowBack} alt="Logo" />
              </a>
              <h1 className={' text-[35px] font-extrabold leading-[50px] text-white'}>Xác minh SĐT</h1>
            </div>
            <h2 className={'mt-4  text-[18px] font-medium leading-[28px] text-white'}>
              {`Mã xác minh của bạn sẽ được gửi đến số thuê bao 0${phoneNumber}`}
            </h2>
          </div>
          <div className={'w-full sm:w-6/12'}>
            <form>
              <div
                className={
                  'flex min-h-[280px] w-[500px] flex-col rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] p-[31px] shadow-base'
                }
              >
                <VerifyOTP handleChange={handleChange} isErrorOTP={isErrorOTP} otp={otp} />
                {isErrorOTP ? (
                  <div className={'mt-[6px] mb-[10px] flex w-full flex-col items-start'}>
                    <p className="text-sm font-medium text-[#FF7575]">Mã xác minh không đúng hoặc hết hạn</p>
                  </div>
                ) : (
                  <div
                    className={classNames(
                      'mt-4 flex w-full flex-col',
                      countdownVerify === 0 ? 'items-start' : 'items-center'
                    )}
                  >
                    <p
                      className={classNames(
                        'text-sm font-medium leading-[20px]',
                        countdownVerify === 0 ? 'text-[#FF7575]' : 'text-gray-400'
                      )}
                    >
                      {countdownVerify === 0 ? (
                        'Mã xác minh không đúng hoặc hết hạn'
                      ) : (
                        <>
                          Mã xác minh hết hạn sau{' '}
                          <span className="text-[#F15836]">{secondsToHms(countdownVerify)}</span>
                        </>
                      )}
                    </p>
                  </div>
                )}
                <ButtonCustom
                  label={'Tiếp tục'}
                  type={'button'}
                  className={'mt-4 w-full uppercase'}
                  onClick={() => submitData()}
                />
                <div className={'mt-6 flex w-full flex-col items-center'}>
                  <div id={'recaptcha-container'} />
                  <p className={'text-sm font-medium leading-[20px] text-gray-400'}>
                    Bạn chưa nhận được mã?{' '}
                    <button
                      type="button"
                      className={'h-fit w-fit !border-0 !bg-transparent text-gray-600'}
                      aria-disabled={countdown > 0}
                      disabled={countdown > 0}
                      onClick={() => !countdown && resendOTP()}
                    >
                      <span className="font-bold text-[#F15836]">
                        GỬI LẠI{' '}
                        {countdown ? (
                          <>
                            (<span className="font-medium text-[#F15836]">{minTwoDigits(countdown)}s</span>)
                          </>
                        ) : (
                          ''
                        )}
                      </span>
                    </button>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className={'mt-4 w-full px-2'}>
          <Stepper />
          <div className={'flex items-center'}>
            <a className={'mr-[24px]'} href={'/register'}>
              <img className="h-[24px] w-[24px] fill-black" src={ArrowBackMobile} alt="Logo" />
            </a>
            <h1 className={' text-[18px] font-extrabold leading-[28px] text-[#404040]'}>Xác minh số điện thoại</h1>
          </div>
          <h2 className={'my-4 text-[14px] font-medium leading-[20px] text-[#404040]'}>
            {`Mã xác minh của bạn sẽ được gửi qua tin nhắn SMS đến số điện thoại `}
            <span className={'font-semibold text-[#F15836]'}>0{phoneNumber}</span>
          </h2>
          <form>
            <div className={'flex w-full flex-col bg-[#F8F8F8]'}>
              <VerifyOTP handleChange={handleChange} isErrorOTP={isErrorOTP} otp={otp} />
              {isErrorOTP ? (
                <div className={'mt-[6px] mb-[10px] flex w-full flex-col items-start'}>
                  <p className="text-sm font-medium text-[#FF7575]">Mã xác minh không đúng hoặc hết hạn</p>
                </div>
              ) : (
                <div
                  className={classNames(
                    'mt-4 flex w-full flex-col',
                    countdownVerify === 0 ? 'items-start' : 'items-center'
                  )}
                >
                  <p
                    className={classNames(
                      'text-sm font-medium leading-[20px]',
                      countdownVerify === 0 ? 'text-[#FF7575]' : 'text-gray-400'
                    )}
                  >
                    {countdownVerify === 0 ? (
                      'Mã xác minh không đúng hoặc hết hạn'
                    ) : (
                      <>
                        Mã xác minh hết hạn sau <span className="text-[#F15836]">{secondsToHms(countdownVerify)}</span>
                      </>
                    )}
                  </p>
                </div>
              )}
              <ButtonCustom
                label={'Tiếp tục'}
                type={'button'}
                className={'mt-4 w-full uppercase'}
                onClick={() => submitData()}
              />
              <div className={'mt-6 flex w-full flex-col items-center'}>
                <div id={'recaptcha-container'} />
                <p className={'text-sm font-medium leading-[20px] text-gray-400'}>
                  Bạn chưa nhận được mã?{' '}
                  <button
                    type="button"
                    className={'h-fit w-fit !border-0 !bg-transparent text-gray-600'}
                    aria-disabled={countdown > 0}
                    disabled={countdown > 0}
                    onClick={() => !countdown && resendOTP()}
                  >
                    <span className="font-bold text-[#F15836]">
                      GỬI LẠI{' '}
                      {countdown ? (
                        <>
                          (<span className="font-medium text-[#F15836]">{minTwoDigits(countdown)}s</span>)
                        </>
                      ) : (
                        ''
                      )}
                    </span>
                  </button>
                </p>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};
