import { useNavigate } from 'react-router';
import { HeaderCommon } from '../../../components/header';
import { CalendarBackIcon } from '../../../assets/images';
import Scrollbars from 'react-custom-scrollbars';
import { get, upperCase } from 'lodash';
import useCheckDeviceMobi from '../../../hooks/useCheckDeviceMobi';
import { NavLink } from 'react-router-dom';
import { PATH_PAGE } from 'constant';
import { classNames } from 'utils/utils';

export const BodyPackage = ({ infoData }: any) => {
  const navigate = useNavigate();
  const isMobile = useCheckDeviceMobi();
  const content = checkJSON(infoData?.regContent);

  function checkJSON(str: any) {
    try {
      JSON.parse(str);
    } catch (e) {
      return str;
    }
    let res = JSON.parse(str);
    return res?.error?.msg || "-";
  }

  return (
    <div className={'h-full w-full rounded-[20px] bg-[#F8F8F8] p-6 !pr-0'}>
      <HeaderCommon
        children={
          <div className={'flex flex-row items-center space-x-2'}>
            <span className={'mr-[8px]'} onClick={() => navigate(-1)}>
              <img className="h-[18px] w-[18px]" src={CalendarBackIcon} alt="Logo" />
            </span>
            <span className={'text-[22px] font-extrabold text-[#404040]'}>Thông tin giao dịch</span>
          </div>
        }
      />
      {isMobile ? (
        <div className={'mr-7'}>
          <div
            className={
              'mb-2 mt-10 flex cursor-pointer flex-row space-x-2 text-[12px] font-medium leading-[16px] text-[#71717A]' +
              ' md:hidden'
            }
          >
            <NavLink to={PATH_PAGE.DASHBOARD}>Trang chủ</NavLink>
            <span>|</span>
            <span>Báo cáo thống kê</span>
            <span>|</span>
            <span>Danh sách giao dịch</span>
          </div>
          <div className="mb-4 flex flex-row items-center space-x-2">
            <div onClick={() => navigate(-1)}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.54637 8.40283L9.33137 13.1888C9.52498 13.3824 9.78757 13.4912 10.0614 13.4912C10.3352 13.4912 10.5978 13.3824 10.7914 13.1888C10.985 12.9952 11.0938 12.7326 11.0938 12.4588C11.0938 12.185 10.985 11.9224 10.7914 11.7288L6.72637 7.66583L10.7914 3.60384C10.9848 3.41023 11.0935 3.14769 11.0934 2.87398C11.0934 2.73845 11.0666 2.60426 11.0147 2.47907C10.9628 2.35388 10.8867 2.24014 10.7909 2.14434C10.695 2.04854 10.5812 1.97256 10.456 1.92074C10.3308 1.86891 10.1965 1.84227 10.061 1.84231C9.78731 1.84241 9.52485 1.95123 9.33137 2.14484L4.54637 6.92983C4.35115 7.12522 4.24148 7.39013 4.24148 7.66633C4.24148 7.94254 4.35115 8.20745 4.54637 8.40283Z"
                  fill="#6A6A6A"
                />
              </svg>
            </div>
            <span className="text-lg font-bold text-baseColor">Thông tin giao dịch</span>
          </div>
          <div className={' grid h-fit w-full grid-cols-1 gap-4 md:grid-cols-2'}>
            <div
              className={
                'h-[40px] max-h-[40px] w-full border border-[#F15836] bg-white' +
                ' flex flex-row items-center justify-center space-x-2 rounded-lg px-4'
              }
            >
              <label className={'text-lg font-bold text-[#F15836]/80'}>Mã giao dịch</label>
              <label className={'text-lg font-bold text-[#F15836]'}>{get(infoData, 'transCode')}</label>
            </div>
            <div
              className={
                'h-[40px] w-full border border-[#E5E7EB] bg-white shadow-sm' +
                ' flex flex-row items-center justify-between space-x-2 rounded-lg px-4'
              }
            >
              <label className={'text-sm font-bold text-baseColor '}>Trạng thái</label>
              <label
                className={classNames('text-sm font-extrabold')}
                style={{ color: get(infoData, 'statusColor', '#404040') }}
              >
                {get(infoData, 'statusStr', '-')}
              </label>
            </div>
          </div>
          <div className={'mt-4 grid h-fit w-full grid-cols-1 gap-4 md:grid-cols-2'}>
            <span className={'w-full text-base font-bold text-[#404040]'}>Thông tin chung</span>
            <div className={'rounded-[15px]'}>
              <div
                role={'list'}
                className={'rounded-[15px] border border-[#E5E7EB]/60 bg-white px-4 py-2 shadow-base '}
              >
                <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] px-6 py-3'}>
                  <label className={'text-sm font-extrabold text-baseColor'}>Cộng tác viên</label>
                  <label className={'text-end text-sm font-medium text-baseColor'}>
                    {get(infoData, 'userName', '-')}
                  </label>
                </div>
                <div className={'grid grid-cols-3 space-x-2 rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] px-6 py-3'}>
                  <label className={'col-span-2 text-sm font-extrabold text-baseColor'}>
                    Số thuê bao đăng ký gói cước
                  </label>
                  <label className={'text-end text-sm font-medium text-baseColor'}>
                    {get(infoData, 'msisdn', '-')}
                  </label>
                </div>
                <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] px-6 py-3'}>
                  <label className={'text-sm font-extrabold text-baseColor'}>Kênh đăng ký</label>
                  <label className={'text-end text-sm font-medium text-baseColor'}>{getSourceInfo(infoData)}</label>
                </div>
                <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] px-6 py-3'}>
                  <label className={'text-sm font-extrabold text-baseColor'}>Thời gian đăng ký</label>
                  <label className={'text-end text-sm font-medium text-baseColor'}>
                    {get(infoData, 'genDate', '-')}
                  </label>
                </div>
                <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] px-6 py-3'}>
                  <label className={'text-sm font-extrabold text-baseColor'}>Nội dung</label>
                  <label className={'text-end text-sm font-medium text-baseColor'}>
                    {/* {get(infoData, 'regContent', '-')} */}
                    {content?.error?.msg ? content?.error?.msg : content || '-'}
                  </label>
                </div>
              </div>
            </div>
            <span className={'w-full text-base font-bold text-[#404040]'}>Thông tin số thuê bao</span>
            <div className={'rounded-[15px]'}>
              <div
                role={'list'}
                className={'rounded-[15px] border border-[#E5E7EB]/60 bg-white px-4 py-2 shadow-base '}
              >
                <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] px-6 py-3'}>
                  <label className={'text-sm font-extrabold text-baseColor'}>Tên gói cước</label>
                  <label className={'text-end text-sm font-medium text-baseColor'}>{infoData?.itemName || '-'}</label>
                </div>
                <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] px-6 py-3'}>
                  <label className={'text-sm font-extrabold text-baseColor'}>Nhà mạng</label>
                  <label className={'text-end text-sm font-medium text-baseColor'}>{infoData?.typeStr || '-'}</label>
                </div>
                <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] px-6 py-3'}>
                  <label className={'text-sm font-extrabold text-baseColor'}>Loại gói cước</label>
                  <label className={'text-end text-sm font-medium text-baseColor'}>
                    {get(infoData, 'packTypeStr', '-')}
                  </label>
                </div>
                <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] px-6 py-3'}>
                  <label className={'text-sm font-extrabold text-baseColor'}>Ưu đãi gói cước</label>
                  <label className={'text-end text-sm font-medium text-baseColor'}>
                    {get(infoData, 'packDetail', '-')}
                  </label>
                </div>
                <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] px-6 py-3'}>
                  <label className={'text-sm font-extrabold text-baseColor'}>Giá gói</label>
                  <label className={'text-end text-sm font-medium text-baseColor'}>
                    {(get(infoData, 'amount', 0) || 0).toLocaleString('vi')} VNĐ
                  </label>
                </div>
                <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] px-6 py-3'}>
                  <label className={'text-sm font-extrabold text-baseColor'}>Chu kỳ</label>
                  <label className={'text-end text-sm font-medium text-baseColor'}>{`${get(
                    infoData,
                    'numExpired',
                    0
                  )} ngày`}</label>
                </div>
              </div>
            </div>
            <span className={'w-full text-base font-bold text-[#404040]'}>Thông tin hoa hồng</span>
            <div className={'rounded-[15px] bg-white'}>
              <div
                role={'list'}
                className={'rounded-[15px] border border-[#E5E7EB]/60 bg-white px-4 py-2 shadow-base '}
              >
                <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] px-6 py-3'}>
                  <label className={'text-sm font-extrabold text-baseColor'}>Doanh thu</label>
                  <label className={'text-end text-sm font-medium text-baseColor'}>
                    {(get(infoData, 'amount', 0) || 0).toLocaleString('vi')} VNĐ
                  </label>
                </div>
                <div className={'grid grid-cols-3 space-x-2 rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] px-6 py-3'}>
                  <label className={'text-sm font-extrabold text-baseColor'}>Loại hoa hồng</label>
                  <label className={'col-span-2 text-end text-sm font-medium text-baseColor'}>
                    {get(infoData, 'shareTypeStr', '-')}
                  </label>
                </div>
                <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] px-6 py-3'}>
                  <label className={'text-sm font-extrabold text-baseColor'}>Trạng thái</label>
                  <label
                    className={'text-end text-sm font-medium '}
                    style={{ color: get(infoData, 'shareStatusColor', '#404040') }}
                  >
                    {get(infoData, 'shareStatusStr', '-')}
                  </label>
                </div>
                <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] px-6 py-3'}>
                  <label className={'text-sm font-extrabold text-baseColor'}>Hoa hồng</label>
                  <label className={'text-end text-sm font-medium text-baseColor'}>
                    {(get(infoData, 'shareValue', 0) || 0).toLocaleString('vi')} VNĐ
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={'relative mt-[60px] h-full w-full md:mt-0 md:h-[calc(100%-90px)]'}>
          <Scrollbars className={'h-full w-full'}>
            <div className={'mr-7'}>
              <div className={'grid h-fit w-full grid-cols-1 gap-4 md:grid-cols-2'}>
                <div
                  className={
                    'h-[50px] w-full rounded-t-[15px] rounded-bl-[15px] border-dashed bg-[#FFECD6]' +
                    ' flex flex-row items-center justify-between space-x-2 border-[1px] border-[#F15836] pl-4 pr-10'
                  }
                >
                  <div className={'flex flex-row items-center '}>
                    <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_632_30665)">
                        <path
                          d="M10.9883 20.3828C10.9883 14.86 15.4654 10.3828 20.9883 10.3828H26.9883C32.5111 10.3828 36.9883 14.86 36.9883 20.3828V36.3828H20.9883C15.4654 36.3828 10.9883 31.9057 10.9883 26.3828V20.3828Z"
                          fill="url(#paint0_linear_632_30665)"
                        />
                      </g>
                      <path
                        d="M29.2297 19.8722C29.3214 19.9665 29.3735 20.0939 29.3735 20.2275V29.9222C29.3735 30.2004 29.1547 30.4252 28.884 30.4252H19.0949C18.8242 30.4252 18.6055 30.2004 18.6055 29.9222V16.8429C18.6055 16.5646 18.8242 16.3398 19.0949 16.3398H25.5909C25.7209 16.3398 25.8463 16.3933 25.9381 16.4876L29.2297 19.8722ZM28.2446 20.4586L25.366 17.5V20.4586H28.2446ZM21.0527 22.9109C21.0203 22.9109 20.9892 22.9242 20.9662 22.9478C20.9433 22.9714 20.9304 23.0033 20.9304 23.0367V23.7913C20.9304 23.8246 20.9433 23.8566 20.9662 23.8802C20.9892 23.9038 21.0203 23.917 21.0527 23.917H26.9262C26.9586 23.917 26.9898 23.9038 27.0127 23.8802C27.0357 23.8566 27.0485 23.8246 27.0485 23.7913V23.0367C27.0485 23.0033 27.0357 22.9714 27.0127 22.9478C26.9898 22.9242 26.9586 22.9109 26.9262 22.9109H21.0527ZM21.0527 25.0489C21.0203 25.0489 20.9892 25.0621 20.9662 25.0857C20.9433 25.1093 20.9304 25.1413 20.9304 25.1747V25.9292C20.9304 25.9626 20.9433 25.9946 20.9662 26.0182C20.9892 26.0417 21.0203 26.055 21.0527 26.055H23.8671C23.8995 26.055 23.9307 26.0417 23.9536 26.0182C23.9766 25.9946 23.9895 25.9626 23.9895 25.9292V25.1747C23.9895 25.1413 23.9766 25.1093 23.9536 25.0857C23.9307 25.0621 23.8995 25.0489 23.8671 25.0489H21.0527Z"
                        fill="white"
                      />
                      <defs>
                        <filter
                          id="filter0_d_632_30665"
                          x="0.988281"
                          y="0.382812"
                          width="46"
                          height="46"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood floodOpacity="0" result="BackgroundImageFix" />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset />
                          <feGaussianBlur stdDeviation="5" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 1 0 0 0 0 0.589833 0 0 0 0 0.108333 0 0 0 0.25 0"
                          />
                          <feBlend mode="darken" in2="BackgroundImageFix" result="effect1_dropShadow_632_30665" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_632_30665" result="shape" />
                        </filter>
                        <linearGradient
                          id="paint0_linear_632_30665"
                          x1="10.9883"
                          y1="23.3828"
                          x2="36.9883"
                          y2="23.3828"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#F15836" />
                          <stop offset="1" stopColor="#FF924F" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <label className={'text-[12px] font-medium text-baseColor '}>Mã giao dịch</label>
                  </div>
                  <label className={'text-base font-extrabold text-baseColor'}>{get(infoData, 'transCode')}</label>
                </div>
                <div
                  className={
                    'flex h-[50px] w-full flex-row items-center space-x-2 rounded-t-[15px] rounded-bl-[15px] border-dashed bg-[#FFECD6]' +
                    ' justify-between border-[1px] border-[#F15836] pl-4 pr-10'
                  }
                >
                  <div className={'flex flex-row items-center'}>
                    <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_d_632_30681)">
                        <path
                          d="M10.4531 20.3828C10.4531 14.86 14.9303 10.3828 20.4531 10.3828H26.4531C31.976 10.3828 36.4531 14.86 36.4531 20.3828V36.3828H20.4531C14.9303 36.3828 10.4531 31.9057 10.4531 26.3828V20.3828Z"
                          fill="url(#paint0_linear_632_30681)"
                        />
                      </g>
                      <path
                        d="M16.6719 18.2441H26.3298V20.0001H16.6719V18.2441ZM16.6719 21.7561H26.3298V23.5121H16.6719V21.7561ZM16.6719 25.2681H22.8178V27.024H16.6719V25.2681ZM30.1042 22.8905L26.3289 26.6579L25.1945 25.5244L23.953 26.7668L26.3289 29.1409L31.344 24.1337L30.1042 22.8905Z"
                        fill="white"
                      />
                      <defs>
                        <filter
                          id="filter0_d_632_30681"
                          x="0.453125"
                          y="0.382812"
                          width="46"
                          height="46"
                          filterUnits="userSpaceOnUse"
                          colorInterpolationFilters="sRGB"
                        >
                          <feFlood floodOpacity="0" result="BackgroundImageFix" />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset />
                          <feGaussianBlur stdDeviation="5" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 1 0 0 0 0 0.589833 0 0 0 0 0.108333 0 0 0 0.25 0"
                          />
                          <feBlend mode="darken" in2="BackgroundImageFix" result="effect1_dropShadow_632_30681" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_632_30681" result="shape" />
                        </filter>
                        <linearGradient
                          id="paint0_linear_632_30681"
                          x1="10.4531"
                          y1="23.3828"
                          x2="36.4531"
                          y2="23.3828"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#F15836" />
                          <stop offset="1" stopColor="#FF924F" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <label className={'text-[12px] font-medium text-baseColor '}>Trạng thái giao dịch</label>
                  </div>
                  <label className={'text-base font-extrabold text-baseColor'}>{get(infoData, 'statusStr', '-')}</label>
                </div>
              </div>
              <div className={'mt-4 grid h-fit w-full grid-cols-1 gap-4 md:grid-cols-1 lg:grid-cols-2'}>
                <div className={'rounded-t-[15px] rounded-bl-[15px]'}>
                  <div
                    className={
                      'flex h-[58px] w-full flex-row items-center space-x-2 rounded-t-[15px] border-b pl-6' +
                      ' border-[#EFEFEF] bg-white px-4 shadow-base'
                    }
                  >
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="6.43159" cy="6.7519" r="6.04878" fill="#F15836" />
                    </svg>
                    <span className={'w-full text-base font-bold text-[#404040]'}>Thông tin chung</span>
                  </div>
                  <div
                    role={'list'}
                    className={'rounded-bl-[15px] bg-white p-4 pb-6 shadow-base odd:bg-red-700' + ' even:bg-white'}
                  >
                    <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] p-6'}>
                      <label className={'text-sm font-extrabold text-baseColor'}>Cộng tác viên</label>
                      <label className={'text-end text-sm font-medium text-baseColor'}>
                        {get(infoData, 'userName', '-')}
                      </label>
                    </div>
                    <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] p-6'}>
                      <label className={'text-sm font-extrabold text-baseColor'}>Số thuê bao đăng ký gói cước</label>
                      <label className={'text-end text-sm font-medium text-baseColor'}>{get(infoData, 'msisdn')}</label>
                    </div>
                    <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] p-6'}>
                      <label className={'text-sm font-extrabold text-baseColor'}>Kênh đăng ký</label>
                      <label className={'text-end text-sm font-medium text-baseColor'}>{getSourceInfo(infoData)}</label>
                    </div>
                    <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] p-6'}>
                      <label className={'text-sm font-extrabold text-baseColor'}>Thời gian đăng ký</label>
                      <label className={'text-end text-sm font-medium text-baseColor'}>
                        {get(infoData, 'genDate', '-')}
                      </label>
                    </div>
                    <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] p-6'}>
                      <label className={'text-sm font-extrabold text-baseColor'}>Nội dung</label>
                      <label className={'text-end text-sm font-medium text-baseColor'}>
                        {/* {get(infoData, 'regContent', '-')} */}
                        {content || '-'}
                      </label>
                    </div>
                  </div>
                </div>
                <div className={'rounded-t-[15px] rounded-bl-[15px]'}>
                  <div
                    className={
                      'flex h-[58px] w-full flex-row items-center space-x-2 rounded-t-[15px] border-b pl-6' +
                      ' border-[#EFEFEF] bg-white px-4 shadow-base'
                    }
                  >
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="6.43159" cy="6.7519" r="6.04878" fill="#F15836" />
                    </svg>
                    <span className={'w-full text-base font-bold text-[#404040]'}>Thông tin số thuê bao</span>
                  </div>
                  <div
                    role={'list'}
                    className={'rounded-bl-[15px] bg-white p-4 pb-6 shadow-base odd:bg-red-700' + ' even:bg-white'}
                  >
                    <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] p-6'}>
                      <label className={'text-sm font-extrabold text-baseColor'}>Tên gói cước</label>
                      <label className={'text-end text-sm font-medium text-baseColor'}>
                        {infoData?.itemName || '-'}
                      </label>
                    </div>
                    <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] p-6'}>
                      <label className={'text-sm font-extrabold text-baseColor'}>Nhà mạng</label>
                      <label className={'text-end text-sm font-medium text-baseColor'}>
                        {infoData?.typeStr || '-'}
                      </label>
                    </div>
                    <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] p-6'}>
                      <label className={'text-sm font-extrabold text-baseColor'}>Loại gói cước</label>
                      <label className={'text-end text-sm font-medium text-baseColor'}>
                        {get(infoData, 'packTypeStr', '-')}
                      </label>
                    </div>
                    <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] p-6'}>
                      <label className={'text-sm font-extrabold text-baseColor'}>Ưu đãi gói cước</label>
                      <label className={'text-end text-sm font-medium text-baseColor'}>
                        {get(infoData, 'packDetail', '-')}
                      </label>
                    </div>
                    <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] p-6'}>
                      <label className={'text-sm font-extrabold text-baseColor'}>Giá gói</label>
                      <label className={'text-end text-sm font-medium text-baseColor'}>
                        {(get(infoData, 'amount', 0) || 0).toLocaleString('vi')} VNĐ
                      </label>
                    </div>
                    <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] p-6'}>
                      <label className={'text-sm font-extrabold text-baseColor'}>Chu kỳ</label>
                      <label className={'text-end text-sm font-medium text-baseColor'}>{`${get(
                        infoData,
                        'numExpired',
                        0
                      )} ngày`}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'mt-6 h-fit w-full'}>
                <div className={'rounded-t-[15px] rounded-bl-[15px] bg-white'}>
                  <div
                    className={
                      'flex h-[58px] w-full flex-row items-center space-x-2 rounded-t-[15px] border-b pl-6' +
                      ' border-[#EFEFEF] bg-white px-4 shadow-base'
                    }
                  >
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="6.43159" cy="6.7519" r="6.04878" fill="#F15836" />
                    </svg>
                    <span className={'w-full text-base font-bold text-[#404040]'}>Thông tin đối soát</span>
                  </div>
                  <div role={'list'} className={'grid grid-cols-2 gap-y-6 gap-x-10 p-4 pb-6 shadow-base'}>
                    <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] p-6'}>
                      <label className={'text-sm font-extrabold text-baseColor'}>Doanh thu</label>
                      <label className={'text-end text-sm font-medium text-baseColor'}>
                        {(get(infoData, 'amount', 0) || 0).toLocaleString('vi')} VNĐ
                      </label>
                    </div>
                    <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] p-6'}>
                      <label className={'text-sm font-extrabold text-baseColor'}>Loại hoa hồng</label>
                      <label className={'text-end text-sm font-medium text-baseColor'}>
                        {get(infoData, 'shareTypeStr', '-')}
                      </label>
                    </div>
                    <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] p-6'}>
                      <label className={'text-sm font-extrabold text-baseColor'}>Trạng thái</label>
                      <label
                        className={'text-end text-sm font-medium '}
                        style={{ color: get(infoData, 'shareStatusColor', '#404040') }}
                      >
                        {get(infoData, 'shareStatusStr', '-')}
                      </label>
                    </div>
                    <div className={'grid grid-cols-2 space-x-2 rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] p-6'}>
                      <label className={'text-sm font-extrabold text-baseColor'}>Hoa hồng</label>
                      <label className={'text-end text-sm font-medium text-baseColor'}>
                        {(get(infoData, 'shareValue', 0) || 0).toLocaleString('vi')} VNĐ
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
      )}
    </div>
  );
};

const getSourceInfo = (infoData: any) => {
  const source = get(infoData, 'source', '-');
  if (upperCase(source) === 'CUS') {
    return 'Khách hàng';
  }
  if (upperCase(source) === 'CTV') {
    return 'Cộng tác viên';
  }
  if (upperCase(source) === 'CTVOSP') {
    return 'Web';
  }
  return '-';
};
