import {ButtonCustom} from 'components/common/ButtonCustom';
import {SelectForm} from 'components/common/form-item/SelectForm';
import {TextForm} from 'components/common/form-item/TextForm';
import {TimeForm} from 'components/common/form-item/TimeForm';
import {SelectOptionEntity} from '../../../../entity/SearchEntity';
import {PaginationCustom} from '../../../../components/common/pagination/Pagination';
import {TableCollaborator} from './TableCollaborator';
import {useState} from 'react';

const productStatus: Array<SelectOptionEntity> = [
	{id: 0, name: '-- Tất cả --', value: 0},
	{id: 1, name: 'Hiển thị', value: 1},
	{id: 2, name: 'Ẩn', value: 2},
];

export const Collaborators = () => {
	const [startDay, setStartDay] = useState<Date | null>(null);
	const [endDay, setEndDay] = useState<Date | null>(null);

	return (
		<>
			<div className={'w-full rounded-b-2xl rounded-tr-2xl border border-gray-300 bg-white p-6 shadow'}>
				<div className={'grid-cols-1-1 grid gap-4 md:grid-cols-2'}>
					<TextForm label={'Cộng tác viên'} placeholder={'Tìm kiếm theo số điện thoại CTV'}/>
					<SelectForm label={'Trạng thái'} options={productStatus} classIcon={'text-gray-400'}/>
					<TimeForm
						label={'Thời gian đăng ký'}
						startDay={startDay}
						setStartDay={setStartDay}
						endDay={endDay}
						setEndDay={setEndDay}
					/>
				</div>
				<div className={'mt-6 flex flex-row items-center justify-center space-x-2.5'}>
					<ButtonCustom
						label={'Xoá điều kiện'}
						className={'border border-gray-600 bg-white px-2 py-[7px] !text-sm !font-semibold !text-gray-600'}
					/>
					<ButtonCustom label={'Tìm kiếm'} className={'bg-gray-600 px-4 py-[8px] !text-sm !font-semibold'}/>
				</div>
			</div>
			<div className={'flex flex-row items-center justify-start py-6'}>
				<span className={'text-2xl font-semibold text-gray-700'}>{'100 cộng tác viên'}</span>
			</div>
			<TableCollaborator dataRows={dataRows}/>
			<div className={'mt-6'}>
				<PaginationCustom/>
			</div>
			<div className={'h-6'}></div>
		</>
	);
};

const dataRows = [
	{
		stt: 1,
		collaborator: '0902123456',
		name: 'Nguyễn Văn A',
		time: '12/12/2022| 09:09:09',
		status: false,
	},
	{
		stt: 2,
		collaborator: '0902123456',
		name: 'Nguyễn Văn A',
		time: '12/12/2022| 09:09:09',
		status: true,
	},
	{
		stt: 3,
		collaborator: '0902123456',
		name: 'Nguyễn Văn A',
		time: '12/12/2022| 09:09:09',
		status: true,
	},
	{
		stt: 4,
		collaborator: '0902123456',
		name: 'Nguyễn Văn A',
		time: '12/12/2022| 09:09:09',
		status: true,
	},
	{
		stt: 5,
		collaborator: '0902123456',
		name: 'Nguyễn Văn A',
		time: '12/12/2022| 09:09:09',
		status: true,
	},
];
