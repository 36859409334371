import React from 'react';
import { HeaderCMSCommon } from 'components/header/HeaderCMSCommon';
import { Footer } from 'components/footer';
import { QRCode, TaiAppShop360ImageRight } from 'assets/images';
import { GooglePlay } from 'assets/images';
import { AppStore } from 'assets/images';

function DownloadApp() {
    return (
        <div className={'md:bg-gradient-to-r md:from-[#FFF] md:to-[#FFF]'}>
            <HeaderCMSCommon />
            <div className={'w-full bg-[#F8F8F8] pt-[60px]'}>
                <div className="bg-cover bg-center bg-no-repeat min-h-[180px]  md:min-h-[600px] bg-[url('assets/images/bannerTaiAppShop360.png')]">
                    <section
                        className={
                            'mx-auto flex min-h-[600px] w-full max-w-[85rem] items-center justify-center md:h-[calc(100vh_-_400px)] md:px-4'
                        }
                    >
                    <div className={'flex w-full flex-col sm:flex-row sm:space-x-4 sm:px-10 items-center'}>
                        <div className={'flex w-full p-4 flex-col justify-start sm:w-6/12'}>
                            <h1 className={' text-[42px] font-extrabold uppercase leading-[60px] text-transparent bg-clip-text bg-gradient-to-r from-[#EE370E] to-[#F08644]'}>
                                Chính thức ra mắt app Shop360
                            </h1>
                            <h2 className={'mt-4 text-[20px] font-medium leading-[26px] text-baseColor'}>
                                Nâng tầm trải nghiệm - Đột phá kinh doanh
                            </h2>
                            <div className="flex gap-4 mt-8">
                                <img src={QRCode} alt="QRCode" className='w-1/4 rounded-lg' />
                                <img src={AppStore} alt="AppStore" className="w-1/3 cursor-pointer" onClick={()=> window.open('https://apps.apple.com/us/app/shop360/id6449772995', "_blank")} />
                                <img src={GooglePlay} alt="GooglePlay" className="w-1/3 cursor-pointer" onClick={()=> window.open('https://play.google.com/store/apps/details?id=vn.osp.shop360', "_blank")}/>
                            </div>
                        </div>
                        <div className={'flex w-full justify-end sm:w-6/12'}>
                            <img src={TaiAppShop360ImageRight} alt="TaiAppShop360ImageRight"  className=' w-4/6'/>
                        </div>
                    </div>
                    </section>
                </div>
            </div>
            <Footer />
        </div>
    )   
}

export default DownloadApp