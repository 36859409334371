import ArrowBack from 'assets/images/arrow-back-ios.svg';
import { ButtonCustom } from '../common/ButtonCustom';
import { useContext } from 'react';
import { InputCustom } from '../common/InputCustom';
import { useForm } from 'react-hook-form';
import { CheckSuccessIcon } from 'assets/images/form';
import { RegisterContext } from 'pages/resigter';
import usePasswordToggle from '../../hooks/usePasswordToggle';
import { useMutation } from 'react-query';
import { registerAccount } from '../../services/account';
import { IRegisterAccount } from '../../entity/Account';
import { Stepper } from '../common/Stepper';
import { classNames } from '../../utils/utils';
import useCheckDeviceMobi from '../../hooks/useCheckDeviceMobi';
import { ArrowBackMobile } from '../../assets/images';

interface StepResetPasswordType {
  fullName: string | null;
  password: string | null;
  confirmPassword: string | null;
}

export const StepResetPassword = () => {
  const { activeStepIndex, setActiveStepIndex, phoneNumber, setIsShowStep, setMessageError } =
    useContext(RegisterContext);

  const isMobile = useCheckDeviceMobi();

  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [PasswordInputTypeRe, ToggleIconRe] = usePasswordToggle();

  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useForm<StepResetPasswordType>({
    defaultValues: {
      fullName: null,
      password: null,
      confirmPassword: null,
    },
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const mutation = useMutation((data: IRegisterAccount) => registerAccount(data));

  const onSubmit = (data: StepResetPasswordType) => {
    mutation.mutate(
      {
        msisdn: `0${phoneNumber}`,
        fullName: data.fullName || '',
        password: data.password || '',
        srcReq: 'CMSCTV',
      },
      {
        onSuccess: (response) => {
          if (response?.data?.data?.status === 2) {
            setActiveStepIndex(3);
            setMessageError(response?.data?.data?.statusStr);
            return;
          }
          if (!response?.data?.data?.status) {
            setActiveStepIndex(3);
            setMessageError(response?.data?.message || 'Đã có lỗi xảy ra, vui lòng thử lại sau');
            return;
          }
          setActiveStepIndex(2);
        },
      }
    );
  };

  return (
    <>
      {isMobile ? (
        <div className={'mt-4 w-full px-2'}>
          <Stepper />
          <div className={'flex items-center'}>
            <p
              className={'mr-[24px]'}
              onClick={() => {
                setIsShowStep(false);
                setActiveStepIndex(0);
              }}
            >
              <img className="w-50 h-auto" src={ArrowBackMobile} alt="Logo" />
            </p>
            <h1 className={'text-[18px] font-extrabold leading-[28px] text-[#404040]'}>Thiết lập tên người dùng và mật khẩu</h1>
          </div>
          <h2 className={'my-4 text-[14px] font-medium leading-[20px] text-[#404040]'}>
            {`Thiết lập mật khẩu cho tài khoản `}
            <span className={'font-semibold text-[#F15836]'}>0{phoneNumber}</span>
          </h2>
          <div className={'mt-4 flex flex-row items-center space-x-2'}>
            <img
              src={CheckSuccessIcon}
              alt={'success-icon'}
              className={classNames(
                'h-4 w-4',
                getValues('password') && (!errors.password || !errors.password.message?.includes('3'))
                  ? ''
                  : 'opacity-30'
              )}
            />
            <span className={'text-xs font-medium text-[#3F3F46]'}>Chứa ít nhất 1 số</span>
          </div>
          <div className={'mt-1 flex flex-row items-center space-x-2'}>
            <img
              src={CheckSuccessIcon}
              alt={'success-icon'}
              className={classNames(
                'h-4 w-4',
                getValues('password') && (!errors.password || !errors.password.message?.includes('4'))
                  ? ''
                  : 'opacity-30'
              )}
            />
            <span className={'text-xs font-medium text-[#3F3F46]'}>
              Chứa ít nhất 1 ký tự đặc biệt (Ví dụ: *, @, ~, ...)
            </span>
          </div>
          <div className={'mt-1 flex flex-row items-center space-x-2'}>
            <img
              src={CheckSuccessIcon}
              alt={'success-icon'}
              className={classNames(
                'h-4 w-4',
                getValues('password') && (!errors.password || !errors.password.message?.includes('1'))
                  ? ''
                  : 'opacity-30'
              )}
            />
            <span className={'text-xs font-medium text-[#3F3F46]'}>Chứa ít nhất 1 chữ cái viết hoa</span>
          </div>
          <div className={'mt-1 flex flex-row items-center space-x-2'}>
            <img
              src={CheckSuccessIcon}
              alt={'success-icon'}
              className={classNames(
                'h-4 w-4',
                getValues('password') && (!errors.password || !errors.password.message?.includes('5'))
                  ? ''
                  : 'opacity-30'
              )}
            />
            <span className={'text-xs font-medium text-[#3F3F46]'}>Dài từ 8 - 16 ký tự</span>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={'flex min-h-[280px] w-full flex-col rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] p-2'}>
              <div className={'relative mt-2 h-full w-full'}>
                <InputCustom
                    label={'fullName'}
                    placeholder={'Họ và tên'}
                    maxLength={16}
                    className={classNames('w-full !pr-10', errors.fullName ? 'border border-[#FC5555]' : '')}
                    autoFocus
                    register={register}
                    control={control}
                    required={{
                      required: 'Vui lòng nhập họ và tên',
                      validate: (val: string) => {
                        // debugger
                        let fullNameError = '';
                        // if (!/[A-Z]/.test(val)) {
                        //   fullNameError = fullNameError + '1';
                        // }
                        if (val.length === 0) {
                          fullNameError = fullNameError + '5';
                        }
                        if (fullNameError) {
                          return fullNameError;
                        }
                      },
                    }}
                />
                {errors.fullName &&
                    (errors.fullName.type === 'validate' ? (
                        <span className={'mt-2 text-[12px] font-normal leading-[18px] text-[#F15836]'}>
                    Họ và tên không hợp lệ
                  </span>
                    ) : (
                        <span className={'mt-2 text-[12px] font-normal leading-[18px] text-[#F15836]'}>
                    {errors.fullName.message}
                  </span>
                    ))}
              </div>
              <div className={'relative mt-2 h-full w-full'}>
                <InputCustom
                  label={'password'}
                  placeholder={'Mật khẩu'}
                  maxLength={16}
                  className={classNames('w-full !pr-10', errors.password ? 'border border-[#FC5555]' : '')}
                  type={PasswordInputType as string}
                  register={register}
                  control={control}
                  required={{
                    required: 'Vui lòng nhập mật khẩu',
                    validate: (val: string) => {
                      let passwordError = '';
                      if (!/[A-Z]/.test(val)) {
                        passwordError = passwordError + '1';
                      }
                      if (!/\d/.test(val)) {
                        passwordError = passwordError + '3';
                      }
                      if (!/[@$!%*#?&]/.test(val)) {
                        passwordError = passwordError + '4';
                      }
                      if (val.length < 8) {
                        passwordError = passwordError + '5';
                      }
                      if (passwordError) {
                        return passwordError;
                      }
                    },
                  }}
                />
                {watch('password') && (
                  <div className="absolute top-2.5 right-2 flex cursor-pointer items-center justify-center">
                    {ToggleIcon}
                  </div>
                )}
              </div>
              {errors.password &&
                (errors.password.type === 'validate' ? (
                  <span className={'mt-2 text-[12px] font-normal leading-[18px] text-[#F15836]'}>
                    Mật khẩu không hợp lệ
                  </span>
                ) : (
                  <span className={'mt-2 text-[12px] font-normal leading-[18px] text-[#F15836]'}>
                    {errors.password.message}
                  </span>
                ))}
              <div className={'relative mt-2 h-full w-full'}>
                <InputCustom
                  placeholder={'Xác nhận lại mật khẩu'}
                  type={PasswordInputTypeRe as string}
                  className={classNames('w-full !pr-10', errors.confirmPassword ? 'border border-[#FC5555]' : '')}
                  register={register}
                  label={'confirmPassword'}
                  control={control}
                  required={{
                    required: 'Vui lòng nhập xác nhận mật khẩu',
                    validate: (val: string) => {
                      if (watch('password') !== val) {
                        return 'Xác nhận mật khẩu không trùng khớp';
                      }
                    },
                  }}
                />
                {watch('confirmPassword') && (
                  <div className="absolute top-2.5 right-2 flex cursor-pointer items-center justify-center">
                    {ToggleIconRe}
                  </div>
                )}
              </div>
              {errors.confirmPassword && (
                <span className={'mt-2 text-[12px] font-normal leading-[18px] text-[#F15836]'}>
                  {errors.confirmPassword.message}
                </span>
              )}
              <ButtonCustom label={'Tiếp tục'} className={'mt-4 w-full uppercase'} type={'submit'} />
              <div className={'mt-[16px] flex w-full flex-col items-center'}>
                <p className={' text-sm font-medium text-[#818181]'}>
                  Bằng việc đăng ký, bạn đã đồng ý với{' '}
                  <a className={'uppercase text-[#F15836]'} href={'/terms'} target="_blank" rel="noreferrer">
                    Điều khoản & điều kiện
                  </a>{' '}
                  và{' '}
                  <a className={'uppercase text-[#F15836]'} href={'/privacy'} target="_blank" rel="noreferrer">
                    Chính sách bảo mật
                  </a>{' '}
                  của chúng tôi
                </p>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className={'mt-16 flex w-full flex-col sm:flex-row sm:space-x-4 sm:px-10'}>
          <div className={'w-full sm:w-6/12'}>
            <Stepper />
            <div className={'flex items-center'}>
              <p
                className={'mr-[24px]'}
                onClick={() => {
                  setIsShowStep(false);
                  setActiveStepIndex(0);
                }}
              >
                <img className="w-50 h-auto" src={ArrowBack} alt="Logo" />
              </p>
              <h1 className={' text-[35px] font-extrabold leading-[50px] text-white'}>Thiết lập mật khẩu</h1>
            </div>
            <h2 className={'mt-4  text-[18px] text-white'}>
              Để tài khoản của bạn được an toàn nhất, xin hãy đảm bảo rằng mật khẩu của bạn:
            </h2>
            <div className={'mt-1 flex flex-row items-center space-x-2'}>
              <img
                src={CheckSuccessIcon}
                alt={'success-icon'}
                className={classNames(
                  'h-4 w-4',
                  getValues('password') && (!errors.password?.message || !errors.password?.message?.includes('3'))
                    ? ''
                    : 'opacity-30'
                )}
              />
              <span className={' text-sm font-medium text-white'}>Chứa ít nhất 1 số</span>
            </div>
            <div className={'mt-1 flex flex-row items-center space-x-2'}>
              <img
                src={CheckSuccessIcon}
                alt={'success-icon'}
                className={classNames(
                  'h-4 w-4',
                  getValues('password') && (!errors.password?.message || !errors.password?.message?.includes('4'))
                    ? ''
                    : 'opacity-30'
                )}
              />
              <span className={' text-sm font-medium text-white'}>
                Chứa ít nhất 1 ký tự đặc biệt (Ví dụ: *, @, ~, ...)
              </span>
            </div>
            <div className={'mt-1 flex flex-row items-center space-x-2'}>
              <img
                src={CheckSuccessIcon}
                alt={'success-icon'}
                className={classNames(
                  'h-4 w-4',
                  getValues('password') && (!errors.password?.message || !errors.password?.message?.includes('1'))
                    ? ''
                    : 'opacity-30'
                )}
              />
              <span className={' text-sm font-medium text-white'}>Chứa ít nhất 1 chữ cái viết hoa</span>
            </div>
            <div className={'mt-1 flex flex-row items-center space-x-2'}>
              <img
                src={CheckSuccessIcon}
                alt={'success-icon'}
                className={classNames(
                  'h-4 w-4',
                  getValues('password') && (!errors.password?.message || !errors.password?.message?.includes('5'))
                    ? ''
                    : 'opacity-30'
                )}
              />
              <span className={' text-sm font-medium text-white'}>Dài tối thiểu 8 ký tự</span>
            </div>
          </div>
          <div className={'w-full sm:w-6/12'}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className={
                  'flex min-h-[280px] w-[480px] flex-col rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] p-[31px] shadow-base'
                }
              >
                <div className={'relative mt-2 h-full w-full'}>
                  <InputCustom
                      label={'fullName'}
                      placeholder={'Họ và tên'}
                      maxLength={16}
                      className={classNames('w-full !pr-10', errors.fullName ? 'border border-[#FC5555]' : '')}
                      autoFocus
                      register={register}
                      control={control}
                      required={{
                        required: 'Vui lòng nhập họ và tên',
                        validate: (val: string) => {
                          let fullNameError = '';
                          // if (!/[A-Z]/.test(val)) {
                          //   fullNameError = fullNameError + '1';
                          // }
                          if (val.length === 0) {
                            fullNameError = fullNameError + '5';
                          }
                          if (fullNameError) {
                            return fullNameError;
                          }
                        },
                      }}
                  />
                </div>
                {errors.fullName &&
                    (errors.fullName.type === 'validate' ? (
                        <span className={'mt-2 text-sm font-normal leading-[18px] text-[#F15836]'}>
                      Mật khẩu không hợp lệ
                    </span>
                    ) : (
                        <span className={'mt-2 text-sm font-normal leading-[18px] text-[#F15836]'}>
                      {errors.fullName.message}
                    </span>
                    ))}
                <div className={'relative mt-2 h-full w-full'}>
                  <InputCustom
                    label={'password'}
                    placeholder={'Mật khẩu'}
                    maxLength={16}
                    className={classNames('w-full !pr-10', errors.password ? 'border border-[#FC5555]' : '')}
                    type={PasswordInputType as string}
                    register={register}
                    control={control}
                    required={{
                      required: 'Vui lòng nhập mật khẩu',
                      validate: (val: string) => {
                        let passwordError = '';
                        if (!/[A-Z]/.test(val)) {
                          passwordError = passwordError + '1';
                        }
                        if (!/\d/.test(val)) {
                          passwordError = passwordError + '3';
                        }
                        if (!/[@$!%*#?&]/.test(val)) {
                          passwordError = passwordError + '4';
                        }
                        if (val.length < 8) {
                          passwordError = passwordError + '5';
                        }
                        if (passwordError) {
                          return passwordError;
                        }
                      },
                    }}
                  />
                  {watch('password') && (
                    <div className="absolute top-2.5 right-2 flex cursor-pointer items-center justify-center">
                      {ToggleIcon}
                    </div>
                  )}
                </div>
                {errors.password &&
                  (errors.password.type === 'validate' ? (
                    <span className={'mt-2 text-sm font-normal leading-[18px] text-[#F15836]'}>
                      Mật khẩu không hợp lệ
                    </span>
                  ) : (
                    <span className={'mt-2 text-sm font-normal leading-[18px] text-[#F15836]'}>
                      {errors.password.message}
                    </span>
                  ))}
                <div className={'relative mt-2 h-full w-full'}>
                  <InputCustom
                    placeholder={'Xác nhận lại mật khẩu'}
                    type={PasswordInputTypeRe as string}
                    className={classNames('w-full !pr-10', errors.confirmPassword ? 'border border-[#FC5555]' : '')}
                    register={register}
                    label={'confirmPassword'}
                    control={control}
                    required={{
                      required: 'Vui lòng nhập xác nhận mật khẩu',
                      validate: (val: string) => {
                        if (watch('password') !== val) {
                          return 'Xác nhận mật khẩu không trùng khớp';
                        }
                      },
                    }}
                  />
                  {watch('confirmPassword') && (
                    <div className="absolute top-2.5 right-2 flex cursor-pointer items-center justify-center">
                      {ToggleIconRe}
                    </div>
                  )}
                </div>
                {errors.confirmPassword && (
                  <span className={'mt-2 text-sm font-normal leading-[18px] text-[#F15836]'}>
                    {errors.confirmPassword.message}
                  </span>
                )}
                <ButtonCustom label={'Tiếp tục'} className={'mt-4 w-full uppercase'} type={'submit'} />
                <div className={'mt-[16px] flex w-full flex-col items-center'}>
                  <p className={' text-sm font-medium text-[#818181]'}>
                    Bằng việc đăng ký, bạn đã đồng ý với{' '}
                    <a className={'uppercase text-[#F15836]'} rel="noreferrer" href={'/terms'} target="_blank">
                      Điều khoản & điều kiện
                    </a>{' '}
                    và{' '}
                    <a className={'uppercase text-[#F15836]'} rel="noreferrer" href={'/privacy'} target="_blank">
                      Chính sách bảo mật
                    </a>{' '}
                    của chúng tôi
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
