import { AppStore, GooglePlay, QRCode } from 'assets/images';
import backIcon from 'assets/images/back.svg';
import errorCloseIcon from 'assets/images/error-close.svg';
import { ButtonCustom } from 'components/common/ButtonCustom';
import { InputCustom } from 'components/common/InputCustom';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { classNames, onKeyDownNumber } from 'utils/utils';
import configs from '../../configs/config';
import { PATH_PAGE } from '../../constant';
import { IAccount } from '../../entity/Account';
import useAuth from '../../hooks/useAuth';
import useCheckDeviceMobi from '../../hooks/useCheckDeviceMobi';
import usePasswordToggle from '../../hooks/usePasswordToggle';
import { postLogin } from '../../services/account';
import { LoginMobi } from './LoginMobi';
import { AxiosError } from 'axios';

export const Login = () => {
  const navigate = useNavigate();

  navigate('/')

  const { setToken, persist, setPersist } = useAuth();
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [isErrorLogin, setIsErrorLogin] = useState<boolean>(false);
  const [isAccountLocked, setIsAccountLocked] = useState<string>('');
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const isMobile = useCheckDeviceMobi();
  const [error, setError] = useState<string>('');

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<{
    password: string | null;
    phoneNumber: string | null;
  }>({
    defaultValues: {
      password: null,
      phoneNumber: null,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const useGetTokenByPassword = useMutation((data: IAccount) => postLogin(data), {
    onSuccess: (data) => {
      console.log('data', data);
      if (data?.data?.code === 510) {
        setIsAccountLocked(data?.data?.message);
        return;
      }
      if (data?.data?.code === 513) {
        setShowDialog(true);
        return;
      }
      if (data?.data?.code !== 1 && data?.data?.code !== 200) {
        setIsErrorLogin(true);
        setError(data?.data?.message);
        return;
      }
      if (persist) {
        localStorage.setItem('token', JSON.stringify(data));
      }

      if (!data?.data?.data?.source?.includes('SHOP360')) {
        setIsErrorLogin(true);
        setError('Tài khoản và mật khẩu không đúng, vui lòng kiểm tra lại!');
        return;
      }

      setToken && setToken(data.data.data.accessTokenInfo.accessToken);
      Cookies.set('token', data.data.data.accessTokenInfo.accessToken);
      navigate(PATH_PAGE.DASHBOARD);
    },
    onError: (error: AxiosError<any, any>) => {
      if (error?.response?.data?.message) {
        setIsErrorLogin(true);
        setError(error?.response?.data?.message);
      }
    },
  });

  const onSubmit = (data: any) => {
    const _phoneNumber = (data.phoneNumber + '').at(0) === '0' ? data.phoneNumber : `0${data.phoneNumber}`;
    if (persist) {
      localStorage.setItem('username', _phoneNumber + '');
      localStorage.setItem('ps', data.password + '');
    } else {
      localStorage.removeItem('username');
      localStorage.removeItem('ps');
    }

    // submit
    useGetTokenByPassword.mutate({
      username: _phoneNumber?.trim(),
      password: data.password,
      deviceId: configs.DEVICE_ID,
      firebaseToken: configs.FIREBASE_TOKEN,
      srcRq: configs.SRC_RQ,
      userType: configs.USER_TYPE,
    });
    // setShowDialog(true);
  };

  useEffect(() => {
    const _userName = localStorage.getItem('username');
    const _password = localStorage.getItem('ps');
    reset({
      phoneNumber: _userName || null,
      password: _password || null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const togglePersist = (event: any) => {
    setPersist && setPersist((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem('persist', persist + '');
  }, [persist]);

  return (
    <>
      {isMobile ? (
        <LoginMobi />
      ) : (
        <>
          {isAccountLocked ? (
            <div className={'flex h-full w-full flex-row items-center justify-center'}>
              <div className={'flex h-[400px] w-[450px] flex-col items-center justify-center space-y-6 text-center'}>
                <h1
                  className={
                    'flex flex-row items-center justify-center text-[35px] font-extrabold leading-[50px] text-white'
                  }
                >
                  <img
                    className="mr-4 cursor-pointer"
                    alt="back-icon"
                    src={backIcon}
                    onClick={() => setIsAccountLocked('')}
                  />
                  Tài khoản đã bị khóa
                </h1>
                <h2 className={'text-center  text-[16px] font-medium leading-[26px] text-white'}>{isAccountLocked}</h2>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)} onChange={() => isErrorLogin && setIsErrorLogin(false)} key={1}>
              <div className={'hidden w-full flex-col sm:flex-row sm:space-x-4 sm:px-10 md:flex'}>
                <div className={'flex w-full flex-col justify-start sm:w-6/12'}>
                  <h1 className={' text-[42px] font-extrabold uppercase leading-[60px] text-white'}>
                    Chính thức ra mắt app <span className={'text-[#FDFFAE]'}> Shop360</span>
                  </h1>
                  <h2 className={'mt-4 text-[20px] font-medium leading-[26px] text-white'}>
                    Nâng tầm trải nghiệm - Đột phá kinh doanh
                  </h2>
                  <div className="mt-8 flex gap-4">
                    <img src={QRCode} alt="QRCode" className="w-1/4 rounded-lg" />
                    <img
                      src={AppStore}
                      alt="AppStore"
                      className="w-1/3 cursor-pointer"
                      onClick={() => window.open('https://apps.apple.com/us/app/shop360/id6449772995', '_blank')}
                    />
                    <img
                      src={GooglePlay}
                      alt="GooglePlay"
                      className="w-1/3 cursor-pointer"
                      onClick={() =>
                        window.open('https://play.google.com/store/apps/details?id=vn.osp.shop360', '_blank')
                      }
                    />
                  </div>
                </div>
                <div className={'flex w-full justify-end sm:w-6/12'}>
                  <div
                    className={
                      'flex min-h-[280px] w-[480px] flex-col rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] p-[31px] shadow-base'
                    }
                  >
                    <h1 className={'text-[35px] font-extrabold leading-[50px] text-[#5B5B5B]'}>Đăng nhập</h1>
                    {isErrorLogin && (
                      <div className={'flex flex-row items-center text-sm text-[#818181]'}>
                        {/* <img className="mr-2" src={errorCloseIcon} alt="error-login" /> */}
                        <svg
                          className={'mr-2'}
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_579_29839)">
                            <path
                              d="M10.5 0.276367C4.97 0.276367 0.5 4.74637 0.5 10.2764C0.5 15.8064 4.97 20.2764 10.5 20.2764C16.03 20.2764 20.5 15.8064 20.5 10.2764C20.5 4.74637 16.03 0.276367 10.5 0.276367ZM14.8 14.5764C14.7075 14.6691 14.5976 14.7426 14.4766 14.7928C14.3557 14.843 14.226 14.8688 14.095 14.8688C13.964 14.8688 13.8343 14.843 13.7134 14.7928C13.5924 14.7426 13.4825 14.6691 13.39 14.5764L10.5 11.6864L7.61 14.5764C7.42302 14.7633 7.16943 14.8684 6.905 14.8684C6.64057 14.8684 6.38698 14.7633 6.2 14.5764C6.01302 14.3894 5.90798 14.1358 5.90798 13.8714C5.90798 13.7404 5.93377 13.6108 5.98387 13.4898C6.03398 13.3689 6.10742 13.2589 6.2 13.1664L9.09 10.2764L6.2 7.38637C6.01302 7.19939 5.90798 6.94579 5.90798 6.68137C5.90798 6.41694 6.01302 6.16334 6.2 5.97637C6.38698 5.78939 6.64057 5.68435 6.905 5.68435C7.16943 5.68435 7.42302 5.78939 7.61 5.97637L10.5 8.86637L13.39 5.97637C13.4826 5.88379 13.5925 5.81035 13.7135 5.76024C13.8344 5.71013 13.9641 5.68435 14.095 5.68435C14.2259 5.68435 14.3556 5.71013 14.4765 5.76024C14.5975 5.81035 14.7074 5.88379 14.8 5.97637C14.8926 6.06895 14.966 6.17886 15.0161 6.29982C15.0662 6.42079 15.092 6.55044 15.092 6.68137C15.092 6.8123 15.0662 6.94195 15.0161 7.06291C14.966 7.18387 14.8926 7.29379 14.8 7.38637L11.91 10.2764L14.8 13.1664C15.18 13.5464 15.18 14.1864 14.8 14.5764Z"
                              fill="url(#paint0_linear_579_29839)"
                            />
                          </g>
                          <defs>
                            <linearGradient
                              id="paint0_linear_579_29839"
                              x1="10.5"
                              y1="0.276367"
                              x2="10.5"
                              y2="20.2764"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#FF3F3F" />
                              <stop offset="1" stopColor="#E01D1D" />
                            </linearGradient>
                            <clipPath id="clip0_579_29839">
                              <rect width="20" height="20" fill="white" transform="translate(0.5 0.276367)" />
                            </clipPath>
                          </defs>
                        </svg>
                        {error || 'Tài khoản và mật khẩu không đúng. Vui lòng kiểm tra lại'}
                      </div>
                    )}
                    <div className={'flex flex-col'}>
                      <InputCustom
                        placeholder={'Số điện thoại'}
                        type={'tel'}
                        className={classNames(
                          'mt-2 w-full appearance-none outline-none',
                          errors.phoneNumber ? 'border border-[#FC5555]' : ''
                        )}
                        register={register}
                        label={'phoneNumber'}
                        control={control}
                        value={watch('phoneNumber') || ""}
                        required={{
                          required: 'Vui lòng nhập số điện thoại',
                        }}
                        autoComplete={'off'}
                        onKeyDown={onKeyDownNumber}
                        maxLength={12}
                      />
                      {errors.phoneNumber && (
                        <span className={'mt-2 text-[12px] font-normal leading-[18px] text-[#F15836]'}>
                          {errors?.phoneNumber?.message || '-'}
                        </span>
                      )}
                      <div className="relative h-full w-full">
                        <InputCustom
                          placeholder={'Mật khẩu'}
                          type={PasswordInputType as string}
                          className={classNames('mt-2 w-full', errors.password ? 'border border-[#FC5555]' : '')}
                          register={register}
                          label={'password'}
                          control={control}
                          autoComplete={'off'}
                          onPaste={(e: any) => {
                            e.preventDefault();
                            return false;
                          }}
                          onCopy={(e: any) => {
                            e.preventDefault();
                            return false;
                          }}
                          required={{
                            required: 'Vui lòng nhập mật khẩu',
                            pattern: '^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&]$',
                          }}
                        />
                        {watch('password') && (
                          <div className="absolute right-2 top-4 flex cursor-pointer items-center justify-center">
                            {ToggleIcon}
                          </div>
                        )}
                      </div>
                      {errors.password && (
                        <span className={'mt-2 text-[12px] font-normal leading-[18px] text-[#F15836]'}>
                          {errors?.password?.message}
                        </span>
                      )}
                      <div className={'mt-[8px] flex'}>
                        <div className={'w-fit !cursor-pointer'}>
                          <input
                            type={'checkbox'}
                            className={'cursor-pointer'}
                            id="remember-password"
                            onChange={togglePersist}
                            checked={persist}
                          />
                          <label
                            className={'ml-1 cursor-pointer text-sm font-medium text-[#818181]'}
                            htmlFor={'remember-password'}
                          >
                            Ghi nhớ mật khẩu
                          </label>
                        </div>
                        <p
                          onClick={
                            () =>
                              toast.error(
                                'Vui lòng liên hệ hotline 0896688688 hoặc email congtacvien.cskh@osp.com.vn để được hỗ trợ đổi mật khẩu!'
                              )
                            // setShowDialog(true)
                          }
                          className={'ml-auto mt-auto text-sm font-extrabold uppercase text-[#F15836]'}
                        >
                          Quên mật khẩu?
                        </p>
                      </div>
                      <ButtonCustom
                        label={'Đăng nhập'}
                        className={'mt-4 w-full uppercase'}
                        type={'submit'}
                        // type='button'
                        // onClick={() => setShowDialog(true)}
                        disabled={useGetTokenByPassword.isLoading}
                      />
                    </div>

                    <div className={'mt-[16px] flex w-full flex-col items-center'}>
                      <p className={'text-sm font-medium text-[#818181]'}>
                        Bạn chưa có tài khoản?{' '}
                        <a className={'font-extrabold uppercase text-[#F15836]'} href={PATH_PAGE.REGISTER}>
                          Đăng ký
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* <AppDownloadNotice showDialog={showDialog} setShowDialog={setShowDialog} /> */}
            </form>
          )}
        </>
      )}
    </>
  );
};
