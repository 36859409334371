import { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import InjectAxiosInterceptors from 'services/InjectAxiosInterceptor';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { HeaderCMS } from './components/header/HeaderCMS';
import { Main } from './pages/main';
import { Register } from './pages/resigter';
import { Login } from './pages/login';
// import { ForwardPassword } from './pages/forward-password';
import { PATH_PAGE } from './constant';
import { HeaderAdmin } from './components/header/HeaderAdmin';
import { Dashboard } from './pages/dashboard';
import { AuthProvider } from './context/AuthProvider';
import CheckAuth from './features/auth/CheckAuth';
import { ProductsCategory } from './pages/products/category';
import { ProductsServicePlan } from './pages/products/service-plan';
import { ProductsSim } from './pages/products/sim';
import { StatisticTransaction } from './pages/statistic/transaction';
import RequireAuth from './features/auth/RequireAuth';
import { StatisticReportsCollaborators } from './pages/statistic/reports-collaborators';
import { AccountCollaborator } from './pages/account-collaborator';
import { Profile } from './pages/profile';
import { ConfigPayment } from './pages/config-payment';
import { ChangePassword } from './pages/change-password';
import { resolveValue, Toaster, ToastIcon } from 'react-hot-toast';
import { InfoTrans } from './pages/statistic/info';
import { InfoPackage } from './pages/products/service-plan/Info/InfoPackage';
import { InfoSim } from './pages/products/sim/InfoSim';
import { Transition } from '@headlessui/react';
import { CreatePayment } from './pages/create-payment';
import { PlanPage } from './pages/plan';
import { InstructionsPage } from './pages/instructions';
import { NewsPage } from 'pages/news';
import { TermsPage } from './pages/terms';
import { PrivacyPage } from './pages/privacy';
import { PoliciesPage } from './pages/policies';
import { DetailNewsPage } from 'pages/news/detail';
import { InfoPayment } from 'pages/statistic/info-payment';
import { QuestionsPage } from 'pages/questions';
import { OperatingRegulationsPage } from 'pages/operating-regulations';
import { SupportPage } from 'pages/support';
import { NotificationPage } from 'pages/notifications';
import DownloadApp from 'pages/download-app';
import LogoVertical from './logoVertical.svg';
import SimInstructions from 'pages/sim-instructions';
import UsageInstructions from 'pages/usage-instructions';
import { TransactionTerms } from 'pages/transaction-terms';
import { DeliveryPolicy } from 'pages/delivery-policy';
import { PaymentPolicy } from 'pages/payment-policy';
import { NotFound } from 'pages/NotFound';

const exceptionPaths = [
  'policies',
  'terms',
  'privacy',
  'plan',
  'news',
  'instructions',
  'download',
  'hdsd',
  'question',
  'transaction-terms',
  'delivery-policy',
  'payment-policy',
];

const AppRoutes = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  // if (!exceptionPaths.some((item) => location.pathname.includes(item))) {
  // return (
  //   <div
  //     style={{
  //       width: '100vw',
  //       height: '100vh',
  //       display: 'flex',
  //       flexDirection: 'column',
  //       justifyContent: 'center',
  //       alignItems: 'center',
  //       fontSize: 28,
  //     }}
  //   >
  //     <img src={LogoVertical} alt="logo" style={{ marginBottom: 10, height: 200 }} />
  //     Hệ thống đang nâng cấp
  //   </div>
  // );
  // return <Navigate to="/download" />;
  // }

  return (
    <AuthProvider>
      <InjectAxiosInterceptors />
      <Routes>
        {/*public routes*/}
        <Route path={'/'} element={<Main />} />
        <Route path={'/instructions'} element={<InstructionsPage />} />
        <Route path={'/hdkh'} element={<SimInstructions />} />
        <Route path={'/hdsd'} element={<UsageInstructions />} />

        <Route path={'/terms'} element={<TermsPage />} />
        <Route path={'/policies'} element={<PoliciesPage />} />
        <Route path={'/privacy'} element={<PrivacyPage />} />
        <Route path={'/questions'} element={<QuestionsPage />} />
        <Route path={PATH_PAGE.NEWS}>
          <Route path={''} element={<NewsPage />} />
          <Route path={':pathName/:id'} element={<DetailNewsPage />} />
        </Route>
        {/* <Route path="/operating-regulations" element={<OperatingRegulationsPage />} /> */}
        <Route path={'download'} element={<DownloadApp />} />
        <Route path={'transaction-terms'} element={<TransactionTerms />} />
        <Route path={'delivery-policy'} element={<DeliveryPolicy />} />
        <Route path={'payment-policy'} element={<PaymentPolicy />} />
        <Route element={<RequireAuth />}>
          {/* <Route
            path={"*"}
            element={
              <HeaderAdmin>
                <NotFound />
              </HeaderAdmin>
            }
          /> */}
          <Route
            path={PATH_PAGE.DASHBOARD}
            element={
              <HeaderAdmin>
                <Dashboard />
              </HeaderAdmin>
            }
          />
          <Route
            path={PATH_PAGE.PRODUCTS_CATEGORY}
            element={
              <HeaderAdmin>
                <ProductsCategory />
              </HeaderAdmin>
            }
          />
          <Route
            path={PATH_PAGE.SUPPORT}
            element={
              <HeaderAdmin>
                <SupportPage />
              </HeaderAdmin>
            }
          />
          <Route
            path={PATH_PAGE.NOTIFICATIONS}
            element={
              <HeaderAdmin>
                <NotificationPage />
              </HeaderAdmin>
            }
          />
          <Route path={PATH_PAGE.PRODUCTS_SIM}>
            <Route
              path={''}
              element={
                <HeaderAdmin>
                  <ProductsSim />
                </HeaderAdmin>
              }
            />
            <Route
              path={':id'}
              element={
                <HeaderAdmin>
                  <InfoSim />
                </HeaderAdmin>
              }
            />
          </Route>
          <Route path={PATH_PAGE.PRODUCTS_SERVICE_PLAN}>
            <Route
              path={''}
              element={
                <HeaderAdmin>
                  <ProductsServicePlan />
                </HeaderAdmin>
              }
            />
            <Route
              path={':id'}
              element={
                <HeaderAdmin>
                  <InfoPackage />
                </HeaderAdmin>
              }
            />
            <Route
              path={'vnp/:id'}
              element={
                <HeaderAdmin>
                  <InfoPackage />
                </HeaderAdmin>
              }
            />
          </Route>
          <Route path={PATH_PAGE.STATISTIC_TRANSACTION}>
            <Route
              path={''}
              element={
                <HeaderAdmin>
                  <StatisticTransaction />
                </HeaderAdmin>
              }
            />
            <Route
              path={'info/:id'}
              element={
                <HeaderAdmin>
                  <InfoTrans />
                </HeaderAdmin>
              }
            />
          </Route>
          <Route
            path={PATH_PAGE.STATISTIC_REPORTS_COLLABORATORS}
            element={
              <HeaderAdmin>
                <StatisticReportsCollaborators />
              </HeaderAdmin>
            }
          />
          {/* <Route
            path={PATH_PAGE.CREATE_PAYMENT}
            element={
              <HeaderAdmin>
                <CreatePayment />
              </HeaderAdmin>
            }
          ></Route> */}
          <Route path={PATH_PAGE.ACCOUNT_COLLABORATOR}>
            <Route path="" element={<AccountCollaborator />} />
            <Route
              path={'info-payment'}
              element={
                <HeaderAdmin>
                  <InfoPayment />
                </HeaderAdmin>
              }
            />
          </Route>
          {/* <Route
            path={PATH_PAGE.PROFILE}
            element={
              <HeaderAdmin>
                <Profile />
              </HeaderAdmin>
            }
          /> */}
          {/* <Route
            path={PATH_PAGE.CONFIG_PAYMENT}
            element={
              <HeaderAdmin>
                <ConfigPayment />
              </HeaderAdmin>
            }
          /> */}
          <Route
            path={PATH_PAGE.CHANGE_PASSWORD}
            element={
              <HeaderAdmin>
                <ChangePassword />
              </HeaderAdmin>
            }
          />
        </Route>
        <Route element={<CheckAuth />}>
          <Route
            path={'register'}
            element={
              <HeaderCMS>
                <Register />
              </HeaderCMS>
            }
          />
          <Route
            path={'login'}
            element={
              <HeaderCMS>
                <Login />
              </HeaderCMS>
            }
          />
        </Route>
        {/* <Route
                path={'forward-password'}
                element={
                  <HeaderCMS>
                    <ForwardPassword />
                  </HeaderCMS>
                }
              /> */}
        <Route path={'/plan'} element={<PlanPage />}></Route>
        <Route path={'*'} element={<Navigate to="/" />} />
      </Routes>
    </AuthProvider>
  );
};

export const App = () => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  );
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
        <Toaster position="top-right">
          {(t) => (
            <Transition
              appear
              show={t.visible}
              className="flex max-w-[400px] transform rounded rounded-t-[16px] rounded-bl-[16px] bg-white p-4 shadow-lg"
              enter="transition-all duration-150"
              enterFrom="opacity-0 scale-50"
              enterTo="opacity-100 scale-100"
              leave="transition-all duration-150"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-75"
            >
              <ToastIcon toast={t} />
              <p className="ml-2 px-2">{resolveValue(t.message, t)}</p>
            </Transition>
          )}
        </Toaster>
      </QueryClientProvider>
    </>
  );
};
