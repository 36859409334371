import {
  add,
  compareAsc,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isAfter,
  isBefore,
  isEqual,
  isSameMonth,
  parse,
  set,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { classNames } from 'utils/utils';
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CalendarBackIcon, CalendarNextIcon } from 'assets/images';

enum ETypeDatePicker {
  Day = 0,
  Month = 1,
  Year = 2,
}

const MONTH_FORMAT = 'MM/yyyy';

const YEAR_FORMAT = 'yyyy';

const colStartClasses = ['', 'col-start-2', 'col-start-3', 'col-start-4', 'col-start-5', 'col-start-6', 'col-start-7'];

interface IPopupSelectDayProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  calendarPopupRef: RefObject<HTMLDivElement>;
  currentMonth: string;
  setCurrentMonth: (currentMonth: string) => void;
  otherDay?: Date;
  selectedDay: Date;
  today: Date;
  onChange: (selectedDay: Date | null, count?: number) => void;
  isDisabledSelectToday?: boolean;
  setTodayToDate: (date: Date) => void;
}

export const PopupSelectDay = ({
  isOpen,
  setIsOpen,
  calendarPopupRef,
  currentMonth,
  setCurrentMonth,
  otherDay,
  today,
  selectedDay,
  onChange,
  setTodayToDate,
  isDisabledSelectToday,
}: IPopupSelectDayProps) => {
  const [status, setStatus] = useState<ETypeDatePicker>(ETypeDatePicker.Day);
  const [yearList, setYearList] = useState<Array<string>>([]);
  const firstDayCurrentMonth = useMemo(() => parse(currentMonth, MONTH_FORMAT, new Date()), [currentMonth]);
  const timesRef = useRef<{ times: number }>({ times: 0 });
  const days = useMemo(
    () =>
      eachDayOfInterval({
        start: startOfWeek(startOfMonth(firstDayCurrentMonth)),
        end: endOfWeek(endOfMonth(firstDayCurrentMonth)),
      }),
    [firstDayCurrentMonth]
  );

  const previousMonth = useCallback(() => {
    const firstDayPreviousMonth = add(firstDayCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayPreviousMonth, MONTH_FORMAT));
  }, [firstDayCurrentMonth]);

  const nextMonth = useCallback(() => {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayNextMonth, MONTH_FORMAT));
  }, [firstDayCurrentMonth]);

  const previousYear = useCallback(() => {
    const firstDayPreviousMonth = add(firstDayCurrentMonth, { years: -1 });
    setCurrentMonth(format(firstDayPreviousMonth, MONTH_FORMAT));
  }, [firstDayCurrentMonth]);

  const nextYear = useCallback(() => {
    const firstDayNextMonth = add(firstDayCurrentMonth, { years: 1 });
    setCurrentMonth(format(firstDayNextMonth, MONTH_FORMAT));
  }, [firstDayCurrentMonth]);

  const rangerSelectedDay = useMemo(() => {
    if (!otherDay || !selectedDay) {
      return null;
    }
    return [selectedDay, otherDay].sort(compareAsc);
  }, [selectedDay, otherDay]);

  useEffect(() => {
    setCurrentMonth(format(selectedDay || today, MONTH_FORMAT));
    const currentYear = currentMonth.split('/').at(-1) + '';
    setYearList(
      Array(10)
        .fill(null)
        .map((_, index: number) => `${currentYear.substring(0, 3)}${index}`)
    );
  }, []);

  const isSelectedMonth = (month: number) => {
    const _currentMonth = parse(currentMonth, MONTH_FORMAT, selectedDay);
    return (
      selectedDay &&
      isEqual(
        new Date(selectedDay.getFullYear(), month),
        new Date(_currentMonth.getFullYear(), _currentMonth.getMonth())
      )
    );
  };

  const isSelectedYear = (_year: string) => {
    return currentMonth?.split('/')?.at(-1) === _year;
  };

  useEffect(() => {
    const currentYear = currentMonth.split('/').at(-1) + '';
    setYearList(
      Array(10)
        .fill(null)
        .map((_, index: number) => `${currentYear.substring(0, 3)}${index}`)
    );
  }, [currentMonth]);

  const renderBody = () => {
    switch (status) {
      case ETypeDatePicker.Day:
        return (
          <>
            <div className="datepicker-header">
              <div className="datepicker-title hidden bg-white px-2 py-3 text-center font-semibold dark:bg-gray-700 dark:text-white"></div>
              <div className="datepicker-controls mb-2 flex justify-between">
                <button
                  type="button"
                  onClick={() => setStatus(ETypeDatePicker.Month)}
                  className="view-switch rounded-lg bg-white py-2.5 px-5 font-semibold text-[#44494D] hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
                >
                  {`Tháng ${format(firstDayCurrentMonth, MONTH_FORMAT)}`}
                </button>
                <div className={'flex flex-row space-x-0.5'}>
                  <button
                    type="button"
                    className="prev-btn rounded-lg bg-white  p-1.5 text-lg text-gray-500 hover:bg-gray-100 hover:text-gray-900"
                    onClick={() => previousMonth()}
                  >
                    <img src={CalendarBackIcon} alt={'back-calendar'} className={'h-4 w-4'} />
                  </button>
                  <button
                    type="button"
                    className="next-btn rounded-lg bg-white p-1.5 text-lg text-gray-500 hover:bg-gray-100 hover:text-gray-900"
                    onClick={() => nextMonth()}
                  >
                    <img src={CalendarNextIcon} alt={'next-calendar'} className={'h-4 w-4'} />
                  </button>
                </div>
              </div>
            </div>
            <div className="datepicker-main p-1">
              <div className="datepicker-view flex">
                <div className="days">
                  <div className="days-of-week mb-1 grid grid-cols-7">
                    <span className="dow h-6 text-center text-sm font-extrabold leading-6 text-[#44494D] dark:text-gray-400">
                      S
                    </span>
                    <span className="dow h-6 text-center text-sm font-extrabold leading-6 text-[#44494D] dark:text-gray-400">
                      M
                    </span>
                    <span className="dow h-6 text-center text-sm font-extrabold leading-6 text-[#44494D] dark:text-gray-400">
                      T
                    </span>
                    <span className="dow h-6 text-center text-sm font-extrabold leading-6 text-[#44494D] dark:text-gray-400">
                      W
                    </span>
                    <span className="dow h-6 text-center text-sm font-extrabold leading-6 text-[#44494D] dark:text-gray-400">
                      T
                    </span>
                    <span className="dow h-6 text-center text-sm font-extrabold leading-6 text-[#44494D] dark:text-gray-400">
                      F
                    </span>
                    <span className="dow h-6 text-center text-sm font-extrabold leading-6 text-[#44494D] dark:text-gray-400">
                      S
                    </span>
                  </div>
                  <div className="grid w-64 grid-cols-7 ">
                    {days.map((day, index) => {
                      return (
                        <div
                          key={`${day.toString()}-${index}`}
                          className={classNames(index === 0 && colStartClasses[getDay(day)], 'py-1.5')}
                        >
                          <span
                            className={classNames(
                              'datepicker-cell block flex-1 cursor-pointer border-0 text-center leading-9 text-[#727272]',
                              rangerSelectedDay &&
                                isAfter(day, rangerSelectedDay[0]) &&
                                isBefore(day, rangerSelectedDay[1]) &&
                                'bg-[#FF6341]/20 text-[#44494D]',
                              selectedDay && isEqual(day, selectedDay) && 'text-white',
                              selectedDay &&
                                (!rangerSelectedDay ||
                                  !isAfter(day, rangerSelectedDay[0]) ||
                                  !isBefore(day, rangerSelectedDay[1])) &&
                                !isEqual(day, selectedDay) &&
                                !isSameMonth(day, firstDayCurrentMonth) &&
                                'text-[#D1D1D1]',
                              selectedDay &&
                                isEqual(day, selectedDay) &&
                                !rangerSelectedDay &&
                                'rounded-t-[12px] rounded-bl-[12px] bg-gradient-to-r from-[#F15836] to-[#FF924F] !text-white',
                              rangerSelectedDay &&
                                isEqual(day, rangerSelectedDay[0]) &&
                                'rounded-t-[12px] rounded-bl-[12px] bg-gradient-to-r from-[#F15836] to-[#FF924F] !text-white',
                              rangerSelectedDay &&
                                isEqual(day, rangerSelectedDay[1]) &&
                                !isEqual(rangerSelectedDay[0], rangerSelectedDay[1]) &&
                                'rounded-t-[12px] rounded-br-[12px] bg-gradient-to-r from-[#F15836] to-[#FF924F] !text-white',
                              selectedDay &&
                                !isEqual(day, selectedDay) &&
                                'hover:rounded-t-[12px] hover:rounded-bl-[12px] hover:bg-[#FF6341]/20 hover:text-[#44494D]',
                              isDisabledSelectToday && isBefore(today, day)
                                ? 'cursor-not-allowed bg-[#44494D]/5 text-[#44494D]/60'
                                : 'cursor-pointer'
                            )}
                            onClick={() => {
                              if (isDisabledSelectToday && isBefore(today, day)) {
                                return;
                              }
                              timesRef.current.times += 1;
                              if (timesRef.current.times > 1) {
                                setIsOpen(false);
                              }
                              onChange(day, timesRef.current.times);
                            }}
                          >
                            <time dateTime={format(day, 'yyyy-MM-dd')}>{format(day, 'd')}</time>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      case ETypeDatePicker.Month:
        return (
          <>
            <div className="datepicker-header">
              <div className="datepicker-title hidden bg-white px-2 py-3 text-center font-semibold dark:bg-gray-700 dark:text-white"></div>
              <div className="datepicker-controls mb-2 flex justify-between">
                <button
                  type="button"
                  onClick={() => setStatus(ETypeDatePicker.Year)}
                  className="view-switch rounded-lg bg-white py-2.5 px-5 font-extrabold font-semibold text-[#44494D] hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:bg-gray-700 dark:text-white dark:hover:bg-gray-600"
                >
                  {`${format(firstDayCurrentMonth, YEAR_FORMAT)}`}
                </button>
                <div className={'flex flex-row space-x-0.5'}>
                  <button
                    type="button"
                    className="prev-btn rounded-lg bg-white  p-1.5 text-lg text-gray-500 hover:bg-gray-100 hover:text-gray-900"
                    onClick={() => previousYear()}
                  >
                    <img src={CalendarBackIcon} alt={'back-calendar'} className={'h-4 w-4'} />
                  </button>
                  <button
                    type="button"
                    className="next-btn rounded-lg bg-white p-1.5 text-lg text-gray-500 hover:bg-gray-100 hover:text-gray-900"
                    onClick={() => nextYear()}
                  >
                    <img src={CalendarNextIcon} alt={'next-calendar'} className={'h-4 w-4'} />
                  </button>
                </div>
              </div>
            </div>
            <div className="datepicker-main p-1">
              <div className="datepicker-view flex">
                <div className="mb-3 grid w-64 grid-cols-4 ">
                  {Array(12)
                    .fill(null)
                    .map((_, i) => (
                      <div
                        key={i}
                        onClick={() => {
                          const _month = parse(currentMonth, MONTH_FORMAT, new Date());
                          const _newMonth = set(_month, { month: i });
                          setCurrentMonth(format(_newMonth, MONTH_FORMAT));
                          setStatus(ETypeDatePicker.Day);
                        }}
                      >
                        <div
                          className={`datepicker-cell block flex-1 cursor-pointer border-0 p-2 text-center text-[#727272] ${
                            isSelectedMonth(i)
                              ? 'rounded-t-[12px] rounded-br-[12px] bg-gradient-to-r from-[#F15836] to-[#FF924F] !text-white'
                              : 'hover:rounded-t-[12px] hover:rounded-bl-[12px] hover:bg-[#FF6341]/20 hover:text-[#44494D]'
                          }`}
                        >
                          Tháng {i + 1}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </>
        );
      case ETypeDatePicker.Year:
        return (
          <>
            <div className="datepicker-header">
              <div className="datepicker-title hidden bg-white px-2 py-3 text-center font-semibold dark:bg-gray-700 dark:text-white"></div>
              <div className="datepicker-controls mb-2 flex justify-between">
                <div></div>
                <div className={'flex flex-row space-x-0.5'}>
                  <button
                    type="button"
                    disabled={yearList.at(0) === '1970'}
                    className="prev-btn rounded-lg bg-white  p-1.5 text-lg text-gray-500 hover:bg-gray-100 hover:text-gray-900"
                    onClick={() => setYearList((v) => v.map((i) => (Number(i) - 10).toString()))}
                  >
                    <img src={CalendarBackIcon} alt={'back-calendar'} className={'h-4 w-4'} />
                  </button>
                  <button
                    type="button"
                    className="next-btn rounded-lg bg-white p-1.5 text-lg text-gray-500 hover:bg-gray-100 hover:text-gray-900"
                    onClick={() => setYearList((v) => v.map((i) => (Number(i) + 10).toString()))}
                  >
                    <img src={CalendarNextIcon} alt={'next-calendar'} className={'h-4 w-4'} />
                  </button>
                </div>
              </div>
            </div>
            <div className="datepicker-main p-1">
              <div className="datepicker-view flex">
                <div className="mb-3 grid w-64 grid-cols-2 gap-2">
                  {yearList.map((value) => (
                    <div
                      key={value}
                      onClick={() => {
                        const _month = [currentMonth.split('/')[0], value];
                        setCurrentMonth(_month.join('/'));
                        setStatus(ETypeDatePicker.Month);
                      }}
                    >
                      <div
                        className={`datepicker-cell block flex-1 cursor-pointer border-0 p-2 text-center text-[#727272] ${
                          isSelectedYear(value)
                            ? 'rounded-t-[12px] rounded-br-[12px] bg-gradient-to-r from-[#F15836] to-[#FF924F] !text-white'
                            : 'hover:rounded-t-[12px] hover:rounded-bl-[12px] hover:bg-[#FF6341]/20 hover:text-[#44494D]'
                        }`}
                      >
                        {value}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {isOpen && (
        <div
          ref={calendarPopupRef}
          className="absolute right-0 z-[100] mt-3 w-fit max-w-sm transform overflow-hidden rounded-lg px-4 sm:px-0 lg:max-w-3xl"
        >
          <div className="datepicker datepicker-inline active block">
            <div className="datepicker-picker inline-block rounded-lg bg-white p-4 shadow-lg ring-1 ring-black ring-opacity-5 dark:bg-gray-700">
              {renderBody()}
              <div className="datepicker-footer">
                <div className="datepicker-controls flex space-x-2">
                  <div
                    className={
                      'h-fit w-1/2 cursor-pointer rounded-t-[10px] rounded-bl-[10px] bg-gradient-to-r from-[#F15836] to-[#FF924F] p-[2px]'
                    }
                  >
                    <button
                      type="button"
                      className="button clear-btn w-full rounded-t-[8px] rounded-bl-[8px] bg-white px-5 py-2 text-center"
                      onClick={() => {
                        // onChange(null);
                        setIsOpen(false);
                      }}
                    >
                      <span
                        className={
                          'bg-gradient-to-r from-[#F15836] to-[#FF924F] bg-clip-text text-sm font-extrabold text-transparent'
                        }
                      >
                        Bỏ qua
                      </span>
                    </button>
                  </div>
                  <button
                    type="button"
                    className="button today-btn w-1/2 cursor-pointer rounded-t-[8px] rounded-bl-[8px] bg-gradient-to-l from-[#F15836] to-[#FF924F] px-5 py-2 text-center"
                    onClick={() => {
                      setTodayToDate && setTodayToDate(today);
                      setIsOpen(false);
                    }}
                  >
                    <span className={'text-sm font-extrabold text-white'}>Hôm nay</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
