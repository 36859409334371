import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { TableCustom } from 'components/common/TableCustom';

interface ITableCustom {
  dataRows: Array<any>;
}

export const TableCollaborator = ({ dataRows }: ITableCustom) => {
  const navigate = useNavigate();
  const renderRow = useCallback((row: any) => {
    return (
      <tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
        <td scope="row" className="px-6 py-4 text-center">
          {row.stt}
        </td>
        <td className="px-2 py-4 text-center">{row.collaborator}</td>
        <td className="px-2 py-4 text-center">{row.name}</td>
        <td className="px-2 py-4 text-center">{row.time}</td>
        <td className="px-2 py-4 text-center">
          {row.status ? (
            <div className={'flex flex-row items-center justify-center'}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8 12C6.93913 12 5.92172 11.5786 5.17157 10.8284C4.42143 10.0783 4 9.06087 4 8C4 6.93913 4.42143 5.92172 5.17157 5.17157C5.92172 4.42143 6.93913 4 8 4C9.06087 4 10.0783 4.42143 10.8284 5.17157C11.5786 5.92172 12 6.93913 12 8C12 9.06087 11.5786 10.0783 10.8284 10.8284C10.0783 11.5786 9.06087 12 8 12Z"
                  fill="#8EC165"
                />
              </svg>
              <span>Đang hoạt động</span>
            </div>
          ) : (
            <div className={'flex flex-row items-center justify-center'}>
              <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.5 12C7.43913 12 6.42172 11.5786 5.67157 10.8284C4.92143 10.0783 4.5 9.06087 4.5 8C4.5 6.93913 4.92143 5.92172 5.67157 5.17157C6.42172 4.42143 7.43913 4 8.5 4C9.56087 4 10.5783 4.42143 11.3284 5.17157C12.0786 5.92172 12.5 6.93913 12.5 8C12.5 9.06087 12.0786 10.0783 11.3284 10.8284C10.5783 11.5786 9.56087 12 8.5 12Z"
                  fill="#A1A1AA"
                />
              </svg>
              <span>Dừng hoạt động</span>
            </div>
          )}
        </td>
      </tr>
    );
  }, []);
  return <TableCustom labelHeaders={labelHeader} dataRows={dataRows} renderRow={renderRow} />;
};

const labelHeader = [
  {
    label: 'STT',
    className: 'text-gray-600 font-semibold text-[15px] w-[65px]',
  },
  {
    label: 'Cộng tác viên',
    className: 'text-gray-600 font-semibold text-[15px]',
  },
  {
    label: 'Họ và tên',
    className: 'text-gray-600 font-semibold text-[15px]',
  },
  {
    label: 'Thời gian đăng ký',
    className: 'text-gray-600 font-semibold text-[15px]',
  },
  {
    label: 'Trạng thái',
    className: 'text-gray-600 font-semibold text-[15px]',
  },
];
