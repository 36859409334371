import { SpinCustom } from 'components/common/SpinCustom';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { getInfoPayment } from 'services/payment';
import { QUERY_KEY } from '../../../constant';
import { AdjustPayment } from './AdjustPayment';
import { BodyInfoPayment } from './BodyInfoPayment';
import { EventPayment } from './EventPayment';

export const InfoPayment = () => {
  const [searchParams] = useSearchParams();
  const transCode = searchParams.get('transCode');
  const transType = searchParams.get('transType');
  const stt = searchParams.get('stt');
  const { data, isLoading } = useQuery(
    QUERY_KEY.TRANS_PAYMENT_INFO,
    () =>
      getInfoPayment({
        transaction: transCode,
        transactionType: Number(transType),
        transactionId: Number(stt),
      }),
    { enabled: !!transType && !!stt }
  );

  const infoData = useMemo(() => {
    return data?.data?.data;
  }, [data]);

  return (
    <>
      {isLoading && <SpinCustom />}
      {/* Loại giao dịch (1 gói cước, 2 sim số, 3 thanh toán, 4 sự kiện, 5 OSP điều chỉnh, 6 duy trì) */}
      {infoData && transType === '4' && <EventPayment infoData={infoData} />}
      {infoData && (transType === '5' || transType === '6') && <AdjustPayment infoData={infoData} />}
      {infoData && transType === '3' && <BodyInfoPayment infoData={infoData} />}
    </>
  );
};
