import { IAccount, IChangePassword, IRegisterAccount } from '../entity/Account';
import { apiAuth } from './api/auth';
import { sendGetRequest, sendPostRequest, sendPostRequestAuth } from './index';

export const postLogin = (formLogin: IAccount) => sendPostRequestAuth(apiAuth.getTokenByPassword, formLogin);

export const getUserInfo = () => sendGetRequest(apiAuth.getUserInfo);

export const registerAccount = (formRegister: IRegisterAccount) =>
  sendPostRequest(apiAuth.sendPostRegister, formRegister);

export const postChangePassword = (formChangePassword: IChangePassword) =>
  sendPostRequest(apiAuth.changePassword, formChangePassword);

export const postCheckMsisdnIsExist = (msisdn: string) => sendPostRequest(apiAuth.msisdnIsExist, { msisdn });
