import { FooterMobi } from 'components/footer/FooterMobi';
import { HeaderCMSCommon } from '../../components/header/HeaderCMSCommon';
import { useState } from 'react';
import ScrollToTop from 'components/common/ScrollToTop';

export const TermsPage = () => {
  const [indexDataCarousel, setIndexDataCarousel] = useState(0);
  return (
    <>
      <ScrollToTop />
      <HeaderCMSCommon />
      <div className={'w-full bg-[#F8F8F8] pt-24'}>
        <div className={'mx-auto w-full max-w-[85rem] px-4'}>
          <div className={'relative h-52 w-full rounded-[16px] bg-[#D9D9D9] md:h-[450px]'}>
            <div className={'absolute bottom-0 mx-auto hidden w-full flex-row justify-center space-x-4 pb-4 md:flex'}>
              {Array.from({ length: 3 }).map((item, index) => {
                if (index === indexDataCarousel) {
                  return (
                    <svg
                      className={'cursor-pointer'}
                      width="38"
                      height="16"
                      viewBox="0 0 38 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect x="0.828125" y="0.163574" width="36.3507" height="15" rx="7.5" fill="#F15836" />
                    </svg>
                  );
                }
                return (
                  <svg
                    onClick={() => setIndexDataCarousel(index)}
                    className={'cursor-pointer'}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="8" cy="8" r="8" fill="#818181" />
                  </svg>
                );
              })}
            </div>
          </div>
          <div className={'mx-auto w-full max-w-[62rem] py-16'}>
            <h1 className={'text-[22px] font-extrabold leading-[26px] text-baseColor'}>ĐIỂU KHOẢN & ĐIỀU KIỆN</h1>
            <div className={'mt-6 grid grid-cols-1 gap-6'}>
              <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut tellus nisi, consectetur egestas. Varius
                montes, pulvinar eget arcu felis eros, sit lectus. Velit hendrerit in arcu semper cras mi consequat
                massa egestas. Amet, massa lacus, vitae egestas lectus phasellus ornare. Duis lacus, commodo malesuada
                urna enim purus. Feugiat accumsan pellentesque cras semper et turpis. Urna rutrum diam morbi gravida
                nisl. Urna, libero, arcu risus, egestas tempus consectetur vitae. Urna porta augue gravida est morbi
                interdum consectetur. Vel mattis vestibulum dui vitae volutpat ante. Velit eros nunc, risus tortor vel
                purus volutpat. Nunc, pellentesque pharetra, viverra lorem ultricies in integer dui nisl. Diam laoreet
                ut phasellus neque elementum lacus arcu, neque. Mauris nunc aliquam sed sed quis condimentum. Et
                hendrerit leo commodo, porttitor enim pulvinar pharetra, faucibus aliquam. Dolor potenti elit nulla
                mattis eu gravida maecenas. Eu elementum, id viverra eget aenean tellus nunc risus. Eget a in amet, a.
                Senectus neque augue tempus velit. Facilisis velit ut a, auctor diam sagittis urna eu non. Sed tellus
                convallis ut semper tristique suscipit nam eget. Dapibus proin est volutpat pharetra convallis. Tortor
                adipiscing arcu ornare elit tempor faucibus mauris ac. Sit egestas penatibus risus sit donec sed. Ut
                tristique in nullam turpis.
              </p>
              <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                Aliquet pellentesque odio dui amet lacus, quis. Sem ornare nibh nascetur sit sem. Vitae, ut metus
                pulvinar nibh eu. Faucibus volutpat nibh augue enim egestas. At nulla maecenas pellentesque egestas quis
                aliquam sed bibendum. Massa ac suspendisse eget non odio consequat sit. Tempus sapien aliquam nec diam
                et etiam tellus velit scelerisque. Curabitur vulputate feugiat amet eget nunc turpis posuere viverra
                elementum. Neque nunc dolor est augue nullam rutrum. Ac vel volutpat ut sit. Convallis nisl sit eu in
                purus pharetra, at quam urna.
              </p>
              <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                Maecenas lobortis amet urna maecenas. Aliquam, ut habitant non neque ullamcorper leo euismod habitant
                urna. Malesuada volutpat arcu vel risus. Vitae duis at maecenas vitae sit arcu, volutpat tortor. Proin
                sed orci, egestas sit turpis curabitur dui. Tristique enim eget pretium arcu purus libero egestas
                pharetra dictumst. Leo metus ac nascetur sed nisl in risus. Sed viverra sed maecenas justo ultrices.
                Aliquam enim sodales vitae eget et nunc. Dolor cum augue amet nulla ut id rhoncus eu. Ornare ornare
                integer at vestibulum. Turpis sapien lectus arcu, sem a cursus tristique sit. Orci commodo cursus
                volutpat dui aliquet vitae semper accumsan tellus. Nullam lectus congue congue donec massa felis.
                Euismod pretium justo, est, nibh euismod sed quisque tincidunt.
              </p>
              <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                Nisl, neque vestibulum, nibh tortor congue in sagittis pellentesque nisl. Sagittis facilisis eget donec
                ipsum maecenas. Arcu porta penatibus tempor elit ac facilisis. Gravida accumsan nulla iaculis commodo
                pharetra. Quis facilisis scelerisque ut donec orci morbi lorem gravida fermentum. Amet id et lectus
                magnis. Nullam leo ut nibh varius pharetra arcu dictum at. A ut laoreet nec maecenas dignissim nec.
                Metus, lectus egestas mollis bibendum velit magna dolor leo. Mattis purus enim vitae, nisl pellentesque
                arcu venenatis id. A cursus varius vel nibh ullamcorper. Amet, risus egestas at congue donec netus netus
                quisque. Quisque eget commodo mauris tortor elementum sem. Senectus ornare rhoncus feugiat amet congue
                amet ac ornare sed. Sed scelerisque faucibus semper volutpat. Imperdiet imperdiet risus tincidunt
                condimentum nisl, accumsan bibendum. Condimentum in fermentum eget felis
              </p>
            </div>
          </div>
        </div>
      </div>
      <FooterMobi />
    </>
  );
};
