import { classNames } from 'utils/utils';

export const ButtonGradient = ({ children, className, onClick, background, ...otherProps }: any) => {
  return (
    <div
      className={classNames(
        'flex cursor-pointer flex-row items-center justify-center rounded-t-[12px] rounded-bl-[12px] bg-gradient-to-r from-[#F15836] to-[#FF924F] p-[2px]',
        className || 'h-[40px] w-[158px]'
      )}
    >
      <button
        className={classNames(
          'flex h-full w-full flex-row items-center justify-center space-x-1 rounded-t-[10px] rounded-bl-[10px]',
          // background || 'bg-[#FEE6DB]'
          background || 'bg-white'
        )}
        type={'button'}
        onClick={() => onClick && onClick()}
        {...otherProps}
      >
        {children}
      </button>
    </div>
  );
};
