import { sendGetRequest, sendPostRequest } from './index';
import { apiServicePlan, apiVNPServicePlan } from './api/service-plan';
import { QueryFunctionContext } from 'react-query';

// export const searchServicePlans = (params: any) => sendPostRequest(apiServicePlan.searchServicePlan, params);

export const searchServicePlans = ({ queryKey }: QueryFunctionContext<[string, any]>) =>
  sendPostRequest(apiServicePlan.searchServicePlan, queryKey[1]);

export const searchServicePlansInfinite = ({ queryKey, pageParam }: QueryFunctionContext<[string, any], number>) => {
  return sendPostRequest(apiServicePlan.searchServicePlan, { ...queryKey[1], page: pageParam || 1 });
};

export const getSuggestPlans = ({ queryKey }: QueryFunctionContext<[string, string]>) =>
  sendGetRequest(apiServicePlan.getSuggest + `?msisdn=${encodeURIComponent(queryKey[1])}`, queryKey[1]);

export const getSuggestPlansV2 = ({ queryKey }: QueryFunctionContext<[string, any]>) => {
  const { hotPackage, supplier, ...rest } = queryKey[1];
  return sendPostRequest(apiServicePlan.getSuggest, rest);
};

export const getDetailServicePlan = (params: any) => sendPostRequest(apiServicePlan.detailServicePlan, params);

export const getOtpRegisterServicePlan = (params: any) => sendPostRequest(apiServicePlan.getOtp, params);

export const confirmOtpRegisterServicePlan = (params: any) => sendPostRequest(apiServicePlan.confirmOtp, params);

export const searchVNPServicePlans = (params: any) => sendPostRequest(apiVNPServicePlan.searchServicePlan, params);

export const searchVNPServicePlansInfinite = ({ queryKey, pageParam }: QueryFunctionContext<[string, any], number>) => {
  return sendPostRequest(apiVNPServicePlan.searchServicePlan, { ...queryKey[1], page: pageParam || 1 });
};

export const searchVNPPhoneServicePlans = (params: any) =>
  sendPostRequest(apiVNPServicePlan.searchPhoneServicePlan, params);

export const getDetailVNPServicePlan = (params: any) => sendPostRequest(apiVNPServicePlan.detailServicePlan, params);

export const getOtpRegisterVNPServicePlan = (params: any) => sendPostRequest(apiVNPServicePlan.getOtp, params);

export const confirmOtpRegisterVNPServicePlan = (params: any) => sendPostRequest(apiVNPServicePlan.confirmOtp, params);
