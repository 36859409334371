import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { ComingSoonIcon } from 'assets/images';
import { redirectDownload } from 'utils/utils';

interface Props {
    showDialog: boolean,
    setShowDialog: React.Dispatch<React.SetStateAction<boolean>>
}

const AppDownloadNotice = ({showDialog, setShowDialog}: Props) => {
    return (
        <Transition show={showDialog} as={Fragment}>
            <Dialog
                open={showDialog}
                onClose={() => { }}
                className="fixed inset-0 z-50 flex h-full min-h-screen w-screen flex-row items-center justify-center overflow-y-auto bg-[#000000]/60"
            >
                <Dialog.Panel
                    className={
                        'h-[400px] min-h-[400px] w-[400px] rounded-t-[16px] rounded-bl-[16px] border border-gray-200' +
                        ' bg-[#F8F8F8] shadow-base'
                    }
                >
                    <Dialog.Title className={'mb-2 flex h-fit flex-col items-start px-6 pt-6'}>
                        <div className={'flex h-fit w-full flex-row justify-end'}>
                            <button type={'button'} onClick={() => setShowDialog(false)}>
                                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clipPath="url(#clip0_573_26413)">
                                        <path
                                            d="M0.314708 0.701427C0.414214 0.601669 0.532424 0.522521 0.662566 0.468518C0.792707 0.414515 0.932225 0.386719 1.07313 0.386719C1.21403 0.386719 1.35354 0.414515 1.48369 0.468518C1.61383 0.522521 1.73204 0.601669 1.83154 0.701427L7.5004 6.37242L13.1692 0.701427C13.2688 0.60183 13.3871 0.522825 13.5172 0.468924C13.6473 0.415022 13.7868 0.38728 13.9277 0.38728C14.0685 0.38728 14.208 0.415022 14.3381 0.468924C14.4682 0.522825 14.5865 0.60183 14.6861 0.701427C14.7857 0.801024 14.8647 0.919262 14.9186 1.04939C14.9725 1.17952 15.0002 1.31899 15.0002 1.45984C15.0002 1.6007 14.9725 1.74017 14.9186 1.8703C14.8647 2.00043 14.7857 2.11867 14.6861 2.21826L9.01509 7.88711L14.6861 13.556C14.7857 13.6556 14.8647 13.7738 14.9186 13.9039C14.9725 14.0341 15.0002 14.1735 15.0002 14.3144C15.0002 14.4552 14.9725 14.5947 14.9186 14.7248C14.8647 14.855 14.7857 14.9732 14.6861 15.0728C14.5865 15.1724 14.4682 15.2514 14.3381 15.3053C14.208 15.3592 14.0685 15.3869 13.9277 15.3869C13.7868 15.3869 13.6473 15.3592 13.5172 15.3053C13.3871 15.2514 13.2688 15.1724 13.1692 15.0728L7.5004 9.40181L1.83154 15.0728C1.73195 15.1724 1.61371 15.2514 1.48358 15.3053C1.35345 15.3592 1.21398 15.3869 1.07313 15.3869C0.932275 15.3869 0.792802 15.3592 0.662673 15.3053C0.532543 15.2514 0.414305 15.1724 0.314708 15.0728C0.215111 14.9732 0.136106 14.855 0.0822051 14.7248C0.0283036 14.5947 0.000560851 14.4552 0.000560851 14.3144C0.000560851 14.1735 0.0283036 14.0341 0.0822051 13.9039C0.136106 13.7738 0.215111 13.6556 0.314708 13.556L5.9857 7.88711L0.314708 2.21826C0.21495 2.11876 0.135802 2.00055 0.0817995 1.87041C0.0277966 1.74026 0 1.60075 0 1.45984C0 1.31894 0.0277966 1.17943 0.0817995 1.04928C0.135802 0.919142 0.21495 0.800933 0.314708 0.701427Z"
                                            fill="#A9A9A9"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_573_26413">
                                            <rect width="15" height="15" fill="white" transform="translate(0 0.386719)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </button>
                        </div>
                        <div className={'m-4 flex h-fit w-full flex-1 items-center justify-center'}>
                            <img src={ComingSoonIcon} alt="image1" className="text-center" />
                        </div>
                    </Dialog.Title>
                    <Dialog.Description className={'flex h-full w-full flex-col items-center px-6 pb-6'}>
                        <h2 className={'mb-4 text-[22px] font-extrabold leading-[26px] text-baseColor'}>Thông báo</h2>
                        <p className={'text-center text-sm font-medium text-[#818181]'}>Vui lòng tải ứng dụng Shop360 để có thể kinh doanh sản phẩm dễ dàng hơn!</p>
                        <div
                            className={
                                'h-[40px] w-full rounded-t-[8px] rounded-bl-[8px] bg-gradient-to-r from-[#F15836]' +
                                ' mt-6 to-[#FF924F] p-[2px]'
                            }
                        >
                            <button
                                className={'h-full w-full rounded-t-[6px] rounded-bl-[6px]'}
                                onClick={redirectDownload}
                            >
                                <span className={'text-sm font-extrabold text-white'}>Tải ứng dụng</span>
                            </button>
                        </div>
                    </Dialog.Description>
                </Dialog.Panel>
            </Dialog>
        </Transition>
    )
}

export default AppDownloadNotice;