import { PATH_PAGE } from '../../constant';
import { useNavigate } from 'react-router-dom';

export const BankCardInfoMobi = ({ data }: any) => {
  const navigation = useNavigate();
  return (
    <div className={'h-full w-full rounded-[15px] bg-gradient-to-r from-[#F15836]/20 to-[#FF924F]/20'}>
      <div
        className={
          "relative flex h-[200px] flex-col justify-between rounded-[15px] bg-[url('assets/images/background-card-icon2.svg')] bg-cover p-6 "
        }
      >
        <label className={'text-sm font-medium text-[#404040]'}>Thông tin ngân hàng nhận thanh toán</label>
        {data?.data?.data ? (
          <>
            <div className={'flex flex-row space-x-2'}>
              <label className={' text-[22px] font-bold text-[#404040]'}>{data.data.data.accountNumber}</label>
              <div
                className={
                  'mt-1.5 flex h-[25px] w-[82px] flex-row items-center justify-center rounded-t-[12px]' +
                  ' rounded-bl-[12px] bg-gradient-to-r from-[#F15836] to-[#FF924F] p-0.5'
                }
              >
                <div
                  className={
                    'flex h-full w-full flex-row items-center justify-center space-x-1 rounded-t-[10px] rounded-bl-[10px] bg-[#FFF3EC]'
                  }
                >
                  <span className={'text-sm font-normal text-[#F15836]'}>Mặc định</span>
                </div>
              </div>
            </div>
            <label className={'text-sm font-medium text-[#404040]'}>
              Ngân hàng <span>{data.data.data.accountBank}</span>
            </label>
            <label className={'text-sm font-medium text-[#404040]'}>{data.data.data.accountBranch}</label>
            <label className={'text-sm font-medium text-[#404040]'}>{data.data.data.fullName}</label>
          </>
        ) : (
          <span className={'text-sm font-medium text-[#818181]'}>Chưa có thông tin</span>
        )}
        <div className={'absolute right-6 top-7'}>
          <svg
            onClick={() => navigation(PATH_PAGE.CONFIG_PAYMENT)}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 6C0 2.68629 2.68629 0 6 0H18C21.3137 0 24 2.68629 24 6V24H6C2.68629 24 0 21.3137 0 18V6Z"
              fill="url(#paint0_linear_227_15655)"
            />
            <g clipPath="url(#clip0_227_15655)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.85914 17.8727H17.1371C17.3318 17.8727 17.5185 17.9501 17.6562 18.0877C17.7939 18.2254 17.8712 18.4121 17.8712 18.6069C17.8712 18.8016 17.7939 18.9883 17.6562 19.126C17.5185 19.2636 17.3318 19.341 17.1371 19.341H6.85914C6.66443 19.341 6.4777 19.2636 6.34002 19.126C6.20235 18.9883 6.125 18.8016 6.125 18.6069C6.125 18.4121 6.20235 18.2254 6.34002 18.0877C6.4777 17.9501 6.66443 17.8727 6.85914 17.8727ZM6.125 14.202L13.4664 6.86062L15.6688 9.06304L8.32742 16.4044H6.125V14.202ZM14.2005 6.12648L15.6688 4.6582L17.8712 6.86062L16.4022 8.32963L14.2005 6.12648Z"
                fill="white"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_227_15655"
                x1="0"
                y1="24"
                x2="-2.30172e-06"
                y2="0"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#F15836" />
                <stop offset="1" stopColor="#FF924F" />
              </linearGradient>
              <clipPath id="clip0_227_15655">
                <rect width="16" height="16" fill="white" transform="translate(3.99805 3.99805)" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
  );
};
