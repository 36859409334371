import { useEffect, useState } from 'react';
import { classNames } from 'utils/utils';
import { Call } from 'assets/images';
import { ZaloIcon } from 'assets/images';

export const BackToTop = () => {
  const [showButton, setShowButton] = useState(false);
  const [showButtonHigh, setShowButtonHigh] = useState(false);

  useEffect(() => {
    const checkScrollHeight = () => {
      const scrollMax = document.body.scrollHeight - window.innerHeight;
      setShowButtonHigh(scrollMax - window.scrollY < 300);
      if (!showButton && window.scrollY > 400) {
        setShowButton(true);
      } else if (showButton && window.scrollY <= 400) {
        setShowButton(false);
      }
    };

    document.addEventListener('scroll', checkScrollHeight, false);
    return () => {
      document.removeEventListener('scroll', checkScrollHeight);
    };
  }, [showButton]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div
      className={classNames(
        showButton ? `fixed ${showButtonHigh ? 'bottom-[90px]' : 'bottom-[90px]'}  right-6 z-10` : 'hidden',
        'flex h-fit w-fit flex-col items-center justify-center space-y-2'
      )}
    >
      <div className={'flex h-[60px] w-[60px] cursor-pointer items-center justify-center rounded-full'
          }>
      <a href="https://zalo.me/g/czjvyl860" target="_blank"><img src={ZaloIcon} alt="" width={'70px'} /></a>
        
      </div>   
      <div className={'flex h-[60px] w-[60px] cursor-pointer items-center justify-center rounded-full bg-gradient-to-r' +
          ' from-[#F15836] to-[#FF924F]'}>
      <a href="tel:+0896686686"><img src={Call} /></a>
        
      </div>    
      {/* <div className={'h-fit w-fit'}>
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_290_8341)">
            <path
              d="M25 0C11.25 0 1.30385e-07 11.25 1.30385e-07 25C1.30385e-07 30.7501 2 36.2501 5.75001 40.7501L0.750001 45.7501C-0.25 46.7501 -0.25 48.2501 0.750001 49.2501C1.25 49.7501 1.75 50.0001 2.5 50.0001H25C38.7501 50.0001 50.0001 38.7501 50.0001 25C50.0001 11.25 38.7501 0 25 0ZM15 27.5C13.5 27.5 12.5 26.5 12.5 25C12.5 23.5 13.5 22.5 15 22.5C16.5 22.5 17.5 23.5 17.5 25C17.5 26.5 16.5 27.5 15 27.5ZM25 27.5C23.5 27.5 22.5 26.5 22.5 25C22.5 23.5 23.5 22.5 25 22.5C26.5 22.5 27.5 23.5 27.5 25C27.5 26.5 26.5 27.5 25 27.5ZM35.0001 27.5C33.5001 27.5 32.5001 26.5 32.5001 25C32.5001 23.5 33.5001 22.5 35.0001 22.5C36.5001 22.5 37.5001 23.5 37.5001 25C37.5001 26.5 36.5001 27.5 35.0001 27.5Z"
              fill="url(#paint0_linear_290_8341)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_290_8341"
              x1="0"
              y1="25"
              x2="50.0001"
              y2="25"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFB01F" />
              <stop offset="1" stopColor="#FFCF45" />
            </linearGradient>
            <clipPath id="clip0_290_8341">
              <rect width="50" height="50" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div> */}
      <div
        className={
          'flex h-[60px] w-[60px] cursor-pointer items-center justify-center rounded-full bg-gradient-to-r' +
          ' from-[#F15836] to-[#FF924F]'
        }
        onClick={scrollToTop}
      >
        <svg width="22" height="14" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20.8712 12.4248C20.0864 13.2276 18.9938 13.2906 18.0344 12.4248L11 5.6802L3.96556 12.4248C3.00616 13.2906 1.91176 13.2276 1.13236 12.4248C0.347557 11.6238 0.397957 10.2702 1.13236 9.5178C1.86316 8.7654 9.58336 1.4142 9.58336 1.4142C9.7672 1.22366 9.98754 1.07212 10.2312 0.968615C10.4749 0.865111 10.737 0.811768 11.0018 0.811768C11.2665 0.811768 11.5286 0.865111 11.7723 0.968615C12.016 1.07212 12.2363 1.22366 12.4202 1.4142C12.4202 1.4142 20.1368 8.7654 20.8712 9.5178C21.6074 10.2702 21.656 11.6238 20.8712 12.4248Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  );
};
