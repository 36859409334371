import { ImgHTMLAttributes, useEffect, useMemo, useState } from 'react';
import { classNames } from '../../utils/utils';

interface ImageWithFallbackProps extends ImgHTMLAttributes<HTMLImageElement> {
  fallback: string;
  src: string;
  statusVerify: boolean;
  name?: string;
  isFull?: boolean;
}

const ImageWithFallback = ({
  fallback,
  src,
  statusVerify,
  className,
  name,
  isFull,
  ...props
}: ImageWithFallbackProps) => {
  const [imgSrc, setImgSrc] = useState(src);
  const onError = () => setImgSrc(fallback);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  const avatarName = useMemo(() => {
    if (!name) return null;
    const arrayName = name.split(' ');
    const first = arrayName.at(0)?.split('')?.at(0);
    const last = arrayName.at(-1)?.split('')?.at(0);
    if (!first || !last) return null;
    return `${first.toUpperCase()}${last.toUpperCase()}`;
  }, [name]);

  return (
    <>
      {avatarName && statusVerify && !imgSrc ? (
        <div className={classNames('flex items-center justify-center rounded-full bg-[#FFC27D] p-[2px]', className)}>
          <div className={'flex h-full w-full items-center justify-center rounded-full bg-[#FF924F]'}>
            <span className={classNames(isFull && '!text-[26px]', 'text-sm font-semibold text-white')}>
              {avatarName}
            </span>
          </div>
        </div>
      ) : (
        <div
          className={classNames(
            'flex items-center justify-center rounded-full bg-[#FFC27D] p-[2px]',
            className,
            imgSrc === fallback && '!p-0'
          )}
        >
          <img
            src={imgSrc ? `${imgSrc}?dummy=none` : fallback}
            onError={onError}
            className={'h-full w-full rounded-full'}
            alt={'avatar'}
          />
        </div>
      )}
    </>
  );
};

export default ImageWithFallback;
