import { Listbox, Transition } from '@headlessui/react';
import { Fragment, useCallback } from 'react';
import Pagination from 'rc-pagination';
import { classNames, minTwoDigits } from '../../../utils/utils';
import { PAGINATION_OPTION } from '../../../constant';

export const PaginationCustom = ({
  setCurrentPage,
  currentPage,
  totalItem,
  perPage,
  setPerPage,
  isHiddenPerPage,
  isBackgroundOrange,
}: any) => {
  const renderItem = useCallback(
    (
      page: number,
      type: 'page' | 'prev' | 'next' | 'jump-prev' | 'jump-next',
      isSelected: boolean,
      _currentPage: number,
      isBackgroundOrange?: boolean
    ) => {
      switch (type) {
        case 'page':
          return (
            <span
              className={classNames(
                'inline-flex h-6 w-6 cursor-pointer items-center justify-center border' +
                  ' border-transparent p-4 text-sm text-[#404040] hover:rounded-t-[8px] hover:rounded-bl-[8px]' +
                  ' hover:border-[#F15836]',
                isSelected && isBackgroundOrange ? '!rounded-t-[8px] !rounded-bl-[8px] bg-[#F15836] !text-white ' : '',
                isSelected && !isBackgroundOrange ? '!rounded-t-[8px] !rounded-bl-[8px] !border-[#F15836]' : ''
              )}
            >
              {page}
            </span>
          );
        case 'prev':
          if (_currentPage === 1) {
            return <></>;
          }
          return (
            <button className="inline-flex h-[34px] w-[34px] cursor-pointer items-center justify-center border border-transparent text-sm text-[#404040] hover:rounded-t-[8px] hover:rounded-bl-[8px] hover:border-[#F15836]">
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.80636 2.66797L3.69922 6.77511L7.80636 10.8823"
                  stroke="#404040"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          );
        case 'next':
          if (_currentPage === Math.ceil(totalItem / perPage)) {
            return <></>;
          }
          return (
            <button className="inline-flex h-[34px] w-[34px] cursor-pointer items-center justify-center border border-transparent text-sm text-[#404040] hover:rounded-t-[8px] hover:rounded-bl-[8px] hover:border-[#F15836]">
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.20926 2.66797L9.31641 6.77511L5.20926 10.8823"
                  stroke="#404040"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          );
        case 'jump-prev':
          return (
            <button className="inline-flex h-[34px] w-[34px] cursor-pointer items-center justify-center border border-transparent text-sm text-[#404040] hover:rounded-t-[8px] hover:rounded-bl-[8px] hover:border-[#F15836]">
              <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.77121 2.66797L3.66406 6.77511L7.77121 10.8823"
                  stroke="#404040"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.8064 2.66797L8.69922 6.77511L12.8064 10.8823"
                  stroke="#404040"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          );
        case 'jump-next':
          return (
            <button className="inline-flex  h-[34px] w-[34px] rotate-180 cursor-pointer items-center justify-center border border-transparent text-sm text-[#404040] hover:rounded-b-[8px] hover:rounded-tr-[8px] hover:border-[#F15836]">
              <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.77121 2.66797L3.66406 6.77511L7.77121 10.8823"
                  stroke="#404040"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.8064 2.66797L8.69922 6.77511L12.8064 10.8823"
                  stroke="#404040"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          );
        default:
          return <></>;
      }
    },
    [perPage, totalItem]
  );

  const _onChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
  };

  return (
    <div className={'flex flex-row items-center justify-between'}>
      <div className={'h-1 w-1'}></div>
      <Pagination
        onChange={_onChange}
        current={currentPage}
        total={totalItem}
        pageSize={perPage}
        itemRender={(_current, type) =>
          renderItem(_current, type, _current === currentPage, currentPage, isBackgroundOrange)
        }
        className="flex items-center space-x-2"
      />
      <div
        className={'flex flex-row items-center justify-center space-x-2 rounded-t-[8px] rounded-bl-[8px] shadow-base'}
      >
        {!isHiddenPerPage && (
          <>
            <span className={'p-2 text-sm font-normal text-[#A9A9A9]'}>Hiển thị</span>
            <Listbox value={perPage} onChange={setPerPage}>
              <div className="relative rounded-tr-[8px] bg-[#FFF0EA]">
                <Listbox.Button
                  aria-label="button"
                  className="relative w-full cursor-pointer py-2 pl-3 pr-10 text-left"
                >
                  <span className="block truncate font-bold text-[#404040]">{perPage}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.082 5.18778L6.97489 9.29492L2.86774 5.18778"
                        stroke="#404040"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {PAGINATION_OPTION.map((_item, index) => (
                      <Listbox.Option
                        aria-label="option"
                        key={`${index}-${_item}`}
                        className={({ active }) =>
                          `relative cursor-pointer select-none px-4 py-2 ${
                            active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                          }`
                        }
                        value={_item}
                      >
                        {({ selected }) => (
                          <span
                            className={`block truncate text-sm text-[#404040] ${
                              selected ? 'font-bold' : 'font-normal'
                            }`}
                          >
                            {minTwoDigits(_item)}
                          </span>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </>
        )}
      </div>
    </div>
  );
};
