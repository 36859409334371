import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import * as React from 'react';
import { ReactElement } from 'react';
import { isArray, isEmpty } from 'lodash';
import { HandleProps } from 'rc-slider/es/Handles/Handle';
import { classNames } from '../../../utils/utils';

const HandleTooltip = (props: { value: number; children: ReactElement; maxAmount: number }) => {
  const { value, children, maxAmount } = props;

  return (
    <>
      <div
        className={classNames(
          'absolute z-50 mt-4 overflow-hidden text-ellipsis rounded-t-[8px] rounded-bl-[8px] bg-[#F15836]' +
          ' w-max px-2 py-1 text-[10px] font-medium text-white',
          props.value > maxAmount - 200000 && '!left-[90%]',
          props.value < 100000 && '!left-[5%]'
        )}
        style={{ ...children.props.style }}
      >
        {`${value ? value.toLocaleString('vi') : 0} VNĐ`}
      </div>
      {children}
    </>
  );
};

const handleRender = (origin: React.ReactElement<HandleProps>, props: any, maxAmount: number) => {
  return (
    <HandleTooltip value={props.value} maxAmount={maxAmount}>
      {origin}
    </HandleTooltip>
  );
};

export const RangeSlider = ({ value, setValue, maxAmount }: any) => {
  // const [value, setValue] = useState<Array<number>>([0, 800000]);

  return (
    <>
      {/* {!isEmpty(value) && value.some((i: number) => i !== 0) && ( */}
      {!isEmpty(value) && (
        <div className={'text-sm font-medium text-baseColor'}>
          {`${value[0] ? value[0].toLocaleString('vi') : 0} VNĐ - ${value[1] ? value[1].toLocaleString('vi') : 0} VNĐ`}
        </div>
      )}
      <div className={'relative h-fit w-full'}>
        <Slider
          className={'mt-4'}
          range
          min={0}
          max={maxAmount || 1000000}
          step={10000}
          prefixCls={'rc-slider'}
          defaultValue={value}
          value={value}
          railStyle={{ backgroundColor: '#C0C0C0' }}
          trackStyle={{ backgroundColor: '#F15836' }}
          dotStyle={{ visibility: 'hidden' }}
          onChange={(value) => isArray(value) && setValue(value)}
          handleStyle={{ backgroundColor: '#F15836', borderColor: '#F15836', opacity: 1 }}
          handleRender={(origin, props) => handleRender(origin, props, maxAmount)}
        />
      </div>
    </>
  );
};
