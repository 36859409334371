import { PATH_PAGE } from 'constant';
import { useNavigate } from 'react-router-dom';

export const CarouselCard = ({ item }: any) => {
  const navigator = useNavigate();
  return (
    <>
      <div
        className={
          'hidden h-[288px] w-[234px] transform cursor-pointer rounded-t-[30px] rounded-bl-[30px] bg-white shadow-base transition duration-300 ease-in-out hover:scale-[1.02] md:block'
        }
        onClick={() => navigator(`${PATH_PAGE.NEWS}/${item?.formatUrl}/${item?.id}`)}
      >
        <img
          src={item.imageUrl}
          alt={''}
          className={'h-[140px] w-full rounded-t-[30px] rounded-bl-[30px] object-cover'}
        />
        <div className={'flex h-fit w-full flex-col p-4'}>
          <span className={'text-ellipsis text-[18px] font-bold leading-[28px] text-baseColor line-clamp-1'}>
            {item.title}
          </span>
          <span className={'mt-2 text-sm font-normal text-[#818181] line-clamp-2'}>{item.shortContent}</span>
          <span className={'mt-3 text-[12px] font-normal leading-[20px] text-[#818181]'}>{item.lastUpdatedStr}</span>
        </div>
      </div>
      <div
        className={
          'flex max-h-[132px] min-h-[132px] w-full flex-row rounded-t-[12px] rounded-bl-[12px] border border-[#D6D5D5] bg-white md:hidden'
        }
        onClick={() => navigator(`${PATH_PAGE.NEWS}/${item?.formatUrl}/${item?.id}`)}
      >
        <img
          src={item.imageUrl}
          alt={''}
          className={'h-full w-[154px] min-w-[154px] max-w-[154px] rounded-t-[12px] rounded-bl-[12px] object-cover'}
        />
        <div className={'flex h-full w-full flex-col px-4 py-2'}>
          <span className={'min-h-18 h-10 text-sm font-bold text-baseColor line-clamp-1'}>{item.title}</span>
          <span
            className={
              ' mt-1 h-10 min-h-[24px] max-w-[202px] whitespace-normal text-sm font-normal text-[#818181] line-clamp-1'
            }
          >
            {item.shortContent}
          </span>
          <span className={'mt-2 text-[12px] font-normal leading-[20px] text-[#818181]'}>{item.lastUpdatedStr}</span>
        </div>
      </div>
    </>
  );
};
