import { HeaderCMSCommon } from 'components/header/HeaderCMSCommon';
import { default as Image3 } from './image3.png';
import { default as Image8 } from './image8.png';
import { default as Image1 } from './image1.png';
import { default as Image9 } from './image9.png';
import { default as Image5 } from './image5.png';
import { default as Image4 } from './image4.png';
import { default as Image2 } from './image2.png';
import { default as Image6 } from './image6.png';
import { default as Image7 } from './image7.png';
import { default as Image10 } from './image10.png';
import { FooterMobi } from 'components/footer/FooterMobi';
import useCheckDeviceMobi from 'hooks/useCheckDeviceMobi';

const SimInstructions = () => {
  const isMobile = useCheckDeviceMobi();

  return (
    <>
      <HeaderCMSCommon />
      <div className={'w-full bg-[#F8F8F8] pt-5'}>
        <div className={'mx-auto w-full max-w-[85rem] px-4'}>
          <div className={'mx-auto w-full max-w-[62rem] py-16'}>
            <h1 className={'my-6 text-center text-[22px] font-extrabold leading-[26px] text-[#F15836]'}>
              HƯỚNG DẪN KÍCH HOẠT SIM MỚI DỄ DÀNG, THUẬN TIỆN{' '}
            </h1>
            <p className={'font-medium text-baseColor'}>
              Sau khi mua sim mới, khách hàng cần thực hiện thao tác kích hoạt sim để có thể nghe gọi, gửi tin nhắn…Theo
              dõi và thực hiện ngay 6 bước kích hoạt nhanh chóng - chính xác - an toàn sau đây!
            </p>
            <h2 className={'mb-4 mt-6 text-[18px] font-bold leading-[28px] text-[#F15836]'}>
              I. Cách kích hoạt sim mới
            </h2>
            <p className={'mb-2 font-medium text-baseColor'}>
              Đối với sim vật lý hay eSIM thì các thao tác để kích hoạt sim mới đều được thực hiện giống nhau như sau:
            </p>
            <ul className="ml-4 list-disc space-y-2">
              <li>
                <h2>
                  <strong>Bước 1: Tải app Chọn số trên CH Play (Android) và App Store (IOS).</strong>
                </h2>
              </li>
              <div className="my-4 flex justify-center">
                <img src={Image6} alt="image6" />
              </div>
              <li className="mt-2">
                <h2>
                  <strong>Bước 2: Xác nhận Sim</strong>
                </h2>
                <ul className="ml-4 list-[circle]">
                  <li>
                    <strong>Bước 2.1:</strong> Mở app chọn số, nhấn chọn ô “Hòa mạng” sau đó nhập mã giữ số thuê bao,
                    nhấn nút “Tra cứu”.
                  </li>
                  <li>
                    <strong>Bước 2.2:</strong> Kiểm tra thông tin trên phiếu giữ số, nhấn “Tiếp tục”.
                  </li>
                </ul>
                <div className="my-4 flex justify-center">
                  <img src={Image8} alt="image8" />
                </div>
              </li>
              <li className="mt-2">
                <h2>
                  <strong>Bước 3: Xác thực thông tin - Chụp mặt trước - mặt sau của giấy tờ</strong>
                </h2>
                <p className={'mb-2 font-medium text-baseColor'}>
                  Lựa chọn sử dụng một trong các loại giấy tờ sau: Chứng minh nhân dân (CMND) hoặc Căn cước công dân(
                  CCCD) hoặc Hộ chiếu( HC) để xác thực thông tin:
                </p>
                <ul className="ml-4 list-[circle]">
                  <li>
                    <strong>Bước 3.1:</strong> Nhấn nút “Chụp” để thực hiện chụp ảnh mặt trước của giấy tờ.
                  </li>
                  <li>
                    <strong>Bước 3.2:</strong> Tiếp tục nhấn nút “Chụp” để hoàn tất chụp ảnh mặt sau giấy tờ.
                  </li>
                  <li>
                    <strong>Bước 3.3:</strong> Tại mỗi lần chụp, nhấn nút “Đồng ý” khi hình ảnh đã hợp lệ. Nếu dữ liệu
                    không hợp lệ, Người dùng nhấn nút “Chụp lại”.
                  </li>
                  <div className="my-4 flex justify-center">
                    <img src={Image1} alt="image1" />
                  </div>
                  <li>
                    <strong>Bước 3.4:</strong> Tiếp tục thực hiện chụp ảnh chân dung, nhấn nút “Xác thực thông tin” nếu
                    hình ảnh chụp đã hợp lệ.
                  </li>
                  <div className="my-4 flex justify-center">
                    <img src={Image9} alt="image9" />
                  </div>
                </ul>
              </li>
              <li className="mt-2">
                <h2>
                  <strong>Bước 4: Xác minh video call</strong>
                </h2>
                <p className={'font-medium text-baseColor'}>
                  Nhấn nút “Gọi video” để trực tiếp xác minh với đội ngũ nhân viên hòa mạng:
                </p>
                <ul className="ml-4 list-[circle]">
                  <li>
                    Nếu thông tin chính xác, hệ thống sẽ hiển thị thông báo: “Xác thực video call thành công!” và chuyển
                    sang Bước 5 Xác nhận thông tin.
                  </li>
                  <li>
                    Nếu thông tin không chính xác, hệ thống sẽ hiển thị thông báo: “Xác minh video call thất bại!” và
                    người dùng cần phải xác minh lại.
                  </li>
                </ul>
                <div className="my-4 flex justify-center">
                  <img src={Image5} alt="image5" />
                </div>
              </li>
              <li className="mt-2">
                <h2>
                  <strong>Bước 5: Xác nhận thông tin</strong>
                </h2>
                <p className={'font-medium text-baseColor'}>Hệ thống hiển thị màn hình Xác nhận thông tin:</p>
                <ul className="ml-4 list-[circle]">
                  <li>
                    <strong>Bước 5.1:</strong> Kiểm tra lại các thông tin trên màn hình hiển thị, sau đó khai báo thông
                    tin bắt buộc.
                  </li>
                  <li>
                    <strong>Bước 5.2:</strong> Tích chọn “Tôi xác nhận những thông tin trên là chính xác” và nhấn nút
                    “Xác nhận”.
                  </li>
                </ul>
                <div className="my-4 flex justify-center">
                  <img src={Image4} alt="image4" />
                </div>
              </li>
              <li className="mt-2">
                <h2>
                  <strong>Bước 6: Ký hợp đồng</strong>
                </h2>
                <ul className="ml-4 list-[circle]">
                  <li>
                    <strong>Bước 6.1:</strong> Đọc kỹ thông tin hiển thị, sau đó tích chọn ô “Tôi cam kết đã đọc và hiểu
                    rõ nội dung Chính sách sim số”.
                  </li>
                  <li>
                    <strong>Bước 6.2:</strong> Thực hiện: Ký tên, nhập/quét số Serial SIM và chụp ảnh SIM.
                  </li>
                  <p>
                    Tại thao tác nhập/ quét số Serial SIM người dùng có thể nhập số trên bàn phím hoặc nhấn vào biểu
                    tượng quét mã để thiết bị quét số serial SIM trên SIM trắng.
                  </p>
                  <li>
                    <strong>Bước 6.3:</strong> Nhấn chọn “Gửi thông tin hòa mạng” để hoàn tất quy trình hòa mạng sim
                    mới.
                  </li>
                </ul>
                <div className="my-4 flex justify-center">
                  <img src={Image2} alt="image2" />
                </div>
              </li>
            </ul>
            <h2 className={'mb-4 mt-6 text-[18px] font-bold leading-[28px] text-[#F15836]'}>
              II. Một số lưu ý khi kích hoạt sim
            </h2>
            <p className={'font-medium text-baseColor'}>
              Để việc kích hoạt sim diễn ra nhanh chóng, thuận tiện người dùng cần đảm bảo thực hiện đúng theo 6 bước
              trên và nắm chắc các lưu ý sau:
            </p>
            <div className="my-4 flex justify-center">
              <img src={Image3} alt="image3" />
            </div>
            <ul className="ml-4 list-decimal space-y-2">
              <li>
                Người dùng cần nhập đúng “mã giữ số” có tồn tại trên hệ thống và là mã giữ số của phiếu giữ số còn hiệu
                lực đã được xác nhận để được thực hiện hòa mạng.
              </li>
              <li>
                Phiếu giữ số cần có trạng thái hòa mạng là Chưa hòa mạng và Hòa mạng thất bại thì mới có thể tiếp tục
                các bước hòa mạng tiếp theo.
              </li>
              <li>
                Đối với bước chụp xác minh giấy tờ, người dùng cần đảm bảo các điều kiện sau để xác minh thành công:
                <ul className="ml-4 list-[circle]">
                  <li>Người dùng cần chụp ảnh giấy tờ đầy đủ, không bị lóa hay chói sáng, bị mờ hoặc mất góc. </li>
                  <li>
                    Mỗi loại giấy tờ chỉ được hòa mạng tối đa 3 lần, người dùng cần sử dụng giấy tờ chưa vượt quá số lần
                    hòa mạng để thực hiện được tiếp các bước sau đó.
                  </li>
                </ul>
              </li>
              <li>
                Tại bước gọi video call:
                <ul className="ml-4 list-[circle]">
                  <li>
                    Trường hợp video call thất bại do ảnh chân dung và ảnh người gọi video không trùng khớp &rArr; Người
                    dùng cần quay lại bước chụp ảnh chân dung để chụp lại, sau đó thực hiện gọi lại video call.
                  </li>
                  <li>
                    Trường hợp kết nối video call thất bại do kết nối mạng/ tổ xác minh không nghe máy &rArr; Người dùng
                    thực hiện lại cuộc gọi bằng cách nhấn nút “Gọi video”.
                  </li>
                </ul>
              </li>
              <li>Người dùng cần nhập đầy đủ các thông tin để có thể gửi thông tin hòa mạng.</li>
              <li>
                Đối với trường hợp người dùng gửi yêu cầu hòa mạng thành công đầu tiên tại thiết bị:
                <ul className="ml-4 list-[circle]">
                  <li>
                    Hệ thống sẽ hiển thị cửa sổ Đánh giá App. Người dùng có thể đánh giá app hoặc tắt cửa sổ bằng cách
                    nhấn biểu tượng [X] để đóng hoặc nhấn nút “Để sau” đối với người dùng thiết bị IOS và nút “Hủy” đối
                    với người dùng thiết bị Android.
                  </li>
                </ul>
              </li>
            </ul>
            <div className={`my-4 flex justify-center gap-2 ${isMobile ? 'flex-col' : 'flex-row'}`}>
              <img src={Image7} alt="image7" />
              <img src={Image10} alt="image10" />
            </div>
            <p className="mt-2 font-medium text-baseColor">
              Trên đây là 6 bước hòa mạng dành cho các thuê bao mới siêu tiện ích, dễ dàng, chỉ cần thực hiện ngay tại
              nhà. Chúc các khách hàng hòa mạng thành công!
            </p>
          </div>
        </div>
      </div>
      <FooterMobi />
    </>
  );
};

export default SimInstructions;
