import { createContext, Dispatch, SetStateAction } from 'react';

type SimContextType = {
  traSau: any;
  traTruoc: any;
  preNumber: Array<any>;
  typeNumber: Array<any>;
  setTraTruoc: Dispatch<SetStateAction<any>>;
  setPreNumber: Dispatch<SetStateAction<Array<any>>>;
  setTraSau: Dispatch<SetStateAction<any>>;
  setTypeNumber: Dispatch<SetStateAction<Array<any>>>;
  supplier: Array<any>;
  setSupplier: Dispatch<SetStateAction<Array<any>>>;
};

export const SimContext = createContext<SimContextType>({} as SimContextType);
