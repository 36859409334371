import { createContext, Dispatch, SetStateAction } from 'react';

type ServicePlanContextType = {
  packTypeChoice: Array<any>;
  numExpiredChoice: Array<any>;
  traSau: any;
  traTruoc: any;
  dataPack: any;
  amount: Array<any>;
  setPackTypeChoice: Dispatch<SetStateAction<Array<any>>>;
  setTraTruoc: Dispatch<SetStateAction<any>>;
  setAmount: Dispatch<SetStateAction<Array<any>>>;
  setTraSau: Dispatch<SetStateAction<any>>;
  setNumExpiredChoice: Dispatch<SetStateAction<Array<any>>>;
  supplier: Array<any>;
  setSupplier: Dispatch<SetStateAction<Array<any>>>;
  setDataPack: Dispatch<SetStateAction<any>>;
};

export const ServicePlanContext = createContext<ServicePlanContextType>({} as ServicePlanContextType);
