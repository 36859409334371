import ScrollToTop from 'components/common/ScrollToTop';
import { FooterMobi } from 'components/footer/FooterMobi';
import useCheckDeviceMobi from 'hooks/useCheckDeviceMobi';
import { classNames } from 'utils/utils';
import { HeaderCMSCommon } from '../../components/header/HeaderCMSCommon';

export const PrivacyPage = () => {
  const isMobile = useCheckDeviceMobi();

  return (
    <>
      <ScrollToTop />
      <HeaderCMSCommon />
      <div className={classNames('w-full bg-[#F8F8F8] pb-10', isMobile ? 'pt-20' : 'pt-24')}>
        <div className={'mx-auto w-full max-w-[85rem] px-4'}>
          {/* <div className={'relative h-52 w-full rounded-[16px] bg-[#D9D9D9] md:h-[450px]'}>
            <div className={'absolute bottom-0 mx-auto hidden w-full flex-row justify-center space-x-4 pb-4 md:flex'}>
              {Array.from({ length: 3 }).map((item, index) => {
                if (index === indexDataCarousel) {
                  return (
                    <svg
                      className={'cursor-pointer'}
                      width="38"
                      height="16"
                      viewBox="0 0 38 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect x="0.828125" y="0.163574" width="36.3507" height="15" rx="7.5" fill="#F15836" />
                    </svg>
                  );
                }
                return (
                  <svg
                    onClick={() => setIndexDataCarousel(index)}
                    className={'cursor-pointer'}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="8" cy="8" r="8" fill="#818181" />
                  </svg>
                );
              })}
            </div>
          </div> */}
          <div
            className={classNames(
              'mx-auto w-full max-w-[62rem] rounded-[4px] bg-white shadow-[0_0_20px_0_rgba(0,0,0,0.05)]',
              isMobile ? 'p-4' : 'p-8'
            )}
          >
            <h1 className={'text-center text-[32px] font-semibold leading-[40px] text-[#F15836]'}>
              CHÍNH SÁCH BẢO MẬT THÔNG TIN CÁ NHÂN
            </h1>
            <div className={'mt-2'}>
              <section className="[&>*]:text-justify [&>*]:leading-[25px]">
                <p>
                  <strong>
                    <i>
                      Chính sách này là Thông báo xử lý và bảo vệ Dữ liệu cá nhân, có thể thay đổi khi cần thiết và
                      phiên bản cập nhật mới nhất sẽ được đăng tải trên Trang thông tin điện tử chính thức.
                    </i>
                  </strong>
                </p>
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Công ty Cổ phần Công nghệ phần mềm và nội dung số OSP (“OSP”) luôn coi trọng sự riêng tư và bảo vệ Dữ
                  liệu cá nhân của Khách hàng, Cộng tác viên (“Sau đây gọi chung là “Quý khách”). Chúng tôi hiểu rằng,
                  thông qua Chính sách Bảo vệ Dữ liệu cá nhân (“Chính sách”) này, Quý khách sẽ biết một cách minh bạch
                  về các nội dung liên quan đến cách thức chúng tôi xử lý và bảo vệ Dữ liệu cá nhân của Quý khách trong
                  quá trình tìm kiếm, tiếp cận, mua, đăng ký sử dụng, sử dụng Sản phẩm, hàng hóa, dịch vụ của OSP nhằm
                  đảm bảo Dữ liệu cá nhân luôn riêng tư và an toàn theo quy định pháp luật cũng như chuẩn mực bảo mật
                  của OSP.
                </p>
                <p className={'mt-2 break-words text-left text-sm font-medium text-baseColor'}>
                  Chính sách này là Thông báo xử lý và bảo vệ Dữ liệu cá nhân, có thể thay đổi khi cần thiết và phiên
                  bản cập nhật mới nhất sẽ được đăng tải trên Trang thông tin điện tử chính thức của chúng tôi. Vì vậy,
                  chúng tôi khuyến nghị Quý khách nên định kỳ kiểm tra Chính sách trên Trang thông tin điện tử của chúng
                  tôi.
                </p>
              </section>

              <section className={classNames(isMobile ? 'mt-6' : 'mt-8', '[&>*]:text-justify [&>*]:leading-[25px]')}>
                <h1 className={classNames('text-[16px] font-semibold  text-baseColor', !isMobile ? 'uppercase' : '')}>
                  ĐIỀU 1. ĐỊNH NGHĨA
                </h1>
                <p className={'mt-2 break-words text-left text-sm font-medium text-baseColor'}>
                  Trong Chính sách này, các từ ngữ dưới đây được hiểu như sau:
                </p>
              </section>

              <section className="mt-4 grid gap-y-4 [&>*]:text-justify [&>*]:leading-[25px]">
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  <i>1. OSP:</i> là Công ty Cổ phần Công nghệ và Nội dung số OSP, Giấy chứng nhận đăng ký doanh nghiệp
                  số 0102886901; trụ sở tại Tầng 4, Toà nhà Garden Hill, số 99 Trần Bình, Mỹ Đình 2, Nam Từ Liêm, Hà Nội
                </p>
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  <i>2. Khách hàng/Cộng tác viên:</i> là (i) cá nhân và/hoặc (ii) tổ chức cung cấp dữ liệu cá nhân trong
                  hoạt động tìm kiếm, tiếp cận, mua, đăng ký sử dụng, sử dụng Sản phẩm, hàng hóa, dịch vụ của OSP.
                </p>
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  <i>3. Sản phẩm, hàng hóa, dịch vụ:</i> là bất kỳ sản phẩm, hàng hóa, dịch vụ nào do OSP cung cấp
                  và/hoặc do OSP hợp tác với đối tác mà Quý khách tìm kiếm, tiếp cận, mua, đăng ký sử dụng, sử dụng.
                </p>
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  <i>4. Dữ liệu cá nhân:</i> là thông tin dưới dạng ký hiệu, chữ viết, chữ số, hình ảnh, âm thanh hoặc
                  dạng tương tự trên môi trường điện tử gắn liền với Quý khách cụ thể hoặc giúp xác định Quý khách cụ
                  thể. Dữ liệu cá nhân bao gồm Dữ liệu cá nhân cơ bản và Dữ liệu cá nhân nhạy cảm.
                </p>
                <section className={'grid gap-y-2 break-words text-left text-sm font-medium text-baseColor'}>
                  <i>5. Dữ liệu cá nhân cơ bản là những thông tin bao gồm:</i>
                  <ul className="grid gap-y-2">
                    <li>
                      <strong>(i)</strong> Họ, chữ đệm và tên khai sinh, tên gọi khác (nếu có);
                    </li>
                    <li>
                      <strong>(ii)</strong> Ngày, tháng, năm sinh; ngày, tháng, năm chết hoặc mất tích;
                    </li>
                    <li>
                      <strong>(iii)</strong> Giới tính;
                    </li>
                    <li>
                      <strong>(iv)</strong> Nơi sinh, nơi đăng ký khai sinh, nơi thường trú, nơi tạm trú, nơi ở hiện
                      tại, quê quán, địa chỉ liên hệ;
                    </li>
                    <li>
                      <strong>(v)</strong> Quốc tịch;
                    </li>
                    <li>
                      <strong>(vi)</strong> Hình ảnh của cá nhân;
                    </li>
                    <li>
                      <strong>(vii)</strong> Số điện thoại, số chứng minh nhân dân, số căn cước công dân, số định danh
                      cá nhân, số hộ chiếu, số giấy phép lái xe, số biển số xe, số mã số thuế cá nhân, số bảo hiểm xã
                      hội, số thẻ bảo hiểm y tế;
                    </li>
                    <li>
                      <strong>(viii)</strong> Thông tin về mối quan hệ gia đình (cha mẹ, con cái);
                    </li>
                    <li>
                      <strong>(ix)</strong> Thông tin về tài khoản số của cá nhân; địa chỉ thư điện tử;
                    </li>
                    <li>
                      <strong>(x)</strong> Thông tin về hoạt động sử dụng dịch vụ viễn thông của Quý khách, bao gồm:
                      thông tin liên quan đến việc sử dụng dịch vụ thoại và tin nhắn (bao gồm nhưng không giới hạn số
                      điện thoại khởi tạo cuộc gọi, số điện thoại nhận cuộc gọi, thời gian khởi tạo và kết thúc cuộc
                      gọi, thời gian cuộc gọi, số điện thoại gửi tin nhắn, số điện thoại nhận tin nhắn, thời gian gửi và
                      nhận tin nhắn); các gói cước đã đăng ký và thông tin cước (thông tin cước sử dụng, nợ cước, cước
                      đóng trước...); phí dịch vụ, tiền cước; lịch sử sử dụng dữ liệu di động (sms, dịch vụ giá trị gia
                      tăng, data...), thời gian sử dụng dữ liệu di động; dung lượng sử dụng, lịch sử và tần suất sử dụng
                      Sản phẩm, hàng hóa, dịch vụ; địa chỉ IP; thông tin đánh giá Sản phẩm, hàng hóa, dịch vụ/khiếu
                      nại/yêu cầu sửa chữa/xử lý sự cố;
                    </li>
                    <li>
                      <strong>(xi)</strong> Dữ liệu cá nhân phản ánh hoạt động, lịch sử hoạt động trên không gian mạng,
                      bao gồm nhưng không giới hạn: các dữ liệu liên quan đến các Trang thông tin điện tử /wapsite/mạng
                      xã hội hoặc Ứng dụng; dữ liệu kỹ thuật (bao gồm loại thiết bị, hệ điều hành, loại trình duyệt, cài
                      đặt trình duyệt, địa chỉ IP, cài đặt ngôn ngữ, ngày và giờ kết nối với Trang thông tin điện tử,
                      thống kê sử dụng Ứng dụng, cài đặt Ứng dụng, ngày và giờ kết nối với Ứng dụng, thông tin liên lạc
                      kỹ thuật khác); tên tài khoản; mật khẩu; chi tiết đăng nhập bảo mật; dữ liệu sử dụng; dữ liệu
                      cookie; lịch sử duyệt web; dữ liệu clickstream; lịch sử xem kênh, VOD (video theo yêu cầu);
                    </li>
                    <li>
                      <strong>(xii)</strong> Các thông tin khác gắn liền hoặc giúp xác định với Quý khách cụ thể theo
                      quy định pháp luật tại từng thời điểm không thuộc phạm vi Dữ liệu cá nhân nhạy cảm theo quy định
                      tại khoản 6 Điều này.
                    </li>
                  </ul>
                </section>

                <section className={'break-words text-left text-sm font-medium text-baseColor'}>
                  <i>6. Dữ liệu cá nhân nhạy cảm:</i> là Dữ liệu cá nhân gắn liền với quyền riêng tư của Quý khách mà
                  khi bị xâm phạm sẽ gây ảnh hưởng trực tiếp tới quyền và lợi ích hợp pháp của Quý khách, bao gồm nhưng
                  không giới hạn ở các loại dữ liệu sau:
                  <ul className="grid gap-y-2">
                    <li>
                      <strong>(i)</strong> Thông tin tài khoản của Quý khách, bao gồm: lịch sử thanh toán, lịch sử biến
                      động số dư, nạp tiền vào tài khoản, lịch sử yêu cầu kiểm tra, số dư tài khoản;
                    </li>

                    <li>
                      <strong>(ii)</strong> Thông tin liên quan tới việc Quý khách sử dụng dịch vụ trung gian thanh
                      toán, bao gồm: thông tin định danh Quý khách theo quy định pháp luật, thông tin về tài khoản,
                      thông tin về tiền gửi, thông tin về tài sản gửi, thông tin về giao dịch, thông tin về tổ chức, cá
                      nhân là bên bảo đảm tại tổ chức tín dụng, chi nhánh ngân hàng, tổ chức cung ứng dịch vụ trung gian
                      thanh toán;
                    </li>

                    <li>
                      <strong>(iii)</strong> Thông tin liên quan đến nguồn gốc dân tộc, thuộc tính vật lý, đặc điểm sinh
                      học riêng mà Quý khách cung cấp cho OSP hoặc chúng tôi có được trong quá trình Quý khách mua, sử
                      dụng Sản phẩm, hàng hóa, dịch vụ;
                    </li>

                    <li>
                      <strong>(iv)</strong> Dữ liệu về vị trí của Quý khách được xác định qua dịch vụ định vị;
                    </li>

                    <li>
                      <strong>(v)</strong> Dữ liệu cá nhân khác được pháp luật quy định là đặc thù và cần có biện pháp
                      bảo mật cần thiết.
                    </li>
                  </ul>
                </section>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  <i>7. Xử lý Dữ liệu cá nhân:</i> là một hoặc nhiều hoạt động tác động tới Dữ liệu cá nhân, như: thu
                  thập, ghi, phân tích, xác nhận, lưu trữ, chỉnh sửa, công khai, kết hợp, truy cập, truy xuất, thu hồi,
                  mã hóa, giải mã, sao chép, chia sẻ, truyền đưa, cung cấp, chuyển giao, xóa, hủy Dữ liệu cá nhân hoặc
                  các hành động khác có liên quan.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  <i>8. Bên Kiểm soát Dữ liệu cá nhân:</i> là tổ chức, cá nhân quyết định mục đích và phương tiện Xử lý
                  Dữ liệu cá nhân.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  <i>9. Bên Xử lý Dữ liệu cá nhân:</i> là tổ chức, cá nhân thực hiện việc Xử lý Dữ liệu cá nhân thay mặt
                  cho Bên Kiểm soát dữ liệu cá nhân, thông qua một hợp đồng hoặc thỏa thuận với Bên Kiểm soát dữ liệu cá
                  nhân.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  <i>10. Bên Kiểm soát và xử lý Dữ liệu cá nhân:</i> là tổ chức, cá nhân đồng thời quyết định mục đích,
                  phương tiện và trực tiếp Xử lý Dữ liệu cá nhân.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  <i>11. Kênh giao dịch của OSP:</i> bao gồm kênh tổng đài, kênh giao dịch điện tử (
                  <i>
                    Trang thông tin điện tử như{' '}
                    <a href="https://shop360.vn" className="text-blue-500" target="_blank" rel="noopener noreferrer">
                      https://shop360.vn
                    </a>
                    , mạng xã hội
                  </i>
                  ) và kênh giao dịch vật lý (<i>cửa hàng, điểm kinh doanh,.. của Mobifone</i>) hoặc các kênh giao dịch
                  khác tùy từng thời điểm do Mobifone cung cấp hoặc ủy quyền cung cấp cho Quý khách.
                </p>
              </section>

              <section className="[&>*]:text-justify [&>*]:leading-[25px]">
                <h1
                  className={classNames(
                    'text-[16px]  font-semibold text-baseColor',
                    isMobile ? 'mt-6' : 'mt-8 uppercase'
                  )}
                >
                  ĐIỀU 2. LOẠI DỮ LIỆU CÁ NHÂN ĐƯỢC XỬ LÝ
                </h1>
              </section>

              <section className="mt-4 grid gap-y-4 [&>*]:text-justify [&>*]:leading-[25px]">
                <section className={'break-words text-left text-sm font-medium text-baseColor'}>
                  1. Dữ liệu cá nhân được xử lý gồm các dữ liệu Quý khách cung cấp cho chúng tôi khi tìm kiếm, tiếp cận,
                  mua, đăng ký sử dụng, sử dụng Sản phẩm, hàng hóa, dịch vụ và dữ liệu phát sinh trong quá trình Quý
                  khách sử dụng Sản phẩm, hàng hóa, dịch vụ của chúng tôi, cụ thể như sau:
                  <ul className="grid gap-y-2">
                    <li>(i) Các Dữ liệu cá nhân cơ bản quy định tại khoản 5 Điều 1 của Chính sách này; và</li>
                    <li>(ii) Các Dữ liệu cá nhân nhạy cảm quy định tại khoản 6 Điều 1 của Chính sách này.</li>
                  </ul>
                </section>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  2. Dữ liệu cá nhân được xử lý phù hợp với từng loại Sản phẩm, hàng hóa, dịch vụ tương ứng.
                </p>
              </section>

              <section className="[&>*]:text-justify [&>*]:leading-[25px]">
                <h1
                  className={classNames(
                    'text-[16px]  font-semibold text-baseColor',
                    isMobile ? 'mt-6' : 'mt-8 uppercase'
                  )}
                >
                  ĐIỀU 3. MỤC ĐÍCH XỬ LÝ DỮ LIỆU
                </h1>
                <p className={'mt-2 break-words text-left text-sm font-medium text-baseColor'}>
                  Dữ liệu cá nhân theo Điều 2 trên đây có thể được xử lý cho các mục đích sau:
                </p>
              </section>

              <section className="mt-4 grid gap-y-4 [&>*]:text-justify [&>*]:leading-[25px]">
                <section className={'break-words text-left text-sm font-medium text-baseColor'}>
                  1. Cung cấp Sản phẩm, hàng hóa, dịch vụ cho Quý khách theo Hợp đồng và thực hiện quyền, nghĩa vụ của
                  OSP theo quy định pháp luật, bao gồm nhưng không giới hạn:
                  <ul className="grid gap-y-2">
                    <li>
                      (i) Phục vụ hoạt động quản lý nghiệp vụ; vận hành, khai thác, tối ưu chất lượng mạng, dịch vụ di
                      động, viễn thông do OSP cung cấp;
                    </li>

                    <li>
                      (ii) Xác thực và thực hiện các giao dịch thanh toán; đối soát cước, thanh toán cước; trao đổi cung
                      cấp thông tin liên quan đến người sử dụng dịch vụ viễn thông với các doanh nghiệp viễn thông khác
                      để phục vụ cho việc tính giá cước, lập hóa đơn và ngăn chặn hành vi trốn tránh thực hiện nghĩa vụ
                      theo hợp đồng trong phạm vi pháp luật cho phép; đối soát với các đối tác hợp tác của OSP nhằm mục
                      đích cung cấp Sản phẩm, hàng hóa, dịch vụ cho Quý khách;
                    </li>

                    <li>
                      (iii) Cung cấp, kích hoạt hoặc xác minh Sản phẩm, hàng hóa, dịch vụ mà Quý khách yêu cầu theo
                      Phiếu yêu cầu/Hợp đồng hoặc qua Kênh giao dịch của OSP hoặc các yêu cầu khác của Quý khách phát
                      sinh trong quá trình tìm kiếm, tiếp cận, mua đăng ký sử dụng, sử dụng Sản phẩm, hàng hóa, dịch vụ;
                    </li>

                    <li>(iv) Phục vụ cho mục đích liên hệ, thông báo với Quý khách;</li>

                    <li>
                      (v) Thực hiện các quyền của Quý khách liên quan đến Dữ liệu cá nhân theo quy định pháp luật, thỏa
                      thuận giữa Quý khách và OSP;
                    </li>

                    <li>
                      (vi) Thực hiện nghĩa vụ theo hợp đồng của Quý khách và nghĩa vụ của OSP với cơ quan, tổ chức, cá
                      nhân có liên quan theo quy định pháp luật;
                    </li>

                    <li>(vii) Công khai Dữ liệu cá nhân của Quý khách theo quy định pháp luật;</li>

                    <li>
                      (viii) Thực hiện các nghĩa vụ của OSP về thanh tra, kiểm tra, thống kê, báo cáo, tài chính, kế
                      toán và thuế;
                    </li>

                    <li>
                      (ix) Thực hiện các nghiệp vụ bảo đảm an toàn dữ liệu; bảo đảm an toàn hệ thống thông tin của OSP
                      như sao lưu, dự phòng, giám sát, tối ưu tài nguyên và bảo vệ Dữ liệu cá nhân Quý khách;
                    </li>

                    <li>
                      (x) Nhắn tin thông báo, truyền thông, vận động, ủng hộ liên quan đến Cổng thông tin nhân đạo quốc
                      gia và theo yêu cầu của cơ quan quản lý nhà nước;
                    </li>

                    <li>
                      (xi) Phát hiện, ngăn chặn các hành vi vi phạm pháp luật trên không gian mạng theo yêu cầu của cơ
                      quan nhà nước có thẩm quyền và quy định pháp luật, bao gồm nhưng không giới hạn: tin nhắn rác, thư
                      điện tử rác, cuộc gọi rác, tin nhắn có mục đích lừa đảo, thư điện tử có mục đích lừa đảo, cuộc gọi
                      có mục đích lừa đảo;
                    </li>

                    <li>
                      (xii) Phát hiện, ngăn chặn các hành vi gian lận, lừa đảo, tấn công, xâm nhập, chiếm đoạt trái
                      phép, hành vi mang tính chất tội phạm và các hành vi bất hợp pháp khác;
                    </li>

                    <li>
                      (xiii) Thực hiện các hoạt động có mục đích kiểm toán, quản lý rủi ro, phòng chống rửa tiền, tài
                      trợ khủng bố và tuân thủ cấm vận;
                    </li>

                    <li>
                      (xiv) Trong trường hợp tình trạng khẩn cấp về quốc phòng, an ninh quốc gia, trật tự an toàn xã
                      hội, thảm họa lớn, dịch bệnh nguy hiểm; khi có nguy cơ đe dọa an ninh, quốc phòng nhưng chưa đến
                      mức ban bố tình trạng khẩn cấp; phòng, chống bạo loạn, khủng bố, phòng, chống tội phạm và vi phạm
                      pháp luật theo quy định pháp luật;
                    </li>

                    <li>(xv) Phục vụ hoạt động của cơ quan nhà nước theo quy định pháp luật chuyên ngành.</li>
                  </ul>
                </section>

                <section className={'break-words text-left text-sm font-medium text-baseColor'}>
                  2. Hỗ trợ Quý khách khi tiếp thị/mua, sử dụng Sản phẩm, hàng hóa, dịch vụ do OSP cung cấp theo hợp
                  đồng và quy định pháp luật, bao gồm:
                  <ul className="grid gap-y-2">
                    <li>(i) Cập nhật, xử lý thông tin khi Quý khách mua, sử dụng Sản phẩm, hàng hóa, dịch vụ;</li>

                    <li>
                      (ii) Chăm sóc Quý khách, tiếp nhận và giải quyết thắc mắc, khiếu nại của Quý khách đối với các Sản
                      phẩm, hàng hóa, dịch vụ của OSP;
                    </li>

                    <li>
                      (iii) Sử dụng, chuyển giao cho đối tác các Dữ liệu cá nhân, thông tin vướng mắc, sự cố, báo cáo
                      lỗi do Quý khách phản ánh để xác định và khắc phục sự cố của Sản phẩm, hàng hóa, dịch vụ; sửa chữa
                      thiết bị của Quý khách; thực hiện hoạt động khác về chăm sóc và hỗ trợ Quý khách.
                    </li>
                  </ul>
                </section>

                <section className={'break-words text-left text-sm font-medium text-baseColor'}>
                  3. Nâng cao chất lượng Sản phẩm, hàng hóa, dịch vụ mà OSP cung cấp cho Quý khách:
                  <ul className="grid gap-y-2">
                    <li>
                      (i) Cung cấp thông tin mà Quý khách đã yêu cầu hoặc OSP cho rằng Quý khách có thể thấy hữu ích,
                      bao gồm thông tin về các Sản phẩm, hàng hóa, dịch vụ của OSP theo quy định pháp luật;
                    </li>

                    <li>
                      (ii) Cải tiến công nghệ, giao diện Trang thông tin điện tử, website, mạng xã hội, Ứng dụng đảm bảo
                      tiện lợi cho Quý khách;
                    </li>

                    <li>(iii) Quản lý tài khoản Quý khách;</li>

                    <li>
                      (iv) Lưu trữ thông tin, nghiên cứu thị trường, phân tích, thống kê và các hoạt động quản lý nội bộ
                      khác nhằm nâng cao trải nghiệm Quý khách;
                    </li>

                    <li>
                      (v) Báo cáo, thống kê, phân tích dữ liệu nội bộ để nghiên cứu, xây dựng, phát triển, quản lý, đo
                      lường, cung cấp và cải tiến Sản phẩm, hàng hóa, dịch vụ cũng như điều hành hoạt động kinh doanh
                      của OSP;
                    </li>

                    <li>
                      (vi) Xây dựng chiến dịch tiếp thị Sản phẩm, hàng hóa, dịch vụ và xác định cách OSP có thể cá nhân
                      hóa các sản phẩm, hàng hóa dịch vụ đó;
                    </li>

                    <li>
                      (vii) Phát triển, cung cấp Sản phẩm, hàng hóa, dịch vụ mới được cá nhân hóa theo nhu cầu, điều
                      kiện thực tế của Quý khách với phương pháp đo lường hiệu quả;
                    </li>

                    <li>
                      (viii) Giới thiệu, cung cấp các chương trình khuyến mại cho Sản phẩm, hàng hóa, dịch vụ, ưu đãi,
                      khuyến mại của OSP và của OSP hợp tác với đối tác;
                    </li>

                    <li>
                      (ix) Đánh giá khả năng mua, sử dụng Sản phẩm, hàng hóa, dịch vụ của OSP thông qua điểm xếp hạng
                      viễn thông của khách hàng nhằm hỗ trợ tốt nhất trong việc cung cấp các sản phẩm, hàng hóa, dịch vụ
                      cho khách hàng.
                    </li>
                  </ul>
                </section>

                <section className={'break-words text-left text-sm font-medium text-baseColor'}>
                  4. Kinh doanh dịch vụ tiếp thị, quảng cáo, giới thiệu sản phẩm phù hợp với nhu cầu của khách hàng hoặc
                  OSP cho rằng khách hàng quan tâm theo nội dung, hình thức, tần suất như sau:
                  <ul className="grid gap-y-2">
                    <li>
                      (i) Nội dung: Giới thiệu thông tin các sản phẩm, hàng hóa, dịch vụ, ưu đãi do OSP và đối tác của
                      OSP cung cấp;
                    </li>

                    <li>
                      (ii) Phương thức: Qua tin nhắn quảng cáo (SMS, USSD, MMS…), cuộc gọi IVR, thông báo trên Kênh giao
                      dịch của OSP hoặc các phương thức khác theo quy định pháp luật;
                    </li>

                    <li>
                      (iii) Hình thức: Gửi trực tiếp cho khách hàng qua thiết bị, phương tiện điện tử hoặc các hình thức
                      khác trên không gian mạng theo quy định pháp luật;
                    </li>

                    <li>(iv) Tần suất: Theo quy định pháp luật về quảng cáo.</li>
                  </ul>
                </section>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  5. Kinh doanh dịch vụ nghiên cứu thị trường, thăm dò dư luận, môi giới;
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  6. Tổ chức giới thiệu và xúc tiến thương mại.
                </p>
              </section>

              <section className="[&>*]:text-justify [&>*]:leading-[25px]">
                <h1
                  className={classNames(
                    'text-[16px]  font-semibold text-baseColor',
                    isMobile ? 'mt-6' : 'mt-8 uppercase'
                  )}
                >
                  ĐIỀU 4. CÁCH THỨC XỬ LÝ DỮ LIỆU
                </h1>
              </section>

              <section className="mt-4 grid gap-y-4 [&>*]:text-justify [&>*]:leading-[25px]">
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>1. Cách thức thu thập</p>

                <section className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Dữ liệu cá nhân được thu thập trực tiếp từ Quý khách trong các trường hợp sau:
                  <ul className="grid gap-y-2">
                    <li>
                      (i) Từ các Trang thông tin điện tử của OSP: Chúng tôi có thể thu thập Dữ liệu cá nhân khi Quý
                      khách truy cập bất kỳ trang thông tin điện tử nào của OSP (gọi chung là “Trang thông tin điện tử”)
                      hoặc sử dụng bất kỳ tính năng, tài nguyên nào có sẵn trên hoặc thông qua Trang thông tin điện tử.
                      Khi Quý khách truy cập Trang thông tin điện tử, OSP thu thập thông tin về thiết bị và trình duyệt
                      của Quý khách (chẳng hạn như loại thiết bị, hệ điều hành, loại trình duyệt, cài đặt trình duyệt,
                      địa chỉ IP, cài đặt ngôn ngữ, ngày và giờ kết nối với Trang thông tin điện tử và các thông tin
                      liên lạc kỹ thuật khác);
                    </li>

                    <li>
                      (ii) Từ Ứng dụng: Chúng tôi có thể thu thập Dữ liệu cá nhân khi Quý khách tải xuống hoặc sử dụng
                      ứng dụng của OSP dành cho thiết bị di động (gọi chung là “Ứng dụng”). Các Ứng dụng này có thể ghi
                      lại một số thông tin nhất định (bao gồm thống kê khi sử dụng Ứng dụng, loại thiết bị, hệ điều
                      hành, cài đặt Ứng dụng, địa chỉ IP, cài đặt ngôn ngữ, ngày giờ kết nối với Ứng dụng và các thông
                      tin liên lạc kỹ thuật khác);
                    </li>

                    <li>
                      (iii) Từ các Sản phẩm, hàng hóa, dịch vụ: Chúng tôi có thể thu thập Dữ liệu cá nhân khi Quý khách
                      tìm kiếm, tiếp cận, mua, đăng ký sử dụng, sử dụng bất kỳ Sản phẩm, hàng hóa, dịch vụ nào thông qua
                      bất kỳ hình thức nào (SMS, USSD, IVR, website, wapsite, ứng dụng…); Kênh giao dịch của OSP, không
                      gian mạng; và/hoặc các phương thức khác theo quy định pháp luật;
                    </li>

                    <li>
                      (iv) Từ các trao đổi, liên lạc với Quý khách: Chúng tôi có thể thu thập Dữ liệu cá nhân thông qua
                      tương tác giữa Chúng tôi và Quý khách (gặp trực tiếp, qua thư, điện thoại, trực tuyến, hệ thống
                      tổng đài, liên lạc điện tử hoặc bất kỳ phương tiện nào khác) bao gồm cả các cuộc khảo sát Quý
                      khách;
                    </li>

                    <li>
                      (v) Từ mạng xã hội: Là các mạng xã hội của OSP và/hoặc các mạng xã hội do chúng tôi hợp tác với
                      các đối tác;
                    </li>

                    <li>
                      (vi) Từ các thiết bị ghi âm, ghi hình được đặt tại các cửa hàng, điểm kinh doanh hoặc nơi thực
                      hiện một phần hoặc toàn bộ hoạt động kinh doanh của OSP mà Quý khách gặp, xuất hiện hoặc tương tác
                      với chúng tôi. Việc đặt các thiết bị ghi âm, ghi hình nhằm mục đích góp phần bảo vệ trật tự an
                      toàn xã hội, bảo vệ quyền và lợi ích hợp pháp của Quý khách và của OSP theo quy định pháp luật;
                    </li>

                    <li>
                      (vii) Từ các tương tác hoặc các công nghệ thu thập dữ liệu tự động: Chúng tôi có thể thu thập
                      thông tin bao gồm địa chỉ IP, URL giới thiệu, hệ điều hành, trình duyệt tin điện tử và bất kỳ
                      thông tin nào khác được ghi tự động từ kết nối:
                      <ul className="grid gap-y-2">
                        <li>
                          <strong>+</strong> Cookie, flash cookie, plug-in, thẻ pixel, tin điện tử beacons, trình kết
                          nối mạng xã hội của bên thứ ba hoặc các công nghệ theo dõi khác;
                        </li>

                        <li>
                          <strong>+</strong> Bất kỳ công nghệ nào có khả năng theo dõi hoạt động cá nhân trên các thiết
                          bị hoặc Trang thông tin điện tử;
                        </li>

                        <li>
                          <strong>+</strong> Thông tin dữ liệu khác được cung cấp bởi một thiết bị.
                        </li>
                      </ul>
                    </li>

                    <li>
                      (viii) Các phương tiện khác: Chúng tôi có thể thu thập Dữ liệu cá nhân khi Quý khách tương tác với
                      OSP thông qua bất kỳ phương tiện nào khác.
                    </li>
                  </ul>
                </section>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Chúng tôi cũng có thể thu thập Dữ liệu cá nhân gián tiếp từ Quý khách thông qua các nguồn thông tin
                  công khai, chính thống; hoặc thông qua việc nhận chia sẻ dữ liệu cần thiết từ các công ty con, đối tác
                  mà họ thu thập được trong quá trình hợp tác với OSP cung cấp Sản phẩm, hàng hóa, dịch vụ cho Quý khách
                  và được Quý khách cho phép chia sẻ.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>2. Cách thức lưu trữ</p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Dữ liệu cá nhân được lưu trữ tại Việt Nam tại hệ thống cơ sở dữ liệu Quý khách của OSP hoặc tại bất cứ
                  đâu mà chúng tôi hoặc các chi nhánh, công ty con, công ty liên kết, đối tác hoặc nhà cung cấp dịch vụ
                  của chúng tôi có cơ sở và tạo bản sao lưu trữ cho trung tâm dữ liệu ở một khu vực khác.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Trong quá trình Quý khách truy cập Trang thông tin điện tử, wapsite, Ứng dụng, mạng xã hội của OSP,
                  chúng tôi cũng có thể lưu trữ thông tin tạm thời qua cookie, clickstream hoặc các công cụ lưu trữ dữ
                  liệu duyệt website tương tự để lưu trữ những dữ liệu mà máy chủ web trong miền có thể truy lại.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  3. Cách thức chuyển giao/chia sẻ dữ liệu
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Chúng tôi sẽ sử dụng các biện pháp bảo mật cần thiết để đảm bảo việc chuyển giao/chia sẻ Dữ liệu cá
                  nhân của Quý khách đến (i) các doanh nghiệp viễn thông khác; (ii) cá nhân/tổ chức tham gia quá trình
                  Xử lý Dữ liệu cá nhân quy định tại Điều 8 Chính sách này); hoặc (iii) cơ quan nhà nước có thẩm quyền,
                  đảm bảo an toàn thông tin, không bị lộ, lọt dữ liệu và yêu cầu các bên tiếp nhận Dữ liệu cá nhân sẽ có
                  biện pháp bảo mật dữ liệu.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>4. Cách thức phân tích</p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Việc phân tích Dữ liệu cá nhân được thực hiện theo các quy trình nội bộ của OSP. Chúng tôi luôn có cơ
                  chế giám sát nghiêm ngặt từng quy trình phân tích dữ liệu, trong đó yêu cầu kiểm tra việc đáp ứng các
                  yêu cầu của pháp luật về bảo mật dữ liệu, bảo đảm an toàn thông tin đối với hệ thống công nghệ thông
                  tin trước khi tiến hành phân tích. Chúng tôi cũng có các quy tắc nghiêm ngặt đảm bảo rằng thông tin cá
                  nhân được ẩn danh hoặc hủy nhận dạng ở giai đoạn thích hợp trong quá trình xử lý.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>5. Cách thức mã hóa</p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Dữ liệu cá nhân thu thập được mã hóa theo các tiêu chuẩn mã hóa phù hợp khi cần thiết trong quá trình
                  lưu trữ hoặc chuyển giao dữ liệu, để đảm bảo các dữ liệu được bảo vệ, xác thực, toàn vẹn và không thể
                  bị thay đổi sau khi đã được gửi đi.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>6. Cách thức xóa dữ liệu</p>

                <section className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Khi Quý khách chấm dứt sử dụng Sản phẩm, hàng hóa, dịch vụ của OSP và có yêu cầu hợp lệ, chúng tôi sẽ
                  tiến hành xóa Dữ liệu cá nhân với toàn bộ Dữ liệu cá nhân mà Quý khách đã cung cấp và/hoặc chúng tôi
                  thu thập được trong quá trình Quý khách sử dụng Sản phẩm, hàng hóa, dịch vụ, trừ trường hợp pháp luật
                  có quy định khác và một số trường hợp không thể thực hiện được như sau:
                  <ul className="grid gap-y-2">
                    <li>
                      (i) Pháp luật quy định không cho phép xóa dữ liệu hoặc yêu cầu bắt buộc phải lưu trữ dữ liệu;
                    </li>

                    <li>
                      (ii) Dữ liệu cá nhân được xử lý bởi cơ quan nhà nước có thẩm quyền với mục đích phục vụ hoạt động
                      của cơ quan nhà nước theo quy định pháp luật;
                    </li>

                    <li>(iii) Dữ liệu cá nhân đã được công khai theo quy định pháp luật;</li>

                    <li>
                      (iv) Dữ liệu cá nhân được xử lý nhằm phục vụ yêu cầu pháp lý, nghiên cứu khoa học, thống kê theo
                      quy định pháp luật;
                    </li>

                    <li>
                      (v) Trong trường hợp tình trạng khẩn cấp về quốc phòng, an ninh quốc gia, trật tự an toàn xã hội,
                      thảm họa lớn, dịch bệnh nguy hiểm; khi có nguy cơ đe dọa an ninh, quốc phòng nhưng chưa đến mức
                      ban bố tình trạng khẩn cấp; phòng, chống bạo loạn, khủng bố, phòng, chống tội phạm và vi phạm pháp
                      luật;
                    </li>

                    <li>
                      (vi) Ứng phó với tình huống khẩn cấp đe dọa đến tính mạng, sức khỏe hoặc sự an toàn của Quý khách
                      hoặc cá nhân khác.
                    </li>
                  </ul>
                </section>
              </section>

              <section className="[&>*]:text-justify [&>*]:leading-[25px]">
                <h1
                  className={classNames(
                    'text-[16px]  font-semibold text-baseColor',
                    isMobile ? 'mt-6' : 'mt-8 uppercase'
                  )}
                >
                  ĐIỀU 5. NGUYÊN TẮC BẢO MẬT DỮ LIỆU CỦA OSP
                </h1>
              </section>

              <section className="mt-4 grid gap-y-4 [&>*]:text-justify [&>*]:leading-[25px]">
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  1. Dữ liệu cá nhân của Quý khách được bảo mật theo Chính sách này và quy định pháp luật.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  2. Chúng tôi có trách nhiệm bảo đảm an toàn thông tin mạng theo quy định pháp luật, bảo đảm quốc
                  phòng, an ninh quốc gia, bí mật nhà nước, giữ vững ổn định chính trị, trật tự, an toàn xã hội và thúc
                  đẩy phát triển kinh tế - xã hội.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  3. Việc xử lý sự cố an toàn thông tin mạng khi thực hiện luôn bảo đảm quyền và lợi ích hợp pháp của
                  Quý khách.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  4. Hoạt động đảm bảo an toàn thông tin mạng được thực hiện thường xuyên, liên tục, kịp thời và hiệu
                  quả.
                </p>
              </section>

              <section className="[&>*]:text-justify [&>*]:leading-[25px]">
                <h1
                  className={classNames(
                    'text-[16px]  font-semibold text-baseColor',
                    isMobile ? 'mt-6' : 'mt-8 uppercase'
                  )}
                >
                  ĐIỀU 6. HẬU QUẢ, THIỆT HẠI KHÔNG MONG MUỐN CÓ KHẢ NĂNG XẢY RA
                </h1>
              </section>

              <section className="mt-4 grid gap-y-4 [&>*]:text-justify [&>*]:leading-[25px]">
                <section className={'break-words text-left text-sm font-medium text-baseColor'}>
                  1. Chúng tôi sử dụng nhiều công nghệ bảo mật thông tin khác nhau như: chuẩn quốc tế PCI, SSL, tường
                  lửa, mã hóa… nhằm bảo vệ và ngăn chặn việc Dữ liệu cá nhân của Quý khách bị truy cập, sử dụng hoặc
                  chia sẻ ngoài ý muốn. Tuy nhiên, không một dữ liệu nào có thể được bảo mật hoàn toàn. Do vậy, chúng
                  tôi không thể cam kết bảo mật một cách tuyệt đối Dữ liệu cá nhân của Quý khách trong một số trường hợp
                  như:
                  <ul className="grid gap-y-2">
                    <li>(i) Lỗi phần cứng, phần mềm trong quá trình xử lý dữ liệu làm mất dữ liệu của Quý khách;</li>

                    <li>
                      (ii) Lỗ hổng bảo mật nằm ngoài khả năng kiểm soát của chúng tôi, hệ thống bị hacker tấn công gây
                      lộ lọt dữ liệu.
                    </li>
                  </ul>
                </section>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  2. Chúng tôi khuyến cáo Quý khách bảo mật các thông tin liên quan đến mật khẩu đăng nhập vào tài khoản
                  của Quý khách, mã OTP và không chia sẻ nội dung này với bất kỳ người nào khác.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  3. Quý khách cần biết rõ rằng bất kỳ thời điểm nào Quý khách tiết lộ và công khai Dữ liệu cá nhân của
                  Quý khách, dữ liệu đó có thể bị người khác thu thập và sử dụng bởi các mục đích nằm ngoài tầm kiểm
                  soát của Quý khách và chúng tôi.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  4. Chúng tôi khuyến cáo Quý khách bảo quản thiết bị cá nhân (máy điện thoại, máy tính bảng, máy tính
                  cá nhân…) trong quá trình sử dụng; Quý khách nên đăng xuất khỏi tài khoản của mình khi không có nhu
                  cầu sử dụng.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  5. Trong trường hợp máy chủ lưu trữ dữ liệu bị tấn công dẫn đến bị mất, lộ, lọt Dữ liệu cá nhân của
                  Quý khách, OSP sẽ có trách nhiệm thông báo vụ việc cho cơ quan chức năng điều tra xử lý kịp thời và
                  thông báo cho Quý khách được biết theo quy định pháp luật.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  6. Không gian mạng không phải là một môi trường an toàn và chúng tôi không thể đảm bảo tuyệt đối rằng
                  Dữ liệu cá nhân của Quý khách được chia sẻ qua không gian mạng sẽ luôn được bảo mật. Khi Quý khách
                  truyền tải Dữ liệu cá nhân qua không gian mạng, Quý khách chỉ nên sử dụng các hệ thống an toàn để truy
                  cập trang thông tin điện tử, ứng dụng hoặc thiết bị. Quý khách có trách nhiệm giữ thông tin xác thực
                  truy cập của mình cho từng trang thông tin điện tử, ứng dụng hoặc thiết bị an toàn và bí mật.
                </p>
              </section>

              <section className="[&>*]:text-justify [&>*]:leading-[25px]">
                <h1
                  className={classNames(
                    'text-[16px]  font-semibold text-baseColor',
                    isMobile ? 'mt-6' : 'mt-8 uppercase'
                  )}
                >
                  ĐIỀU 7. THỜI GIAN BẮT ĐẦU, THỜI GIAN KẾT THÚC XỬ LÝ DỮ LIỆU CÁ NHÂN
                </h1>
              </section>

              <section className="mt-4 grid gap-y-4 [&>*]:text-justify [&>*]:leading-[25px]">
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  1. Dữ liệu cá nhân được xử lý kể từ thời điểm chúng tôi nhận được Dữ liệu cá nhân do Quý khách cung
                  cấp và chúng tôi đã có cơ sở pháp lý phù hợp để xử lý dữ liệu theo quy định pháp luật.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  2. Trong phạm vi pháp luật cho phép, Dữ liệu cá nhân sẽ được xử lý cho đến khi các mục đích xử lý dữ
                  liệu đã được hoàn thành.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  3. Chúng tôi có thể phải lưu trữ Dữ liệu cá nhân của Quý khách ngay cả khi hợp đồng giữa Quý khách và
                  OSP đã chấm dứt để thực hiện các nghĩa vụ pháp lý của chúng tôi theo quy định pháp luật và/hoặc yêu
                  cầu của cơ quan nhà nước có thẩm quyền.
                </p>
              </section>

              <section className="[&>*]:text-justify [&>*]:leading-[25px]">
                <h1
                  className={classNames(
                    'text-[16px]  font-semibold text-baseColor',
                    isMobile ? 'mt-6' : 'mt-8 uppercase'
                  )}
                >
                  ĐIỀU 8. TỔ CHỨC, CÁ NHÂN THAM GIA QUÁ TRÌNH XỬ LÝ DỮ LIỆU CÁ NHÂN
                </h1>
              </section>

              <section className="mt-4 grid gap-y-4 [&>*]:text-justify [&>*]:leading-[25px]">
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  1. Tùy từng trường hợp, chúng tôi có thể là Bên Kiểm soát Dữ liệu cá nhân hoặc Bên Kiểm soát và xử lý
                  Dữ liệu cá nhân.
                </p>

                <section className={'break-words text-left text-sm font-medium text-baseColor'}>
                  2. Trong phạm vi pháp luật cho phép, Quý khách hiểu rõ rằng chúng tôi có thể chia sẻ Dữ liệu cá nhân
                  nhằm các mục đích được đề cập tại Điều 3 với các tổ chức, cá nhân dưới đây:
                  <ul className="grid gap-y-2">
                    <li>(i) Doanh nghiệp thành viên, công ty liên kết của OSP;</li>

                    <li>
                      (ii) Tổ chức, cá nhân cung cấp dịch vụ và/hoặc hợp tác với OSP, bao gồm nhưng không giới hạn: đại
                      lý, các đơn vị tư vấn, kiểm toán, luật sư, công chứng viên và các đối tác hợp tác kinh doanh, cung
                      cấp cung cấp giải pháp công nghệ thông tin, phần mềm, ứng dụng, các dịch vụ vận hành, quản lý, xử
                      lý sự cố, phát triển hạ tầng;
                    </li>

                    <li>
                      (iii) Bất kỳ cá nhân, tổ chức nào là bên đại diện, bên được ủy quyền của Quý khách, hành động thay
                      mặt Quý khách;
                    </li>

                    <li>(iv) Các nhà cung cấp dịch vụ thanh toán dựa trên ủy quyền hoặc chấp thuận của Quý khách. </li>
                  </ul>
                </section>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Việc chia sẻ dữ liệu sẽ được thực hiện theo đúng trình tự, cách thức và quy định pháp luật hiện hành.
                  Các bên tiếp nhận Dữ liệu cá nhân có nghĩa vụ bảo mật Dữ liệu cá nhân của Quý khách phù hợp với Chính
                  sách này; các quy định, quy trình, tiêu chuẩn về Bảo vệ Dữ liệu cá nhân của OSP và quy định pháp luật
                  hiện hành.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  3. Chúng tôi có thể chia sẻ thông tin Quý khách với cơ quan nhà nước có thẩm quyền khi thực hiện mục
                  đích Xử lý Dữ liệu cá nhân nêu tại Điều 3 Chính sách này theo quy định pháp luật.
                </p>
              </section>

              <section className="[&>*]:text-justify [&>*]:leading-[25px]">
                <h1
                  className={classNames(
                    'text-[16px]  font-semibold text-baseColor',
                    isMobile ? 'mt-6' : 'mt-8 uppercase'
                  )}
                >
                  ĐIỀU 9. QUYỀN CỦA QUÝ KHÁCH
                </h1>
              </section>

              <section className="mt-4 grid gap-y-4 [&>*]:text-justify [&>*]:leading-[25px]">
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  1. Quý khách được biết về hoạt động Xử lý Dữ liệu cá nhân của mình, trừ trường hợp pháp luật có quy
                  định khác.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  2. Quý khách được quyết định sự đồng ý liên quan đến việc Xử lý Dữ liệu cá nhân của mình, trừ trường
                  hợp pháp luật quy định khác.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  3. Quý khách được quyền truy cập để xem, chỉnh sửa hoặc yêu cầu chỉnh sửa Dữ liệu cá nhân của mình,
                  trừ trường hợp pháp luật có quy định khác.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Chúng tôi sẽ chỉnh sửa Dữ liệu cá nhân khi được Quý khách yêu cầu hoặc theo quy định pháp luật chuyên
                  ngành. Trường hợp không thể thực hiện, chúng tôi sẽ thông báo tới Quý khách theo thỏa thuận giữa chúng
                  tôi và Quý khách, quy định pháp luật.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  4. Quý khách được quyền xóa hoặc yêu cầu xóa Dữ liệu cá nhân của mình theo quy định của khoản 6 Điều 4
                  Chính sách này.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  5. Quý khách được quyền yêu cầu hạn chế Xử lý Dữ liệu cá nhân của mình theo quy định pháp luật.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Việc hạn chế xử lý dữ liệu sẽ được chúng tôi thực hiện sau khi có yêu cầu của Quý khách phù hợp với
                  điều kiện kỹ thuật cho phép trừ trường hợp pháp luật có quy định khác hoặc theo thỏa thuận của các
                  bên.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  6. Quý khách được quyền yêu cầu chúng tôi cung cấp cho bản thân Dữ liệu cá nhân của mình, trừ trường
                  hợp pháp luật có quy định khác.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Việc cung cấp Dữ liệu cá nhân của Quý khách sẽ được OSP thực hiện sau khi có yêu cầu của Quý khách,
                  trừ trường hợp pháp luật có quy định khác
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  7. Quý khách được quyền yêu cầu rút lại sự đồng ý đối với các mục đích xử lý mà Quý khách đồng ý cho
                  phép OSP xử lý, trừ trường hợp pháp luật có quy định khác. Khi nhận được yêu cầu, OSP thông báo cho
                  Quý khách về hậu quả, thiệt hại có thể xảy ra khi rút lại sự đồng ý.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Trường hợp việc rút lại sự đồng ý của Quý khách ảnh hưởng tới việc thực hiện hợp đồng giữa OSP và Quý
                  khách, nghĩa vụ pháp lý của OSP, tính mạng, tài sản và quyền, lợi ích hợp pháp của Quý khách, tổ chức,
                  cá nhân khác, nhiệm vụ bảo vệ an ninh quốc gia, trật tự an toàn xã hội, chúng tôi có quyền hạn chế,
                  tạm ngừng, chấm dứt, hủy bỏ một phần hoặc toàn bộ hợp đồng cung cấp sản phẩm, hàng hóa, dịch vụ giữa
                  OSP và Quý khách. Chúng tôi không chịu bất kỳ trách nhiệm pháp lý hoặc bồi thường cho bất kỳ tổn thất
                  nào phát sinh đối với Quý khách trong trường hợp này.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  8. Quý khách được quyền phản đối OSP Xử lý Dữ liệu cá nhân của mình nhằm ngăn chặn hoặc hạn chế tiết
                  lộ Dữ liệu cá nhân hoặc lý do khác theo quy định pháp luật.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Chúng tôi sẽ thực hiện yêu cầu phản đối của Quý khách sau khi nhận được yêu cầu, trừ trường hợp pháp
                  luật có quy định khác.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Trường hợp việc phản đối của Quý khách ảnh hưởng tới việc thực hiện hợp đồng giữa OSP và Quý khách,
                  nghĩa vụ pháp lý của OSP, tính mạng, tài sản và quyền, lợi ích hợp pháp của Quý khách, tổ chức, cá
                  nhân khác, nhiệm vụ bảo vệ an ninh quốc gia, trật tự an toàn xã hội, chúng tôi có quyền hạn chế, tạm
                  ngừng, chấm dứt, hủy bỏ một phần hoặc toàn bộ hợp đồng cung cấp Sản phẩm, hàng hóa, dịch vụ giữa OSP
                  và Quý khách. Chúng tôi không chịu bất kỳ tổn thất nào phát sinh đối với Quý khách trong trường hợp
                  này.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  9. Quý khách có quyền khiếu nại, tố cáo hoặc khởi kiện theo quy định pháp luật.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  10. Quý khách có quyền yêu cầu bồi thường đối với thiệt hại thực tế theo quy định pháp luật nếu OSP có
                  hành vi vi phạm quy định về bảo vệ Dữ liệu cá nhân của mình, trừ trường hợp các bên có thỏa thuận khác
                  hoặc pháp luật có quy định khác.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  11. Quý khách có quyền tự bảo vệ theo quy định pháp luật có liên quan, bao gồm nhưng không giới hạn Bộ
                  luật Dân sự, hoặc yêu cầu cơ quan, tổ chức có thẩm quyền thực hiện các phương thức bảo vệ quyền dân sự
                  như buộc chấm dứt hành vi xâm phạm, buộc xin lỗi, cải chính công khai, buộc bồi thường thiệt hại.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  12. Các quyền khác theo Chính sách này và theo quy định pháp luật.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  13. Quý khách có thể thực hiện các quyền tại khoản 1, 2, 3, 4, 5, 6, 7, 8, 9 và 10 Điều này bằng cách
                  truy cập vào Trang thông tin điện tử{' '}
                  <a href="https://shop360.vn" className="text-blue-500" target="_blank" rel="noopener noreferrer">
                    https://shop360.vn/
                  </a>{' '}
                  hoặc gọi tới số đường dây nóng 0896.686.686 hoặc gửi email theo địa chỉ shop360.cskh@osp.vn hoặc
                  phương thức khác theo quy định pháp luật và quy định của OSP tại từng thời điểm. OSP có thể xác minh
                  các thông tin cần thiết từ Quý khách để thực hiện các yêu cầu của Quý khách.
                </p>
              </section>

              <section className="[&>*]:text-justify [&>*]:leading-[25px]">
                <h1
                  className={classNames(
                    'text-[16px]  font-semibold text-baseColor',
                    isMobile ? 'mt-6' : 'mt-8 uppercase'
                  )}
                >
                  ĐIỀU 10. NGHĨA VỤ CỦA QUÝ KHÁCH
                </h1>
              </section>

              <section className="mt-4 grid gap-y-4 [&>*]:text-justify [&>*]:leading-[25px]">
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  1. Tuân thủ các quy định pháp luật, quy định của OSP liên quan đến Xử lý Dữ liệu cá nhân của Quý
                  khách.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  2. Cung cấp đầy đủ, trung thực, chính xác Dữ liệu cá nhân, các thông tin khác theo yêu cầu của OSP khi
                  tìm kiếm, tiếp cận, mua, đăng ký sử dụng và sử dụng Sản phẩm, hàng hóa, dịch vụ và khi có thay đổi về
                  các thông tin này. Chúng tôi sẽ tiến hành bảo mật Dữ liệu cá nhân của Quý khách căn cứ trên thông tin
                  Quý khách đã cung cấp. Do đó, nếu có bất kỳ thông tin sai lệch nào, chúng tôi sẽ không chịu trách
                  nhiệm trong trường hợp thông tin đó làm ảnh hưởng hoặc hạn chế quyền lợi của Quý khách. Trường hợp
                  không thông báo, nếu có phát sinh rủi ro, tổn thất thì Quý khách chịu trách nhiệm về những sai sót hay
                  hành vi lợi dụng, lừa đảo khi sử dụng Sản phẩm, hàng hóa, dịch vụ do lỗi của Quý khách hoặc do không
                  cung cấp đúng, đầy đủ, chính xác, kịp thời sự thay đổi thông tin; bao gồm cả thiệt hại về tài chính,
                  chi phí phát sinh do thông tin cung cấp sai hoặc không thống nhất.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  3. Phối hợp với chúng tôi, cơ quan nhà nước có thẩm quyền hoặc bên thứ ba trong trường hợp phát sinh
                  các vấn đề ảnh hưởng đến tính bảo mật Dữ liệu cá nhân của Quý khách.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  4. Tự bảo vệ Dữ liệu cá nhân của Quý khách; yêu cầu các tổ chức, cá nhân khác có liên quan bảo vệ Dữ
                  liệu cá nhân của Quý khách; chủ động áp dụng các biện pháp nhằm bảo vệ Dữ liệu cá nhân của Quý khách
                  trong quá trình sử dụng Sản phẩm, hàng hóa, dịch vụ của OSP; thông báo kịp thời cho chúng tôi khi phát
                  hiện thấy có sai sót, nhầm lẫn về Dữ liệu cá nhân hoặc nghi ngờ Dữ liệu cá nhân đang bị xâm phạm.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  5. Tôn trọng, bảo vệ Dữ liệu cá nhân của người khác.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  6. Tự chịu trách nhiệm đối với những thông tin, dữ liệu mà Quý khách tạo lập, cung cấp trên không gian
                  mạng; tự chịu trách nhiệm trong trường hợp Dữ liệu cá nhân bị rò rỉ, xâm phạm do lỗi của mình.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  7. Thường xuyên cập nhật các quy định, chính sách liên quan đến việc bảo vệ và Xử lý Dữ liệu cá nhân
                  của OSP trong từng thời kỳ được thông báo tới Quý khách qua Kênh giao dịch của OSP.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  8. Thực hiện quy định pháp luật về bảo vệ dữ liệu cá nhân và tham gia phòng, chống các hành vi vi phạm
                  quy định về bảo vệ dữ liệu cá nhân.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  9. Các nghĩa vụ khác theo quy định pháp luật.
                </p>
              </section>

              <section className="[&>*]:text-justify [&>*]:leading-[25px]">
                <h1
                  className={classNames(
                    'text-[16px]  font-semibold text-baseColor',
                    isMobile ? 'mt-6' : 'mt-8 uppercase'
                  )}
                >
                  ĐIỀU 11. ĐIỀU KHOẢN CHUNG
                </h1>
              </section>

              <section className="mt-4 grid gap-y-4 [&>*]:text-justify [&>*]:leading-[25px]">
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  1. Chính sách này có hiệu lực từ ngày 01/01/2024 và có thể được sửa đổi theo từng thời kỳ và được
                  thông báo tới Quý khách thông qua các Kênh giao dịch của OSP trước khi áp dụng. Những thay đổi này sẽ
                  có hiệu lực theo quy định pháp luật.
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  2. Chính sách này là Thông báo Xử lý Dữ liệu cá nhân theo quy định pháp luật hiện hành.
                </p>

                <section className={'break-words text-left text-sm font-semibold text-baseColor'}>
                  <div>Mọi vướng mắc Quý khách vui lòng liên hệ với OSP để được hỗ trợ sớm nhất:</div>
                  <div>CÔNG TY CỔ PHẦN CÔNG NGHỆ PHẦN MỀM VÀ NỘI DUNG SỐ OSP</div>
                  <div>+ Trụ sở: Tầng 4, Tòa nhà The Garden Hill, 99 Trần Bình, Mỹ Đình 2, Nam Từ Liêm, Hà Nội</div>
                  <div>+ Điện thoại: 0896.686.686</div>
                  <div>
                    + Email:{' '}
                    <a href="mailto:shop360.cskh@osp.vn" className="text-blue-500">
                      shop360.cskh@osp.vn
                    </a>
                  </div>
                </section>
              </section>
            </div>
          </div>
        </div>
      </div>
      <FooterMobi />
    </>
  );
};
