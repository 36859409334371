import { SideBar } from '../sidebar';
import { Fragment, useCallback, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { useQuery } from 'react-query';
import { getUserInfo } from '../../services/account';
import { PATH_PAGE, QUERY_KEY } from '../../constant';
import Cookies from 'js-cookie';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { classNames } from '../../utils/utils';
import useCheckDeviceMobi from '../../hooks/useCheckDeviceMobi';
import logo from '../../assets/images/LogoWhite.png';
import logoMobile from '../../assets/images/header/Logo.png';
import { AvatarDefault } from 'assets/images/header';
import ImageWithFallback from 'components/common/ImageWithFallback';
import AuthContext from 'context/AuthProvider';
import { AccountAuthen } from 'components/common/AccountAuthen';

export const HeaderAdmin = ({ children }: any) => {
  const isMobile = useCheckDeviceMobi();
  const { token, setToken } = useAuth();
  const { setUserLogin, userLogin } = useContext(AuthContext);
  const navigate = useNavigate();
  const { pathname, key } = useLocation();

  const pathnames = pathname.split('/').filter(Boolean);
  const [isOpen, setIsOpen] = useState(false);
  const [isShowUserPopup, setIsShowUserPopup] = useState(false);
  const [isShowNotification, setIsShowNotification] = useState<boolean>(false);

  useQuery(QUERY_KEY.USER_INFO, () => getUserInfo(), {
    enabled: !userLogin?.id,
    onSuccess: (response) => {
      if (!response?.data?.data) {
        setUserLogin(undefined);
        return;
      }
      setUserLogin(response.data.data);
    },
  });

  const onLogout = useCallback(() => {
    Cookies.remove('token');
    setToken && setToken(undefined);
    setUserLogin(undefined);
    navigate(PATH_PAGE.LOGIN);
  }, [navigate, setToken, setUserLogin]);

  const scrollToTop = (link: string) => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate(link);
    setIsOpen(false);
  };

  if (userLogin?.userId && !userLogin?.source?.includes('SHOP360')) {
    onLogout();
    return <></>;
  }

  return (
    <>
      {isMobile ? (
        <>
          <header
            className={classNames(
              'absolute z-50 flex h-[50px]  w-full flex-row items-center justify-between px-2',
              pathnames.includes('dashboard') ? 'bg-transparent' : 'bg-white'
            )}
          >
            <div className="md:hidden">
              <button
                type="button"
                onClick={() => setIsOpen(true)}
                className="hs-collapse-toggle inline-flex items-center justify-center gap-2 border-0 p-2 align-middle text-sm font-medium text-gray-700 transition-all"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M3 4H21V6H3V4ZM3 11H15V13H3V11ZM3 18H21V20H3V18Z"
                    fill={pathnames.includes('dashboard') ? 'white' : 'black'}
                  />
                </svg>
              </button>
            </div>
            <img
              className="h-20 w-28 object-contain"
              src={logo}
              alt="Logo"
              onClick={() => navigate(PATH_PAGE.DASHBOARD)}
            />
            <div onClick={() => setIsShowUserPopup(true)} className="h-fit w-fit">
              <ImageWithFallback
                src={userLogin?.avatarPath}
                fallback={AvatarDefault}
                statusVerify={userLogin?.statusVerify === 1}
                name={userLogin?.fullName}
                className={'mr-2 h-[24px] w-[24px] min-w-[24px]'}
              />
            </div>
          </header>
          <Fragment key={key}>{children}</Fragment>
        </>
      ) : (
        <div
          className={'hidden h-screen flex-nowrap bg-gradient-to-r from-[#F15836] to-[#FF924F] md:flex md:pl-[264px]'}
        >
          <SideBar />
          <section className={'m-[24px] min-h-[calc(100vh-48px)] w-full rounded-[20px]'}>
            <Fragment key={key}>{children}</Fragment>
          </section>
        </div>
      )}
      {isOpen && (
        <Transition show={isOpen} as={Fragment}>
          <Dialog
            open={isOpen}
            onClose={() => {}}
            className="fixed inset-0 z-50 flex h-full min-h-screen w-screen flex-row items-center justify-center overflow-y-auto bg-[#000000]/60"
          >
            <Dialog.Panel className={'h-screen w-screen bg-white'}>
              <div className="flex items-center justify-between px-2 py-3">
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="hs-collapse-toggle inline-flex items-center justify-center gap-2 border-0 bg-white p-2 align-middle text-sm font-medium text-gray-700 transition-all focus:outline-none"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.0007 10.586L16.9507 5.63599L18.3647 7.04999L13.4147 12L18.3647 16.95L16.9507 18.364L12.0007 13.414L7.05072 18.364L5.63672 16.95L10.5867 12L5.63672 7.04999L7.05072 5.63599L12.0007 10.586Z"
                      fill="black"
                    />
                  </svg>
                </button>
                <img
                  className="h-10 w-20 object-contain"
                  src={logoMobile}
                  alt="Logo"
                  onClick={() => navigate(PATH_PAGE.DASHBOARD)}
                />
                <div className={'w-10 text-base font-bold text-black'}></div>
              </div>
              <div className={'flex h-[calc(100%-80px)] w-full flex-col justify-between px-4'}>
                <div className={'flex h-fit w-full flex-col'}>
                  <p
                    className={'mb-6 mt-4 cursor-pointer text-[18px] font-bold leading-[28px] text-baseColor'}
                    onClick={() => scrollToTop(PATH_PAGE.DASHBOARD)}
                  >
                    Dashboard
                  </p>
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className={'flex flex-row justify-between'}>
                          <span className={'text-[18px] font-bold leading-[28px] text-baseColor'}>Sản phẩm</span>
                          <svg
                            className={`${open ? 'transform' : 'rotate-180 transform'}`}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.8">
                              <path
                                d="M12.6038 17.179L19.7828 10.0015C20.0732 9.71106 20.2363 9.31718 20.2363 8.90647C20.2363 8.49577 20.0732 8.10189 19.7828 7.81147C19.4924 7.52106 19.0985 7.35791 18.6878 7.35791C18.2771 7.35791 17.8832 7.52106 17.5928 7.81147L11.4983 13.909L5.40526 7.81147C5.11485 7.52126 4.72105 7.3583 4.31048 7.35844C4.10719 7.35851 3.90591 7.39862 3.71812 7.47648C3.53033 7.55434 3.35971 7.66843 3.21601 7.81222C3.07232 7.95602 2.95835 8.12671 2.88062 8.31456C2.80288 8.5024 2.76291 8.70371 2.76298 8.907C2.76312 9.31757 2.92635 9.71126 3.21676 10.0015L10.3943 17.179C10.6873 17.4718 11.0847 17.6363 11.499 17.6363C11.9133 17.6363 12.3107 17.4718 12.6038 17.179Z"
                                fill="#404040"
                              />
                            </g>
                          </svg>
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-4 flex flex-col space-y-4 text-[16px] font-normal leading-[26px] text-baseColor">
                          {/* <p className={'cursor-pointer'} onClick={() => scrollToTop(`${PATH_PAGE.PRODUCTS}/category`)}>
                            Danh mục
                          </p>
                          <p className={'cursor-pointer'} onClick={() => scrollToTop(`${PATH_PAGE.PRODUCTS}/sim`)}>
                            Sim số
                          </p> */}
                          <p
                            className={'cursor-pointer'}
                            onClick={() => scrollToTop(`${PATH_PAGE.PRODUCTS}/service-plan`)}
                          >
                            Gói cước
                          </p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className={'mt-6 flex flex-row justify-between'}>
                          <span className={'text-[18px] font-bold leading-[28px] text-baseColor'}>
                            Báo cáo thống kê
                          </span>
                          <svg
                            className={`${open ? 'transform' : 'rotate-180 transform'}`}
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.8">
                              <path
                                d="M12.6038 17.179L19.7828 10.0015C20.0732 9.71106 20.2363 9.31718 20.2363 8.90647C20.2363 8.49577 20.0732 8.10189 19.7828 7.81147C19.4924 7.52106 19.0985 7.35791 18.6878 7.35791C18.2771 7.35791 17.8832 7.52106 17.5928 7.81147L11.4983 13.909L5.40526 7.81147C5.11485 7.52126 4.72105 7.3583 4.31048 7.35844C4.10719 7.35851 3.90591 7.39862 3.71812 7.47648C3.53033 7.55434 3.35971 7.66843 3.21601 7.81222C3.07232 7.95602 2.95835 8.12671 2.88062 8.31456C2.80288 8.5024 2.76291 8.70371 2.76298 8.907C2.76312 9.31757 2.92635 9.71126 3.21676 10.0015L10.3943 17.179C10.6873 17.4718 11.0847 17.6363 11.499 17.6363C11.9133 17.6363 12.3107 17.4718 12.6038 17.179Z"
                                fill="#404040"
                              />
                            </g>
                          </svg>
                        </Disclosure.Button>
                        <Disclosure.Panel className="mt-4 flex flex-col space-y-4 text-[16px] font-normal leading-[26px] text-baseColor">
                          <p
                            className={'cursor-pointer'}
                            onClick={() => scrollToTop(`${PATH_PAGE.STATISTIC_TRANSACTION}`)}
                          >
                            Danh sách đơn hàng
                          </p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                  <p
                    className={'mt-6 text-[18px] font-bold leading-[28px] text-baseColor'}
                    onClick={() => scrollToTop(PATH_PAGE.ACCOUNT_COLLABORATOR)}
                  >
                    Tài khoản
                  </p>
                  <p className={'mt-6 text-[18px] font-bold leading-[28px] text-baseColor'}>Hỗ trợ</p>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </Transition>
      )}
      {isShowUserPopup && (
        <Transition show={isShowUserPopup} as={Fragment}>
          <Dialog
            open={isShowUserPopup}
            onClose={() => {}}
            className="fixed inset-0 z-50 flex h-full min-h-screen w-screen flex-row items-center justify-center overflow-y-auto bg-[#000000]/60"
          >
            <Dialog.Panel className={'h-screen w-screen bg-white'}>
              <div className="flex items-center justify-between px-2 py-3">
                <button
                  type="button"
                  onClick={() => setIsShowUserPopup(false)}
                  className="hs-collapse-toggle inline-flex items-center justify-center gap-2 border-0 bg-white p-2 align-middle text-sm font-medium text-gray-700 transition-all focus:outline-none"
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M12.0007 10.586L16.9507 5.63599L18.3647 7.04999L13.4147 12L18.3647 16.95L16.9507 18.364L12.0007 13.414L7.05072 18.364L5.63672 16.95L10.5867 12L5.63672 7.04999L7.05072 5.63599L12.0007 10.586Z"
                      fill="black"
                    />
                  </svg>
                </button>
                <img
                  className="h-10 w-20 object-contain"
                  src={logoMobile}
                  alt="Logo"
                  onClick={() => navigate(PATH_PAGE.DASHBOARD)}
                />
                <div className={'w-10 text-base font-bold text-black'}></div>
              </div>
              <div className={'flex h-[calc(100%-80px)] w-full flex-col justify-between px-4'}>
                <div className={'flex h-fit w-full flex-col'}>
                  <div className="mb-4 flex flex-row items-center space-x-2">
                    <ImageWithFallback
                      src={userLogin?.avatarPath}
                      fallback={AvatarDefault}
                      statusVerify={userLogin?.statusVerify === 1}
                      name={userLogin?.fullName}
                      className={'mr-2 h-[32px] w-[32px] min-w-[32px]'}
                    />
                    <span className="text-lg font-bold text-baseColor">
                      {userLogin?.fullName || userLogin?.phone || userLogin?.mobile}
                    </span>
                  </div>
                  {/* <p
                    className={'my-2 mt-4 cursor-pointer text-base font-medium text-baseColor'}
                    onClick={() => {
                      navigate(PATH_PAGE.PROFILE);
                      setIsShowUserPopup(false);
                    }}
                  >
                    Quản lý tài khoản CTV
                  </p>
                  <p
                    className={'my-2 cursor-pointer text-base font-medium text-baseColor'}
                    onClick={() => {
                      setIsShowUserPopup(false);
                      if (userLogin?.statusVerify === 1) {
                        navigate(PATH_PAGE.CONFIG_PAYMENT);
                        return;
                      }
                      setIsShowNotification(true);
                    }}
                  >
                    Thiết lập tài khoản thanh toán
                  </p>
                  <p
                    className={'my-2 cursor-pointer text-base font-medium text-baseColor'}
                    onClick={() => {
                      navigate(PATH_PAGE.CHANGE_PASSWORD);
                      setIsShowUserPopup(false);
                    }}
                  >
                    Thay đổi mật khẩu
                  </p> */}
                  <p className={'my-2 cursor-pointer text-base font-medium text-baseColor'} onClick={onLogout}>
                    Đăng xuất
                  </p>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </Transition>
      )}
      {isShowNotification && <AccountAuthen setIsShowNotification={setIsShowNotification} />}
    </>
  );
};
