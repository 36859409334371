import { HeaderCommon } from '../../../components/header';
import useCheckDeviceMobi from '../../../hooks/useCheckDeviceMobi';
import { TransactionMobile } from './mobile/TransactionMobile';
import { TransactionWeb } from './web/TransactionWeb';

export const StatisticTransaction = () => {
  const isMobile = useCheckDeviceMobi();

  return (
    <div className={'h-fit w-full bg-[#F8F8F8] pt-4 md:h-full md:rounded-[20px] md:pr-0 md:pt-6 md:pl-6 md:pb-6'}>
      <HeaderCommon
        children={<span className={'text-[22px] font-extrabold text-[#404040]'}>Danh sách giao dịch</span>}
      />
      {isMobile ? <TransactionMobile /> : <TransactionWeb />}
    </div>
  );
};
