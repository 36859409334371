import { useCallback } from 'react';
import { TableV2 } from '../../../../components/common/TableV2';
import { getIconCategory } from '../../../../utils/getIconCategory';
import { useNavigate } from 'react-router';
import { format, parse } from 'date-fns';
import { PATH_PAGE } from 'constant';
import Mobiphone from '../../../../assets/images/mobifone.svg';
import Vinaphone from '../../../../assets/images/vinaphone.svg';
import Saymee from '../../../../assets/images/saymee.svg';

export const TableTransaction = ({ dataRows, currentPage, perPage }: any) => {
  const navigate = useNavigate();
  const onRowClick = (row: any) => {
    switch (row.transType) {
      case 1:
      case 2:
        return navigate(`${PATH_PAGE.STATISTIC_TRANSACTION}/info/${row.transCode}`);
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
        return navigate(
          `${PATH_PAGE.ACCOUNT_COLLABORATOR}/info-payment?transCode=${row.transCode}&transType=${row.transType}&stt=${row.stt}`
        );
      default:
        return;
    }
  };

  const renderRow = useCallback(
    (row: any, index: number) => {
      const dateFormat = row.genDate
        ? format(parse(row.genDate, 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd/MM/yyyy - HH:mm:ss')
        : '';
      return (
        <tr
          key={row.id}
          className="cursor-fancy bg-white shadow-base hover:z-50 hover:shadow-[0px_0px_10px_rgba(209,209,209,0.2)] hover:drop-shadow-[0px_0px_8px_rgba(255,158,110,0.6)]"
          onClick={() => onRowClick(row)}
        >
          <td className="rounded-l-[15px] px-6 py-4 text-center text-sm text-[#404040]">
            {index + 1 + (currentPage - 1) * perPage}
          </td>
          <td className="px-2 py-4 text-center  text-sm text-[#404040]">{dateFormat}</td>
          <td className="px-2 py-4 text-center text-sm text-[#F15836] underline underline-offset-2">
            <span onClick={() => onRowClick(row)}>{row.transCode}</span>
          </td>
          <td className="px-2 py-4 text-center text-sm text-[#F15836]">
            <div className={'flex w-full justify-center'}>
              <img src={row.agent === 3 ? Vinaphone : row.agent === 0 ? Mobiphone : ''} alt="mạng" />
            </div>
          </td>
          <td className="flex items-center justify-center px-2 py-4 text-center">{getIconCategory(row.itemType)}</td>
          <td className="px-2 py-4 text-center text-sm text-[#404040]">{row.itemName}</td>
          <td className="px-2 py-4 text-center text-sm text-[#404040]">{getCustomer(row)}</td>
          <td className="px-2 py-4 text-center text-sm text-[#404040]">{row.shareTypeStr}</td>
          <td className="px-2 py-4 text-center">
            {row.status === 1 ? (
              <div className={'flex flex-row items-center justify-center'}>
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_632_31299)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10.0625 0.609375C4.53977 0.609375 0.0625 5.08665 0.0625 10.6094C0.0625 16.1321 4.53977 20.6094 10.0625 20.6094C15.5852 20.6094 20.0625 16.1321 20.0625 10.6094C20.0625 5.08665 15.5852 0.609375 10.0625 0.609375ZM14.397 8.91847C14.4769 8.82723 14.5376 8.72096 14.5758 8.60589C14.6139 8.49083 14.6286 8.3693 14.6191 8.24845C14.6096 8.12761 14.576 8.00989 14.5203 7.90222C14.4646 7.79455 14.3879 7.69911 14.2948 7.6215C14.2017 7.5439 14.094 7.4857 13.978 7.45034C13.8621 7.41498 13.7402 7.40316 13.6197 7.41558C13.4991 7.42801 13.3822 7.46442 13.2759 7.52268C13.1696 7.58094 13.076 7.65987 13.0007 7.75483L9.09159 12.4448L7.06886 10.4212C6.89741 10.2556 6.66777 10.164 6.42941 10.166C6.19105 10.1681 5.96304 10.2637 5.79448 10.4323C5.62593 10.6008 5.53032 10.8288 5.52825 11.0672C5.52618 11.3056 5.61781 11.5352 5.78341 11.7066L8.51068 14.4339C8.60001 14.5232 8.70695 14.5929 8.8247 14.6385C8.94244 14.6842 9.06841 14.7048 9.19456 14.6991C9.32072 14.6933 9.4443 14.6614 9.55742 14.6052C9.67054 14.5491 9.77073 14.47 9.85159 14.373L14.397 8.91847Z"
                      fill="#29CC6A"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_632_31299">
                      <rect width="20" height="20" fill="white" transform="translate(0.0625 0.609375)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            ) : (
              <div className={'flex flex-row items-center justify-center'}>
                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_632_31343)">
                    <path
                      d="M10.0625 0.609375C4.5325 0.609375 0.0625 5.07937 0.0625 10.6094C0.0625 16.1394 4.5325 20.6094 10.0625 20.6094C15.5925 20.6094 20.0625 16.1394 20.0625 10.6094C20.0625 5.07937 15.5925 0.609375 10.0625 0.609375ZM14.3625 14.9094C14.27 15.0021 14.1601 15.0756 14.0391 15.1258C13.9182 15.176 13.7885 15.2018 13.6575 15.2018C13.5265 15.2018 13.3968 15.176 13.2759 15.1258C13.1549 15.0756 13.045 15.0021 12.9525 14.9094L10.0625 12.0194L7.1725 14.9094C6.98552 15.0964 6.73193 15.2014 6.4675 15.2014C6.20307 15.2014 5.94948 15.0964 5.7625 14.9094C5.57552 14.7224 5.47048 14.4688 5.47048 14.2044C5.47048 14.0734 5.49627 13.9438 5.54637 13.8228C5.59648 13.7019 5.66992 13.592 5.7625 13.4994L8.6525 10.6094L5.7625 7.71937C5.57552 7.5324 5.47048 7.2788 5.47048 7.01437C5.47048 6.74995 5.57552 6.49635 5.7625 6.30937C5.94948 6.1224 6.20307 6.01735 6.4675 6.01735C6.73193 6.01735 6.98552 6.1224 7.1725 6.30937L10.0625 9.19938L12.9525 6.30937C13.0451 6.21679 13.155 6.14335 13.276 6.09325C13.3969 6.04314 13.5266 6.01735 13.6575 6.01735C13.7884 6.01735 13.9181 6.04314 14.039 6.09325C14.16 6.14335 14.2699 6.21679 14.3625 6.30937C14.4551 6.40196 14.5285 6.51187 14.5786 6.63283C14.6287 6.7538 14.6545 6.88344 14.6545 7.01437C14.6545 7.14531 14.6287 7.27495 14.5786 7.39592C14.5285 7.51688 14.4551 7.62679 14.3625 7.71937L11.4725 10.6094L14.3625 13.4994C14.7425 13.8794 14.7425 14.5194 14.3625 14.9094Z"
                      fill="#FC5555"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_632_31343">
                      <rect width="20" height="20" fill="white" transform="translate(0.0625 0.609375)" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            )}
          </td>
          <td className="px-2 py-4 text-center text-sm text-[#404040]">{getAmount(row)}</td>
          <td className="rounded-tr-[15px] px-2 py-4 text-center text-sm text-[#404040]">
            {row.status === 1 ? (row.shareValue || 0).toLocaleString('vi') : 0}
          </td>
        </tr>
      );
    },
    [navigate, currentPage]
  );
  return <TableV2 labelHeaders={labelHeader} dataRows={dataRows} renderRow={renderRow} />;
};
const labelHeader = [
  {
    label: 'STT',
    className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase w-[65px]',
  },
  {
    label: 'Thời gian',
    className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase',
  },
  {
    label: 'Mã giao dịch',
    className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase',
  },
  {
    label: 'Mạng',
    className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase',
  },
  {
    label: 'Danh mục',
    className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase',
  },
  {
    label: 'Sản phẩm',
    className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase',
  },
  {
    label: 'Khách hàng',
    className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase',
  },
  {
    label: 'Loại hoa hồng',
    className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase',
  },
  {
    label: 'Trạng thái',
    className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase',
  },
  {
    label: 'Giá trị (VNĐ)',
    className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase',
  },
  {
    label: 'Hoa hồng (VNĐ)',
    className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase',
  },
];

const getCustomer = (item: any) => {
  switch (item.itemType) {
    case 'SIMSO':
      return item.msisdnContact;
    case 'GOICUOC':
      return item.msisdnContact;
    default:
      return '-';
  }
};

const getAmount = (item: any) => {
  switch (item.itemType) {
    case 'SIMSO':
      return (item.amount || 0).toLocaleString('vi');
    case 'GOICUOC':
      return (item.amount || 0).toLocaleString('vi');
    default:
      return '-';
  }
};
