import { ButtonCustom } from '../common/ButtonCustom';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { PATH_PAGE, TIME_COUNTDOWN } from '../../constant';
import { minTwoDigits } from '../../utils/utils';
// import { SuccessFormIcon } from 'assets/images/form';
import useCheckDeviceMobi from '../../hooks/useCheckDeviceMobi';
import { BannerRegisterSuccess, QRZalo } from '../../assets/images';
import { RegisterContext } from 'pages/resigter';

export const StepSuccess = () => {
  const navigate = useNavigate();
  const { phoneNumber } = useContext(RegisterContext);
  const [countdown, setCountdown] = useState<number>(TIME_COUNTDOWN);
  const isMobile = useCheckDeviceMobi();

  useEffect(() => {
    if (!countdown) {
      // navigate(PATH_PAGE.LOGIN);
      return;
    }
    const intervalId = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [countdown, setCountdown]);

  return (
    <>
      {!isMobile ? (
          // <div className={'grid grid-cols-1 justify-center items-center gap-42 sm:grid-cols-2'}>
          //   <div className={'flex flex-col items-center space-y-6'}>
          //     <img src={BannerRegisterSuccess} className={'w-3/4 object-cover'}  alt={'success-icon'} />
          //     <h1 className={'text-[28px] font-extrabold leading-[32px] text-white'}>Đăng ký tài khoản thành công!</h1>
          //     <ButtonCustom
          //         label={'Đăng nhập'}
          //         className={'!mt-6 w-[150px]'}
          //         type={'button'}
          //         background={'bg-gradient-to-r from-[#FFB01F] to-[#FFCF45]'}
          //         onClick={() => navigate(PATH_PAGE.LOGIN)}
          //     />
          //   </div>
          //   <div className={'flex h-full flex-col items-center space-y-8'}>
          //     <img src={QRZalo} className={'w-1/2 mt-4 object-cover'}  alt={'success-icon'} />
          //     <span className={'text-[16px] mt-4 font-extrabold text-white'}>
          //       Tham gia Group CTV Shop360 để nhận tài liệu kinh doanh hoàn toàn miễn phí
          //     </span>
          //     <a href="https://zalo.me/g/czjvyl860" target="_blank" rel="noreferrer">
          //       <ButtonCustom
          //           label={'THAM GIA NGAY'}
          //           className={'-mt-2 w-[150px]'}
          //           type={'button'}
          //           background={'bg-gradient-to-r from-[#1f62ff] to-[#0096ff]'}
          //       />
          //     </a>
          //   </div>
          // </div>
        <div className={'flex flex-col items-center gap-12 mt-8'}>
          <div className={'flex flex-col items-center space-y-4'}>
            <img src={BannerRegisterSuccess} className={'w-7/8 object-cover'}  alt={'success-icon'} />
            <h1 className={'text-[28px] font-extrabold leading-[32px] text-white'}>Đăng ký tài khoản thành công!</h1>
            <ButtonCustom
                label={'ĐĂNG NHẬP NGAY'}
                className={'w-[200px]'}
                type={'button'}
                background={'bg-gradient-to-r from-[#FFB01F] to-[#FFCF45]'}
                onClick={() => navigate(PATH_PAGE.LOGIN)}
            />
          </div>
          <div className={'flex h-full items-center gap-4 px-8 py-4 rounded-2xl bg-[#404040]'}>
            <span className={'text-[16px] font-extrabold text-white'}>
              Tham gia Group CTV Shop360 để nhận <br /> tài liệu kinh doanh hoàn toàn miễn phí
            </span>
            <a href="https://zalo.me/g/czjvyl860" target="_blank" rel="noreferrer">
              <ButtonCustom
                  label={'THAM GIA NGAY'}
                  className={'w-[200px]'}
                  type={'button'}
                  background={'bg-gradient-to-r from-[#F15836] to-[#FF924F]'}
              />
            </a>
            <img src={QRZalo} className={'h-[85%] w-auto object-cover'}  alt={'success-icon'} />
          </div>
        </div>
      ) : (
        <div className={'flex w-full flex-col items-center justify-center p-2'}>
          <h1 className={'mb-4 text-[22px] font-extrabold leading-[26px] text-[#404040]'}>
            Đăng ký tài khoản thành công!
          </h1>
          <img src={BannerRegisterSuccess} alt={'success'} />
          <h2 className={'mt-4 text-center text-[14px] font-medium leading-[20px] text-[#404040]'}>
            Bạn đã thiết lập mật khẩu mới thành công cho tài khoản 0{phoneNumber}. Hệ thống sẽ tự động điều hướng về
            trang đăng nhập sau
            {countdown ? (
              <>
                (<span className="font-medium text-[#F15836]">{minTwoDigits(countdown)}s</span>)
              </>
            ) : (
              ''
            )}
          </h2>
          <ButtonCustom
            label={'Đăng nhập'}
            className={'mt-[25px] w-[150px]'}
            type={'button'}
            background={'bg-gradient-to-r from-[#FFB01F] to-[#FFCF45]'}
            onClick={() => navigate(PATH_PAGE.LOGIN)}
          />
          <div className={'flex flex-col h-full items-center gap-4 px-8 py-4 mt-8 rounded-2xl bg-[#404040]'}>
            <span className={'text-[16px] text-justify font-extrabold text-white'}>
              Tham gia Group CTV Shop360 để nhận tài liệu kinh doanh hoàn toàn miễn phí
            </span>
            <div className="flex items-center gap-4">
              <a href="https://zalo.me/g/czjvyl860" target="_blank" rel="noreferrer">
                <ButtonCustom
                    label={'THAM GIA NGAY'}
                    className={'w-[200px]'}
                    type={'button'}
                    background={'bg-gradient-to-r from-[#F15836] to-[#FF924F]'}
                />
              </a>
              <img src={QRZalo} className={'h-[85%] w-auto object-cover'}  alt={'success-icon'} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
