import { AccountAuthen } from 'components/common/AccountAuthen';
import { TableV2 } from 'components/common/TableV2';
import configs from 'configs/config';
import AuthContext from 'context/AuthProvider';
import { useCallback, useContext, useState } from 'react';
import toast from 'react-hot-toast';
import { getLabelItemAgent, getLabelItemType } from 'utils/utils';

export const TableBusiness = ({ record }: any) => {
  const { userLogin } = useContext(AuthContext);
  const [isShowNotification, setIsShowNotification] = useState<boolean>(false);

  const renderRow = useCallback((row: any, index: number) => {
    return (
      <tr key={row.id} className=" bg-white">
        <td className="p-4 text-center  text-sm font-bold text-[#404040]">{row?.itemName}</td>
        <td className="p-4 text-center text-sm font-normal text-[#404040]">{getLabelItemType(row.itemType)}</td>
        <td className="p-4 text-center text-sm font-normal text-[#404040]">
          {getLabelItemAgent(row?.agent) || row?.agentStr}
        </td>
        <td className="p-4 text-center  text-sm font-normal text-[#404040]">
          {row.amount ? row.amount.toLocaleString('vi') : 0}
        </td>
        <td className="p-4 text-center  text-sm font-normal text-[#404040]">
          {(row.shareValue || 0).toLocaleString('vi')}
        </td>
        {/* <td className="text-center text-sm font-medium text-[#404040]">
          <div className={'flex h-full w-full flex-col items-center'}>
            <button
              className={
                'bg-g h-[30px] w-[70px] whitespace-nowrap rounded-l-[10px] rounded-tr-[10px] border-[2px] border-[#F15836] !bg-white px-2 !text-sm !font-bold !text-[#F15836]'
              }
              onClick={() => {
                if (userLogin?.statusVerify !== 1) {
                  setIsShowNotification(true);
                  return;
                }
                if (row?.agent === 3) {
                  navigator?.clipboard?.writeText(
                    configs.API_DOMAIN +
                      `/product/goi-cuoc-uu-dai/${encodeURIComponent(row.itemName)}?packType=vinaphone`
                  );
                } else {
                  navigator?.clipboard?.writeText(row.link);
                }
                // navigator?.clipboard?.writeText(row.link);
                toast.success('Sao chép link thành công!');
              }}
            >
              {'Lấy link'}
            </button>
          </div>
        </td> */}
      </tr>
    );
  }, []);

  return (
    <>
      <TableV2
        labelHeaders={labelHeader}
        dataRows={record || []}
        renderRow={renderRow}
        className={'!border-spacing-y-[1px]'}
        classNameHeader={'first:!rounded-bl-[0px]'}
      />
      {isShowNotification && <AccountAuthen setIsShowNotification={setIsShowNotification} />}
    </>
  );
};

const labelHeader = [
  {
    className: 'w-[155px]',
    label: 'SẢN PHẨM',
  },
  {
    className: 'w-[150px]',
    label: 'PHÂN LOẠI',
  },
  {
    className: 'w-[150px]',
    label: 'NHÀ MẠNG',
  },
  {
    className: 'w-[154px]',
    label: 'GIÁ BÁN(VNĐ)',
  },
  {
    className: 'w-[154px]',
    label: 'HOA HỒNG(VNĐ)',
  },
  // {
  //   className: 'w-[155px]',
  //   label: 'THAO TÁC',
  // },
];
