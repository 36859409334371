import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { CarouselCard } from './CarouselCard';
import { useQuery } from 'react-query';
import { PATH_PAGE } from '../../../constant';
import { getNewsList } from '../../../services/common';

export const Carousel = () => {
  const [indexDataCarousel, setIndexDataCarousel] = useState(0);
  const [currentNews, setCurrentNews] = useState<any>([]);

  useEffect(() => {
    const idInter = setInterval(() => {
      // console.log('a', indexDataCarousel)
      if (indexDataCarousel >= 3) {
        setIndexDataCarousel(0);
      } else setIndexDataCarousel((prev: any) => ++prev);
    }, 3500);
    return () => clearInterval(idInter);
  }, [indexDataCarousel]);

  const { data: response } = useQuery([PATH_PAGE.NEWS], () =>
    getNewsList({
      homenum: 1,
      page: 1,
      sizeOfPage: 16,
      title: '',
    })
  );

  const dataNews = useMemo(() => {
    return response?.data?.data?.items || [];
  }, [response]);

  useLayoutEffect(() => {
    const newList = [...dataNews];
    setCurrentNews(newList.slice(indexDataCarousel * 4, (indexDataCarousel + 1) * 4) || []);
  }, [indexDataCarousel, dataNews]);

  return (
    <div className={'mx-auto h-fit max-w-[61rem]'}>
      <div className={'mx-auto mb-2 grid h-fit w-full grid-cols-1 gap-2 px-4 md:grid-cols-4 md:gap-4 md:px-0'}>
        {currentNews.map((item: any, index: number) => (
          <CarouselCard item={item} key={index} />
        ))}
      </div>
      <div className={'mx-auto hidden w-full flex-row justify-center space-x-2 pt-14 md:flex'}>
        {Array.from({ length: 4 }).map((item, index) => {
          if (index === indexDataCarousel) {
            return (
              <svg
                key={index}
                className={'cursor-pointer hover:scale-[1.1]'}
                width="38"
                height="16"
                viewBox="0 0 38 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="0.828125" y="0.163574" width="36.3507" height="15" rx="7.5" fill="#F15836" />
              </svg>
            );
          }
          return (
            <svg
              key={index}
              className={'cursor-pointer hover:scale-[1.1]'}
              onClick={() => setIndexDataCarousel(index)}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="7.67969" cy="7.66357" r="7.5" fill="#D9D9D9" />
            </svg>
          );
        })}
      </div>
    </div>
  );
};
