import ScrollToTop from 'components/common/ScrollToTop';
import { FooterMobi } from 'components/footer/FooterMobi';
import { HeaderCMSCommon } from 'components/header/HeaderCMSCommon';
import useCheckDeviceMobi from 'hooks/useCheckDeviceMobi';
import { classNames } from 'utils/utils';

export const DeliveryPolicy = () => {
  const isMobile = useCheckDeviceMobi();

  return (
    <>
      <ScrollToTop />
      <HeaderCMSCommon />

      <div className={classNames('w-full bg-[#F8F8F8] min-h-[600px]', isMobile ? 'pt-20' : 'pt-24')}>
        <div className={'mx-auto w-full max-w-[85rem] px-4 min-h-[350px]'}>
          <div
            className={classNames(
              'mx-auto w-full max-w-[62rem] rounded-[4px] bg-white shadow-[0_0_20px_0_rgba(0,0,0,0.05)]',
              isMobile ? 'p-4' : 'p-8'
            )}
          >
            <h1 className={'text-center text-[32px] font-semibold leading-[40px] text-[#F15836]'}>
              CHÍNH SÁCH VẬN CHUYỂN
            </h1>

            <div className={'mt-6'}>
              <section className="[&>*]:text-justify [&>*]:leading-[25px]">
                <p className={'break-words text-left text-sm font-medium text-baseColor mt-6'}>
                  <strong>
                    Để đảm bảo quyền lợi tốt nhất khi mua hàng, Cộng Tác Viên vui lòng đọc chính sách vận chuyển và giao
                    nhận khi khởi tạo đơn hàng:
                  </strong>
                </p>

                <p className={'break-words text-left text-sm font-medium text-baseColor mt-6'}>
                  Khi khởi tạo đơn hàng thành công, Cộng tác viên có quyền lựa chọn trên website Shop360.vn về phương
                  thức nhận hàng. Phương thức nhận hàng sẽ do Nhân viên bán hàng của MobiFone giao hoặc do Cộng tác viên
                  tự mình giao. Shop360 chỉ ghi nhận thông tin hình thức giao để chuyển cho Nhân viên bán hàng và không
                  thu bất kỳ chi phí vận chuyển nào.
                </p>
              </section>
            </div>
          </div>
        </div>
      </div>
      <FooterMobi />
    </>
  );
};
