import { PackageIcon, SimIcon, VietlottIcon } from 'assets/images/category';

export const getIconCategory = (key: string, className?: string) => {
  switch (key) {
    case 'SIMSO':
      return <img className="h-[58px] w-[58px]" src={SimIcon} alt="sim-icon" />;
    case 'GOICUOC':
      return <img className="h-[58px] w-[58px]" src={PackageIcon} alt="package-icon" />;
    case 'Vietlott':
      return <img className="h-[58px] w-[58px]" src={VietlottIcon} alt="vietlott-icon" />;
    default:
      return <></>;
  }
};
