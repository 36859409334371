import { useMemo, useState } from 'react';
import { classNames } from '../../../utils/utils';
import { Collaborators } from './Collaborators';
import { Transaction } from './transaction';

const TabInfo = {
  List: 0,
  Transaction: 1,
};

export const StatisticReportsCollaborators = () => {
  const [tab, setTab] = useState<number>(TabInfo.List);

  const renderBody = useMemo(() => {
    if (tab === TabInfo.List) {
      return <Collaborators />;
    } else if (tab === TabInfo.Transaction) {
      return <Transaction />;
    }
    return <></>;
  }, [tab]);

  return (
    <div className={classNames('h-fit w-full bg-gray-50 p-6')}>
      <div className={'mb-5 flex flex-row items-center'}>
        <h2 className={'text-[22px] font-semibold uppercase text-gray-700'}>BÁO CÁO CỘNG TÁC VIÊN CẤP DƯỚI</h2>
      </div>
      <ul className="flex flex-wrap space-x-2 text-center text-base font-bold text-white">
        <li className={'cursor-pointer'} onClick={() => setTab(TabInfo.List)}>
          <span
            className={classNames(
              tab === TabInfo.List ? '!bg-gray-600' : 'text-gray-500',
              'inline-block rounded-t-[10px] bg-gray-300 px-4 py-2 text-white hover:bg-zinc-600 hover:text-white'
            )}
          >
            Danh sách CTV cấp dưới
          </span>
        </li>
        <li className={'cursor-pointer'} onClick={() => setTab(TabInfo.Transaction)}>
          <span
            className={classNames(
              tab === TabInfo.Transaction ? '!bg-gray-600' : 'text-gray-500',
              'inline-block rounded-t-[10px] bg-gray-300 px-4 py-2 text-white hover:bg-zinc-600 hover:text-white'
            )}
          >
            Giao dịch từ CTV cấp dưới
          </span>
        </li>
      </ul>
      {renderBody}
    </div>
  );
};
