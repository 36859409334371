import {TableCustom} from 'components/common/TableCustom';
import {useCallback} from 'react';

export const TableTransaction = ({dataRows}: any) => {
	const renderRow = useCallback((row: any) => {
		return (
			<tr className="border-b bg-white dark:border-gray-700 dark:bg-gray-800">
				<th scope="row" className="px-6 py-4 text-center">
					{row.stt}
				</th>
				<td className="px-2 py-4 text-center">{row.time}</td>
				<td className="px-2 py-4 text-center">{row.code}</td>
				<td className="px-2 py-4 text-center">{row.collaborator}</td>
				<td className="px-2 py-4 text-center">{row.category}</td>
				<td className="px-2 py-4 text-center">{row.product}</td>
				<td className="px-2 py-4 text-center">{row.customer}</td>
				<td className="px-2 py-4 text-center">{row.type}</td>
				<td className="px-2 py-4 text-center">
					{row.status ? (
						<div className={'flex flex-row items-center justify-center'}>
							<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M8 12C6.93913 12 5.92172 11.5786 5.17157 10.8284C4.42143 10.0783 4 9.06087 4 8C4 6.93913 4.42143 5.92172 5.17157 5.17157C5.92172 4.42143 6.93913 4 8 4C9.06087 4 10.0783 4.42143 10.8284 5.17157C11.5786 5.92172 12 6.93913 12 8C12 9.06087 11.5786 10.0783 10.8284 10.8284C10.0783 11.5786 9.06087 12 8 12Z"
									fill="#8EC165"
								/>
							</svg>
							<span>Đủ điều kiện</span>
						</div>
					) : (
						<div className={'flex flex-row items-center justify-center'}>
							<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path
									d="M8.5 12C7.43913 12 6.42172 11.5786 5.67157 10.8284C4.92143 10.0783 4.5 9.06087 4.5 8C4.5 6.93913 4.92143 5.92172 5.67157 5.17157C6.42172 4.42143 7.43913 4 8.5 4C9.56087 4 10.5783 4.42143 11.3284 5.17157C12.0786 5.92172 12.5 6.93913 12.5 8C12.5 9.06087 12.0786 10.0783 11.3284 10.8284C10.5783 11.5786 9.56087 12 8.5 12Z"
									fill="#A1A1AA"
								/>
							</svg>
							<span>Chưa đủ điều kiện</span>
						</div>
					)}
				</td>
				<td className="px-2 py-4 text-center">{row.value}</td>
				<td className="px-2 py-4 text-center">{row.commission}</td>
				<td className="px-2 py-4 text-center">
					<button>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								d="M14.1213 14.1213C14.6839 13.5587 15 12.7956 15 12C15 11.2044 14.6839 10.4413 14.1213 9.87868C13.5587 9.31607 12.7956 9 12 9C11.2044 9 10.4413 9.31607 9.87868 9.87868C9.31607 10.4413 9 11.2044 9 12C9 12.7956 9.31607 13.5587 9.87868 14.1213C10.4413 14.6839 11.2044 15 12 15C12.7956 15 13.5587 14.6839 14.1213 14.1213Z"
								stroke="#6B7280"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
							<path
								d="M2.45801 12C3.73201 7.943 7.52301 5 12 5C16.478 5 20.268 7.943 21.542 12C20.268 16.057 16.478 19 12 19C7.52301 19 3.73201 16.057 2.45801 12Z"
								stroke="#6B7280"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</button>
				</td>
			</tr>
		);
	}, []);
	return <TableCustom labelHeaders={labelHeader} dataRows={dataRows} renderRow={renderRow}/>;
};
const labelHeader = [
	{
		label: 'STT',
		className: 'text-gray-600 font-semibold text-[15px] w-[65px]',
	},
	{
		label: 'Thời gian',
		className: 'text-gray-600 font-semibold text-[15px]',
	},
	{
		label: 'Mã giao dịch',
		className: 'text-gray-600 font-semibold text-[15px]',
	},
	{
		label: 'Cộng tác viên',
		className: 'text-gray-600 font-semibold text-[15px]',
	},
	{
		label: 'Danh mục',
		className: 'text-gray-600 font-semibold text-[15px]',
	},
	{
		label: 'Sản phẩm',
		className: 'text-gray-600 font-semibold text-[15px]',
	},
	{
		label: 'Khách hàng',
		className: 'text-gray-600 font-semibold text-[15px]',
	},
	{
		label: 'Loại hoa hồng',
		className: 'text-gray-600 font-semibold text-[15px]',
	},
	{
		label: 'Trạng thái',
		className: 'text-gray-600 font-semibold text-[15px]',
	},
	{
		label: 'Giá trị (VNĐ)',
		className: 'text-gray-600 font-semibold text-[15px]',
	},
	{
		label: 'Hoa hồng (VNĐ)',
		className: 'text-gray-600 font-semibold text-[15px]',
	},
	{
		label: 'Thao tác',
		className: 'text-gray-600 font-semibold text-[15px] w-[272px]',
	},
];
