import {
  IconBill,
  IconCarbon,
  IconComment,
  IconHome,
  IconOrder,
  IconPark,
  IconRose,
} from 'assets/images/sidebar/index';

export const TIME_COUNTDOWN: number = 5;
export const TIME_COUNTDOWN_RESEND: number = 60;
export const TIME_COUNTDOWN_VERIFY: number = 120;
export const TIME_COUNTDOWN_VERIFY_VINAPHONE: number = 300;
export const PER_PAGE_DEFAULT: number = 15;
export const PAGINATION_OPTION = [5, 15, 25];

export const QUERY_KEY = {
  USER_INFO: 'USER_INFO',
  DASHBOARD: 'DASHBOARD',
  BANK_LIST: 'BANK_LIST',
  SERVICE_PLAN_LIST: 'SERVICE_PLAN_LIST',
  PAYMENT_HISTORY: 'PAYMENT_HISTORY',
  TRANSACTION_LIST: 'TRANSACTION_LIST',
  EXPORT_EXCEL_TRANSACTION_LIST: 'EXPORT_EXCEL_TRANSACTION_LIST',
  TRANS_INFO: 'TRANS_INFO',
  TRANS_PAYMENT_INFO: 'TRANS_PAYMENT_INFO',
  REQ_PAYMENT: 'REQ_PAYMENT',
  PACKAGE_DETAIL: 'PACKAGE_DETAIL',
  SIM_DETAIL: 'SIM_DETAIL',
  GET_PLACE: 'GET_PLACE',
  GET_COMMON_OPTION: 'GET_COMMON_OPTION',
  SIM_LIST: 'SIM_LIST',
  PACKAGE_LIST: 'PACKAGE_LIST',
  SIM_SUGGEST: 'SIM_SUGGEST'
};

export const PATH_PAGE = {
  LOGIN: '/login',
  REGISTER: '/register',
  DASHBOARD: '/dashboard',
  PRODUCTS: '/products',
  PRODUCTS_CATEGORY: '/products/category',
  PRODUCTS_SIM: '/products/sim',
  PRODUCTS_SERVICE_PLAN: '/products/service-plan',
  STATISTIC: '/statistic',
  STATISTIC_TRANSACTION: '/statistic/transaction',
  STATISTIC_REPORTS_COLLABORATORS: '/statistic/reports-collaborators',
  ACCOUNT_COLLABORATOR: '/account',
  PROFILE: '/profile',
  CONFIG_PAYMENT: '/config-payment',
  CHANGE_PASSWORD: '/change-password',
  ORDERS: '/orders',
  NOTIFICATIONS: '/notifications',
  PAYMENT: '/payment',
  SUPPORT: '/support',
  CREATE_PAYMENT: '/create-payment',
  NEWS: '/news',
};

export const ROUTER_PATH = [
  {
    id: 1,
    name: 'Chính',
    children: [
      {
        id: 1,
        name: 'Dashboard',
        path: PATH_PAGE.DASHBOARD,
        icon: IconHome,
      },
      {
        id: 2,
        name: 'Tài khoản',
        path: PATH_PAGE.ACCOUNT_COLLABORATOR,
        icon: IconBill,
      },
      {
        id: 3,
        name: 'Hỗ trợ',
        path: PATH_PAGE.SUPPORT,
        icon: IconComment,
      },
    ],
  },
  {
    id: 2,
    name: 'Sản phẩm',
    children: [
      // {
      //   id: 1,
      //   name: 'Danh mục',
      //   path: `${PATH_PAGE.PRODUCTS}/category`,
      //   icon: IconCarbon,
      // },
      // {
      //   id: 2,
      //   name: 'Sim số',
      //   path: `${PATH_PAGE.PRODUCTS}/sim`,
      //   icon: IconPark,
      // },
      {
        id: 3,
        name: 'Gói cước',
        path: `${PATH_PAGE.PRODUCTS}/service-plan`,
        icon: IconRose,
      },
      // {
      //   id: 3,
      //   name: 'Báo cáo thống kê',
      //   path: PATH_PAGE.STATISTIC,
      //   icon: IconPark,
      //   children: [
      //     {
      //       id: 1,
      //       name: 'Danh sách đơn hàng',
      //       path: PATH_PAGE.STATISTIC_TRANSACTION,
      //     },
      //     {
      //       id: 2,
      //       name: 'Báo cáo Cộng tác viên cấp dưới',
      //       path: PATH_PAGE.STATISTIC_REPORTS_COLLABORATORS,
      //     },
      //   ],
      // },
    ],
  },
  {
    id: 3,
    name: 'Báo cáo thống kê',
    children: [
      {
        id: 1,
        name: 'Danh sách giao dịch',
        icon: IconOrder,
        path: PATH_PAGE.STATISTIC_TRANSACTION,
      },
    ],
  },
];

export const STATUS_TRANSFER = [
  {
    value: 0,
    label: 'Đăng ký gói thất bại',
  },
  {
    value: 1,
    label: 'Lấy OTP thất bại',
  },
  {
    value: 2,
    label: 'Đã gửi mã OTP',
  },
  {
    value: 3,
    label: 'Đăng ký gói thành công',
  },
  {
    value: 4,
    label: 'Giữ số thành công',
  },
  {
    value: 5,
    label: 'Đang xử lý',
  },
  {
    value: 6,
    label: 'Hoà mạng số thành công',
  },
  {
    value: 7,
    label: 'Hết hiệu lực giữ số',
  },
  {
    value: 9,
    label: 'Chờ thanh toán',
  },
  {
    value: 10,
    label: 'Chờ xác nhận',
  },
  {
    value: 11,
    label: 'Đợi hòa mạng',
  },
  {
    value: 12,
    label: 'Hủy đơn',
  },
  {
    value: 13,
    label: 'Đang giao hàng',
  },
  {
    value: 14,
    label: 'Đợi lấy hàng',
  },
  {
    value: 15,
    label: 'Đang hòa mạng',
  },
  {
    value: 17,
    label: 'Đợi hòa mạng lại',
  },
  {
    value: 18,
    label: 'Hủy do hết hạn thanh toán',
  },
  {
    value: 20,
    label: 'Hủy do bạn',
  },
  {
    value: 21,
    label: 'Hủy do không liên hệ được với bạn',
  },
  {
    value: 22,
    label: 'Hủy do ghtk không giao được đến bạn',
  },
  {
    value: 23,
    label: 'Hủy đơn xác nhận từ bạn',
  },
  {
    value: 24,
    label: 'Hủy vì khách hàng yêu cầu',
  },
  {
    value: 25,
    label: 'Hủy vì đơn hàng quá hạn',
  },
  {
    value: 26,
    label: 'Hủy vì lí do khác',
  },
];

export const PACK_TYPE_CHOICE = [
  {
    value: 1,
    name: 'Trả trước',
  },
  {
    value: 2,
    name: 'Trả sau',
  },
  {
    value: 3,
    name: 'Data',
  },
  {
    value: 4,
    name: 'Thoại & SMS',
  },
  {
    value: 5,
    name: 'Gói Hot',
  },
];

export const TYPE_NUMBERS = [
  {
    value: 1,
    label: 'Số thường',
  },
  {
    value: 2,
    label: 'Số đẹp',
  },
  {
    value: 3,
    label: 'Số đẹp cam kết 150,000 VNĐ',
  },
  {
    value: 4,
    label: 'Số đẹp cam kết 250,000 VNĐ',
  },
  {
    value: 5,
    label: 'Số đẹp cam kết 350,000 VNĐ',
  },
];

export const NUM_EXPIRED_CHOICE = [
  {
    value: 1,
    name: 'Dưới 7 ngày',
  },
  {
    value: 2,
    name: 'Từ 7 đến 30 ngày',
  },
  {
    value: 3,
    name: 'Từ 30 đến 90 ngày',
  },
  {
    value: 4,
    name: 'Từ 90 đến 180 ngày',
  },
  {
    value: 5,
    name: 'Từ 180 đến 270 ngày',
  },
  {
    value: 6,
    name: 'Từ 270 đến 360 ngày',
  },
  {
    value: 7,
    name: 'Trên 360 ngày',
  },
];

export const VNP_PACKTYPE_TYPE = [
  { value: 1, label: 'Trả trước' },
  { value: 2, label: 'Trả sau' },
  { value: 3, label: 'Data' },
];

export const PRE_NUMBERS = ['093', '090', '089', '070', '076', '077', '078', '079'];
export const PRE_NUMBERS_VINA = ['088', '094', '091', '085', '084', '083', '082', '081'];

export const SUPPLIER = {
  MOBIFONE: 0,
  VINAPHONE: 3,
  SAYMEE: 4,
};

export const SUPPLIER_OPTIONS = [
  { value: SUPPLIER.MOBIFONE, name: 'MobiFone' },
  { value: SUPPLIER.VINAPHONE, name: 'Vinaphone' },
  { value: SUPPLIER.SAYMEE, name: 'Saymee' },
];

export const MSIDN_TYPE = [
  { value: '0', name: 'Trả trước' },
  { value: '1', name: 'Trả sau' },
];

export const TRANS_STATUS = [
  { value: '0', name: 'Thất bại' },
  { value: '1', name: 'Thành công' },
];

export const MAX_AMOUNT = 3000000;

export const PACKAGE_PRICE = [
  { value: '1', name: 'Dưới 50.000 VNĐ' },
  { value: '2', name: 'Từ 50.000 VNĐ đến 100.000 VNĐ' },
  { value: '3', name: 'Từ 100.000 VNĐ đến 200.000 VNĐ' },
  { value: '4', name: 'Từ 200.000 VNĐ đến 500.000 VNĐ' },
  { value: '5', name: 'Từ 500.000 VNĐ đến 1.000.000 VNĐ' },
  { value: '6', name: 'Trên 1.000.000 VNĐ' },
];


export const TRANS_TYPE = {
  HHKD_GOICUOC: 1,
  HHKD_THUEBAO: 2,
  RUT_TIEN: 3,
  SU_KIEN: 4,
  DIEU_CHINH: 5,
  DUY_TRI: 7,
  EDUCA: 10,
  EDUCA_GIAHAN: 11,
  HH_CAPDUOI_GOICUOC: 85,
  HH_CAPDUOI_THUEBAO: 86,
  HH_CAPDUOI_EDUCA: 87
}