import useCheckDeviceMobi from 'hooks/useCheckDeviceMobi';
import { classNames } from 'utils/utils';

export const CardIntroduce = ({ label, description, icon }: any) => {
  const isMobile = useCheckDeviceMobi();
  return (
    <div
      className={classNames(
        'flex flex-col items-center justify-center text-center md:h-[260px] md:w-[316px] md:space-y-4 md:rounded-t-[30px]' +
          'h-[130px] w-[120px] space-y-2 bg-white py-4 px-3 shadow-base md:rounded-bl-[30px] md:px-6 md:py-8' +
          ' transform rounded-t-[12px] rounded-bl-[12px] backdrop-blur-sm transition duration-500 ',
        !isMobile && 'hover:scale-110'
      )}
    >
      {icon}
      <p className={'text-center text-sm font-extrabold text-[#404040] md:text-[20px] md:leading-[28px]'}>{label}</p>
      <p className={'mt-2 hidden text-center text-base font-normal text-[#818181] md:contents'}>{description}</p>
    </div>
  );
};
