import { useContext } from 'react';
import { RegisterContext } from '../../pages/resigter';
import { classNames } from '../../utils/utils';
import useCheckDeviceMobi from '../../hooks/useCheckDeviceMobi';

export const Stepper = () => {
  const { activeStepIndex } = useContext(RegisterContext);
  const isMobile = useCheckDeviceMobi();
  return (
    <div className={classNames('flex w-full flex-row items-center justify-start', !isMobile && 'mb-3')}>
      <div className={classNames('h-fit w-fit pb-[4px]')}>
        <div
          className={classNames(
            'flex flex-row items-end space-x-1 pr-4 pb-2',
            activeStepIndex === 0 ? '' : 'opacity-40'
          )}
        >
          <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8.3125" cy="12.2241" r="7.5" fill={isMobile ? '#F15836' : 'white'} />
            <path
              d="M10.3655 13.9241V14.7241H6.77547V13.9241H8.19547V10.0241C8.13547 10.1041 8.04214 10.1908 7.91547 10.2841C7.7888 10.3708 7.64547 10.4541 7.48547 10.5341C7.32547 10.6141 7.16547 10.6808 7.00547 10.7341C6.84547 10.7808 6.6988 10.8041 6.56547 10.8041V9.96412C6.71214 9.96412 6.87214 9.92412 7.04547 9.84412C7.22547 9.75745 7.3988 9.65745 7.56547 9.54412C7.73214 9.43079 7.87214 9.32412 7.98547 9.22412C8.10547 9.12412 8.17547 9.05412 8.19547 9.01412H9.09547V13.9241H10.3655Z"
              fill={isMobile ? 'white' : '#F15836'}
            />
          </svg>
          <span className={classNames('text-sm font-medium', isMobile ? 'text-[#404040]' : 'text-white')}>
            Xác minh số điện thoại
          </span>
        </div>
        <div
          className={classNames(
            isMobile ? 'bg-gradient-to-r from-[#F15836] to-[#FF924F]' : 'bg-white',
            'h-[4px] w-full',
            activeStepIndex === 0 ? '' : 'opacity-40'
          )}
        ></div>
      </div>
      <div className={classNames('h-fit w-fit pb-[4px]')}>
        <div
          className={classNames(
            'flex flex-row items-end space-x-1 pr-4 pb-2',
            activeStepIndex === 1 ? '' : 'opacity-40'
          )}
        >
          <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8.3125" cy="12.2241" r="7.5" fill={isMobile ? '#F15836' : 'white'} />
            <path
              d="M6.09797 14.7241C6.09797 14.4575 6.11797 14.2075 6.15797 13.9741C6.20464 13.7408 6.28464 13.5175 6.39797 13.3041C6.51797 13.0908 6.68464 12.8875 6.89797 12.6941C7.1113 12.4941 7.38797 12.3008 7.72797 12.1141C7.92797 12.0008 8.12797 11.8941 8.32797 11.7941C8.53464 11.6941 8.72464 11.5875 8.89797 11.4741C9.07797 11.3608 9.2213 11.2375 9.32797 11.1041C9.43464 10.9641 9.48797 10.8041 9.48797 10.6241C9.48797 10.4708 9.4413 10.3241 9.34797 10.1841C9.2613 10.0375 9.12797 9.92079 8.94797 9.83412C8.77464 9.74079 8.55797 9.69412 8.29797 9.69412C8.09797 9.69412 7.91464 9.72079 7.74797 9.77412C7.58797 9.82745 7.4413 9.89745 7.30797 9.98412C7.1813 10.0641 7.06797 10.1508 6.96797 10.2441C6.87464 10.3308 6.79464 10.4108 6.72797 10.4841L6.17797 9.86412C6.2313 9.80412 6.3213 9.72412 6.44797 9.62412C6.57464 9.51745 6.73464 9.41079 6.92797 9.30412C7.1213 9.19745 7.34464 9.10745 7.59797 9.03412C7.85797 8.95412 8.1413 8.91412 8.44797 8.91412C8.8613 8.91412 9.21464 8.98745 9.50797 9.13412C9.80797 9.27412 10.038 9.46745 10.198 9.71412C10.358 9.95412 10.438 10.2241 10.438 10.5241C10.438 10.7841 10.3813 11.0108 10.268 11.2041C10.1613 11.3975 10.0213 11.5675 9.84797 11.7141C9.6813 11.8608 9.50464 11.9875 9.31797 12.0941C9.13797 12.1941 8.97464 12.2808 8.82797 12.3541C8.52797 12.5008 8.2713 12.6341 8.05797 12.7541C7.84464 12.8741 7.66797 12.9941 7.52797 13.1141C7.39464 13.2275 7.28464 13.3475 7.19797 13.4741C7.11797 13.6008 7.05797 13.7508 7.01797 13.9241H10.548V14.7241H6.09797Z"
              fill={isMobile ? 'white' : '#F15836'}
            />
          </svg>
          <span className={classNames('text-sm font-medium', isMobile ? 'text-[#404040]' : 'text-white')}>
            Thiết lập mật khẩu
          </span>
        </div>
        <div
          className={classNames(
            isMobile ? 'bg-gradient-to-r from-[#F15836] to-[#FF924F]' : 'bg-white',
            'h-[4px] w-full',
            activeStepIndex === 1 ? '' : 'opacity-40'
          )}
        ></div>
      </div>
    </div>
  );
};
