import { sendPostRequest, sendPostRequestAuth } from './index';
import { apiCommon } from './api/common';

export const getPlaceList = (param: any) => sendPostRequest(apiCommon.getPlace, param);

export const getNewsList = (param: any) => sendPostRequestAuth(apiCommon.getNews, param);

export const getNewsDetail = (id: string) => sendPostRequestAuth(apiCommon.getNewDetail, { id });

export const getOptionSearch = () => sendPostRequest(apiCommon.getOptionSearch);
