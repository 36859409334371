import { KeyboardDoubleArrowDown } from 'assets/images';
import { NotFoundIcon } from 'assets/images/common';
import { ButtonCustom } from 'components/common/ButtonCustom';
import { ButtonGradient } from 'components/common/button/ButtonGradient';
import { CheckBoxLabel } from 'components/common/checkbox/CheckBoxLabel';
import { SelectForm } from 'components/common/form-item/SelectForm';
import { TextForm } from 'components/common/form-item/TextForm';
import { TimeForm } from 'components/common/form-item/TimeForm';
import { PaginationAuto } from 'components/common/pagination/PaginationAuto';
import {
  MSIDN_TYPE,
  NUM_EXPIRED_CHOICE,
  PACKAGE_PRICE,
  PACK_TYPE_CHOICE,
  PER_PAGE_DEFAULT,
  QUERY_KEY,
  SUPPLIER_OPTIONS,
  TRANS_STATUS,
} from 'constant';
import { SelectOptionEntity } from 'entity/SearchEntity';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useMutation, useQuery } from 'react-query';
import { getOptionSearch } from 'services/common';
import { exportExcelTransaction, searchTransaction } from 'services/transaction';
import { typeProducts } from '../type';
import { TableTransaction } from './TableTransaction';
import { TransContext } from './TransContext';

export const TransactionWeb = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(PER_PAGE_DEFAULT);
  const [isShowMore, setIsShowMore] = useState<boolean>(false);
  const [isFilterBySim, setIsFilterBySim] = useState<boolean>(false);
  const [isFilterByPackage, setIsFilterByPackage] = useState<boolean>(false);

  const [typeProduct, setTypeProduct] = useState<SelectOptionEntity>({ id: 0, name: '', value: '' });
  const [typeTrans, setTypeTrans] = useState<SelectOptionEntity>({ id: 0, name: '', value: '' });
  const [itemName, setItemName] = useState<string>('');
  const [tranCode, setTranCode] = useState<string>('');
  const [msisdnContact, setMsisdnContact] = useState<string>('');
  const [startDay, setStartDay] = useState<Date | null>(null);
  const [endDay, setEndDay] = useState<Date | null>(null);

  const [typeMsisdn, setTypeMsisdn] = useState<SelectOptionEntity>({ id: 0, name: '', value: '' });
  const [telco, setTelco] = useState<SelectOptionEntity>({ id: 0, name: '', value: '' });
  const [typeSim, setTypeSim] = useState<SelectOptionEntity>({ id: 0, name: '', value: '' });

  const [typePck, setTypePck] = useState<SelectOptionEntity>({ id: 0, name: '', value: '' });
  const [cyclePck, setCyclePck] = useState<SelectOptionEntity>({ id: 0, name: '', value: '' });
  const [pckPrice, setPckPrice] = useState<SelectOptionEntity>({ id: 0, name: '', value: '' });

  const mutation = useMutation(QUERY_KEY.TRANSACTION_LIST, (param: any) => searchTransaction(param));
  const mutationExportExcel = useMutation(
    QUERY_KEY.EXPORT_EXCEL_TRANSACTION_LIST,
    (param: any) => exportExcelTransaction(param),
    {
      onSuccess: (data) => {
        window.open(data.data, '_blank');
      },
    }
  );

  const dataTransaction = useMemo(() => {
    if (mutation?.data?.data?.data?.items) {
      return mutation.data.data.data.items;
    }
    return [];
  }, [mutation]);

  useEffect(() => {
    mutation.mutate({
      itemType: '',
      page: 1,
      numberPerPage: PER_PAGE_DEFAULT,
    });
  }, []);

  const { data: dataCommonOption } = useQuery(QUERY_KEY.GET_COMMON_OPTION, () => getOptionSearch());

  const addOptionFilterAll = (list?: any[]) => {
    if (!list) return [];
    return [{ id: 0, name: 'Tất cả', value: '' }, ...list];
  };

  const listTransStatuses = addOptionFilterAll(TRANS_STATUS);

  const listGroupMsisdnOptions = useMemo(() => {
    return [{ id: 0, name: 'Tất cả', value: '' }].concat(
      dataCommonOption?.data?.data
        ?.map((item: { id: number; groupName: string }) => ({
          id: item?.id,
          name: item?.groupName,
          value: item?.id?.toString(),
        }))
        .filter((item: { id: number; groupName: string }) => item.id !== -1)
    );
  }, [dataCommonOption]);

  const listTelco = useMemo(() => {
    // return addOptionFilterAll(dataCommonOption?.data?.data?.TELCO);
    return addOptionFilterAll(SUPPLIER_OPTIONS);
  }, []);

  const listTypePackage = useMemo(() => {
    // return addOptionFilterAll(dataCommonOption?.data?.data?.LOAIGOICUOC);
    return addOptionFilterAll(PACK_TYPE_CHOICE);
  }, []);

  const listCycle = useMemo(() => {
    // return addOptionFilterAll(dataCommonOption?.data?.data?.CHUKY);
    return addOptionFilterAll(NUM_EXPIRED_CHOICE);
  }, []);

  const listPckPrice = useMemo(() => {
    // return addOptionFilterAll(dataCommonOption?.data?.data?.pckPrice);
    return addOptionFilterAll(PACKAGE_PRICE);
  }, []);

  const listTypeSubscriber = addOptionFilterAll(MSIDN_TYPE);

  const exportExcel = (_param?: { perPage?: number; currentPage?: number }) => {
    let param: any = {
      tranCode: tranCode?.trim() || undefined,
      msisdnContact: msisdnContact?.trim() || undefined,
      itemName: itemName?.trim() || undefined,
      itemType: typeProduct.value || undefined,
      status: typeTrans.value ? +typeTrans.value : undefined,
      fromDate: startDay ? startDay.toISOString() : undefined,
      toDate: endDay ? endDay.toISOString() : undefined,
      page: _param?.currentPage || currentPage,
      numberPerPage: _param?.perPage || perPage,
    };
    if (isFilterBySim) {
      param = {
        ...param,
        agent: telco.value !== '' ? [+telco.value] : [],
        typeMsisdn: isEmpty(typeMsisdn.value) ? [] : [+typeMsisdn.value],
        groupMsisdn: typeSim.value ? [+typeSim.value] : [],
      };
    } else if (isFilterByPackage) {
      param = {
        ...param,
        agent: telco.value !== '' ? [+telco.value] : [],
        amountPck: pckPrice.value ? +pckPrice.value : undefined,
        typePck: typePck.value ? [+typePck.value] : undefined,
        cyclePck: cyclePck.value ? [+cyclePck.value] : undefined,
      };
    }

    mutationExportExcel.mutate(param);
  };

  const clearSearch = () => {
    setTypeProduct({ id: 0, name: '', value: '' });
    setTypeTrans({ id: 0, name: '', value: '' });
    setItemName('');
    setTranCode('');
    setMsisdnContact('');
    setIsFilterBySim(false);
    setIsFilterByPackage(false);
    setStartDay(null);
    setEndDay(null);
    mutation.mutate({
      itemType: '',
      page: 1,
      numberPerPage: PER_PAGE_DEFAULT,
    });
  };

  useEffect(() => {
    setTelco({ id: 0, name: '', value: '' });
    setTypeMsisdn({ id: 0, name: '', value: '' });
    setTypeSim({ id: 0, name: '', value: '' });
  }, [isFilterBySim]);

  useEffect(() => {
    setTelco({ id: 0, name: '', value: '' });
    setTypePck({ id: 0, name: '', value: '' });
    setCyclePck({ id: 0, name: '', value: '' });
    setPckPrice({ id: 0, name: '', value: '' });
  }, [isFilterByPackage]);

  const onSearchTrans = (_param?: { perPage?: number; currentPage?: number }) => {
    let param: any = {
      tranCode: tranCode?.trim() || undefined,
      msisdnContact: msisdnContact?.trim() || undefined,
      itemName: itemName?.trim() || undefined,
      itemType: typeProduct.value || undefined,
      status: typeTrans.value ? +typeTrans.value : undefined,
      fromDate: startDay ? startDay.toISOString() : undefined,
      toDate: endDay ? endDay.toISOString() : undefined,
      page: _param?.currentPage || currentPage,
      numberPerPage: _param?.perPage || perPage,
    };
    console.log('typeSim.value', typeSim.value);
    if (isFilterBySim) {
      param = {
        ...param,
        agent: telco.value !== '' ? [+telco.value] : [],
        typeMsisdn: isEmpty(typeMsisdn.value) ? [] : [+typeMsisdn.value],
        groupMsisdn: typeSim.value ? [+typeSim.value] : [],
      };
    } else if (isFilterByPackage) {
      param = {
        ...param,
        agent: telco.value !== '' ? [+telco.value] : [],
        amountPck: pckPrice.value ? +pckPrice.value : undefined,
        typePck: typePck.value ? [+typePck.value] : undefined,
        cyclePck: cyclePck.value ? [+cyclePck.value] : undefined,
      };
    }

    mutation.mutate(param);
  };

  const isDataMore = useMemo(() => {
    return typeMsisdn?.id !== 0 || telco?.id !== 0 || typeSim?.id !== 0 || typePck?.id !== 0 || cyclePck?.id !== 0;
  }, [cyclePck, telco, typeMsisdn, typePck, typeSim]);

  return (
    <>
      <TransContext.Provider
        value={{
          typeProduct,
          typeTrans,
          itemName,
          tranCode,
          msisdnContact,
          startDay,
          endDay,
          // visible filter by sim or package
          isFilterBySim,
          isFilterByPackage,
          // sim
          typeMsisdn,
          telco,
          typeSim,
          // package
          typePck,
          cyclePck,

          setIsFilterBySim,
          setIsFilterByPackage,
          setTypeProduct,
          setTypeTrans,
          setItemName,
          setTranCode,
          setMsisdnContact,
          setStartDay,
          setEndDay,

          setTypeMsisdn,
          setTelco,
          setTypeSim,

          setTypePck,
          setCyclePck,
        }}
      >
        <div className={'mt-[60px] hidden h-full w-full bg-[#F8F8F8] md:mt-0 md:block md:h-[calc(100%-90px)]'}>
          <Scrollbars className={'h-full w-full bg-[#F8F8F8]'}>
            <div className={'bg-[#F8F8F8] pr-7'}>
              <div className={'mb-5 flex flex-row justify-between'}>
                <h2 className={'text-2xl font-semibold uppercase text-gray-700'}>DANH SÁCH GIAO DỊCH</h2>
              </div>
              <div className={'w-full bg-transparent'}>
                <div className={'grid-cols-1-1 grid gap-4 md:grid-cols-2'}>
                  <SelectForm
                    label={'Danh mục sản phẩm'}
                    options={typeProducts}
                    classIcon={'text-gray-400'}
                    value={typeProduct}
                    onChange={(v) => {
                      if (v) {
                        setTypeProduct(v);
                        if (v.value === 'SIMSO') {
                          setIsFilterBySim(true);
                          setIsFilterByPackage(false);
                          return;
                        } else if (v.value === 'GOICUOC') {
                          setIsFilterByPackage(true);
                          setIsFilterBySim(false);
                          return;
                        }
                      } else {
                        setTypeProduct(typeProducts[0]);
                      }
                      setIsFilterByPackage(false);
                      setIsFilterBySim(false);
                    }}
                  />
                  <TextForm
                    label={'Sản phẩm'}
                    placeholder={'Tìm kiếm tên sản phẩm'}
                    value={itemName}
                    setValue={setItemName}
                  />
                  <TextForm
                    label={'Số thuê bao'}
                    placeholder={'Tìm kiếm theo số thuê bao khách hàng'}
                    value={msisdnContact}
                    setValue={setMsisdnContact}
                  />
                  <SelectForm
                    label={'Trạng thái giao dịch'}
                    options={listTransStatuses}
                    value={typeTrans}
                    onChange={(v) => (v ? setTypeTrans(v) : setTypeTrans(listTransStatuses[0]))}
                    classIcon={'text-gray-400'}
                  />
                  <TextForm
                    label={'Mã giao dịch'}
                    placeholder={'Tìm kiếm theo mã giao dịch, mã giữ số'}
                    value={tranCode}
                    setValue={setTranCode}
                  />
                  <TimeForm
                    label={'Thời gian giao dịch'}
                    startDay={startDay}
                    setStartDay={setStartDay}
                    endDay={endDay}
                    setEndDay={setEndDay}
                    isDisabledSelectToday={true}
                  />
                </div>
                {isShowMore && (
                  <div className={'mt-6 w-full border-t-[1px] border-dashed border-[#D6D5D5]'}>
                    <div className={'flex flex-row items-center space-x-2 py-6'}>
                      <h2 className={'text-[18px] font-bold text-baseColor'}>Bộ lọc theo</h2>

                      {/* <CheckBoxLabel
                        value={true}
                        label={'Sim số'}
                        values={isFilterBySim ? [isFilterBySim] : []}
                        onChange={(v: any) => {
                          if (isEmpty(v) || v.includes((itemValue: any) => !itemValue)) {
                            // setIsFilterBySim(false);
                          } else {
                            setIsFilterBySim(true);
                            setTypeProduct(typeProducts[1]);
                            setIsFilterByPackage(false);
                          }
                        }}
                      /> */}

                      <CheckBoxLabel
                        value={true}
                        label={'Gói cước'}
                        values={isFilterByPackage ? [isFilterByPackage] : []}
                        onChange={(v: any) => {
                          if (isEmpty(v) || v.includes((itemValue: any) => !itemValue)) {
                            // setIsFilterByPackage(false);
                          } else {
                            setIsFilterByPackage(true);
                            setTypeProduct(typeProducts[2]);
                            setIsFilterBySim(false);
                          }
                        }}
                      />
                    </div>
                    {isFilterBySim && (
                      <div className={'grid-cols-1-1 grid gap-4 md:grid-cols-2'}>
                        <SelectForm
                          label={'Loại thuê bao'}
                          options={listTypeSubscriber}
                          classIcon={'text-gray-400'}
                          value={typeMsisdn}
                          onChange={(v) => (v ? setTypeMsisdn(v) : setTypeMsisdn(listTypeSubscriber[0]))}
                        />
                        <SelectForm
                          label={'Loại số'}
                          options={listGroupMsisdnOptions}
                          classIcon={'text-gray-400'}
                          value={typeSim}
                          onChange={(v) => (v ? setTypeSim(v) : setTypeSim(listGroupMsisdnOptions[0]))}
                        />
                        <SelectForm
                          label={'Nhà mạng'}
                          options={listTelco}
                          classIcon={'text-gray-400'}
                          value={telco}
                          onChange={(v) => (v ? setTelco(v) : setTelco(listTelco[0]))}
                        />
                      </div>
                    )}
                    {isFilterByPackage && (
                      <div className={'grid-cols-1-1 grid gap-4 md:grid-cols-2'}>
                        <SelectForm
                          label={'Loại gói cước'}
                          options={listTypePackage}
                          classIcon={'text-gray-400'}
                          value={typePck}
                          onChange={(v) => (v ? setTypePck(v) : setTypePck(listTypePackage[0]))}
                        />
                        <SelectForm
                          label={'Giá gói'}
                          options={listPckPrice}
                          classIcon={'text-gray-400'}
                          value={pckPrice}
                          onChange={(v) => (v ? setPckPrice(v) : setPckPrice(listPckPrice[0]))}
                        />
                        <SelectForm
                          label={'Nhà mạng'}
                          options={listTelco}
                          classIcon={'text-gray-400'}
                          value={telco}
                          onChange={(v) => (v ? setTelco(v) : setTelco(listTelco[0]))}
                        />
                        <SelectForm
                          label={'Chu kỳ'}
                          options={listCycle}
                          value={cyclePck}
                          onChange={(v) => (v ? setCyclePck(v) : setCyclePck(listCycle[0]))}
                          classIcon={'text-gray-400'}
                        />
                      </div>
                    )}
                  </div>
                )}
                <div className={'mt-6 flex flex-row items-center justify-center space-x-2.5'}>
                  <ButtonGradient className={'h-[35px] w-[120px]'} onClick={() => clearSearch()}>
                    <span
                      className={
                        'bg-gradient-to-r from-[#F15836] to-[#FF924F] bg-clip-text !text-sm font-semibold text-transparent'
                      }
                    >
                      Xoá điều kiện
                    </span>
                  </ButtonGradient>
                  <ButtonCustom
                    onClick={() => onSearchTrans()}
                    label={'Tìm kiếm'}
                    className={'h-[35px] w-[120px] bg-gray-600 px-2 py-[8px] !text-sm !font-semibold'}
                  />
                  <div className={'relative h-fit w-fit'}>
                    <ButtonGradient onClick={() => setIsShowMore((v) => !v)} className={'h-[35px] w-[35px]'}>
                      <img
                        src={KeyboardDoubleArrowDown}
                        className={`${isShowMore ? 'rotate-180' : ''}`}
                        alt={'show-more'}
                      />
                    </ButtonGradient>
                    {!isShowMore && isDataMore && (
                      <div className={'absolute -right-1 -top-1 h-fit w-fit rounded-full bg-white'}>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.0013 0.603516C3.9513 0.603516 0.667969 3.88685 0.667969 7.93685C0.667969 11.9868 3.9513 15.2702 8.0013 15.2702C12.0513 15.2702 15.3346 11.9868 15.3346 7.93685C15.3346 3.88685 12.0513 0.603516 8.0013 0.603516ZM11.18 6.69685C11.2385 6.62994 11.2831 6.55201 11.311 6.46763C11.339 6.38325 11.3498 6.29413 11.3428 6.20551C11.3358 6.11689 11.3112 6.03056 11.2704 5.9516C11.2295 5.87264 11.1733 5.80265 11.105 5.74574C11.0367 5.68883 10.9577 5.64616 10.8727 5.62022C10.7877 5.59429 10.6983 5.58562 10.6099 5.59473C10.5215 5.60384 10.4358 5.63055 10.3578 5.67327C10.2798 5.716 10.2112 5.77388 10.156 5.84352L7.2893 9.28285L5.80597 7.79885C5.68023 7.67741 5.51183 7.61021 5.33704 7.61173C5.16224 7.61325 4.99503 7.68336 4.87142 7.80697C4.74782 7.93058 4.6777 8.09778 4.67619 8.27258C4.67467 8.44738 4.74186 8.61578 4.8633 8.74152L6.8633 10.7415C6.92881 10.807 7.00723 10.8581 7.09358 10.8916C7.17993 10.925 7.2723 10.9402 7.36481 10.9359C7.45733 10.9317 7.54795 10.9083 7.63091 10.8671C7.71387 10.826 7.78734 10.768 7.84664 10.6968L11.18 6.69685Z"
                            fill="url(#paint0_linear_524_27473)"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_524_27473"
                              x1="0.667969"
                              y1="7.93685"
                              x2="15.3346"
                              y2="7.93685"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#FFB01F" />
                              <stop offset="1" stopColor="#FFCF45" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={'flex flex-row items-center justify-between py-6'}>
                <span className={'text-2xl font-semibold text-gray-700'}>{`${
                  mutation?.data?.data?.data?.rowCount || 0
                } giao dịch`}</span>
                {/* <button
                  type="button"
                  onClick={() => exportExcel()}
                  className="inline-flex items-center justify-center gap-2 rounded-full px-4 py-2 text-sm font-medium text-[#F15836]"
                >
                  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M0.859957 1.89796L13.4293 0.254639C13.5003 0.245322 13.5726 0.250075 13.6413 0.268577C13.7101 0.287079 13.7737 0.318896 13.8278 0.361873C13.882 0.40485 13.9254 0.457982 13.9552 0.517669C13.9849 0.577355 14.0003 0.6422 14.0003 0.707811V19.7923C14.0003 19.8578 13.9849 19.9226 13.9552 19.9822C13.9255 20.0418 13.8822 20.0949 13.8282 20.1379C13.7741 20.1808 13.7106 20.2127 13.642 20.2312C13.5734 20.2498 13.5012 20.2547 13.4303 20.2455L0.858957 18.6022C0.620588 18.5711 0.402472 18.4623 0.244676 18.2958C0.0868808 18.1293 2.31045e-06 17.9163 0 17.6958V2.80431C2.31045e-06 2.58387 0.0868808 2.37083 0.244676 2.20433C0.402472 2.03783 0.620588 1.92904 0.858957 1.89796H0.859957ZM15.0002 2.01057H19C19.2653 2.01057 19.5196 2.10702 19.7071 2.27871C19.8946 2.4504 20 2.68326 20 2.92607V17.5741C20 17.8169 19.8946 18.0497 19.7071 18.2214C19.5196 18.3931 19.2653 18.4896 19 18.4896H15.0002V2.01057ZM8.20059 10.2501L11.0005 6.58807H8.60057L7.00065 8.6809L5.40073 6.58807H3.00085L5.80071 10.2501L3.00085 13.9121H5.40073L7.00065 11.8192L8.60057 13.9121H11.0005L8.20059 10.2501Z"
                      fill="#F15836"
                    />
                  </svg>
                  Xuất Excel
                </button> */}
              </div>
              {dataTransaction.length > 0 && (
                <TableTransaction dataRows={dataTransaction} currentPage={currentPage} perPage={perPage} />
              )}
              {!dataTransaction.length && (
                <div className={'mt-4 flex h-fit w-full flex-col items-center justify-center'}>
                  <img src={NotFoundIcon} alt={'not-found'} />
                  <span className={'mt-2 text-[18px] font-bold leading-[28px] text-[#A1A1AA]'}>
                    Không tìm thấy dữ liệu
                  </span>
                </div>
              )}
              {dataTransaction.length > 0 && (
                <div className={'mt-6'}>
                  <PaginationAuto
                    currentPage={currentPage || 1}
                    setCurrentPage={(_page: number) => {
                      setCurrentPage(_page);
                      onSearchTrans({ currentPage: _page });
                    }}
                    totalItem={mutation?.data?.data?.data?.rowCount || 0}
                    totalPage={Math.ceil(mutation?.data?.data?.data?.rowCount / perPage) || 0}
                    perPage={perPage}
                    setPerPage={(_perPage: number) => {
                      setPerPage(_perPage);
                      setCurrentPage(1);
                      onSearchTrans({ currentPage: 1, perPage: _perPage });
                    }}
                  />
                </div>
              )}
              <div className={'h-0 md:h-10'}></div>
            </div>
          </Scrollbars>
        </div>
      </TransContext.Provider>
    </>
  );
};
