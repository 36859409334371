import { useNavigate } from 'react-router';
import { HeaderCommon } from '../../components/header';
import Scrollbars from 'react-custom-scrollbars';
import { CheckSuccessIcon, DefaultMobileIcon, SuccessMobileIcon } from '../../assets/images/form';
import { classNames } from '../../utils/utils';
import { useForm } from 'react-hook-form';
import usePasswordToggle from '../../hooks/usePasswordToggle';
import { InputCustom } from '../../components/common/InputCustom';
import { ButtonCustom } from '../../components/common/ButtonCustom';
import { ButtonGradient } from '../../components/common/button/ButtonGradient';
import { useMutation } from 'react-query';
import { IChangePassword } from '../../entity/Account';
import { postChangePassword } from '../../services/account';
import { CalendarBackIcon } from '../../assets/images';
import toast from 'react-hot-toast';
import { PATH_PAGE } from 'constant';
import useCheckDeviceMobi from 'hooks/useCheckDeviceMobi';

interface StepResetPasswordType {
  oldPassword: string | null;
  password: string | null;
  confirmPassword: string | null;
}

export const ChangePassword = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { errors },
  } = useForm<StepResetPasswordType>({
    defaultValues: {
      oldPassword: null,
      password: null,
      confirmPassword: null,
    },
    mode: 'all',
    // reValidateMode: 'onChange',
  });

  const isMobile = useCheckDeviceMobi();

  const [OldPasswordInputType, OldToggleIcon] = usePasswordToggle();
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [PasswordInputTypeRe, ToggleIconRe] = usePasswordToggle();

  const onSubmitChangePassword = useMutation((data: IChangePassword) => postChangePassword(data));

  const onSubmit = (data: StepResetPasswordType) => {
    //TODO: get username
    onSubmitChangePassword.mutate(
      {
        password: data.password!,
        passwordOld: data.oldPassword!,
        srcReq: 'APP',
        type: 'CHANGE_PASS',
      },
      {
        onSuccess: (response) => {
          if (response.data.data.status === 1) {
            toast.success('Cập nhật thành công');
            navigate(PATH_PAGE.DASHBOARD);
            return;
          }
          toast.error(response.data.data.statusStr || 'Cập nhật thất bại');
        },
        onError: (error) => {
          toast.error('Cập nhật thất bại');
        },
      }
    );
  };

  return (
    <div className={'h-full w-full rounded-[20px] bg-[#F8F8F8] pt-6 pl-6 pb-6'}>
      <HeaderCommon
        children={
          <div className={'flex flex-row items-center space-x-2'}>
            <span className={'mr-[8px]'} onClick={() => navigate(-1)}>
              <img className="h-[18px] w-[18px]" src={CalendarBackIcon} alt="Logo" />
            </span>
            <span className={'text-[22px] font-extrabold text-[#404040]'}>Thay đổi mật khẩu</span>
          </div>
        }
      />
      <div className={'relative h-[calc(100%-90px)] w-full'}>
        <form onSubmit={handleSubmit(onSubmit)} className={'h-full w-full'}>
          {isMobile ? (
            <div className={'mt-10 h-fit min-h-[calc(100vh_-_100px)] pr-7'}>
              <div className={'w-full rounded-t-[15px] rounded-bl-[15px] shadow-base'}>
                <div
                  className={
                    'mt-1 flex cursor-pointer flex-row space-x-2 text-[12px] font-medium leading-[16px] text-[#71717A]'
                  }
                >
                  <a href={PATH_PAGE.DASHBOARD}>Trang chủ</a>
                  <span>|</span>
                  <span>Thay đổi mật khẩu</span>
                </div>
                <div className="my-2 h-fit">
                  <button onClick={() => navigate(-1)} className="mr-1 h-fit">
                    <svg
                      className="mt-1"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.54637 7.59619L9.33137 2.81019C9.52498 2.61658 9.78757 2.50781 10.0614 2.50781C10.3352 2.50781 10.5978 2.61658 10.7914 2.81019C10.985 3.0038 11.0938 3.26639 11.0938 3.54019C11.0938 3.81399 10.985 4.07658 10.7914 4.27019L6.72637 8.33319L10.7914 12.3952C10.9848 12.5888 11.0935 12.8513 11.0934 13.125C11.0934 13.2606 11.0666 13.3948 11.0147 13.52C10.9628 13.6451 10.8867 13.7589 10.7909 13.8547C10.695 13.9505 10.5812 14.0265 10.456 14.0783C10.3308 14.1301 10.1965 14.1568 10.061 14.1567C9.78731 14.1566 9.52485 14.0478 9.33137 13.8542L4.54637 9.06919C4.35115 8.8738 4.24148 8.60889 4.24148 8.33269C4.24148 8.05648 4.35115 7.79158 4.54637 7.59619Z"
                        fill="#6A6A6A"
                      />
                    </svg>
                  </button>
                  <span className={'text-[18px] font-bold text-[#404040]'}>Thay đổi mật khẩu</span>
                </div>

                <div
                  className={'grid-cols-1-1 grid gap-4 rounded-t-[15px] rounded-bl-[15px] bg-white p-6 md:grid-cols-2'}
                >
                  <div className={'grid-cols-1-1 grid w-full gap-4'}>
                    <div className={'flex flex-col space-y-2'}>
                      <label className={'text-sm font-bold text-[#5B5B5B]'}>Mật khẩu cũ</label>
                      <div className={'relative mt-2 h-full w-full'}>
                        <InputCustom
                          label={'oldPassword'}
                          placeholder={'Nhập số mật khẩu cũ'}
                          className={classNames(
                            'w-full border !pr-10',
                            errors.oldPassword ? '!border-[#FC5555]' : '!border-[#EFEFEF]'
                          )}
                          type={OldPasswordInputType as string}
                          register={register}
                          control={control}
                          autoFocus
                          required={{
                            required: 'Vui lòng nhập mật khẩu cũ',
                          }}
                        />
                        {watch('oldPassword') && (
                          <div className="absolute top-2.5 right-2 flex cursor-pointer items-center justify-center">
                            {OldToggleIcon}
                          </div>
                        )}
                      </div>
                      {errors.oldPassword && (
                        <span className={'mt-2 text-[12px] font-normal leading-[18px] text-[#F15836]'}>
                          {errors.oldPassword.message}
                        </span>
                      )}
                    </div>
                    <div className={'flex flex-col space-y-2'}>
                      <label className={'text-sm font-bold text-[#5B5B5B]'}>Mật khẩu mới</label>
                      <div className={'relative mt-2 h-full w-full'}>
                        <InputCustom
                          label={'password'}
                          placeholder={'Nhập số mật khẩu mới'}
                          className={classNames(
                            'w-full border !pr-10',
                            errors.password ? '!border-[#FC5555]' : '!border-[#EFEFEF]'
                          )}
                          maxLength={16}
                          type={PasswordInputType as string}
                          register={register}
                          control={control}
                          required={{
                            required: 'Vui lòng nhập mật khẩu',
                            validate: (val: string) => {
                              let passwordError = '';
                              if (!/[A-Z]/.test(val)) {
                                passwordError = passwordError + '1';
                              }
                              if (!/\d/.test(val)) {
                                passwordError = passwordError + '3';
                              }
                              if (!/[@$!%*#?&]/.test(val)) {
                                passwordError = passwordError + '4';
                              }
                              if (val.length < 8) {
                                passwordError = passwordError + '5';
                              }
                              if (passwordError) {
                                return passwordError;
                              }
                            },
                          }}
                        />
                        {watch('password') && (
                          <div className="absolute top-2.5 right-2 flex cursor-pointer items-center justify-center">
                            {ToggleIcon}
                          </div>
                        )}
                      </div>
                      {errors.password && errors.password.type !== 'validate' && (
                        <span className={'mt-2 text-[12px] font-normal leading-[18px] text-[#F15836]'}>
                          {errors.password.message}
                        </span>
                      )}
                    </div>
                    <div className={'flex flex-col space-y-2'}>
                      <label className={'text-sm font-bold text-[#5B5B5B]'}>Xác nhận mật khẩu</label>
                      <div className={'relative mt-2 h-full w-full'}>
                        <InputCustom
                          placeholder={'Nhập lại mật khẩu mới'}
                          type={PasswordInputTypeRe as string}
                          className={classNames(
                            'w-full border !pr-10',
                            errors.confirmPassword ? '!border-[#FC5555]' : '!border-[#EFEFEF]'
                          )}
                          register={register}
                          label={'confirmPassword'}
                          control={control}
                          required={{
                            required: 'Vui lòng nhập xác nhận mật khẩu',
                            validate: (val: string) => {
                              if (watch('password') !== val) {
                                return 'Xác nhận mật khẩu không trùng khớp';
                              }
                            },
                          }}
                        />
                        {watch('confirmPassword') && (
                          <div className="absolute top-2.5 right-2 flex cursor-pointer items-center justify-center">
                            {ToggleIconRe}
                          </div>
                        )}
                      </div>
                      {errors.confirmPassword && (
                        <span className={'mt-2 text-[12px] font-normal leading-[18px] text-[#F15836]'}>
                          {errors.confirmPassword.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className={'flex w-full flex-col space-y-2'}>
                    <h1 className={'text-base font-medium text-[#404040]'}>
                      Vì lý do bảo mật, vui lòng thiết lập mật khẩu với các điều kiện sau:
                    </h1>
                    <div className={'grid w-full gap-2'}>
                      <div className={'flex flex-row items-center space-x-2'}>
                        <img
                          src={
                            getValues('password') &&
                            (!errors.password?.message || !errors.password?.message?.includes('3'))
                              ? SuccessMobileIcon
                              : DefaultMobileIcon
                          }
                          alt={'success-icon'}
                          className={classNames('h-4 w-4')}
                        />
                        <span className={'text-sm font-medium text-[#404040]'}>Chứa ít nhất 1 số</span>
                      </div>
                      <div className={'flex flex-row items-center space-x-2'}>
                        <img
                          src={
                            getValues('password') &&
                            (!errors.password?.message || !errors.password?.message?.includes('4'))
                              ? SuccessMobileIcon
                              : DefaultMobileIcon
                          }
                          alt={'success-icon'}
                          className={classNames('h-4 w-4')}
                        />
                        <span className={' text-sm font-medium text-[#404040]'}>
                          Chứa ít nhất 1 ký tự đặc biệt (Ví dụ: *, @, ~, ...)
                        </span>
                      </div>
                      <div className={'flex flex-row items-center space-x-2'}>
                        <img
                          src={
                            getValues('password') &&
                            (!errors.password?.message || !errors.password?.message?.includes('1'))
                              ? SuccessMobileIcon
                              : DefaultMobileIcon
                          }
                          alt={'success-icon'}
                          className={classNames('h-4 w-4')}
                        />
                        <span className={' text-sm font-medium text-[#404040]'}>Chứa ít nhất 1 chữ cái viết hoa</span>
                      </div>
                      <div className={'flex flex-row items-center space-x-2'}>
                        <img
                          src={
                            getValues('password') &&
                            (!errors.password?.message || !errors.password?.message?.includes('5'))
                              ? SuccessMobileIcon
                              : DefaultMobileIcon
                          }
                          alt={'success-icon'}
                          className={classNames('h-4 w-4')}
                        />
                        <span className={' text-sm font-medium text-[#404040]'}>Dài tối thiểu 8 ký tự</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'fixed bottom-0 left-0 h-[60px] w-screen bg-white'}>
                <div className="flex h-full w-full flex-row items-center justify-center space-x-2">
                  <ButtonGradient className={'h-[35px] w-[120px]'} background={'bg-white'} onClick={() => navigate(-1)}>
                    <span
                      className={
                        'bg-gradient-to-r from-[#F15836] to-[#FF924F] bg-clip-text text-sm font-extrabold text-transparent'
                      }
                    >
                      Quay lại
                    </span>
                  </ButtonGradient>
                  <ButtonCustom
                    label={'Cập nhật'}
                    type={'submit'}
                    className={'h-[35px] w-[120px] bg-gray-600 px-2 py-[8px] !text-sm !font-extrabold'}
                  />
                </div>
              </div>
            </div>
          ) : (
            <>
              <Scrollbars className={'h-full w-full'}>
                <div className={'pr-7'}>
                  <div className={'w-full rounded-t-[15px] rounded-bl-[15px] shadow-base'}>
                    <div className={'w-full rounded-t-[15px] border-b-[1px] border-[#EFEFEF] bg-white p-6'}>
                      <div className={'flex flex-row items-center space-x-2'}>
                        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="6.92378" cy="6.7519" r="6.04878" fill="#F15836" />
                        </svg>
                        <h2 className={'font-bold text-[#404040]'}>Thay đổi mật khẩu</h2>
                      </div>
                    </div>
                    <div className={'grid-cols-1-1 grid gap-4 rounded-bl-[15px] bg-white p-6 md:grid-cols-2'}>
                      <div className={'flex w-full flex-col space-y-2'}>
                        <h1 className={'text-base font-medium text-[#404040]'}>
                          Để tài khoản của bạn được an toàn nhất, xin hãy đảm bảo rằng mật khẩu của bạn:
                        </h1>
                        <div className={'grid w-full gap-2'}>
                          <div className={'flex flex-row items-center space-x-2'}>
                            <img
                              src={CheckSuccessIcon}
                              alt={'success-icon'}
                              className={classNames(
                                'h-4 w-4',
                                getValues('password') &&
                                  (!errors.password?.message || !errors.password?.message?.includes('3'))
                                  ? ''
                                  : 'opacity-30'
                              )}
                            />
                            <span className={'text-sm font-medium text-[#404040]'}>Chứa ít nhất 1 số</span>
                          </div>
                          <div className={'flex flex-row items-center space-x-2'}>
                            <img
                              src={CheckSuccessIcon}
                              alt={'success-icon'}
                              className={classNames(
                                'h-4 w-4',
                                getValues('password') &&
                                  (!errors.password?.message || !errors.password?.message?.includes('4'))
                                  ? ''
                                  : 'opacity-30'
                              )}
                            />
                            <span className={' text-sm font-medium text-[#404040]'}>
                              Chứa ít nhất 1 ký tự đặc biệt (Ví dụ: *, @, ~, ...)
                            </span>
                          </div>
                          <div className={'flex flex-row items-center space-x-2'}>
                            <img
                              src={CheckSuccessIcon}
                              alt={'success-icon'}
                              className={classNames(
                                'h-4 w-4',
                                getValues('password') &&
                                  (!errors.password?.message || !errors.password?.message?.includes('1'))
                                  ? ''
                                  : 'opacity-30'
                              )}
                            />
                            <span className={' text-sm font-medium text-[#404040]'}>
                              Chứa ít nhất 1 chữ cái viết hoa
                            </span>
                          </div>
                          <div className={'flex flex-row items-center space-x-2'}>
                            <img
                              src={CheckSuccessIcon}
                              alt={'success-icon'}
                              className={classNames(
                                'h-4 w-4',
                                getValues('password') &&
                                  (!errors.password?.message || !errors.password?.message?.includes('5'))
                                  ? ''
                                  : 'opacity-30'
                              )}
                            />
                            <span className={' text-sm font-medium text-[#404040]'}>Dài tối thiểu 8 ký tự</span>
                          </div>
                        </div>
                      </div>
                      <div className={'grid-cols-1-1 grid w-full gap-4'}>
                        <div className={'flex flex-col space-y-2'}>
                          <label className={'text-sm font-bold text-[#5B5B5B]'}>Mật khẩu cũ</label>
                          <div className={'relative mt-2 h-full w-full'}>
                            <InputCustom
                              label={'oldPassword'}
                              placeholder={'Nhập số mật khẩu cũ'}
                              className={classNames(
                                'w-full border !pr-10',
                                errors.oldPassword ? '!border-[#FC5555]' : '!border-[#EFEFEF]'
                              )}
                              type={OldPasswordInputType as string}
                              register={register}
                              control={control}
                              autoFocus
                              required={{
                                required: 'Vui lòng nhập mật khẩu cũ',
                              }}
                            />
                            {watch('oldPassword') && (
                              <div className="absolute top-2.5 right-2 flex cursor-pointer items-center justify-center">
                                {OldToggleIcon}
                              </div>
                            )}
                          </div>
                          {errors.oldPassword && (
                            <span className={'mt-2 text-[12px] font-normal leading-[18px] text-[#F15836]'}>
                              {errors.oldPassword.message}
                            </span>
                          )}
                        </div>
                        <div className={'flex flex-col space-y-2'}>
                          <label className={'text-sm font-bold text-[#5B5B5B]'}>Mật khẩu mới</label>
                          <div className={'relative mt-2 h-full w-full'}>
                            <InputCustom
                              label={'password'}
                              placeholder={'Nhập mật khẩu mới'}
                              className={classNames(
                                'w-full border !pr-10',
                                errors.password ? '!border-[#FC5555]' : '!border-[#EFEFEF]'
                              )}
                              maxLength={16}
                              type={PasswordInputType as string}
                              register={register}
                              control={control}
                              required={{
                                required: 'Vui lòng nhập mật khẩu',
                                validate: (val: string) => {
                                  let passwordError = '';
                                  if (!/[A-Z]/.test(val)) {
                                    passwordError = passwordError + '1';
                                  }
                                  if (!/\d/.test(val)) {
                                    passwordError = passwordError + '3';
                                  }
                                  if (!/[@$!%*#?&]/.test(val)) {
                                    passwordError = passwordError + '4';
                                  }
                                  if (val.length < 8) {
                                    passwordError = passwordError + '5';
                                  }
                                  if (passwordError) {
                                    return passwordError;
                                  }
                                },
                              }}
                            />
                            {watch('password') && (
                              <div className="absolute top-2.5 right-2 flex cursor-pointer items-center justify-center">
                                {ToggleIcon}
                              </div>
                            )}
                          </div>
                          {errors.password && errors.password.type !== 'validate' && (
                            <span className={'mt-2 text-[12px] font-normal leading-[18px] text-[#F15836]'}>
                              {errors.password.message}
                            </span>
                          )}
                        </div>
                        <div className={'flex flex-col space-y-2'}>
                          <label className={'text-sm font-bold text-[#5B5B5B]'}>Xác nhận mật khẩu</label>
                          <div className={'relative mt-2 h-full w-full'}>
                            <InputCustom
                              placeholder={'Xác nhận lại mật khẩu'}
                              type={PasswordInputTypeRe as string}
                              className={classNames(
                                'w-full border !pr-10',
                                errors.confirmPassword ? '!border-[#FC5555]' : '!border-[#EFEFEF]'
                              )}
                              register={register}
                              label={'confirmPassword'}
                              control={control}
                              required={{
                                required: 'Vui lòng nhập xác nhận mật khẩu',
                                validate: (val: string) => {
                                  if (watch('password') !== val) {
                                    return 'Xác nhận mật khẩu không trùng khớp';
                                  }
                                },
                              }}
                            />
                            {watch('confirmPassword') && (
                              <div className="absolute top-2.5 right-2 flex cursor-pointer items-center justify-center">
                                {ToggleIconRe}
                              </div>
                            )}
                          </div>
                          {errors.confirmPassword && (
                            <span className={'mt-2 text-[12px] font-normal leading-[18px] text-[#F15836]'}>
                              {errors.confirmPassword.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Scrollbars>
              <div className={'absolute bottom-2 left-[calc(50%-124px)] flex flex-row space-x-2'}>
                <ButtonGradient className={'h-[35px] w-[120px]'} background={'bg-white'} onClick={() => navigate(-1)}>
                  <span
                    className={
                      'bg-gradient-to-r from-[#F15836] to-[#FF924F] bg-clip-text text-sm font-extrabold text-transparent'
                    }
                  >
                    Quay lại
                  </span>
                </ButtonGradient>
                <ButtonCustom
                  label={'Cập nhật'}
                  type={'submit'}
                  className={'h-[35px] w-[120px] bg-gray-600 px-2 py-[8px] !text-sm !font-extrabold'}
                />
              </div>
            </>
          )}
        </form>
      </div>
    </div>
  );
};
