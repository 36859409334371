import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { QUERY_KEY } from '../../../constant';
import { getInfoTransaction } from '../../../services/transaction';
import { useMemo } from 'react';
import { BodyPackage } from './BodyPackage';
import { BodySim } from './BodySim';
import { SpinCustom } from 'components/common/SpinCustom';

export const InfoTrans = () => {
  const { id } = useParams();
  const { data, isLoading } = useQuery(QUERY_KEY.TRANS_INFO, () => getInfoTransaction(id), { enabled: !!id });
  console.log('data', data);

  const infoData = useMemo(() => {
    return data?.data?.data || {};
  }, [data]);

  return (
    <>
      {isLoading && <SpinCustom />}
      {infoData?.itemType === 'GOICUOC' && <BodyPackage infoData={infoData} />}
      {infoData?.itemType === 'SIMSO' && <BodySim infoData={infoData} />}
    </>
  );
};
