import { useNavigate } from 'react-router';
import { HeaderCommon } from '../../../components/header';
import { CalendarBackIcon } from '../../../assets/images';
import Scrollbars from 'react-custom-scrollbars';
import { get } from 'lodash';
import useCheckDeviceMobi from '../../../hooks/useCheckDeviceMobi';
import { classNames } from 'utils/utils';
import { PATH_PAGE } from 'constant';
import { NavLink } from 'react-router-dom';

export const AdjustPayment = ({ infoData }: any) => {
  const navigate = useNavigate();
  const isMobile = useCheckDeviceMobi();

  return (
    <div className={'h-full w-full bg-[#F8F8F8] !pr-0 md:rounded-[20px] md:p-6'}>
      <HeaderCommon
        children={
          <div className={'flex flex-row items-center space-x-2'}>
            <span className={'mr-[8px]'} onClick={() => navigate(-1)}>
              <img className="h-[18px] w-[18px]" src={CalendarBackIcon} alt="Logo" />
            </span>
            <span className={'text-[22px] font-extrabold text-[#404040]'}>Thông tin điều chỉnh hoa hồng</span>
          </div>
        }
      />
      <div className={'relative h-[calc(100%-90px)] w-full px-6 pt-10 md:px-0 md:pt-0'}>
        {isMobile ? (
          <>
            <div
              className={
                'mb-2 mt-10 flex cursor-pointer flex-row space-x-2 text-[12px] font-medium leading-[16px] text-[#71717A]' +
                ' md:hidden'
              }
            >
              <NavLink to={PATH_PAGE.DASHBOARD}>Trang chủ</NavLink>
              <span>|</span>
              <span>Tài khoản</span>
            </div>
            <div className="mb-4 flex flex-row items-center space-x-2">
              <div onClick={() => navigate(-1)}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.54637 8.40283L9.33137 13.1888C9.52498 13.3824 9.78757 13.4912 10.0614 13.4912C10.3352 13.4912 10.5978 13.3824 10.7914 13.1888C10.985 12.9952 11.0938 12.7326 11.0938 12.4588C11.0938 12.185 10.985 11.9224 10.7914 11.7288L6.72637 7.66583L10.7914 3.60384C10.9848 3.41023 11.0935 3.14769 11.0934 2.87398C11.0934 2.73845 11.0666 2.60426 11.0147 2.47907C10.9628 2.35388 10.8867 2.24014 10.7909 2.14434C10.695 2.04854 10.5812 1.97256 10.456 1.92074C10.3308 1.86891 10.1965 1.84227 10.061 1.84231C9.78731 1.84241 9.52485 1.95123 9.33137 2.14484L4.54637 6.92983C4.35115 7.12522 4.24148 7.39013 4.24148 7.66633C4.24148 7.94254 4.35115 8.20745 4.54637 8.40283Z"
                    fill="#6A6A6A"
                  />
                </svg>
              </div>
              <span className="text-lg font-bold text-baseColor">Thông tin điều chỉnh hoa hồng</span>
            </div>
            <div className={'mt-4 grid h-fit w-full grid-cols-1 gap-4'}>
              <span className={'w-full text-base font-bold text-[#404040]'}>Thông tin điều chỉnh hoa hồng</span>
              <div className={'h-full'}>
                <div
                  role={'list'}
                  className={
                    'grid grid-cols-1 gap-x-4 rounded-[15px] border border-[#E5E7EB]/60 bg-white p-4 pb-6 shadow-base'
                  }
                >
                  <div className={'grid grid-cols-3 space-x-2 rounded-t-[15px] rounded-bl-[15px] p-6'}>
                    <label className={'text-sm font-extrabold text-baseColor'}>Số tiền</label>
                    <label
                      className={classNames(
                        'col-span-2 text-end text-sm font-medium text-baseColor'
                        // get(infoData, 'amount') > 0 ? 'text-baseColor' : 'text-[#FC5555]'
                      )}
                    >
                      {get(infoData, 'amount', 0).toLocaleString('vi')} VNĐ
                    </label>
                  </div>
                  <div className={'grid grid-cols-3 space-x-2 rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] p-6'}>
                    <label className={'text-sm font-extrabold text-baseColor'}>Ghi chú</label>
                    <label className={'col-span-2 text-end text-sm font-medium text-baseColor'}>
                      {get(infoData, 'description', '-')}
                    </label>
                  </div>
                  <div className={'grid grid-cols-3 space-x-2 rounded-t-[15px] rounded-bl-[15px] p-6'}>
                    <label className={'text-sm font-extrabold text-baseColor'}>Thời gian</label>
                    <label className={'col-span-2 text-end text-sm font-medium text-baseColor'}>
                      {get(infoData, 'genDateStr', '-')}
                    </label>
                  </div>
                  <div className={'grid grid-cols-3 space-x-2 rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] p-6'}>
                    <label className={'text-sm font-extrabold text-baseColor'}>Trạng thái</label>
                    <label
                      className={classNames(
                        'col-span-2 text-end text-sm font-medium text-baseColor',
                        [0, 1, 2, 4, 5, 7].includes(get(infoData, 'status', 0)) && '!text-[#18AA1F]',
                        [3, 6].includes(get(infoData, 'status', 0)) && '!text-[#FC5555]'
                      )}
                      // style={{ color: get(infoData, 'statusColor', '#404040') }}
                    >
                      {get(infoData, 'statusStr', '-')}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <Scrollbars className={'h-full w-full'}>
            <div className={'mr-7'}>
              <div className={'mt-4 grid h-fit w-full grid-cols-1 gap-4'}>
                <div className={'rounded-t-[15px] rounded-bl-[15px]'}>
                  <div
                    className={
                      'flex h-[58px] w-full flex-row items-center space-x-2 rounded-t-[15px] border-b pl-6' +
                      ' border-[#EFEFEF] bg-white px-4 shadow-base'
                    }
                  >
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="6.43159" cy="6.7519" r="6.04878" fill="#F15836" />
                    </svg>
                    <span className={'w-full text-base font-bold text-[#404040]'}>Thông tin điều chỉnh hoa hồng</span>
                  </div>
                  <div
                    role={'list'}
                    className={'grid grid-cols-2 gap-x-4 rounded-bl-[15px] bg-white p-4 pb-6 shadow-base'}
                  >
                    <div className={'grid grid-cols-3 space-x-2 rounded-t-[15px] rounded-bl-[15px] p-6'}>
                      <label className={'text-sm font-extrabold text-baseColor'}>Số tiền</label>
                      <label
                        className={classNames(
                          'col-span-2 text-end text-sm font-medium text-baseColor'
                          // get(infoData, 'amount') > 0 ? 'text-baseColor' : 'text-[#FC5555]'
                        )}
                      >
                        {get(infoData, 'amount', 0).toLocaleString('vi')} VNĐ
                      </label>
                    </div>
                    <div className={'grid grid-cols-3 space-x-2 rounded-t-[15px] rounded-bl-[15px] p-6'}>
                      <label className={'text-sm font-extrabold text-baseColor'}>Ghi chú</label>
                      <label className={'col-span-2 text-end text-sm font-medium text-baseColor'}>
                        {get(infoData, 'description', '-')}
                      </label>
                    </div>
                    <div className={'grid grid-cols-3 space-x-2 rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] p-6'}>
                      <label className={'text-sm font-extrabold text-baseColor'}>Thời gian</label>
                      <label className={'col-span-2 text-end text-sm font-medium text-baseColor'}>
                        {get(infoData, 'genDateStr', '-')}
                      </label>
                    </div>
                    <div className={'grid grid-cols-3 space-x-2 rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] p-6'}>
                      <label className={'text-sm font-extrabold text-baseColor'}>Trạng thái</label>
                      <label
                        className={classNames(
                          'col-span-2 text-end text-sm font-medium text-baseColor',
                          [0, 1, 2, 4, 5, 7].includes(get(infoData, 'status', 0)) && '!text-[#18AA1F]',
                          [3, 6].includes(get(infoData, 'status', 0)) && '!text-[#FC5555]'
                        )}
                        // style={{ color: get(infoData, 'statusColor', '#404040') }}
                      >
                        {get(infoData, 'statusStr', '-')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        )}
      </div>
    </div>
  );
};
