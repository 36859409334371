import { sendPostRequest } from './index';
import { apiProduct } from './api/product';
import { QueryFunctionContext } from 'react-query';

export const searchSim = (params: any) => sendPostRequest(apiProduct.searchSim, params);

export const searchSimInfinite = ({ queryKey, pageParam }: QueryFunctionContext<[string, any], number>) => {
  return sendPostRequest(apiProduct.searchSim, { ...queryKey[1], page: pageParam || 1 });
};

export const getDetailSim = (params: any) => sendPostRequest(apiProduct.detailSim, params);

export const searchCategory = (params: any) =>
  sendPostRequest(apiProduct.searchSim, {
    groupMsisdn: [],
    preNumber: [],
    region: [-1],
    srcStr: [],
    status: [1],
    type: [0],
    page: 1,
    sizeOfPage: 10,
  });

export const sendLockSim = (params: any) => sendPostRequest(apiProduct.lockSim, params);
