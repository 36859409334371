import { format, startOfToday } from 'date-fns';
import { useMemo, useRef, useState } from 'react';
import { UseCheckOutsideElement } from '../../../hooks/useCheckOutsideElement';
import { PopupSelectDay } from './PopupSelectDay';

const MONTH_FORMAT = 'MM/yyyy';

export const CalendarMobi = ({
  placeholder,
  selectedDay,
  onChange,
  otherDay,
  backgroundColor,
  setTodayToDate,
}: any) => {
  const today = startOfToday();
  const [currentMonth, setCurrentMonth] = useState<string>(format(today, MONTH_FORMAT));
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const calendarPopupRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  UseCheckOutsideElement([calendarPopupRef, inputRef], () => setIsOpen(false));

  const getValueInput = useMemo(() => {
    if (!selectedDay) return '';
    if (!otherDay) return format(selectedDay, 'dd/MM/yyyy');
    return `${format(selectedDay, 'dd/MM/yyyy')} - ${format(otherDay, 'dd/MM/yyyy')}`;
  }, [selectedDay, otherDay]);

  return (
    <div className={'relative w-full'}>
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 flex items-center !fill-[#404040] pl-[calc(100%_-_30px)]">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.33301 7.33268L14.6663 7.33268V13.3327C14.6663 13.5095 14.5961 13.6791 14.4711 13.8041C14.3461 13.9291 14.1765 13.9993 13.9997 13.9993L1.99967 13.9993C1.82286 13.9993 1.65329 13.9291 1.52827 13.8041C1.40325 13.6791 1.33301 13.5095 1.33301 13.3327L1.33301 7.33268ZM11.333 1.99935L13.9997 1.99935C14.1765 1.99935 14.3461 2.06959 14.4711 2.19461C14.5961 2.31964 14.6663 2.4892 14.6663 2.66602V5.99935L1.33301 5.99935L1.33301 2.66602C1.33301 2.4892 1.40325 2.31964 1.52827 2.19461C1.65329 2.06959 1.82286 1.99935 1.99967 1.99935L4.66634 1.99935V0.666016L5.99967 0.666016V1.99935L9.99967 1.99935V0.666016L11.333 0.666016V1.99935Z"
              fill="#A9A9A9"
            />
          </svg>
        </div>
        <input
          ref={inputRef}
          name="start"
          type="text"
          className={
            'datepicker-input text-normal block w-full rounded-t-[8px] rounded-bl-[8px] border text-sm' +
            ' border-[#E5E7EB] bg-white py-2 pl-2 pr-10 placeholder:text-[#A9A9A9] focus:outline-none'
          }
          placeholder={placeholder}
          autoComplete={'off'}
          value={getValueInput}
          onChange={() => {}}
          readOnly
          onFocus={() => setIsOpen(true)}
        />
        {isOpen && (
          <PopupSelectDay
            calendarPopupRef={calendarPopupRef}
            selectedDay={selectedDay}
            otherDay={otherDay}
            onChange={onChange}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            currentMonth={currentMonth}
            setTodayToDate={setTodayToDate}
            setCurrentMonth={setCurrentMonth}
            today={today}
          />
        )}
      </div>
    </div>
  );
};
