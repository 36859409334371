import { useEffect } from 'react';
import { InputCustom } from 'components/common/InputCustom';
import { ButtonCustom } from 'components/common/ButtonCustom';
import { createContext, useCallback, useState } from 'react';
import { AuthedPhoneNumber } from 'components/authentication-phone-number/AuthedPhoneNumber';
import { useForm, useWatch } from 'react-hook-form';
import { classNames, generateRecaptcha, onKeyDownNumber } from '../../utils/utils';
import { authenticationFirebase } from 'configs/firebase';
import { signInWithPhoneNumber } from 'firebase/auth';
import { PATH_PAGE } from '../../constant';
import useCheckDeviceMobi from '../../hooks/useCheckDeviceMobi';
import { RegisterMobile } from './RegisterMobile';
import { useMutation } from 'react-query';
import { postCheckMsisdnIsExist } from 'services/account';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { QRCode } from 'assets/images';
import { GooglePlay } from 'assets/images';
import { AppStore } from 'assets/images';
import { getOPT, getOPTParam } from 'services/otp';
import AppDownloadNotice from 'components/common/AppDownloadNotice';

type RegisterContextType = {
  activeStepIndex: number;
  setActiveStepIndex: (step: number) => void;
  phoneNumber?: number;
  confirmationResult?: any;
  setConfirmationResult?: any;
  setIsShowStep?: any;
  onBack: () => void;
  messageError?: string;
  setMessageError?: any;
};

export const RegisterContext = createContext<RegisterContextType>({} as RegisterContextType);
export const Register = () => {
  const isMobile = useCheckDeviceMobi();
  const navigate = useNavigate();
  const [isShowStep, setIsShowStep] = useState<boolean>(false);
  const [confirmationResult, setConfirmationResult] = useState<any>();
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [phoneNumber, setPhoneNumber] = useState<number>();
  const [messageError, setMessageError] = useState<string>('');
  const [codeStatusCheckExist, setCodeStatusCheckExist] = useState<number>(0);
  const [otpError, setOtpError] = useState("");
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    setFocus,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { phoneNumber: undefined },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const watchAllFields = useWatch({ control });
  let isLoading: boolean = false;

  useEffect(() => {
    setOtpError("");
  }, [watchAllFields])

  const sendOTP = useMutation((param: getOPTParam) => getOPT(param), {
    onSuccess: (response) => {
      if (response.data?.code === 1) {
        isLoading = false;
        console.log('isLoading1', isLoading)
        // setConfirmationResult(result);
        setIsShowStep(true);
        return;
      } else if (response.data?.code && response.data?.message) {
        setOtpError(response.data?.message);
      }

    },
    onError: (error: any) => {
      toast.error('Hệ thống đang bận, vui lòng thử lại sau');
      isLoading = false;
      console.log('isLoading2', isLoading)
    },
  })

  const checkMsisdnExit = useMutation((_phoneNumber: string) => postCheckMsisdnIsExist(_phoneNumber), {
    onSuccess: (res) => {
      isLoading = true;
      if (!res?.data) {
        toast.error('Hệ thống đang bận, vui lòng thử lại sau');
        isLoading = false;
        return;
      }
      if (res.data.code === 170) {
        setCodeStatusCheckExist(170);
        isLoading = false;
        return;
      }
      if (res.data.code === 2) {
        setCodeStatusCheckExist(2);
        isLoading = false;
        return;
      }
      console.log('isLoading', isLoading)
      // const verify = generateRecaptcha();
      // signInWithPhoneNumber(authenticationFirebase, `+84${+res.data?.data}`, verify)
      //   .then((result: any) => {
      //     isLoading = false;
      //     console.log('isLoading1', isLoading)
      //     setConfirmationResult(result);
      //     setIsShowStep(true);
      //   })
      //   .catch((error) => {
      //     toast.error('Hệ thống đang bận, vui lòng thử lại sau');
      //     isLoading = false;
      //     console.log('isLoading2', isLoading)
      //     return;
      //   });
      sendOTP.mutate({
        msisdn: res?.data?.data,
        type: 2
      });
    },
  });

  const onSubmit = (data: any) => {
    isLoading = true;
    console.log('isLoading Start', isLoading)
    setValue('phoneNumber', +data.phoneNumber as any);
    setPhoneNumber(+data.phoneNumber);
    checkMsisdnExit.mutate('0' + Number(data.phoneNumber));
  };

  const onBack = useCallback(() => {
    setIsShowStep(false);
    setActiveStepIndex(0);
    setPhoneNumber(undefined);
    setValue('phoneNumber', undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBody = () => {
    if (isMobile) {
      return (
        <RegisterMobile
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          errors={errors}
          register={register}
          control={control}
          watch={watch}
          setValue={setValue}
          setFocus={setFocus}
          otpError={otpError}
        />
      );
    }
    return (
      <div className={'flex w-full flex-col sm:flex-row sm:space-x-4 sm:px-10 items-center'}>
        <div className={'flex w-full flex-col justify-start sm:w-6/12'}>
          <h1 className={' text-[42px] font-extrabold uppercase leading-[60px] text-white'}>
            Chính thức ra mắt app <span className={'text-[#FDFFAE]'}> Shop360</span>
          </h1>
          <h2 className={'mt-4 text-[20px] font-medium leading-[26px] text-white'}>
            Nâng tầm trải nghiệm - Đột phá kinh doanh
          </h2>
          <div className="flex gap-4 mt-8">
            <img src={QRCode} alt="QRCode" className='w-1/4 rounded-lg' />
            <img src={AppStore} alt="AppStore" className="w-1/3 cursor-pointer" onClick={() => window.open('https://apps.apple.com/us/app/shop360/id6449772995', "_blank")} />
            <img src={GooglePlay} alt="GooglePlay" className="w-1/3 cursor-pointer" onClick={() => window.open('https://play.google.com/store/apps/details?id=vn.osp.shop360', "_blank")} />
          </div>
        </div>
        <div className={'flex w-full justify-end sm:w-6/12 h-[280px]'}>
          <div
            className={
              'flex min-h-[280px] w-[480px] flex-col rounded-t-[15px] rounded-bl-[15px] bg-[#F8F8F8] p-[31px] shadow-base'
            }
          >
            <h1 className={'text-[35px] font-extrabold leading-[50px] text-[#5B5B5B]'}>Đăng ký</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={'flex flex-col'}>
                <div className="relative mt-2 w-full">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-3 ">
                    <p className={'text-sm text-[#A9A9A9]'}>+84</p>
                  </div>
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-12 ">
                    <div className={'leading-0 mt-[-4px] text-xl font-light text-[#A9A9A9]'}>|</div>
                  </div>
                  <InputCustom
                    placeholder={'Số điện thoại'}
                    type={'tel'}
                    autoFocus
                    maxLength={11}
                    className={classNames('w-full pl-14 pr-2.5', errors.phoneNumber ? 'border border-[#FC5555]' : '')}
                    register={register}
                    label={'phoneNumber'}
                    control={control}
                    required={{
                      required: 'Vui lòng nhập số điện thoại',
                      validate: (value: any) => {
                        if (!['9', '8', '7', '5', '3'].includes((+value + '').at(0) + '')) {
                          return 'Số điện thoại không đúng định dạng!';
                        }
                        if ((Number(value) + '').length < 9) {
                          return 'Số điện thoại không đúng định dạng!';
                        }
                        if ((Number(value) + '').length > 10) {
                          return 'Số điện thoại không đúng định dạng!';
                        }
                        setValue('phoneNumber', value);
                      },
                    }}
                    onKeyDown={onKeyDownNumber}
                  />
                  {!!watch('phoneNumber') && (
                    <div
                      className="absolute inset-y-0 right-2 flex cursor-pointer items-center justify-center"
                      onClick={() => {
                        setValue('phoneNumber', undefined);
                        setFocus('phoneNumber', { shouldSelect: true });
                      }}
                    >
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.9999 21.6004C14.546 21.6004 16.9878 20.589 18.7881 18.7886C20.5885 16.9883 21.5999 14.5465 21.5999 12.0004C21.5999 9.45431 20.5885 7.01252 18.7881 5.21217C16.9878 3.41182 14.546 2.40039 11.9999 2.40039C9.45382 2.40039 7.01203 3.41182 5.21168 5.21217C3.41133 7.01252 2.3999 9.45431 2.3999 12.0004C2.3999 14.5465 3.41133 16.9883 5.21168 18.7886C7.01203 20.589 9.45382 21.6004 11.9999 21.6004ZM10.4483 8.75199C10.222 8.5334 9.91886 8.41245 9.60422 8.41518C9.28959 8.41792 8.98861 8.54412 8.76612 8.76661C8.54363 8.9891 8.41743 9.29007 8.41469 9.60471C8.41196 9.91935 8.53291 10.2225 8.7515 10.4488L10.3031 12.0004L8.7515 13.552C8.63689 13.6627 8.54547 13.7951 8.48258 13.9415C8.41969 14.0879 8.38659 14.2454 8.3852 14.4047C8.38382 14.564 8.41418 14.7221 8.47452 14.8695C8.53485 15.017 8.62396 15.151 8.73663 15.2637C8.8493 15.3763 8.98328 15.4654 9.13076 15.5258C9.27823 15.5861 9.43625 15.6165 9.59558 15.6151C9.75492 15.6137 9.91238 15.5806 10.0588 15.5177C10.2052 15.4548 10.3376 15.3634 10.4483 15.2488L11.9999 13.6972L13.5515 15.2488C13.7778 15.4674 14.0809 15.5883 14.3956 15.5856C14.7102 15.5829 15.0112 15.4567 15.2337 15.2342C15.4562 15.0117 15.5824 14.7107 15.5851 14.3961C15.5878 14.0814 15.4669 13.7783 15.2483 13.552L13.6967 12.0004L15.2483 10.4488C15.4669 10.2225 15.5878 9.91935 15.5851 9.60471C15.5824 9.29007 15.4562 8.9891 15.2337 8.76661C15.0112 8.54412 14.7102 8.41792 14.3956 8.41518C14.0809 8.41245 13.7778 8.5334 13.5515 8.75199L11.9999 10.3036L10.4483 8.75199Z"
                          fill="#A1A1AA"
                        />
                      </svg>
                    </div>
                  )}
                </div>
                {errors.phoneNumber && (
                  <span className={'mt-2 text-sm font-normal leading-[18px] text-[#F15836]'}>
                    {errors.phoneNumber.message}
                  </span>
                )}
                {otpError && (
                  <span className={'mt-2 text-sm font-normal leading-[18px] text-[#F15836]'}>
                    {otpError}
                  </span>
                )}
                <div id={'recaptcha-container'}></div>
                {/* <ButtonCustom label={'Tiếp tục'} disabled={isLoading === true} className={classNames('mt-4 w-full uppercase')} type={'submit'} /> */}
                <ButtonCustom
                  onClick={() => setShowDialog(true)}
                  label={'Tiếp tục'}
                  disabled={isLoading === true}
                  className={classNames('mt-4 w-full uppercase')}
                  type={'button'} />

              </div>
            </form>
            <div className={'mt-[40px] flex w-full flex-col items-center'}>
              <p className={'text-sm font-medium text-[#818181]'}>
                Bạn đã có tài khoản?{' '}
                <a className={'font-extrabold uppercase text-[#F15836]'} href={PATH_PAGE.LOGIN}>
                  Đăng nhập
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    if (codeStatusCheckExist === 170) {
      if (isMobile) {
        return (
          <div className={'flex h-full w-full flex-row items-center justify-center pt-12'}>
            <div className={'flex h-[400px] w-[450px] flex-col items-center justify-start space-y-6 text-center'}>
              <h1
                className={classNames(
                  'flex flex-row items-center justify-center',
                  'text-[22px] font-extrabold leading-[26px] text-[#3F3F46]'
                )}
              >
                <svg
                  onClick={() => {
                    setCodeStatusCheckExist(0);
                    setPhoneNumber(undefined);
                    reset({ phoneNumber: undefined });
                  }}
                  className={'mr-2'}
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.18492 10.5038L12.1662 16.4863C12.4082 16.7283 12.7364 16.8643 13.0787 16.8643C13.4209 16.8643 13.7492 16.7283 13.9912 16.4863C14.2332 16.2443 14.3691 15.916 14.3691 15.5738C14.3691 15.2315 14.2332 14.9033 13.9912 14.6613L8.90992 9.58254L13.9912 4.50504C14.233 4.26303 14.3688 3.93486 14.3687 3.59272C14.3686 3.42331 14.3352 3.25557 14.2703 3.09908C14.2054 2.94259 14.1104 2.80041 13.9905 2.68066C13.8707 2.56091 13.7285 2.46594 13.5719 2.40116C13.4154 2.33639 13.2476 2.30308 13.0782 2.30313C12.7361 2.30325 12.408 2.43928 12.1662 2.68129L6.18492 8.66254C5.94089 8.90677 5.80381 9.23791 5.80381 9.58316C5.80381 9.92842 5.94089 10.2596 6.18492 10.5038Z"
                    fill="#6A6A6A"
                  />
                </svg>
                {'Tài khoản đã bị khoá'}
              </h1>
              <>
                <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_1727_32768)">
                    <path
                      d="M78.3932 60.0812L50.085 8.00075C47.9265 4.43481 44.1731 2.27637 39.9972 2.27637C35.8212 2.27637 32.0678 4.43481 29.9093 8.00075C29.9093 8.04732 29.8628 8.04732 29.8628 8.0945L1.64833 59.9874C-0.509801 63.6941 -0.556676 68.1046 1.55458 71.8113C3.66583 75.5178 7.46615 77.7232 11.736 77.7232H68.2581C72.528 77.7232 76.3285 75.5179 78.4396 71.8113C80.5511 68.1045 80.5043 63.694 78.3932 60.0812Z"
                      fill="#FEA832"
                    />
                    <path
                      d="M11.7355 73.031C9.13751 73.031 6.91532 71.7389 5.63234 69.4893C4.34937 67.2373 4.37219 64.6324 5.70563 62.346L33.7065 10.8491L34.5769 9.95795L34.5815 9.50655C35.9285 7.90045 37.8805 6.96826 39.9974 6.96826C42.4899 6.96826 44.7627 8.26264 46.0732 10.43L74.2669 62.314C75.6231 64.6371 75.6276 67.2695 74.3631 69.4893C73.0801 71.7392 70.8579 73.031 68.26 73.031H11.7355Z"
                      fill="#FEDB41"
                    />
                    <path
                      d="M78.4406 71.8109C76.3294 75.5173 72.529 77.7228 68.2592 77.7228H39.998V2.27637C44.1738 2.27637 47.9274 4.43481 50.0859 8.00075L78.3941 60.081C80.5052 63.6938 80.552 68.1043 78.4406 71.8109Z"
                      fill="#FE9923"
                    />
                    <path
                      d="M68.2585 73.0309C70.8565 73.0309 73.0787 71.7387 74.3616 69.4891C75.6263 67.2693 75.6218 64.637 74.2654 62.3138L46.0719 10.4295C44.7614 8.26231 42.4888 6.96777 39.9961 6.96777V73.0307H68.2585V73.0309Z"
                      fill="#FEDB41"
                    />
                    <path
                      d="M44.6885 26.1118V44.8797C44.6885 47.4603 42.577 49.5715 39.9966 49.5715C37.4161 49.5715 35.3047 47.4601 35.3047 44.8797V26.1118C35.3047 23.5312 37.4161 21.4199 39.9966 21.4199C42.577 21.4199 44.6885 23.5312 44.6885 26.1118Z"
                      fill="#495A79"
                    />
                    <path
                      d="M44.6899 26.1118V44.8797C44.6899 47.4603 42.5785 49.5715 39.998 49.5715V21.4199C42.5784 21.4199 44.6899 23.5312 44.6899 26.1118Z"
                      fill="#42516D"
                    />
                    <path
                      d="M39.997 54.2632C36.1024 54.2632 32.959 57.4066 32.959 61.3012C32.959 65.1954 36.1024 68.3391 39.997 68.3391C43.8915 68.3391 47.0349 65.1954 47.0349 61.3012C47.0349 57.4068 43.8913 54.2632 39.997 54.2632Z"
                      fill="#495A79"
                    />
                    <path
                      d="M47.036 61.301C47.036 65.1952 43.8926 68.339 39.998 68.339V54.2632C43.8924 54.263 47.036 57.4066 47.036 61.301Z"
                      fill="#42516D"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1727_32768">
                      <rect width="80" height="80" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                <h2 className={'px-6 text-center text-[14px] font-medium leading-[20px] text-[#3F3F46]'}>
                  Tài khoản <span className="font-bold">0{phoneNumber}</span> của bạn đã bị khoá, vui lòng liên hệ
                  hotline 0896.686.686 hoặc email shop360.cskh@osp.vn để được hỗ trợ!
                </h2>
              </>
            </div>
          </div>
        );
      }
      return (
        <div className={'flex h-full w-full flex-row items-center justify-center'}>
          <div className={'flex h-[400px] w-[450px] flex-col items-center justify-center space-y-6 text-center'}>
            <h1
              className={
                'flex flex-row items-center justify-center text-[35px] font-extrabold leading-[50px] text-white'
              }
            >
              <svg
                className={'mr-4 cursor-pointer'}
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  setCodeStatusCheckExist(0);
                  setPhoneNumber(undefined);
                  reset({ phoneNumber: undefined });
                }}
              >
                <path
                  d="M13.8823 24.6477C13.601 24.929 13.2195 25.0871 12.8217 25.0871C12.4238 25.0871 12.0423 24.929 11.761 24.6477L1.15441 14.0411C0.873106 13.7598 0.715071 13.3783 0.715071 12.9805C0.715071 12.5826 0.873106 12.2011 1.15441 11.9198L11.761 1.31321C12.0423 1.0319 12.4238 0.873867 12.8217 0.873867C13.2195 0.873866 13.601 1.0319 13.8823 1.31321C14.1636 1.59451 14.3217 1.97604 14.3217 2.37387C14.3217 2.77169 14.1636 3.15322 13.8823 3.43453L4.33639 12.9805L13.8823 22.5264C14.1636 22.8077 14.3217 23.1892 14.3217 23.5871C14.3217 23.9849 14.1636 24.3664 13.8823 24.6477Z"
                  fill="white"
                />
                <path
                  d="M24.4907 14.0416C24.3516 14.1811 24.1863 14.2917 24.0042 14.367C23.8222 14.4424 23.6271 14.481 23.4301 14.4807L2.21688 14.4807C1.82147 14.4771 1.44347 14.3176 1.16513 14.0367C0.886785 13.7558 0.730626 13.3764 0.730627 12.981C0.730627 12.5855 0.886786 12.2061 1.16513 11.9252C1.44347 11.6444 1.82147 11.4848 2.21688 11.4812L23.4301 11.4812C23.7267 11.4812 24.0167 11.5692 24.2633 11.734C24.5099 11.8988 24.7021 12.1331 24.8157 12.4071C24.9292 12.6812 24.9589 12.9827 24.9011 13.2736C24.8432 13.5646 24.7004 13.8318 24.4907 14.0416Z"
                  fill="white"
                />
              </svg>
              Tài khoản đã bị khóa
            </h1>
            <h2 className={'text-center text-[16px] font-medium leading-[26px] text-white'}>
              Tài khoản <span className="font-bold">0{phoneNumber}</span> của bạn đã bị khoá, vui lòng liên hệ hotline
              0896.686.686 hoặc email shop360.cskh@osp.vn để được hỗ trợ!
            </h2>
          </div>
        </div>
      );
    }
    if (codeStatusCheckExist === 2) {
      if (isMobile) {
        return (
          <div className={'flex h-full w-full flex-col items-center justify-center space-y-4'}>
            <h1
              className={classNames(
                'flex flex-row items-center justify-center',
                'text-[22px] font-extrabold leading-[26px] text-[#3F3F46]'
              )}
            >
              <svg
                onClick={() => navigate(PATH_PAGE.LOGIN)}
                className={'mr-2'}
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.18492 10.5038L12.1662 16.4863C12.4082 16.7283 12.7364 16.8643 13.0787 16.8643C13.4209 16.8643 13.7492 16.7283 13.9912 16.4863C14.2332 16.2443 14.3691 15.916 14.3691 15.5738C14.3691 15.2315 14.2332 14.9033 13.9912 14.6613L8.90992 9.58254L13.9912 4.50504C14.233 4.26303 14.3688 3.93486 14.3687 3.59272C14.3686 3.42331 14.3352 3.25557 14.2703 3.09908C14.2054 2.94259 14.1104 2.80041 13.9905 2.68066C13.8707 2.56091 13.7285 2.46594 13.5719 2.40116C13.4154 2.33639 13.2476 2.30308 13.0782 2.30313C12.7361 2.30325 12.408 2.43928 12.1662 2.68129L6.18492 8.66254C5.94089 8.90677 5.80381 9.23791 5.80381 9.58316C5.80381 9.92842 5.94089 10.2596 6.18492 10.5038Z"
                  fill="#6A6A6A"
                />
              </svg>
              {'Tài khoản đã tồn tại'}
            </h1>
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1727_32768)">
                <path
                  d="M78.3932 60.0812L50.085 8.00075C47.9265 4.43481 44.1731 2.27637 39.9972 2.27637C35.8212 2.27637 32.0678 4.43481 29.9093 8.00075C29.9093 8.04732 29.8628 8.04732 29.8628 8.0945L1.64833 59.9874C-0.509801 63.6941 -0.556676 68.1046 1.55458 71.8113C3.66583 75.5178 7.46615 77.7232 11.736 77.7232H68.2581C72.528 77.7232 76.3285 75.5179 78.4396 71.8113C80.5511 68.1045 80.5043 63.694 78.3932 60.0812Z"
                  fill="#FEA832"
                />
                <path
                  d="M11.7355 73.031C9.13751 73.031 6.91532 71.7389 5.63234 69.4893C4.34937 67.2373 4.37219 64.6324 5.70563 62.346L33.7065 10.8491L34.5769 9.95795L34.5815 9.50655C35.9285 7.90045 37.8805 6.96826 39.9974 6.96826C42.4899 6.96826 44.7627 8.26264 46.0732 10.43L74.2669 62.314C75.6231 64.6371 75.6276 67.2695 74.3631 69.4893C73.0801 71.7392 70.8579 73.031 68.26 73.031H11.7355Z"
                  fill="#FEDB41"
                />
                <path
                  d="M78.4406 71.8109C76.3294 75.5173 72.529 77.7228 68.2592 77.7228H39.998V2.27637C44.1738 2.27637 47.9274 4.43481 50.0859 8.00075L78.3941 60.081C80.5052 63.6938 80.552 68.1043 78.4406 71.8109Z"
                  fill="#FE9923"
                />
                <path
                  d="M68.2585 73.0309C70.8565 73.0309 73.0787 71.7387 74.3616 69.4891C75.6263 67.2693 75.6218 64.637 74.2654 62.3138L46.0719 10.4295C44.7614 8.26231 42.4888 6.96777 39.9961 6.96777V73.0307H68.2585V73.0309Z"
                  fill="#FEDB41"
                />
                <path
                  d="M44.6885 26.1118V44.8797C44.6885 47.4603 42.577 49.5715 39.9966 49.5715C37.4161 49.5715 35.3047 47.4601 35.3047 44.8797V26.1118C35.3047 23.5312 37.4161 21.4199 39.9966 21.4199C42.577 21.4199 44.6885 23.5312 44.6885 26.1118Z"
                  fill="#495A79"
                />
                <path
                  d="M44.6899 26.1118V44.8797C44.6899 47.4603 42.5785 49.5715 39.998 49.5715V21.4199C42.5784 21.4199 44.6899 23.5312 44.6899 26.1118Z"
                  fill="#42516D"
                />
                <path
                  d="M39.997 54.2632C36.1024 54.2632 32.959 57.4066 32.959 61.3012C32.959 65.1954 36.1024 68.3391 39.997 68.3391C43.8915 68.3391 47.0349 65.1954 47.0349 61.3012C47.0349 57.4068 43.8913 54.2632 39.997 54.2632Z"
                  fill="#495A79"
                />
                <path
                  d="M47.036 61.301C47.036 65.1952 43.8926 68.339 39.998 68.339V54.2632C43.8924 54.263 47.036 57.4066 47.036 61.301Z"
                  fill="#42516D"
                />
              </g>
              <defs>
                <clipPath id="clip0_1727_32768">
                  <rect width="80" height="80" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <h2 className={'text-center text-[14px] font-medium leading-[20px] text-[#3F3F46]'}>
              {`Tài khoản 0${phoneNumber} đã tồn tại trên nền tảng Shop360, vui lòng chuyển qua trang `}
              <a className={'font-bold text-[#F15836]'} href={PATH_PAGE.LOGIN}>
                Đăng nhập
              </a>
            </h2>
          </div>
        );
      }
      return (
        <div className={'flex h-full w-full flex-row items-center justify-center'}>
          <div className={'flex h-[400px] w-[450px] flex-col items-center justify-center space-y-6 text-center'}>
            <h1
              className={
                'flex flex-row items-center justify-center text-[35px] font-extrabold leading-[50px] text-white'
              }
            >
              <svg
                className={'mr-4 cursor-pointer'}
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => navigate(PATH_PAGE.LOGIN)}
              >
                <path
                  d="M13.8823 24.6477C13.601 24.929 13.2195 25.0871 12.8217 25.0871C12.4238 25.0871 12.0423 24.929 11.761 24.6477L1.15441 14.0411C0.873106 13.7598 0.715071 13.3783 0.715071 12.9805C0.715071 12.5826 0.873106 12.2011 1.15441 11.9198L11.761 1.31321C12.0423 1.0319 12.4238 0.873867 12.8217 0.873867C13.2195 0.873866 13.601 1.0319 13.8823 1.31321C14.1636 1.59451 14.3217 1.97604 14.3217 2.37387C14.3217 2.77169 14.1636 3.15322 13.8823 3.43453L4.33639 12.9805L13.8823 22.5264C14.1636 22.8077 14.3217 23.1892 14.3217 23.5871C14.3217 23.9849 14.1636 24.3664 13.8823 24.6477Z"
                  fill="white"
                />
                <path
                  d="M24.4907 14.0416C24.3516 14.1811 24.1863 14.2917 24.0042 14.367C23.8222 14.4424 23.6271 14.481 23.4301 14.4807L2.21688 14.4807C1.82147 14.4771 1.44347 14.3176 1.16513 14.0367C0.886785 13.7558 0.730626 13.3764 0.730627 12.981C0.730627 12.5855 0.886786 12.2061 1.16513 11.9252C1.44347 11.6444 1.82147 11.4848 2.21688 11.4812L23.4301 11.4812C23.7267 11.4812 24.0167 11.5692 24.2633 11.734C24.5099 11.8988 24.7021 12.1331 24.8157 12.4071C24.9292 12.6812 24.9589 12.9827 24.9011 13.2736C24.8432 13.5646 24.7004 13.8318 24.4907 14.0416Z"
                  fill="white"
                />
              </svg>
              Tài khoản đã tồn tại
            </h1>
            <h2 className={'text-center text-[16px] font-medium leading-[26px] text-white'}>
              {`Tài khoản 0${phoneNumber} đã tồn tại trên nền tảng Shop360, vui lòng chuyển qua trang Đăng nhập `}
            </h2>
            <ButtonCustom
              label={'Đăng nhập'}
              className={
                'mt-[25px] w-[150px] !rounded-t-[15px] !rounded-bl-[15px] !bg-gradient-to-r !from-[#FFB01F]' +
                ' !to-[#FFCF45]'
              }
              type={'button'}
              onClick={() => navigate(PATH_PAGE.LOGIN)}
            />
          </div>
        </div>
      );
    }
    if (isShowStep) {
      return (
        <RegisterContext.Provider
          value={{
            activeStepIndex,
            setActiveStepIndex,
            setIsShowStep,
            phoneNumber,
            confirmationResult,
            setConfirmationResult,
            onBack,
            setMessageError,
            messageError,
          }}
        >
          <AuthedPhoneNumber />
        </RegisterContext.Provider>
      );
    }
    return <>
      {getBody()}
      <AppDownloadNotice showDialog={showDialog} setShowDialog={setShowDialog} />
    </>;
  };

  return <div className={'mb-1.5 py-6'}>{renderBody()}</div>;
};
