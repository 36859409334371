import { classNames } from 'utils/utils';

export const TotalCardInfo = ({ iconUrl, label, value, data }: any) => {
  return (
    <div
      className={'flex h-[148px] w-full flex-col space-y-3 rounded-t-[15px] rounded-bl-[15px] bg-white p-4 shadow-base'}
    >
      <div className={'flex flex-row items-center justify-start space-x-2'}>
        <img src={iconUrl} alt={label} className={'h-[35px] w-[35px]'} />
        <span className={'text-sm font-medium text-[#818181]'}>{label}</span>
      </div>
      <div className={'flex flex-row justify-between'}>
        <span className={' text-[22px] font-bold text-[#404040]'}>
          {data ? (+data).toLocaleString('vi') : 0}
          <span className={' text-sm font-extrabold text-[#404040]'}> VNĐ</span>
        </span>
      </div>
      <div className={'flex h-fit w-full flex-row justify-start'}>
        <div
          className={classNames(
            'flex h-[20px] min-w-[54px] flex-row items-center justify-center space-x-1 rounded-t-[12px] rounded-bl-[12px] border bg-[#E3EAFF]',
            value < 0 ? 'border-[#FF3F3F]' : 'border-[#1B50FF]'
          )}
        >
          {value < 0 ? (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.66667 11.3327H14M14 11.3327V5.99935M14 11.3327L8.66667 5.99935L6 8.66602L2 4.66602"
                stroke="url(#paint0_linear_3854_24276)"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_3854_24276"
                  x1="8"
                  y1="4.66602"
                  x2="8"
                  y2="11.3327"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FF3F3F" />
                  <stop offset="1" stop-color="#E01D1D" />
                </linearGradient>
              </defs>
            </svg>
          ) : (
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9.27214 5.52148H14.6055M14.6055 5.52148V10.8548M14.6055 5.52148L9.27214 10.8548L6.60547 8.18815L2.60547 12.1882"
                stroke="#1B50FF"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          <span className={classNames('text-[10px] font-bold', value < 0 ? 'text-[#FF3F3F]' : 'text-[#1B50FF]')}>
            {value !== 0 ? `${Math.abs(value)}%` : '---'}
          </span>
        </div>
      </div>
    </div>
  );
};
