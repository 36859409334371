import { ROUTER_PATH } from '../../constant';
import { NavLink } from 'react-router-dom';
import { IconSidebar } from '../../assets/images/sidebar';
import { LogoHorizontal } from 'assets/images';

export const SideBar = () => {
  return (
    <aside
      className={
        'invisible fixed top-0 left-0 z-50 flex min-h-[100vh] flex-col justify-between p-[24px] pl-[24px] pb-[24px] sm:visible sm:w-[280px]'
      }
    >
      <ul className={'z-50 text-white'}>
        <div className={'ml-[22px]'}>
          <a href={'/dashboard'}>
            <img className="h-[50px] w-full object-contain" src={LogoHorizontal} alt="Logo" />
          </a>
        </div>
        {ROUTER_PATH.map((_item: any) => {
          return (
            <li key={_item.id}>
              <span className={`flex h-[55px] items-center px-[20px]`}>
                <span className={'text-sm font-extrabold text-white'}>{_item.name}</span>
              </span>
              {_item.children &&
                _item.children.map((_child: any) => {
                  if (!_child.path) {
                    return (
                      <span
                        key={_child.id}
                        className={`flex h-[55px] items-center px-[10px] hover:rounded-tr-[15px] hover:rounded-tl-[15px] hover:rounded-bl-[15px] hover:bg-[#FFFEFE]/40`}
                      >
                        <img className={'mr-[12px]'} src={_child.icon} alt={`icon-${_child.name}`} />
                        <span>{_child.name}</span>
                      </span>
                    );
                  }
                  return (
                    <NavLink
                      key={_child.id}
                      to={_child.path}
                      className={({ isActive }) =>
                        `flex h-[55px] items-center px-[10px] hover:rounded-tr-[15px] hover:rounded-tl-[15px] hover:rounded-bl-[15px] hover:bg-[#FFFEFE]/40 ${
                          isActive
                            ? 'disabled rounded-tr-[15px] rounded-tl-[15px] rounded-bl-[15px] bg-[#FFFEFE]/40'
                            : ''
                        }`
                      }
                    >
                      <img className={'mr-[12px]'} src={_child.icon} alt={`icon-${_child.name}`} />
                      <span>{_child.name}</span>
                    </NavLink>
                  );
                })}
            </li>
          );
        })}
      </ul>
      <div className={'h-fit w-full px-7'}>
        <div
          className={
            'relative flex h-[186px] w-[194px] items-end justify-center rounded-[23px] bg-[#FE9D67] p-4 bg-blend-soft-light'
          }
        >
          <img src={IconSidebar} alt={'icon-sidebar'} className={'absolute left-8 -top-8'} />
          <div
            className={
              'h-[45px] w-[157px] items-center rounded-t-[15px] rounded-bl-[15px] bg-gradient-to-r from-[#FFB01F] to-[#FFCF45] p-2.5 text-center text-sm font-black uppercase text-white shadow-[0px_0px_20px_rgba(254,118,59,1)]'
            }
          >
            <a className={'text-[12px]'} href={'/plan'}>
              Tìm hiểu Shop360
            </a>
          </div>
        </div>
      </div>
    </aside>
  );
};
