import { Listbox, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { SelectOptionEntity } from '../../../entity/SearchEntity';
import { classNames } from '../../../utils/utils';

interface ISelectForm {
  label?: string;
  options: Array<SelectOptionEntity>;
  classIcon?: string;
  className?: string;
  onChange?: (value: any) => void;
  value?: any;
  required?: boolean;
  placeholder?: string;
}

export const SelectForm = ({
  label,
  className,
  options,
  classIcon,
  onChange,
  value,
  required,
  placeholder,
}: ISelectForm) => {
  return (
    <div className={'flex flex-col space-y-2'}>
      {label && (
        <label className={'text-sm font-bold text-[#5B5B5B]'}>
          {label}
          {required && <label className={'ml-1 text-[15px] font-semibold leading-[20px] text-[#FC5555]'}>*</label>}
        </label>
      )}
      <Listbox value={value} onChange={(v) => onChange && onChange(v)}>
        <div className={'relative'}>
          <Listbox.Button
            className={classNames(
              className,
              'flex h-[38px] w-full flex-row items-center justify-between rounded-t-[10px] rounded-bl-[10px] border border-[#EFEFEF] bg-white p-2 text-start text-sm font-normal text-gray-700 shadow-base placeholder:text-gray-400'
            )}
          >
            <span className={!value?.name && !value?.value ? 'opacity-40' : ''}>
              {value?.name || placeholder || 'Tất cả'}
            </span>

            <svg
              width="10"
              height="10"
              className={classIcon ?? 'text-gray-500'}
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options
              className={'absolute z-40 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 shadow-2xl'}
            >
              {options.map((product) => (
                <Listbox.Option
                  key={product.id || product.value}
                  className={({ active, selected }) =>
                    `relative cursor-default select-none py-2 px-4 ${
                      active || selected ? 'bg-amber-100' : ''
                    } text-sm font-normal text-gray-700`
                  }
                  value={product}
                >
                  <span className={`block truncate`}>{product.name || placeholder || 'Tất cả'}</span>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};
