import { useCallback, useEffect, useState } from 'react';
import { isBefore } from 'date-fns';
import { CalendarMobi } from '../calendar/CalendarMobi';

interface ITimeForm {
  label?: string;
  startDay: Date | null;
  endDay: Date | null;
  setStartDay: (date: Date | null) => void;
  setEndDay: (date: Date | null) => void;
  backgroundColor?: boolean;
  placeholder?: string;
  isDisabledSelectToday?: boolean;
}

export const TimeFormMobi = ({
  label,
  startDay,
  endDay,
  setEndDay,
  setStartDay,
  backgroundColor,
  placeholder,
  isDisabledSelectToday,
}: ITimeForm) => {
  const [isSelectStartDay, setIsSelectStartDay] = useState<boolean>(false);

  const onChangeDate = useCallback((newDay: Date | null, otherDay: Date | null) => {
    setIsSelectStartDay((v) => !v);
    if (!newDay) {
      setStartDay(null);
      setEndDay(null);
      return;
    } else if (!otherDay) {
      setStartDay(newDay);
      setEndDay(newDay);
      return;
    }

    if (isBefore(newDay, otherDay)) {
      setStartDay(newDay);
      setEndDay(otherDay);
    } else {
      setStartDay(otherDay);
      setEndDay(newDay);
    }
  }, []);

  useEffect(() => {
    return () => {
      setIsSelectStartDay(false);
    };
  }, []);

  return (
    <div className={'flex flex-col space-y-2'}>
      {label && <label className={'text-sm font-bold text-[#5B5B5B]'}>{label}</label>}
      <div className="flex items-center">
        <CalendarMobi
          placeholder={placeholder || 'Từ ngày - Đến ngày'}
          selectedDay={startDay}
          onChange={(day: Date | null, isSelectToday?: boolean) =>
            onChangeDate(day, isSelectToday ? null : isSelectStartDay ? startDay : endDay)
          }
          otherDay={endDay}
          backgroundColor={backgroundColor}
          setTodayToDate={(date: Date) => onChangeDate(date, date)}
          isDisabledSelectToday={isDisabledSelectToday}
        />
      </div>
    </div>
  );
};
