import { HeaderCMSCommon } from 'components/header/HeaderCMSCommon';
import { PATH_PAGE } from '../../../constant';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getNewsDetail } from 'services/common';
import { get } from 'lodash';
import { FooterMobi } from 'components/footer/FooterMobi';
import { Helmet } from 'react-helmet';
import { CardNew } from '../CardNew';
import useCheckDeviceMobi from 'hooks/useCheckDeviceMobi';
import { CarouselCard } from 'pages/main/Carousel/CarouselCard';
import ScrollToTop from 'components/common/ScrollToTop';

export const DetailNewsPage = () => {
  const { id } = useParams();
  const isMobile = useCheckDeviceMobi();
  const { data } = useQuery([PATH_PAGE.NEWS, id], () => getNewsDetail(id!), {
    enabled: !!id,
  });

  return (
    <>
      <Helmet>
        <title>{get(data, 'data.data.items[0].title')}</title>
      </Helmet>
      <ScrollToTop />
      <HeaderCMSCommon />
      <section className="mx-auto grid max-w-[85rem] grid-cols-1 gap-6 bg-transparent px-6 pt-20 pb-10 md:grid-cols-3 md:px-4 md:pt-24">
        <div className="col-span-2 grid grid-cols-1 gap-2">
          <h3 className="mb-6 text-[22px] font-extrabold uppercase leading-3 text-baseColor">Tin tức</h3>
          <div dangerouslySetInnerHTML={{ __html: get(data, 'data.data.items[0].content') }} />
        </div>
        <div className="flex flex-col">
          <div className="grid grid-cols-1 gap-6 border-t border-[#D6D5D5] pt-2 md:border-t-0 md:pt-0">
            <h3 className="text-lg font-bold text-baseColor">Tin tức liên quan</h3>
            {get(data, 'data.data.items[0].lstNewSuggest', []).map((item: any) => {
              if (isMobile) {
                return <CarouselCard key={item.id} item={item} />;
              }
              return <CardNew key={item.id} data={item} />;
            })}
          </div>
        </div>
      </section>
      <FooterMobi />
    </>
  );
};
