import { useForm } from 'react-hook-form';
import { classNames, onKeyDownNumber } from 'utils/utils';
import { PATH_PAGE } from '../../constant';
import { useNavigate } from 'react-router-dom';
import { ButtonCustom } from 'components/common/ButtonCustom';
import { InputCustom } from 'components/common/InputCustom';
import { useMutation } from 'react-query';
import { postLogin } from '../../services/account';
import { IAccount } from '../../entity/Account';
import { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import usePasswordToggle from '../../hooks/usePasswordToggle';
import configs from '../../configs/config';
import Cookies from 'js-cookie';
import toast from 'react-hot-toast';

export const LoginMobi = () => {
  const { setToken, persist, setPersist } = useAuth();
  const [PasswordInputType, ToggleIcon] = usePasswordToggle();
  const [isAccountLocked, setIsAccountLocked] = useState<string>('');
  const navigate = useNavigate();
  const [isErrorLogin, setIsErrorLogin] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<{
    password: string | null;
    phoneNumber: string | null;
  }>({
    defaultValues: {
      password: null,
      phoneNumber: null,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const useGetTokenByPassword = useMutation((data: IAccount) => postLogin(data), {
    onSuccess: (data) => {
      if (data?.data?.code === 510) {
        setIsAccountLocked(data?.data?.message);
        return;
      }
      if (data?.data?.code !== 1) {
        setIsErrorLogin(true);
        return;
      }
      if (persist) {
        localStorage.setItem('token', JSON.stringify(data));
      }
      setToken && setToken(data.data.data.accessTokenInfo.accessToken);
      Cookies.set('token', data.data.data.accessTokenInfo.accessToken);
      navigate(PATH_PAGE.DASHBOARD);
    },
  });

  const onSubmit = (data: any) => {
    const _phoneNumber = (data.phoneNumber + '').at(0) === '0' ? data.phoneNumber : `0${data.phoneNumber}`;
    if (persist) {
      localStorage.setItem('username', _phoneNumber + '');
      localStorage.setItem('ps', data.password + '');
    } else {
      localStorage.removeItem('username');
      localStorage.removeItem('ps');
    }

    // submit
      useGetTokenByPassword.mutate({
        username: _phoneNumber,
        password: data.password,
        deviceId: configs.DEVICE_ID,
        firebaseToken: configs.FIREBASE_TOKEN,
        srcRq: configs.SRC_RQ,
        userType: configs.USER_TYPE,
      });
  };

  const togglePersist = () => {
    setPersist && setPersist((prev) => !prev);
  };

  useEffect(() => {
    const _userName = localStorage.getItem('username');
    const _password = localStorage.getItem('ps');
    reset({
      phoneNumber: _userName || null,
      password: _password || null,
    });
  }, []);

  useEffect(() => {
    localStorage.setItem('persist', persist + '');
  }, [persist]);

  return (
    <>
      {isAccountLocked ? (
        <div className={'flex h-full w-full flex-row items-center justify-center pt-12'}>
          <div className={'flex h-[400px] w-[450px] flex-col items-center justify-start space-y-6 text-center'}>
            <h1
              className={classNames(
                'flex flex-row items-center justify-center',
                'text-[22px] font-extrabold leading-[26px]'
              )}
            >
              <svg
                onClick={() => setIsAccountLocked('')}
                className={'mr-2'}
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.18492 10.5038L12.1662 16.4863C12.4082 16.7283 12.7364 16.8643 13.0787 16.8643C13.4209 16.8643 13.7492 16.7283 13.9912 16.4863C14.2332 16.2443 14.3691 15.916 14.3691 15.5738C14.3691 15.2315 14.2332 14.9033 13.9912 14.6613L8.90992 9.58254L13.9912 4.50504C14.233 4.26303 14.3688 3.93486 14.3687 3.59272C14.3686 3.42331 14.3352 3.25557 14.2703 3.09908C14.2054 2.94259 14.1104 2.80041 13.9905 2.68066C13.8707 2.56091 13.7285 2.46594 13.5719 2.40116C13.4154 2.33639 13.2476 2.30308 13.0782 2.30313C12.7361 2.30325 12.408 2.43928 12.1662 2.68129L6.18492 8.66254C5.94089 8.90677 5.80381 9.23791 5.80381 9.58316C5.80381 9.92842 5.94089 10.2596 6.18492 10.5038Z"
                  fill="#6A6A6A"
                />
              </svg>
              {'Tài khoản đã bị khoá'}
            </h1>
            <>
              <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_1727_32768)">
                  <path
                    d="M78.3932 60.0812L50.085 8.00075C47.9265 4.43481 44.1731 2.27637 39.9972 2.27637C35.8212 2.27637 32.0678 4.43481 29.9093 8.00075C29.9093 8.04732 29.8628 8.04732 29.8628 8.0945L1.64833 59.9874C-0.509801 63.6941 -0.556676 68.1046 1.55458 71.8113C3.66583 75.5178 7.46615 77.7232 11.736 77.7232H68.2581C72.528 77.7232 76.3285 75.5179 78.4396 71.8113C80.5511 68.1045 80.5043 63.694 78.3932 60.0812Z"
                    fill="#FEA832"
                  />
                  <path
                    d="M11.7355 73.031C9.13751 73.031 6.91532 71.7389 5.63234 69.4893C4.34937 67.2373 4.37219 64.6324 5.70563 62.346L33.7065 10.8491L34.5769 9.95795L34.5815 9.50655C35.9285 7.90045 37.8805 6.96826 39.9974 6.96826C42.4899 6.96826 44.7627 8.26264 46.0732 10.43L74.2669 62.314C75.6231 64.6371 75.6276 67.2695 74.3631 69.4893C73.0801 71.7392 70.8579 73.031 68.26 73.031H11.7355Z"
                    fill="#FEDB41"
                  />
                  <path
                    d="M78.4406 71.8109C76.3294 75.5173 72.529 77.7228 68.2592 77.7228H39.998V2.27637C44.1738 2.27637 47.9274 4.43481 50.0859 8.00075L78.3941 60.081C80.5052 63.6938 80.552 68.1043 78.4406 71.8109Z"
                    fill="#FE9923"
                  />
                  <path
                    d="M68.2585 73.0309C70.8565 73.0309 73.0787 71.7387 74.3616 69.4891C75.6263 67.2693 75.6218 64.637 74.2654 62.3138L46.0719 10.4295C44.7614 8.26231 42.4888 6.96777 39.9961 6.96777V73.0307H68.2585V73.0309Z"
                    fill="#FEDB41"
                  />
                  <path
                    d="M44.6885 26.1118V44.8797C44.6885 47.4603 42.577 49.5715 39.9966 49.5715C37.4161 49.5715 35.3047 47.4601 35.3047 44.8797V26.1118C35.3047 23.5312 37.4161 21.4199 39.9966 21.4199C42.577 21.4199 44.6885 23.5312 44.6885 26.1118Z"
                    fill="#495A79"
                  />
                  <path
                    d="M44.6899 26.1118V44.8797C44.6899 47.4603 42.5785 49.5715 39.998 49.5715V21.4199C42.5784 21.4199 44.6899 23.5312 44.6899 26.1118Z"
                    fill="#42516D"
                  />
                  <path
                    d="M39.997 54.2632C36.1024 54.2632 32.959 57.4066 32.959 61.3012C32.959 65.1954 36.1024 68.3391 39.997 68.3391C43.8915 68.3391 47.0349 65.1954 47.0349 61.3012C47.0349 57.4068 43.8913 54.2632 39.997 54.2632Z"
                    fill="#495A79"
                  />
                  <path
                    d="M47.036 61.301C47.036 65.1952 43.8926 68.339 39.998 68.339V54.2632C43.8924 54.263 47.036 57.4066 47.036 61.301Z"
                    fill="#42516D"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1727_32768">
                    <rect width="80" height="80" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <h2 className={'text-center text-[14px] font-medium leading-[20px] text-[#3F3F46]'}>{isAccountLocked}</h2>
            </>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} key={2} onChange={() => isErrorLogin && setIsErrorLogin(false)}>
          <div className={'md:hidden'}>
            <div
              className={
                "h-[300px] w-full rounded-bl-[40px] bg-[url('assets/images/main/header-mobi.svg')] bg-cover bg-center bg-no-repeat pt-4 md:hidden"
              }
            >
              <div className={'mx-auto flex h-full w-full max-w-full flex-row p-2 px-4 md:max-w-[85rem]'}>
                <div className={'flex w-3/5 flex-col md:w-1/2'}>
                  <span className={'text-[18px] font-extrabold text-white md:text-[45px]'}>
                    Shop360 - Nền tảng tiếp thị liên kết các sản phẩm Viễn thông và Sản phẩm số hàng đầu Việt Nam
                  </span>
                  <span
                    className={
                      'text-[12px] font-normal leading-[16px] text-white md:text-[20px]' +
                      ' mr-4 md:mr-0 md:leading-[32px]'
                    }
                  >
                    Thu nhập không giới hạn khi thu hút khách hàng sử dụng các sản phẩm, dịch vụ trên nền tảng Shop360
                  </span>
                </div>
              </div>
            </div>
            <div className={'mt-8 flex min-h-[280px] w-full flex-col bg-[#F8F8F8] px-4'}>
              <h1 className={'mx-auto text-[22px] font-extrabold leading-[26px] text-baseColor'}>Đăng nhập</h1>
              {isErrorLogin && (
                <div
                  className={
                    'mt-2 flex h-16 w-full flex-row items-center rounded-t-2xl rounded-bl-2xl border border-[#FC5555] bg-white px-4 text-sm text-[#404040]'
                  }
                >
                  <svg
                    className={'mr-2'}
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_579_29839)">
                      <path
                        d="M10.5 0.276367C4.97 0.276367 0.5 4.74637 0.5 10.2764C0.5 15.8064 4.97 20.2764 10.5 20.2764C16.03 20.2764 20.5 15.8064 20.5 10.2764C20.5 4.74637 16.03 0.276367 10.5 0.276367ZM14.8 14.5764C14.7075 14.6691 14.5976 14.7426 14.4766 14.7928C14.3557 14.843 14.226 14.8688 14.095 14.8688C13.964 14.8688 13.8343 14.843 13.7134 14.7928C13.5924 14.7426 13.4825 14.6691 13.39 14.5764L10.5 11.6864L7.61 14.5764C7.42302 14.7633 7.16943 14.8684 6.905 14.8684C6.64057 14.8684 6.38698 14.7633 6.2 14.5764C6.01302 14.3894 5.90798 14.1358 5.90798 13.8714C5.90798 13.7404 5.93377 13.6108 5.98387 13.4898C6.03398 13.3689 6.10742 13.2589 6.2 13.1664L9.09 10.2764L6.2 7.38637C6.01302 7.19939 5.90798 6.94579 5.90798 6.68137C5.90798 6.41694 6.01302 6.16334 6.2 5.97637C6.38698 5.78939 6.64057 5.68435 6.905 5.68435C7.16943 5.68435 7.42302 5.78939 7.61 5.97637L10.5 8.86637L13.39 5.97637C13.4826 5.88379 13.5925 5.81035 13.7135 5.76024C13.8344 5.71013 13.9641 5.68435 14.095 5.68435C14.2259 5.68435 14.3556 5.71013 14.4765 5.76024C14.5975 5.81035 14.7074 5.88379 14.8 5.97637C14.8926 6.06895 14.966 6.17886 15.0161 6.29982C15.0662 6.42079 15.092 6.55044 15.092 6.68137C15.092 6.8123 15.0662 6.94195 15.0161 7.06291C14.966 7.18387 14.8926 7.29379 14.8 7.38637L11.91 10.2764L14.8 13.1664C15.18 13.5464 15.18 14.1864 14.8 14.5764Z"
                        fill="url(#paint0_linear_579_29839)"
                      />
                    </g>
                    <defs>
                      <linearGradient
                        id="paint0_linear_579_29839"
                        x1="10.5"
                        y1="0.276367"
                        x2="10.5"
                        y2="20.2764"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#FF3F3F" />
                        <stop offset="1" stopColor="#E01D1D" />
                      </linearGradient>
                      <clipPath id="clip0_579_29839">
                        <rect width="20" height="20" fill="white" transform="translate(0.5 0.276367)" />
                      </clipPath>
                    </defs>
                  </svg>
                  Tài khoản và mật khẩu không đúng. Vui lòng kiểm tra lại
                </div>
              )}
              <div className={'mt-2 flex flex-col'}>
                <InputCustom
                  placeholder={'Số điện thoại'}
                  type={'tel'}
                  className={classNames(
                    'mt-2 w-full appearance-none outline-none',
                    errors.phoneNumber ? 'border border-[#FC5555]' : ''
                  )}
                  register={register}
                  label={'phoneNumber'}
                  control={control}
                  required={{
                    required: 'Vui lòng nhập số điện thoại',
                  }}
                  autoComplete={'off'}
                  onKeyDown={onKeyDownNumber}
                />
                {errors.phoneNumber && (
                  <span className={'mt-2 text-[12px] font-normal leading-[18px] text-[#F15836]'}>
                    {errors?.phoneNumber?.message}
                  </span>
                )}
                <div className="relative h-full w-full">
                  <InputCustom
                    placeholder={'Mật khẩu'}
                    type={PasswordInputType as string}
                    className={classNames('mt-2 w-full', errors.password ? 'border border-[#FC5555]' : '')}
                    register={register}
                    label={'password'}
                    control={control}
                    autoComplete={'off'}
                    onPaste={(e: any) => {
                      e.preventDefault();
                      return false;
                    }}
                    onCopy={(e: any) => {
                      e.preventDefault();
                      return false;
                    }}
                    required={{
                      required: 'Vui lòng nhập mật khẩu',
                      pattern: '^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&]$',
                    }}
                  />
                  {watch('password') && (
                    <div className="absolute right-2 top-4 flex cursor-pointer items-center justify-center">
                      {ToggleIcon}
                    </div>
                  )}
                </div>
                {errors.password && (
                  <span className={'mt-2 text-[12px] font-normal leading-[18px] text-[#F15836]'}>
                    {errors?.password?.message}
                  </span>
                )}
                <ButtonCustom
                  label={'Đăng nhập'}
                  className={'mt-4 w-full uppercase'}
                  type={'submit'}
                  disabled={useGetTokenByPassword.isLoading}
                />
              </div>
              <div className={'mt-[8px] flex'}>
                <div className={'w-fit !cursor-pointer'}>
                  <input
                    type={'checkbox'}
                    className={'cursor-pointer'}
                    id="remember-password"
                    onChange={togglePersist}
                    checked={persist}
                  />
                  <label
                    className={'ml-1 cursor-pointer text-sm font-medium text-[#818181]'}
                    htmlFor={'remember-password'}
                  >
                    Ghi nhớ mật khẩu
                  </label>
                </div>
                {/* <a href={'/forward-password'} className={'ml-auto text-sm font-extrabold text-[#F15836]'}>
                  Quên mật khẩu?
                </a> */}
                <p
                  onClick={() =>
                    toast.error(
                      'Vui lòng liên hệ hotline 0896688688 hoặc email congtacvien.cskh@osp.com.vn để được hỗ trợ đổi mật khẩu!'
                    )
                  }
                  className={'ml-auto mt-auto text-sm font-extrabold uppercase text-[#F15836]'}
                >
                  Quên mật khẩu?
                </p>
              </div>
              <div className={'mb-36 mt-[16px] flex w-full flex-col items-center'}>
                <p className={'text-sm font-medium text-[#818181]'}>
                  Bạn chưa có tài khoản?{' '}
                  <a className={'font-extrabold uppercase text-[#F15836]'} href={PATH_PAGE.REGISTER}>
                    Đăng ký
                  </a>
                </p>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
};
