import Scrollbars from 'react-custom-scrollbars';
import { HeaderCommon } from '../../components/header';
import useCheckDeviceMobi from '../../hooks/useCheckDeviceMobi';
import { AccountTypeDesktop } from './account/AccountTypeDesktop';
import { AccountTypeMobi } from './account/AccountTypeMobi';
import { HeaderAdmin } from 'components/header/HeaderAdmin';

export const AccountCollaborator = () => {
  const isMobile = useCheckDeviceMobi();
  return (
    <>
      {isMobile ? (
        <AccountTypeMobi />
      ) : (
        <HeaderAdmin>
          <div className={'h-full w-full rounded-[20px] bg-[#F8F8F8] pt-6 pl-6 pb-6'}>
            <HeaderCommon
              children={<span className={'text-[22px] font-extrabold text-[#404040]'}>Tài khoản Cộng tác viên</span>}
            />
            <div className={'h-[calc(100%-90px)] w-full'}>
              <Scrollbars className={'h-full w-full'}>
                <div className={'mr-7'}>
                  <AccountTypeDesktop />
                  <div className={'h-6'}></div>
                </div>
              </Scrollbars>
            </div>
          </div>
        </HeaderAdmin>
      )}
    </>
  );
};
