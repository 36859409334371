import { AccountAuthen } from 'components/common/AccountAuthen';
import { ButtonGradient } from 'components/common/button/ButtonGradient';
import { ButtonCustom } from 'components/common/ButtonCustom';
import AuthContext from 'context/AuthProvider';
import { useContext, useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { getIconSupplier } from 'utils/getIconSupplier';
import { ConfirmPackagePopup } from './Info/ConfirmPackagePopup';
import configs from 'configs/config';
import { VNP_PACKTYPE_TYPE } from 'constant';
import AppDownloadNotice from 'components/common/AppDownloadNotice';

export const RowTableServicePlan = (props: any) => {
  const location = useLocation();
  const { row, index, currentPage, perPage, statusReqPayment, packType } = props;
  const { userLogin } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false);
  const [isShowNotification, setIsShowNotification] = useState<boolean>(false);

  const truncateString = (string = '', maxLength = 50) =>
    string?.length > maxLength ? `${string.substring(0, maxLength)}…` : string;

  useEffect(() => {
    // Nếu chuyển sang màn gợi ý khi đăng ký thất bại
    if (location.search?.includes('suggest')) {
      setIsOpenConfirm(false);
    }
  }, [location.search]);

  return (
    <>
      <tr
        key={row.id}
        className="cursor-fancy rounded-l-[15px] rounded-tr-[15px] bg-white shadow-base hover:z-50 hover:shadow-[0px_0px_10px_rgba(209,209,209,0.2)] hover:drop-shadow-[0px_0px_8px_rgba(255,158,110,0.6)]"
      >
        <th
          onClick={() => {
            if (userLogin?.statusVerify === 1) {
              navigate(`${row.packCode || row.packName}?packType=${packType}`);
              return;
            }
            setIsShowNotification(true);
          }}
          scope="row"
          className="rounded-l-[15px] py-2.5 text-center text-[15px] font-normal text-gray-800"
        >
          {index + 1 + (currentPage - 1) * perPage}
        </th>
        {/* <th
          scope="row"
          onClick={() => {
            if (userLogin?.statusVerify === 1) {
              navigate(`${row.packCode || row.packName}?packType=${packType}`)
              return;
            }
            setIsShowNotification(true);
          }}
          className="py-2.5 text-center font-normal"
        >
          <div className={'flex flex-row items-center justify-center space-x-2.5'}>{getIconSupplier(row.supplier)}</div>
        </th> */}
        <th
          onClick={() => {
            if (userLogin?.statusVerify === 1) {
              navigate(`${row.packCode || row.packName}?packType=${packType}`);
              return;
            }
            setIsShowNotification(true);
          }}
          scope="row"
          className="py-2.5 text-center text-base"
        >
          {row.packCode || row.packName || '-'}
        </th>
        <th
          onClick={() => {
            if (userLogin?.statusVerify === 1) {
              navigate(`${row.packCode || row.packName}?packType=${packType}`);
              return;
            }
            setIsShowNotification(true);
          }}
          scope="row"
          className="py-2.5 text-center text-base font-normal"
        >
          {packType !== 'vinaphone'
            ? row.appPackTypeStr
            : VNP_PACKTYPE_TYPE.find((item: any) => item.value === row.appPackType)?.label || '-'}
        </th>
        <th
          onClick={() => {
            if (userLogin?.statusVerify === 1) {
              navigate(`${row.packCode || row.packName}?packType=${packType}`);
              return;
            }
            setIsShowNotification(true);
          }}
          scope="row"
          className="py-2.5 text-center text-base font-normal"
        >
          {row.amount ? `${row.amount.toLocaleString('vi')} VNĐ ` : ''}
        </th>
        <th
          onClick={() => {
            if (userLogin?.statusVerify === 1) {
              navigate(`${row.packCode || row.packName}?packType=${packType}`);
              return;
            }
            setIsShowNotification(true);
          }}
          scope="row"
          className="py-2.5 text-center text-base font-normal"
        >
          {`${row.numExpired} ngày`}
        </th>
        <th
          onClick={() => {
            if (userLogin?.statusVerify === 1) {
              navigate(`${row.packCode || row.packName}?packType=${packType}`);
              return;
            }
            setIsShowNotification(true);
          }}
          scope="row"
          className="py-5 pl-4 text-left text-base font-normal"
          title={row?.packDetail || ''}
        >
          {truncateString(row?.packDetail || '-', 60)}
        </th>
        <th
          onClick={() => {
            if (userLogin?.statusVerify === 1) {
              navigate(`${row.packCode || row.packName}?packType=${packType}`);
              return;
            }
            setIsShowNotification(true);
          }}
          scope="row"
          className="py-2.5 text-center text-base font-normal text-[#F15836]"
        >
          {row.shareValue?.toLocaleString('vi') || '0'}
        </th>
        <th
          scope="row"
          className="rounded-tr-[15px] py-2.5 text-center font-normal text-gray-700"
          onClick={(e: any) => {
            if (e.target.nodeName !== 'TH') {
              return;
            }
            if (userLogin?.statusVerify === 1) {
              navigate(`${row.packCode || row.packName}?packType=${packType}`);
              return;
            }
            setIsShowNotification(true);
          }}
        >
          <div className={'flex flex-row items-center justify-center space-x-2.5'}>
            {/* <ButtonGradient
              className={'h-[30px] w-[100px]'}
              background={'bg-white'}
              onClick={() => {
                // if (userLogin?.statusVerify === 1) {
                // if (packType === 'vinaphone') {
                //   navigator?.clipboard?.writeText(configs.API_DOMAIN + `/product/goi-cuoc-uu-dai/${encodeURIComponent(row.packName)}?packType=vinaphone`);
                // } else {
                // navigator?.clipboard?.writeText(row.link);
                // }
                navigator?.clipboard?.writeText(
                  process.env.REACT_APP_BASE_URL +
                    `/product/goi-cuoc-uu-dai/${encodeURIComponent(row.packCode)}?rf=${userLogin?.refCode}`
                );
                toast.success('Sao chép link thành công!');
                // setIsOpenConfirm(true);
                return;
                // }
                // setIsShowNotification(true);
              }}
            >
              <span className={'text-sm font-semibold text-[#F15836]'}>Lấy link</span>
            </ButtonGradient> */}
            <ButtonCustom
              label={'Đăng ký'}
              onClick={() => {
                // if (userLogin?.statusVerify === 1) {
                setIsOpenConfirm(true);
                // return;
                // }
                // setIsShowNotification(true);
              }}
              background={'bg-[#F15836]'}
              className={'h-[30px] w-[100px] !rounded-l-[10px] !text-sm !font-semibold'}
            />
          </div>
        </th>
      </tr>
      {/* {isShowNotification && <AccountAuthen setIsShowNotification={setIsShowNotification} />} */}
      {isOpenConfirm && (
        <ConfirmPackagePopup
          onClose={() => setIsOpenConfirm(false)}
          item={row}
          packageName={row.packCode || row.packName}
        />
        // <AppDownloadNotice setShowDialog={setIsOpenConfirm} showDialog={isOpenConfirm} />
      )}
    </>
  );
};
