import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { PATH_PAGE } from '../../constant';
import Cookies from 'js-cookie';

const RequireAuth = () => {
  const token = Cookies.get('token');
  const location = useLocation();
  return token ? <Outlet /> : <Navigate to={PATH_PAGE.LOGIN} state={{ from: location }} replace />;
};

export default RequireAuth;
