import useCheckDeviceMobi from 'hooks/useCheckDeviceMobi';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { checkReqPayment } from 'services/payment';
import { PATH_PAGE, QUERY_KEY } from '../../constant';

export const BankCardInfo = ({ data }: any) => {
  const navigation = useNavigate();
  const isMobile = useCheckDeviceMobi();
  const { data: checkReqPaymentData } = useQuery(QUERY_KEY.REQ_PAYMENT, () => checkReqPayment(), { enabled: false });

  return (
    <div
      className={
        'h-full w-full rounded-[15px] border border-[#F15836] bg-gradient-to-r from-[#F15836]/20 to-[#FF924F]/20 md:border-0'
      }
    >
      <div
        className={
          "relative flex h-[130px] flex-col justify-between rounded-[15px] bg-[url('assets/images/background-card-icon2.svg')] bg-cover p-4 md:h-[200px] md:p-6 "
        }
      >
        {!isMobile && (
          <label className={'text-base font-medium text-[#404040]'}>Thông tin ngân hàng nhận thanh toán</label>
        )}
        {data?.data?.data && data.data.data.accountBank && data.data.data.accountBranch && data.data.data.fullName ? (
          <>
            <div className={'flex flex-row space-x-2'}>
              <label className={'text-[18px] font-bold text-[#404040] md:text-[26px]'}>
                {`${'*'.repeat(data.data.data.accountNumber.length - 5)}${data.data.data.accountNumber.slice(-4)}`}
              </label>
              <div
                className={
                  'flex h-[24px] w-[82px] flex-row items-center justify-center rounded-t-[12px] rounded-bl-[12px] bg-gradient-to-r from-[#F15836] to-[#FF924F] p-px md:mt-1.5 md:h-[25px]'
                }
              >
                <div
                  className={
                    'flex h-full w-full flex-row items-center justify-center space-x-1 rounded-t-[10px] rounded-bl-[10px] bg-[#FFE6D3]'
                  }
                >
                  <span className={'text-xs font-normal text-[#F15836] md:text-sm'}>Mặc định</span>
                </div>
              </div>
            </div>
            <label className={'text-sm font-medium uppercase text-[#404040] md:text-base'}>
              NGÂN HÀNG <span>{data.data.data.accountBank}</span>
            </label>
            <label className={'text-sm font-medium text-[#404040] md:text-base'}>
              Chi nhánh {data.data.data.accountBranch}
            </label>
            <label className={'text-sm font-medium uppercase text-[#404040] md:text-base'}>
              {data.data.data.fullName}
            </label>
          </>
        ) : (
          <span className={'text-sm font-medium text-[#818181]'}>Chưa có thông tin</span>
        )}
        {/* {!isMobile && (
          <div
            className={
              'absolute right-6 bottom-6 flex h-[40px] w-[158px] cursor-pointer flex-row items-center justify-center rounded-t-[12px] rounded-bl-[12px] bg-gradient-to-r from-[#F15836] to-[#FF924F] p-[2px]'
            }
          >
            <button
              className={
                'flex h-full w-full flex-row items-center justify-center space-x-1 rounded-t-[10px] rounded-bl-[10px] bg-[#FEE6DB]'
              }
              onClick={() => navigation(PATH_PAGE.CONFIG_PAYMENT)}
            >
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 6.78906C0 3.47535 2.68629 0.789062 6 0.789062H14C17.3137 0.789062 20 3.47535 20 6.78906V20.7891H6C2.68629 20.7891 0 18.1028 0 14.7891V6.78906Z"
                  fill="url(#paint0_linear_657_21783)"
                />
                <g clipPath="url(#clip0_657_21783)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.71725 15.684H14.2822C14.4445 15.684 14.6001 15.7484 14.7148 15.8632C14.8295 15.9779 14.894 16.1335 14.894 16.2957C14.894 16.458 14.8295 16.6136 14.7148 16.7283C14.6001 16.8431 14.4445 16.9075 14.2822 16.9075H5.71725C5.555 16.9075 5.39939 16.8431 5.28466 16.7283C5.16992 16.6136 5.10547 16.458 5.10547 16.2957C5.10547 16.1335 5.16992 15.9779 5.28466 15.8632C5.39939 15.7484 5.555 15.684 5.71725 15.684ZM5.10547 12.6251L11.2233 6.50722L13.0586 8.34257L6.94082 14.4604H5.10547V12.6251ZM11.8351 5.89544L13.0586 4.67188L14.894 6.50722L13.6698 7.7314L11.8351 5.89544Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_657_21783"
                    x1="0"
                    y1="20.7891"
                    x2="-1.9181e-06"
                    y2="0.789063"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F15836" />
                    <stop offset="1" stopColor="#FF924F" />
                  </linearGradient>
                  <clipPath id="clip0_657_21783">
                    <rect width="13.3333" height="13.3333" fill="white" transform="translate(3.33203 4.12109)" />
                  </clipPath>
                </defs>
              </svg>
              <span className={'text-sm font-extrabold text-[#F15836]'}>Cập nhật</span>
            </button>
          </div>
        )} */}
        <div className={'absolute right-6 top-4 md:top-7'}>
          {isMobile ? (
            <button
              className={
                'flex h-full w-full flex-row items-center justify-center space-x-1 rounded-t-[10px] rounded-bl-[10px] bg-[#FEE6DB]'
              }
              onClick={() => navigation(PATH_PAGE.CONFIG_PAYMENT)}
            >
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 6.78906C0 3.47535 2.68629 0.789062 6 0.789062H14C17.3137 0.789062 20 3.47535 20 6.78906V20.7891H6C2.68629 20.7891 0 18.1028 0 14.7891V6.78906Z"
                  fill="url(#paint0_linear_657_21783)"
                />
                <g clipPath="url(#clip0_657_21783)">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.71725 15.684H14.2822C14.4445 15.684 14.6001 15.7484 14.7148 15.8632C14.8295 15.9779 14.894 16.1335 14.894 16.2957C14.894 16.458 14.8295 16.6136 14.7148 16.7283C14.6001 16.8431 14.4445 16.9075 14.2822 16.9075H5.71725C5.555 16.9075 5.39939 16.8431 5.28466 16.7283C5.16992 16.6136 5.10547 16.458 5.10547 16.2957C5.10547 16.1335 5.16992 15.9779 5.28466 15.8632C5.39939 15.7484 5.555 15.684 5.71725 15.684ZM5.10547 12.6251L11.2233 6.50722L13.0586 8.34257L6.94082 14.4604H5.10547V12.6251ZM11.8351 5.89544L13.0586 4.67188L14.894 6.50722L13.6698 7.7314L11.8351 5.89544Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_657_21783"
                    x1="0"
                    y1="20.7891"
                    x2="-1.9181e-06"
                    y2="0.789063"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F15836" />
                    <stop offset="1" stopColor="#FF924F" />
                  </linearGradient>
                  <clipPath id="clip0_657_21783">
                    <rect width="13.3333" height="13.3333" fill="white" transform="translate(3.33203 4.12109)" />
                  </clipPath>
                </defs>
              </svg>
            </button>
          ) : (
            data?.data?.data &&
            checkReqPaymentData?.data?.data?.code &&
            checkReqPaymentData?.data?.data?.code !== 164 && (
              <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.929688 3.96457C0.929688 3.16433 1.24758 2.39687 1.81344 1.83101C2.37929 1.26516 3.14676 0.947266 3.947 0.947266H22.0508C22.8511 0.947266 23.6185 1.26516 24.1844 1.83101C24.7503 2.39687 25.0681 3.16433 25.0681 3.96457V11.5078H0.929688V3.96457ZM18.2792 5.47323C18.0791 5.47323 17.8873 5.5527 17.7458 5.69416C17.6044 5.83563 17.5249 6.02749 17.5249 6.22755V7.73621C17.5249 7.93627 17.6044 8.12813 17.7458 8.2696C17.8873 8.41106 18.0791 8.49053 18.2792 8.49053H21.2965C21.4966 8.49053 21.6884 8.41106 21.8299 8.2696C21.9714 8.12813 22.0508 7.93627 22.0508 7.73621V6.22755C22.0508 6.02749 21.9714 5.83563 21.8299 5.69416C21.6884 5.5527 21.4966 5.47323 21.2965 5.47323H18.2792ZM0.929688 14.5251V16.0338C0.929688 16.834 1.24758 17.6015 1.81344 18.1674C2.37929 18.7332 3.14676 19.0511 3.947 19.0511H22.0508C22.8511 19.0511 23.6185 18.7332 24.1844 18.1674C24.7503 17.6015 25.0681 16.834 25.0681 16.0338V14.5251H0.929688Z"
                  fill="#F15836"
                />
              </svg>
            )
          )}
        </div>
      </div>
    </div>
  );
};
