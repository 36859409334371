import { ComingSoonIcon } from "assets/images";
import { ButtonCustom } from "components/common/ButtonCustom";
import { HeaderCommon } from "components/header";
import { redirectDownload } from "utils/utils";

export const SupportPage = () => {
    return(
        <div className={'h-full w-full bg-[#F8F8F8] md:rounded-[20px] md:p-6 md:!pr-0'}>
            <HeaderCommon children={<span className={'text-[22px] font-extrabold text-[#404040]'}>Hỗ trợ</span>}/>
            <div className={'hidden h-full w-full md:block md:h-[calc(100%-90px)]'}>
                <div className={'flex h-full w-full flex-col items-center justify-center'}>
                    <img src={ComingSoonIcon} alt="image1" className="w-[400px] h-auto"/>
                    <span className=" w-[60%] mt-4 text-center text-base">
                        Tính năng đang trong quá trình hoàn thiện. Vui lòng tải ứng dụng Shop360 để trả nghiệm những tính năng và kinh doanh các sản phẩm mới nhất!
                    </span>
                    <div className={'flex flex-row items-center justify-center space-x-3.5 mt-8'}>
                        <ButtonCustom
                            label={'Tải ứng dụng'}
                            onClick={redirectDownload}
                            background={'bg-[#F15836]'}
                            className={'h-[45px] w-[200px] !rounded-l-[10px] !text-sm !font-semibold'}
                        />
                    </div>
                </div>
            </div>
        </div>
        
    );
};
