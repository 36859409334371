import { classNames } from '../../utils/utils';

interface ITableCustom {
  labelHeaders: Array<{ label: string; className: string }>;
  dataRows: Array<any>;
  renderRow: (dataRow: any) => any;
  classHeader?: string;
}

export const TableCustom = ({ labelHeaders, dataRows, renderRow, classHeader }: ITableCustom) => {
  return (
    <div className="relative overflow-x-auto rounded-t-lg shadow-md">
      <table className="w-full table-auto text-left text-sm text-gray-500  dark:text-gray-400">
        <thead className={classNames('bg-gray-100 dark:bg-gray-700', classHeader ?? '')}>
          <tr>
            {labelHeaders.map((_header: { label: string; className: string }) => (
              <th scope="col" className={classNames('px-2 py-[18px] text-center', _header.className)}>
                <div className={classNames('w-full border-l border-gray-600 pl-1', _header.className)}>
                  {_header.label}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{dataRows.map((row: any) => renderRow(row))}</tbody>
      </table>
    </div>
  );
};
