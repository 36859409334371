// import logo from '../../assets/images/header/logo-new.png';
import { LogoNew } from 'assets/images/header';
import { PageHeader } from './PageHeader';
import { PATH_PAGE } from '../../constant';
import { Fragment, useEffect, useState } from 'react';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { ButtonGradient } from 'components/common/button/ButtonGradient';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageHeaderPopup } from './PageHeaderPopup';
import { ButtonCustom } from 'components/common/ButtonCustom';
import { ArrowDown } from 'assets/images/header';
import AppDownloadNotice from 'components/common/AppDownloadNotice';

export const HeaderCMSCommon = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate();
  const location = useLocation;

  useEffect(() => {
    return () => {
      setIsOpen(false);
    };
  }, []);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <header className="fixed z-50 block h-[60px] w-full flex-wrap bg-white py-4 text-sm md:fixed md:h-[75px] md:flex-nowrap md:justify-start md:bg-gradient-to-r  md:to-[#FF924F]">
      <nav
        className="mx-auto h-full w-full max-w-[85rem] px-4 md:flex md:items-center md:justify-between "
        aria-label="Global"
      >
        <div className="flex h-full items-center justify-between md:ml-8">
          <a href="/" className="hidden md:contents">
            <img className="h-[90px] w-[125px] object-contain" src={LogoNew} alt="Logo" />
          </a>
          <div className="h-[24px] w-[24px] md:hidden">
            <button
              type="button"
              onClick={() => setIsOpen(true)}
              className="hs-collapse-toggle inline-flex h-[24px] w-[24px] items-center justify-center border-0 bg-white align-middle transition-all duration-150 ease-in-out"
            >
              <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 4H21V6H3V4ZM3 11H15V13H3V11ZM3 18H21V20H3V18Z" fill="black" />
              </svg>
            </button>
          </div>
          <img
            className="h-24 w-24 object-contain md:hidden"
            src={LogoNew}
            alt="LogoMobile"
            onClick={() => navigate(PATH_PAGE.DASHBOARD)}
          />
          <div className={'w-10 md:hidden'}></div>
        </div>
        <div
          id="navbar-image-and-text-1"
          className="hs-collapse hidden grow overflow-hidden transition-all duration-300 md:visible md:block"
        >
          <div className="mt-5 flex flex-col gap-5 md:mt-1 md:flex-row md:items-center md:justify-start md:pl-5">
            <PageHeader label={'Trang chủ'} isSelected href={'/'} />
            <PageHeader label={'Giới thiệu'} href={'/plan'} />
            <PageHeader label={'Hướng dẫn'} href={'/instructions'} />
            {/* <PageHeaderPopup
              label={'Hướng dẫn'}
              href={'/instructions'}
              children={[
                { href: '/instructions', label: 'Hướng dẫn tham gia' },
                // { href: '/hdkh', label: 'Hướng dẫn kích hoạt sim' },
              ]}
            /> */}
            <PageHeader label={'Tin Tức'} href={'/news'} />
          </div>
        </div>
        <div className="mr-[34px] hidden flex-col gap-5 md:mt-1 md:flex md:flex-row md:items-center md:justify-end">
          <ButtonCustom
            label={
              <>
                <img className="mr-1" src={ArrowDown} alt="ArrowDown" />
                TẢI APP SHOP360
              </>
            }
            className={'h-[40px] w-auto bg-gradient-to-r !from-[#FFB01F] !to-[#FFCF45] px-2 uppercase '}
            type={'button'}
            onClick={() => navigate('/download')}
          />
          
          {/* <PageHeader label={'Đăng nhập'} href={PATH_PAGE.LOGIN}/> */}
          {/* <PageHeader label={'Đăng ký'} href={PATH_PAGE.REGISTER} /> */}
          <span
            className="flex cursor-pointer justify-center px-2 py-2 text-center text-[14px] font-bold uppercase text-[#F15836]"
            onClick={() => setShowDialog(true)}
          >
            Đăng nhập
          </span>
          <span
            className="flex cursor-pointer justify-center px-2 py-2 text-center text-[14px] font-bold uppercase text-[#F15836]"
            onClick={() => setShowDialog(true)}
          >
            Đăng ký
          </span>
        </div>
      </nav>
      <Transition show={isOpen} as={Fragment}>
        <Dialog
          open={isOpen}
          onClose={() => {}}
          className="fixed inset-0 z-50 flex h-full min-h-screen w-screen flex-row items-center justify-center overflow-y-auto bg-[#000000]/60"
        >
          <Dialog.Panel className={'h-screen w-screen bg-white'}>
            <div className="flex items-center justify-between px-4 py-5">
              <button
                type="button"
                onClick={() => setIsOpen(false)}
                className="hs-collapse-toggle inline-flex items-center justify-center gap-2 border-0 bg-white p-2 align-middle text-sm font-medium text-gray-700 transition-all"
              >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M12.0007 10.586L16.9507 5.63599L18.3647 7.04999L13.4147 12L18.3647 16.95L16.9507 18.364L12.0007 13.414L7.05072 18.364L5.63672 16.95L10.5867 12L5.63672 7.04999L7.05072 5.63599L12.0007 10.586Z"
                    fill="black"
                  />
                </svg>
              </button>
              <img
                className="h-10 w-20 object-contain"
                src={LogoNew}
                alt="LogoMobile"
                onClick={() => navigate(PATH_PAGE.DASHBOARD)}
              />
              <a
                className={'text-base font-bold text-black'}
                href={PATH_PAGE.LOGIN}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Đăng nhập
              </a>
            </div>
            <div className={'flex h-[calc(100%-80px)] w-full flex-col justify-between px-4'}>
              <div className={'flex h-fit w-full flex-col'}>
                <p
                  className={'mb-6 mt-4 cursor-pointer text-[18px] font-bold leading-[28px] text-baseColor'}
                  onClick={() => {
                    navigate('/');
                    setIsOpen(false);
                  }}
                >
                  Trang chủ
                </p>
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className={'flex flex-row justify-between'}>
                        <span className={'text-[18px] font-bold leading-[28px] text-baseColor'}>Chương trình</span>
                        <svg
                          className={`${open ? 'transform' : 'rotate-180 transform'}`}
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.8">
                            <path
                              d="M12.6038 17.179L19.7828 10.0015C20.0732 9.71106 20.2363 9.31718 20.2363 8.90647C20.2363 8.49577 20.0732 8.10189 19.7828 7.81147C19.4924 7.52106 19.0985 7.35791 18.6878 7.35791C18.2771 7.35791 17.8832 7.52106 17.5928 7.81147L11.4983 13.909L5.40526 7.81147C5.11485 7.52126 4.72105 7.3583 4.31048 7.35844C4.10719 7.35851 3.90591 7.39862 3.71812 7.47648C3.53033 7.55434 3.35971 7.66843 3.21601 7.81222C3.07232 7.95602 2.95835 8.12671 2.88062 8.31456C2.80288 8.5024 2.76291 8.70371 2.76298 8.907C2.76312 9.31757 2.92635 9.71126 3.21676 10.0015L10.3943 17.179C10.6873 17.4718 11.0847 17.6363 11.499 17.6363C11.9133 17.6363 12.3107 17.4718 12.6038 17.179Z"
                              fill="#404040"
                            />
                          </g>
                        </svg>
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-4 flex flex-col space-y-4 text-[16px] font-normal leading-[26px] text-baseColor">
                        <a href={'/plan'} onClick={() => setIsOpen(false)}>
                          Giới thiệu chương trình
                        </a>
                        <a href={'/instructions'} onClick={() => setIsOpen(false)}>
                          Hướng dẫn tham gia
                        </a>
                        {/* <a href={'/hdkh'} onClick={() => setIsOpen(false)}>
                          Hướng dẫn kích hoạt sim
                        </a> */}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <a
                  href={PATH_PAGE.NEWS}
                  onClick={() => setIsOpen(false)}
                  className={'mt-6 text-[18px] font-bold leading-[28px] text-baseColor'}
                >
                  Tin tức
                </a>
              </div>
              <div className={'flex h-fit w-full flex-col space-y-4 px-4 pb-32'}>
                <ButtonCustom
                  label={
                    <>
                      <img className="mr-1" src={ArrowDown} alt="ArrowDown" />
                      TẢI APP SHOP360
                    </>
                  }
                  className={'mt-[15px] h-[48px] w-full bg-gradient-to-r !from-[#FFB01F] !to-[#FFCF45] uppercase '}
                  type={'button'}
                  onClick={() => {
                    setIsOpen(false);
                    navigate('/download');
                  }}
                />
                <ButtonGradient
                  className={'h-[48px] w-full'}
                  background={'bg-white'}
                  onClick={() => {
                    setIsOpen(false);
                    // navigate(PATH_PAGE.LOGIN);
                    setShowDialog(true);
                  }}
                >
                  <span className={'text-base font-bold text-[#F15836]'}>Đăng nhập</span>
                </ButtonGradient>
                <button
                  className={
                    'rounded-t-[8px] bg-gradient-to-r from-[#F15836] to-[#FF924F] text-base font-bold text-white' +
                    ' h-[48px] w-full rounded-bl-[8px]'
                  }
                  onClick={() => {
                    setIsOpen(false);
                    // navigate(PATH_PAGE.REGISTER);
                    setShowDialog(true);
                  }}
                >
                  Đăng ký
                </button>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
      </Transition>

      <AppDownloadNotice showDialog={showDialog} setShowDialog={setShowDialog} />
    </header>
  );
};
