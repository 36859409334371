import { Menu, Transition } from '@headlessui/react';
import ArrowDown from 'assets/images/arrow-down.svg';
import NotificationIcon from 'assets/images/notification.svg';
import useAuth from 'hooks/useAuth';
import Cookies from 'js-cookie';
import { Fragment, ReactNode, useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { classNames } from 'utils/utils';
import { AvatarDefault } from '../../assets/images/header';
import { PATH_PAGE, QUERY_KEY } from '../../constant';
import AuthContext from '../../context/AuthProvider';
import { getUserInfo } from '../../services/account';
import { AccountAuthen } from '../common/AccountAuthen';
import ImageWithFallback from '../common/ImageWithFallback';

interface HeaderProps {
  children?: ReactNode;
}

export const HeaderCommon = ({ children }: HeaderProps) => {
  const { setToken } = useAuth();
  const { setUserLogin, userLogin } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isShowNotification, setIsShowNotification] = useState<boolean>(false);

  useQuery(QUERY_KEY.USER_INFO, () => getUserInfo(), {
    enabled: !userLogin?.id,
    onSuccess: (response) => {
      if (!response?.data?.data) {
        setUserLogin(undefined);
        return;
      }
      setUserLogin(response.data.data);
    },
  });

  const onLogout = () => {
    Cookies.remove('token');
    setToken && setToken(undefined);
    setUserLogin(undefined);
    navigate(PATH_PAGE.LOGIN);
  };

  return (
    <div
      className={
        'mb-6 hidden h-[78px] w-[calc(100%-1.5rem)] flex-row justify-between rounded-[15px] bg-white px-6 md:flex' +
        ' shadow-base'
      }
    >
      <div className={'flex flex-col justify-center space-y-0'}>
        {children || (
          <>
            <span className={'line-clamp-1 text-[22px] font-extrabold text-[#404040] max-[950px]:text-[14px]'}>
              Xin chào,
              {userLogin && <span> {userLogin?.fullName || userLogin?.mobil}</span>}
            </span>
            <span className={'line-clamp-1 text-sm font-medium text-[#818181] max-[950px]:text-[12px]'}>
              Chào mừng bạn đến với hệ thống tiếp thị liên kết Shop360
            </span>
          </>
        )}
      </div>
      <div className="flex w-fit items-center justify-between p-2">
        <div className={'h-full w-px bg-[#D6D5D5]'} />
        <Menu as="div" className="relative inline-block w-full text-left ">
          <Menu.Button className="inline-flex w-full items-center justify-center gap-1 bg-white px-5 py-1 align-middle text-[18px] font-semibold text-gray-700 transition-all">
            <div className={'flex w-full flex-row items-center justify-between space-x-2'}>
              <ImageWithFallback
                src={userLogin?.avatarPath}
                fallback={AvatarDefault}
                statusVerify={userLogin?.statusVerify === 1}
                name={userLogin?.fullName}
                className={'mr-2 h-[40px] w-[40px] min-w-[40px]'}
              />
              {userLogin && (
                <span className={'line-clamp-2 text-left max-[950px]:text-[14px] max-[950px]:font-bold'}>
                  {userLogin.fullName || userLogin.mobile}
                </span>
              )}
            </div>
            <img
              className="hs-dropdown-open:rotate-180 ml-1 h-2.5 w-2.5 text-gray-600"
              src={ArrowDown}
              alt={'arrow-down'}
            />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-t-[15px] rounded-bl-[15px] bg-white shadow-base">
              {/* <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => navigate(PATH_PAGE.PROFILE)}
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'flex w-full flex-row items-center space-x-2 rounded-t-[15px] p-3'
                    )}
                  >
                    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.95078 9.52422C8.89893 9.52422 9.80825 9.16469 10.4787 8.52472C11.1491 7.88475 11.5258 7.01677 11.5258 6.11172C11.5258 5.20667 11.1491 4.33868 10.4787 3.69872C9.80825 3.05875 8.89893 2.69922 7.95078 2.69922C7.00263 2.69922 6.09332 3.05875 5.42287 3.69872C4.75243 4.33868 4.37578 5.20667 4.37578 6.11172C4.37578 7.01677 4.75243 7.88475 5.42287 8.52472C6.09332 9.16469 7.00263 9.52422 7.95078 9.52422ZM11.5258 10.5742C12.0987 10.574 12.6492 10.7872 13.06 11.1684C13.4709 11.5496 13.7097 12.0687 13.7258 12.6154L11.8272 14.4277C11.3766 14.8579 11.0569 15.3969 10.9021 15.987L10.7855 16.4311C10.043 16.7041 9.11348 16.8742 7.95078 16.8742C2.17578 16.8742 2.17578 12.6742 2.17578 12.6742C2.17578 12.1173 2.40757 11.5831 2.82015 11.1893C3.23273 10.7955 3.7923 10.5742 4.37578 10.5742H11.5258ZM19.2258 6.89922C19.2258 7.59541 18.9361 8.26309 18.4203 8.75537C17.9046 9.24766 17.2051 9.52422 16.4758 9.52422C15.7464 9.52422 15.047 9.24766 14.5312 8.75537C14.0155 8.26309 13.7258 7.59541 13.7258 6.89922C13.7258 6.20303 14.0155 5.53535 14.5312 5.04306C15.047 4.55078 15.7464 4.27422 16.4758 4.27422C17.2051 4.27422 17.9046 4.55078 18.4203 5.04306C18.9361 5.53535 19.2258 6.20303 19.2258 6.89922ZM17.9168 10.0996L12.6038 15.1711C12.2941 15.4666 12.0744 15.8367 11.968 16.2421L11.5555 17.815C11.5148 17.9715 11.5172 18.1353 11.5624 18.2906C11.6077 18.4459 11.6943 18.5873 11.8137 18.7013C11.9332 18.8152 12.0815 18.8977 12.2442 18.9407C12.4069 18.9838 12.5785 18.9859 12.7424 18.9469L14.3902 18.5542C14.8149 18.4528 15.2028 18.243 15.5122 17.9473L20.8252 12.8758C21.2059 12.5066 21.4184 12.0086 21.4162 11.4905C21.4139 10.9724 21.1973 10.4761 20.8134 10.1098C20.4295 9.74351 19.9095 9.5369 19.3667 9.53499C18.824 9.53308 18.3024 9.73603 17.9157 10.0996H17.9168Z"
                        fill="url(#paint0_linear_576_43433)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_576_43433"
                          x1="2.17578"
                          y1="10.8369"
                          x2="21.4162"
                          y2="10.8369"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#FFA500" />
                          <stop offset="1" stopColor="#FFBD00" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <span className={'block cursor-pointer text-[15px] font-normal text-[#6B7280]'}>
                      Quản lý tài khoản CTV
                    </span>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => {
                      if (userLogin?.statusVerify === 1) {
                        navigate(PATH_PAGE.CONFIG_PAYMENT);
                        return;
                      }
                      setIsShowNotification(true);
                    }}
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'flex w-full flex-row items-center space-x-2 p-3'
                    )}
                  >
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_576_43457)">
                        <path
                          d="M18.4883 8.44531V5.69531H6.23828V8.44531H18.4883ZM18.4883 2.94531C18.9524 2.94531 19.3975 3.13847 19.7257 3.48228C20.0539 3.8261 20.2383 4.29242 20.2383 4.77865V13.9453C20.2383 14.4315 20.0539 14.8979 19.7257 15.2417C19.3975 15.5855 18.9524 15.7786 18.4883 15.7786H6.23828C5.77415 15.7786 5.32903 15.5855 5.00084 15.2417C4.67266 14.8979 4.48828 14.4315 4.48828 13.9453V4.77865C4.48828 4.29242 4.67266 3.8261 5.00084 3.48228C5.32903 3.13847 5.77415 2.94531 6.23828 2.94531H18.4883ZM2.73828 17.612H15.8633V19.4453H2.73828C2.27415 19.4453 1.82903 19.2522 1.50084 18.9083C1.17266 18.5645 0.988281 18.0982 0.988281 17.612V7.52865H2.73828V17.612Z"
                          fill="url(#paint0_linear_576_43457)"
                        />
                      </g>
                      <defs>
                        <linearGradient
                          id="paint0_linear_576_43457"
                          x1="0.988281"
                          y1="11.1953"
                          x2="20.2383"
                          y2="11.1953"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#F15836" />
                          <stop offset="1" stopColor="#FF924F" />
                        </linearGradient>
                        <clipPath id="clip0_576_43457">
                          <rect width="21" height="22" fill="white" transform="translate(0.113281 0.195312)" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span className={'block cursor-pointer text-[15px] font-normal text-[#6B7280]'}>
                      Thiết lập TK thanh toán
                    </span>
                  </div>
                )}
              </Menu.Item> */}
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => navigate(PATH_PAGE.CHANGE_PASSWORD)}
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'flex w-full flex-row items-center space-x-2 p-3'
                    )}
                  >
                    <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.2427 7.63932H17.1475C17.4001 7.63932 17.6423 7.73966 17.8209 7.91827C17.9995 8.09688 18.0999 8.33912 18.0999 8.5917V20.0203C18.0999 20.2729 17.9995 20.5151 17.8209 20.6937C17.6423 20.8723 17.4001 20.9727 17.1475 20.9727H1.90941C1.65683 20.9727 1.41458 20.8723 1.23598 20.6937C1.05737 20.5151 0.957031 20.2729 0.957031 20.0203V8.5917C0.957031 8.33912 1.05737 8.09688 1.23598 7.91827C1.41458 7.73966 1.65683 7.63932 1.90941 7.63932H3.81417V6.68694C3.81417 5.17142 4.41621 3.71797 5.48785 2.64633C6.55949 1.5747 8.01294 0.972656 9.52846 0.972656C11.044 0.972656 12.4974 1.5747 13.5691 2.64633C14.6407 3.71797 15.2427 5.17142 15.2427 6.68694V7.63932ZM13.338 7.63932V6.68694C13.338 5.67659 12.9366 4.70763 12.2222 3.9932C11.5078 3.27878 10.5388 2.87742 9.52846 2.87742C8.51811 2.87742 7.54914 3.27878 6.83472 3.9932C6.12029 4.70763 5.71894 5.67659 5.71894 6.68694V7.63932H13.338ZM8.57608 13.3536V15.2584H10.4808V13.3536H8.57608ZM4.76655 13.3536V15.2584H6.67132V13.3536H4.76655ZM12.3856 13.3536V15.2584H14.2904V13.3536H12.3856Z"
                        fill="url(#paint0_linear_576_43520)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_576_43520"
                          x1="9.52846"
                          y1="0.972656"
                          x2="9.52846"
                          y2="20.9727"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#FF5530" />
                          <stop offset="1" stopColor="#FA1515" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <span className={'block cursor-pointer text-[15px] font-normal text-[#6B7280]'}>
                      Thay đổi mật khẩu
                    </span>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={onLogout}
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'flex w-full flex-row items-center space-x-2 rounded-bl-[15px] p-3'
                    )}
                  >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.52734 19.4727C4.28049 19.4727 0.0273438 15.2195 0.0273438 9.97266C0.0273438 4.72581 4.28049 0.472656 9.52734 0.472656C14.7742 0.472656 19.0273 4.72581 19.0273 9.97266C19.0273 15.2195 14.7742 19.4727 9.52734 19.4727ZM14.2773 13.7727L19.0273 9.97266L14.2773 6.17266V9.02266H6.67734V10.9227H14.2773V13.7727Z"
                        fill="url(#paint0_linear_576_43563)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_576_43563"
                          x1="0.0273438"
                          y1="0.472656"
                          x2="19.0273"
                          y2="0.472656"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#DB457B" />
                          <stop offset="1" stopColor="#C61B59" />
                        </linearGradient>
                      </defs>
                    </svg>
                    <span className={'block cursor-pointer text-[15px] font-normal text-[#6B7280]'}>Đăng xuất</span>
                  </div>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </Menu>
        {/* <span className={'ml-1'}> */}
        <img
          className="ml-1 h-auto !w-[24px] cursor-pointer"
          alt={'notification-icon'}
          src={NotificationIcon}
          onClick={() => navigate(PATH_PAGE.NOTIFICATIONS)}
        />
        {/* </span> */}
      </div>
      {isShowNotification && <AccountAuthen setIsShowNotification={setIsShowNotification} />}
    </div>
  );
};
