import React, { useState } from 'react';
import { classNames } from 'utils/utils';

interface IInputCustom extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: any;
  register?: any;
  required?: any;
  control?: any;
  setFocus?: (isFocus: boolean) => void;
}

export const InputCustom = (props: IInputCustom) => {
  const { className, register, required, label, ...otherProps } = props;
  const [isFocus, setIsFocus] = useState<boolean>(false);

  return (
    <input
      className={classNames(
        `rounded-t-[8px] rounded-bl-[8px] border px-[16px] py-[10px] text-sm font-medium leading-[20px] text-gray-500 placeholder-[#A9A9A9] shadow-base focus:outline-none`,
        className,
        isFocus ? ' !border-[#4568DA]' : '!border-[#E5E7EB]'
      )}
      {...register(label, { ...required })}
      {...otherProps}
      onFocus={() => {
        setIsFocus(true);
        props.setFocus && props.setFocus(true);
      }}
      onBlur={() => {
        setTimeout(() => {
          setIsFocus(false);
          props.setFocus && props.setFocus(false);
        }, 100);
      }}
    />
  );
};
