import { isNumber } from 'lodash';
import { memo } from 'react';
import NumberFormat from 'react-number-format';
import { classNames, onKeyDownNumber } from 'utils/utils';
import useCheckDeviceMobi from '../../../hooks/useCheckDeviceMobi';

const InputOTP = ({ index, onKeyUp, isErrorOTP, inputFocus, isConfirmRegister, handleChange, value }: any) => {
  const isMobile = useCheckDeviceMobi();

  return (
    <NumberFormat
      className={classNames(
        'form-control rounded-t-[8px] rounded-bl-[8px] border text-center text-xl font-bold text-[#5B5B5B] caret-transparent focus:select-all focus:!border-2 focus:!border-[#FF9E6E] focus:outline-none',
        isErrorOTP ? 'border-[#FF7575]' : value ? 'border-2 !border-[#FF9E6E]' : '!border-[#D6D5D5]',
        isMobile
          ? 'm-1 h-[48px] w-[48px] !rounded-br-[0px]'
          : isConfirmRegister
          ? 'm-1 h-[50px] w-[50px]'
          : 'm-2 h-[60px] w-[60px]'
      )}
      maxLength={1}
      tabIndex={index + 1}
      value={value}
      placeholder={'—'}
      autoFocus={index === 0}
      isAllowed={(values: any) => {
        return !values.floatValue || isNumber(values.floatValue);
      }}
      onChange={(e: any) => {
        if (!e.target.value) {
          const next = e.target.tabIndex - 2;
          if (next > -1) {
            e.target.form.elements[next].focus();
          }
        } else {
          const next = e.target.tabIndex;
          if (next < 6) {
            e.target.form.elements[next].focus();
          }
        }
        handleChange(e.target.value, index);
      }}
    />
  );
};

export default memo(InputOTP);
