import { TopCMSIcon } from 'assets/images/dashboard';
import { ButtonCustom } from 'components/common/ButtonCustom';
import { TextForm } from 'components/common/form-item/TextForm';
import { FooterProducts } from 'components/footer/FooterProducts';
import { PATH_PAGE, PER_PAGE_DEFAULT, QUERY_KEY } from 'constant';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMutation, useQuery } from 'react-query';
import { getOptionSearch } from 'services/common';
import { searchTransaction } from 'services/transaction';
import { arrayStringToArrayNumber } from 'utils/utils';
import { PopupFilterTrans } from './PopupFilterTrans';
import { TransCardMobi } from './TransCardMobi';
import { TransMobileContext } from './TransMobileContext';

export const TransactionMobile = () => {
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [isFilterBySim, setIsFilterBySim] = useState<boolean>(false);
  const [isFilterByPackage, setIsFilterByPackage] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [dataMobile, setDataMobile] = useState<any>([]);
  const [typeProduct, setTypeProduct] = useState<any>({ id: 0, name: '', value: '' });
  const [typeTrans, setTypeTrans] = useState<any>({ id: 0, name: '', value: '' });
  const [itemName, setItemName] = useState<Array<string>>([]);
  const [tranCode, setTranCode] = useState<string>('');
  const [msisdnContact, setMsisdnContact] = useState<Array<string>>([]);
  const [startDay, setStartDay] = useState<Date | null>(null);
  const [endDay, setEndDay] = useState<Date | null>(null);

  const [typeMsisdn, setTypeMsisdn] = useState<any>([]);
  const [telco, setTelco] = useState<any>([]);
  const [typeSim, setTypeSim] = useState<any>([]);

  const [typePck, setTypePck] = useState<any>([]);
  const [cyclePck, setCyclePck] = useState<any>([]);
  const [numExpiredChoice, setNumExpiredChoice] = useState<any>([]);
  const [amount, setAmount] = useState<Array<number>>([0, 0]);

  const { data: dataCommonOption, isLoading } = useQuery(QUERY_KEY.GET_COMMON_OPTION, () => getOptionSearch());
  const mutationMobile = useMutation(QUERY_KEY.TRANSACTION_LIST, (param: any) => searchTransaction(param), {
    onSuccess: (response: any) => {
      if (response?.data?.data?.items) {
        setDataMobile([...dataMobile, ...response.data.data.items]);
        return;
      }
      setDataMobile([]);
    },
    onError: (error: any) => {
      setDataMobile([]);
    },
  });

  const mutationMobileResetRecords = useMutation(QUERY_KEY.TRANSACTION_LIST, (param: any) => searchTransaction(param), {
    onSuccess: (response: any) => {
      if (response?.data?.data?.items) {
        setDataMobile([...response.data.data.items]);
        return;
      }
      setDataMobile([]);
    },
    onError: (error: any) => {
      setDataMobile([]);
    },
  });

  const listGroupMsisdnOptions = useMemo(() => {
    return dataCommonOption?.data?.data?.listGroupMsisdn || [];
  }, [dataCommonOption]);

  const listTelco = useMemo(() => {
    return dataCommonOption?.data?.data?.TELCO || [];
  }, [dataCommonOption]);

  const listTypePackage = useMemo(() => {
    return dataCommonOption?.data?.data?.LOAIGOICUOC || [];
  }, [dataCommonOption]);

  const listCycle = useMemo(() => {
    return dataCommonOption?.data?.data?.CHUKY || [];
  }, [dataCommonOption]);

  const listPckPrice = useMemo(() => {
    return dataCommonOption?.data?.data?.pckPrice || [];
  }, [dataCommonOption]);

  const listTransStatuses = useMemo(() => {
    return dataCommonOption?.data?.data?.transStatuses || [];
  }, [dataCommonOption]);

  useEffect(() => {
    mutationMobile.mutate({
      page: 1,
      numberPerPage: PER_PAGE_DEFAULT,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buildFilter = (page?: number) => {
    let param: any = {
      tranCode: tranCode?.trim() || undefined,
      msisdnContact: isEmpty(msisdnContact) ? undefined : msisdnContact.map((item) => item?.trim()).join(','),
      itemName: isEmpty(itemName) ? undefined : itemName.map((item) => item?.trim()),
      itemType: typeProduct.value || undefined,
      status: typeTrans.value ? +typeTrans.value : undefined,
      fromDate: startDay ? startDay.toISOString() : undefined,
      toDate: endDay ? endDay.toISOString() : undefined,
      page: page || currentPage + 1,
      numberPerPage: PER_PAGE_DEFAULT,
    };
    if (isFilterBySim) {
      param = {
        ...param,
        agent: arrayStringToArrayNumber(telco),
        typeMsisdn: arrayStringToArrayNumber(typeMsisdn),
        groupMsisdn: arrayStringToArrayNumber(typeSim),
      };
    } else if (isFilterByPackage) {
      param = {
        ...param,
        agent: arrayStringToArrayNumber(telco),
        typePck: arrayStringToArrayNumber(typePck),
        cyclePck: arrayStringToArrayNumber(cyclePck),
        fromAmount: amount[0] || undefined,
        toAmount: amount[1] || undefined,
      };
    }
    return param;
  };

  const onSearchTransMobile = (page?: number) => {
    setCurrentPage(page || currentPage + 1);
    const dataSearch = buildFilter(page);
    if (page) {
      mutationMobileResetRecords.mutate(dataSearch);
    } else {
      mutationMobile.mutate(dataSearch);
    }
  };

  const isFilterOption =
    isFilterByPackage ||
    isFilterBySim ||
    !isEmpty(typeMsisdn) ||
    !isEmpty(telco) ||
    !isEmpty(typePck) ||
    !isEmpty(cyclePck) ||
    !isEmpty(numExpiredChoice) ||
    startDay ||
    endDay ||
    !isEmpty(msisdnContact) ||
    !isEmpty(itemName) ||
    !isEmpty(typeProduct?.value) ||
    !isEmpty(typeTrans?.value) ||
    !isEmpty(numExpiredChoice) ||
    (amount[0] !== 0 && amount[1] !== 0) ||
    !isEmpty(typeSim);

  const onSearchData = (dataSearch: any) => {
    setCurrentPage(1);
    mutationMobileResetRecords.mutate(dataSearch);
  };

  return (
    <TransMobileContext.Provider
      value={{
        typeProduct,
        typeTrans,
        itemName,
        tranCode,
        msisdnContact,
        startDay,
        endDay,
        // visible filter by sim or package
        isFilterBySim,
        isFilterByPackage,
        // sim
        typeMsisdn,
        telco,
        typeSim,
        // package
        typePck,
        cyclePck,

        setIsFilterBySim,
        setIsFilterByPackage,
        setTypeProduct,
        setTypeTrans,
        setItemName,
        setTranCode,
        setMsisdnContact,
        setStartDay,
        setEndDay,

        amount,
        setAmount,
        setTypeMsisdn,
        setTelco,
        setTypeSim,

        setTypePck,
        setCyclePck,
        numExpiredChoice,
        setNumExpiredChoice,
      }}
    >
      <div className={'grid grid-cols-1 px-4 '}>
        <div
          className={
            'mt-14 flex cursor-pointer flex-row space-x-2 text-[12px] font-medium leading-[16px] text-[#71717A]'
          }
        >
          <a href={PATH_PAGE.DASHBOARD}>Trang chủ</a>
          <span>|</span>
          <span>Báo cáo thống kê</span>
          <span>|</span>
          <span>Danh sách giao dịch</span>
        </div>
        <div className={'mt-1 grid grid-cols-1 gap-4'}>
          <span className={'text-lg font-bold text-baseColor'}>Danh sách giao dịch</span>
        </div>
        <div className={'mt-2 mb-6 flex flex-row space-x-2 md:hidden'}>
          <div className={'relative h-full w-full'}>
            <TextForm
              placeholder={'Tìm kiếm mã giữ số, giao dịch'}
              onChange={(e) => setTranCode(e.target.value)}
              value={tranCode}
              className={'h-[40px] rounded-[10px] pl-6 focus:outline-none'}
            />
          </div>
          <ButtonCustom
            onClick={() => {
              setDataMobile([]);
              onSearchTransMobile(1);
            }}
            label={
              <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13.4688 12.7812L17.5938 16.9062"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M8.3125 14.1562C11.9196 14.1562 14.8438 11.2321 14.8438 7.625C14.8438 4.01789 11.9196 1.09375 8.3125 1.09375C4.70539 1.09375 1.78125 4.01789 1.78125 7.625C1.78125 11.2321 4.70539 14.1562 8.3125 14.1562Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
            className={'h-[40px] w-[40px] px-2.5'}
          />
          <ButtonCustom
            onClick={() => setIsOpenFilter(!isOpenFilter)}
            label={
              <div className={'flex flex-row items-center justify-center space-x-0.5'}>
                {isFilterOption ? (
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_573_26031)">
                      <path
                        d="M10.9518 20.5827H15.1185V18.416H10.9518V20.5827ZM6.78516 14.0827H19.2852V11.916H6.78516V14.0827ZM3.66016 5.41602V7.58268H22.4102V5.41602H3.66016Z"
                        fill="#F15836"
                      />
                      <circle cx="20.12" cy="5.41492" r="4.91296" fill="#FFE1D0" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.1182 0C17.1276 0 14.7031 2.42448 14.7031 5.41509C14.7031 8.40569 17.1276 10.8302 20.1182 10.8302C23.1088 10.8302 25.5333 8.40569 25.5333 5.41509C25.5333 2.42448 23.1088 0 20.1182 0ZM22.4654 4.49945C22.5086 4.45004 22.5415 4.39249 22.5622 4.33018C22.5828 4.26788 22.5908 4.20207 22.5857 4.13663C22.5805 4.07119 22.5623 4.00744 22.5321 3.94914C22.502 3.89084 22.4605 3.83915 22.41 3.79713C22.3596 3.75511 22.3013 3.72359 22.2385 3.70444C22.1757 3.68529 22.1097 3.67889 22.0445 3.68562C21.9792 3.69235 21.9159 3.71207 21.8583 3.74361C21.8007 3.77516 21.7501 3.81791 21.7093 3.86933L19.5925 6.409L18.4971 5.31319C18.4043 5.22351 18.2799 5.17389 18.1509 5.17502C18.0218 5.17614 17.8983 5.22791 17.807 5.31918C17.7158 5.41046 17.664 5.53393 17.6629 5.663C17.6618 5.79207 17.7114 5.91643 17.801 6.00927L19.2779 7.48611C19.3263 7.53445 19.3842 7.57219 19.4479 7.59691C19.5117 7.62163 19.5799 7.63279 19.6482 7.62969C19.7165 7.62658 19.7835 7.60928 19.8447 7.57888C19.906 7.54848 19.9602 7.50566 20.004 7.45313L22.4654 4.49945Z"
                        fill="#F15836"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_573_26031">
                        <rect width="25" height="26" fill="white" transform="translate(0.535156)" />
                      </clipPath>
                    </defs>
                  </svg>
                ) : (
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11.1628 20.5827H15.3294V18.416H11.1628V20.5827ZM6.99609 14.0827H19.4961V11.916H6.99609V14.0827ZM3.87109 5.41602V7.58268H22.6211V5.41602H3.87109Z"
                      fill="#F15836"
                    />
                  </svg>
                )}
                <span className={'text-sm !font-medium text-[#404040]'}>Bộ lọc</span>
              </div>
            }
            className={'h-[40px] min-w-[100px]'}
            background={'bg-[#FFE1D0]'}
          />
          {isOpenFilter && (
            <PopupFilterTrans
              isOpen={isOpenFilter}
              setIsOpen={setIsOpenFilter}
              listGroupMsisdnOptions={listGroupMsisdnOptions}
              listTelco={listTelco}
              listTypePackage={listTypePackage}
              listCycle={listCycle}
              onClearData={(dataSearch: any) => {
                setDataMobile([]);
                onSearchData(dataSearch);
              }}
            />
          )}
        </div>
        <div className={'grid grid-cols-1 gap-4 bg-[#F8F8F8] pb-6'}>
          <InfiniteScroll
            dataLength={dataMobile.length + 15}
            next={() => onSearchTransMobile()}
            hasMore={true}
            loader={<></>}
            endMessage={<></>}
            refreshFunction={() => console.log('refresh')}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={<></>}
            releaseToRefreshContent={<></>}
            className={'space-y-2.5'}
          >
            {dataMobile.map((item: any, index: number) => {
              return <TransCardMobi item={item} key={index} />;
            })}
          </InfiniteScroll>
          {!isLoading && dataMobile.length === 0 && (
            <div className={'flex h-fit w-full flex-col items-center justify-center space-y-2  py-10'}>
              <img src={TopCMSIcon} alt="TopCMSIcon" className={'h-[165px] w-[212px]'} />
              <span className={'text-sm text-[#818181]'}>Chưa có dữ liệu</span>
            </div>
          )}
        </div>
      </div>
      <FooterProducts />
    </TransMobileContext.Provider>
  );
};
