import { createContext, Dispatch, SetStateAction } from 'react';

type ConfirmPackageContextType = {
  activeStepIndex: number;
  setActiveStepIndex: Dispatch<SetStateAction<number>>;
  item: any;
  dataForm: any;
  setDataForm: Dispatch<SetStateAction<any>>;
};

export const ConfirmPackagePopupContext = createContext<ConfirmPackageContextType>({} as ConfirmPackageContextType);
