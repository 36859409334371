import axios, { AxiosRequestConfig } from 'axios';
import configs from 'configs/config';
import Cookies from 'js-cookie';
import { PATH_PAGE } from '../constant';
import toast from 'react-hot-toast';

const axiosInstance = axios.create({
  baseURL: configs.API_DOMAIN,
});

const axiosInstanceAuth = axios.create({
  baseURL: configs.API_DOMAIN,
});

const setupInterceptors = (navigate: any) => {
  axiosInstance.interceptors.request.use(
    (config: AxiosRequestConfig<any>) => {
      const token = Cookies.get('token');
      if (token) {
        config.headers = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          // withCredentials: true,
        };
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
  axiosInstanceAuth.interceptors.request.use(
    (config: AxiosRequestConfig<any>) => {
      config.headers = {
        // withCredentials: true,
        Authorization: `Bearer ${process.env.REACT_APP_BASE_AUTH_TOKEN}`,
      };
      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => Promise.resolve(response),
    (error) => {
      if (!error.response) {
        return Promise.reject(error);
      }
      if (error.response.status === 400) {
        toast.error('Hệ thống đang bận, vui lòng liên hệ với Admin và thử lại !', { id: 'Bad Request' });
        return Promise.reject(error.response);
      }
      if (error.response.status === 401) {
        // localStorage.removeItem('token');
        // Cookies.remove('token');
        // toast.error('Hết phiên đăng nhập, vui lòng đăng nhập lại!', { id: 'Unauthorized' });
        // navigate(PATH_PAGE.LOGIN);
        return Promise.reject(error.response);
      }
      if (error.response.status === 404) {
        // navigate('/404');
        return Promise.reject(error.response);
      }
      if (error.response.status === 403) {
        localStorage.removeItem('token');
        Cookies.remove('token');
        navigate(PATH_PAGE.LOGIN);
        return Promise.reject(error.response);
      }
      // localStorage.removeItem('token');
      // Cookies.remove('token');
      // navigate(PATH_PAGE.LOGIN);
      return Promise.reject(error);
    }
  );
};

export const sendGetRequest = (url: string, params?: any) => axiosInstance.get(url, params);

export const sendPatchRequest = (url: string, params?: any) => axiosInstance.patch(url, params);

export const sendPostRequest = (url: string, params?: any) => axiosInstance.post(url, params);

export const sendPostRequestAuth = (url: string, params?: any) => axiosInstanceAuth.post(url, params);

export const sendDeleteRequest = (url: string, params?: any) => axiosInstance.delete(url, params);

export default setupInterceptors;
