import { RecaptchaVerifier } from 'firebase/auth';
import { authenticationFirebase } from '../configs/firebase';

export const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(' ');
};

export const minTwoDigits = (num: number): string => {
  return ('0' + num).slice(-2);
};

export const secondsToHms = (d: number) => {
  d = Number(d);

  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  const mDisplay = m > 0 ? m + ':' : '00:';
  const sDisplay = minTwoDigits(s);
  return mDisplay + sDisplay;
};

export const onKeyDownNumber = (e: any) => {
  const key = e.keyCode;
  const backspaceKey = 8,
    vKey = 86,
    cKey = 67,
    aKey = 65,
    tabKey = 9,
    deleteKey = 46,
    spaceKey = 32;
  const isPressedCommand = e.ctrlKey || e.metaKey;
  if (key === spaceKey) {
    e.preventDefault();
    return true;
  }
  if (key === backspaceKey) {
    return false;
  }
  if (key === tabKey) {
    return false;
  }
  if (key === deleteKey) {
    return false;
  }

  if (isPressedCommand && key === cKey) {
    return false;
  }
  if (isPressedCommand && key === vKey) {
    return false;
  }
  if (isPressedCommand && key === aKey) {
    return false;
  }

  if (isFinite(e.key)) {
    // Number
    return false;
  }
  e.preventDefault();
  return true;
};

export const onKeyDownCharacterDefault = (e: any) => {
  const key = e.keyCode;
  const backspaceKey = 8,
    vKey = 86,
    cKey = 67,
    aKey = 65,
    tabKey = 9,
    deleteKey = 46,
    spaceKey = 32;
  const isPressedCommand = e.ctrlKey || e.metaKey;

  if (key === spaceKey) {
    e.preventDefault();
    return true;
  }
  if (key === backspaceKey) {
    return false;
  }
  if (key === tabKey) {
    return false;
  }
  if (key === deleteKey) {
    return false;
  }

  if (isPressedCommand && key === cKey) {
    return false;
  }
  if (isPressedCommand && key === vKey) {
    return false;
  }
  if (isPressedCommand && key === aKey) {
    return false;
  }

  if (isFinite(e.key) || /[a-z]/i.test(e.key)) {
    // Number
    return false;
  }
  e.preventDefault();
  return true;
};

export const validatePassword = (value: string) => {
  const regExpPassword = new RegExp('^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&]$');
  return regExpPassword.test(value);
};

export const generateRecaptcha = () => {
  return new RecaptchaVerifier(
    'recaptcha-container',
    {
      size: 'invisible',
      callback: () => {},
    },
    authenticationFirebase
  );
};

export const getLabelItemType = (key: string) => {
  switch (key) {
    case 'GOICUOC':
      return 'Gói cước';
    case 'SIMSO':
      return 'Sim số';
    case 'VIETLOTT':
      return 'Vietlott';
    default:
      return '';
  }
};

export const getLabelItemAgent = (key?: number) => {
  switch (key) {
    case 0:
      return 'MobiFone';
    case 1:
      return 'Viettel';
    // case 2:
    //   return 'Reddi';
    case 3:
      return 'VinaPhone';
    case 4:
      return 'Saymee';
    default:
      return '';
  }
};

export const getTypePackage = (key: number) => {
  switch (key) {
    case 1:
      return 'Gói hot';
    case 2:
      return 'Trả sau';
    case 3:
      return 'Trả trước';
    case 4:
      return 'Data';
    default:
      return '';
  }
};

export const getLabelPackTypeChoice = (packTypeChoice: number) => {
  switch (packTypeChoice) {
    case 1:
      return 'Gói Hot';
    case 2:
      return 'Data';
    case 3:
      return 'Thoại & SMS';
    default:
      return '';
  }
};

export const getLabelNumExpiredChoice = (packTypeChoice: number) => {
  switch (packTypeChoice + '') {
    case '1':
      return 'Dưới 7 ngày';
    case '2':
      return 'Từ 7 - 30 ngày';
    case '3':
      return 'Từ 30 - 90 ngày';
    case '4':
      return 'Từ 90 - 180 ngày';
    case '5':
      return 'Từ 180 - 270 ngày';
    case '6':
      return 'Từ 270 - 360 ngày';
    case '7':
      return 'Trên 360 ngày';
    default:
      return '';
  }
};

export const getLabelTypeNumber = (
  packTypeChoice: string | number,
  list: Array<{ groupName: string; id: string | number }>
) => {
  return list?.find((item) => item.id === packTypeChoice)?.groupName || '';
};

export const fileToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const arrayStringToArrayNumber = (list?: Array<string>) => {
  if (!list) return [];
  return list.map((item) => Number(item));
};

// Tìm kiếm theo khi tìm kiếm cho sđt vinaphone
export const checkNumExpired = (num: string[], data: number) => {
  if (num.includes('1') && data <= 7) {
    return true;
  }

  if (num.includes('2') && data >= 7 && data <= 30) {
    return true;
  }

  if (num.includes('3') && data >= 30 && data <= 90) {
    return true;
  }

  if (num.includes('4') && data >= 90 && data <= 180) {
    return true;
  }

  if (num.includes('5') && data >= 180 && data <= 270) {
    return true;
  }

  if (num.includes('6') && data >= 270 && data <= 360) {
    return true;
  }

  if (num.includes('7') && data > 360) {
    return true;
  }

  return false;
};

export const randomSubArrays = (data: any[], numOfItems: number) => {
  if (numOfItems > data?.length) {
    return data;
  }

  let result = new Array(numOfItems),
    len = data.length,
    taken = new Array(len);
  while (numOfItems--) {
    var x = Math.floor(Math.random() * len);
    result[numOfItems] = data[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
};

export const redirectDownload = () => {
  var platform = (window.navigator as any)?.userAgentData?.platform || window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'];

  if (
    macosPlatforms.indexOf(platform) !== -1 ||
    iosPlatforms.indexOf(platform) !== -1 ||
    /iPad|iPhone|iPod/.test(navigator.userAgent)
  ) {
    window.open('https://apps.apple.com/us/app/shop360/id6449772995', '_blank');
  } else {
    window.open('https://play.google.com/store/apps/details?id=vn.osp.shop360', '_blank');
  }
};

export const numbersWithDots = (x: number | string) => {
  let temp = "0"
  if (x != null) temp = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
  return temp
}

