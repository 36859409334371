import {TableTransaction} from './TableTransaction';
import {PaginationCustom} from 'components/common/pagination/Pagination';
import {TextForm} from 'components/common/form-item/TextForm';
import {TimeForm} from 'components/common/form-item/TimeForm';
import {SelectForm} from 'components/common/form-item/SelectForm';
import {ButtonCustom} from 'components/common/ButtonCustom';
import {useState} from 'react';
import {SelectOptionEntity} from '../../../../entity/SearchEntity';
import {KeyboardDoubleArrowDown} from 'assets/images';

const productStatus: Array<SelectOptionEntity> = [
	{id: 0, name: '-- Tất cả --', value: 0},
	{id: 1, name: 'Hiển thị', value: 1},
	{id: 2, name: 'Ẩn', value: 2},
];

const typeFilters: Array<SelectOptionEntity> = [
	{id: 1, name: 'Sim số', value: 1},
	{id: 2, name: 'Gói cước', value: 2},
];

export const Transaction = () => {
	const [isShowMore, setIsShowMore] = useState<boolean>(false);
	const [typeFilter, setTypeFilter] = useState<SelectOptionEntity>(typeFilters[0]);
	const [startDay, setStartDay] = useState<Date | null>(null);
	const [endDay, setEndDay] = useState<Date | null>(null);

	return (
		<>
			<div className={'w-full rounded-2xl bg-white p-6 shadow'}>
				<div className={'grid-cols-1-1 grid gap-4 md:grid-cols-2'}>
					<SelectForm label={'Danh mục sản phẩm'} options={productStatus} classIcon={'text-gray-400'}/>
					<TextForm label={'Sản phẩm'} placeholder={'Tìm kiếm tên sản phẩm'}/>
					<TextForm label={'Số thuê bao'} placeholder={'Tìm kiếm theo số thuê bao khách hàng'}/>
					<SelectForm label={'Trạng thái giao dịch'} options={productStatus} classIcon={'text-gray-400'}/>
					<TextForm label={'Mã giao dịch'} placeholder={'Tìm kiếm theo mã giao dịch, mã giữ số'}/>
					<TimeForm
						label={'Thời gian giao dịch'}
						startDay={startDay}
						setStartDay={setStartDay}
						endDay={endDay}
						setEndDay={setEndDay}
					/>
					<TextForm label={'Cộng tác viên'} placeholder={'Tìm kiếm theo số điện thoại Cộng tác viên'}/>
				</div>
				{isShowMore && (
					<>
						<div className={'flex flex-row items-center space-x-2 py-6'}>
							<h2 className={'text-[15px] font-semibold text-blue-500'}>Bộ lọc theo</h2>
							<SelectForm
								options={typeFilters}
								className={'w-[7.5rem] border-blue-500 text-sm font-medium text-blue-500'}
								classIcon={'text-blue-500'}
								// onChange={setTypeFilter}
								// value={typeFilter}
							/>
						</div>
						{typeFilter.id === 1 ? (
							<div className={'grid-cols-1-1 grid gap-4 md:grid-cols-2'}>
								<SelectForm label={'Loại thuê bao'} options={productStatus} classIcon={'text-gray-400'}/>
								<SelectForm label={'Loại số'} options={productStatus} classIcon={'text-gray-400'}/>
								<SelectForm label={'Telco'} options={productStatus} classIcon={'text-gray-400'}/>
							</div>
						) : (
							<div className={'grid-cols-1-1 grid gap-4 md:grid-cols-2'}>
								<SelectForm label={'Loại gói cước'} options={productStatus} classIcon={'text-gray-400'}/>
								<SelectForm label={'Giá gói'} options={productStatus} classIcon={'text-gray-400'}/>
								<SelectForm label={'Telco'} options={productStatus} classIcon={'text-gray-400'}/>
								<SelectForm label={'Chu kỳ'} options={productStatus} classIcon={'text-gray-400'}/>
							</div>
						)}
					</>
				)}
				<div className={'mt-6 flex flex-row items-center justify-center space-x-2.5'}>
					<ButtonCustom
						label={'Xoá điều kiện'}
						className={'border border-gray-600 bg-white px-2 py-[7px] !text-sm !font-semibold !text-gray-600'}
					/>
					<ButtonCustom label={'Tìm kiếm'} className={'bg-gray-600 px-2 py-[8px] !text-sm !font-semibold'}/>
					<ButtonCustom
						label={
							<img src={KeyboardDoubleArrowDown} className={`${isShowMore ? 'rotate-180' : ''}`} alt={'keydown'}/>
						}
						onClick={() => setIsShowMore((v) => !v)}
						className={'border border-gray-600 bg-white px-2 py-[7px] !text-sm !font-semibold !text-gray-600'}
					/>
				</div>
			</div>
			<div className={'flex flex-row items-center justify-start py-6'}>
				<span className={'text-2xl font-semibold text-gray-700'}>{'100 cộng tác viên'}</span>
			</div>
			<TableTransaction dataRows={dataRows}/>
			<div className={'mt-6'}>
				<PaginationCustom/>
			</div>
			<div className={'h-6'}></div>
		</>
	);
};

const dataRows = [
	{
		stt: 1,
		time: '12/12/2022 | 09:09:09',
		code: 'S220531-70011',
		category: 'Sim số',
		collaborator: '01121245544',
		product: '0902123456',
		customer: '090XXXXXXX',
		type: 'Hoa hồng phát triển thuê bao trả trước',
		status: false,
		value: 50000,
		commission: 0,
	},
	{
		stt: 2,
		time: '12/12/2022 | 09:09:09',
		code: 'S220531-70011',
		category: 'Sim số',
		collaborator: '01121245544',
		product: '0902123456',
		customer: '090XXXXXXX',
		type: 'Hoa hồng phát triển thuê bao trả trước',
		status: true,
		value: 50000,
		commission: 50000,
	},
	{
		stt: 3,
		time: '12/12/2022 | 09:09:09',
		code: 'S220531-70011',
		category: 'Gói cước',
		collaborator: '01121245544',
		product: '0902123456',
		customer: '090XXXXXXX',
		type: 'Hoa hồng phát triển thuê bao trả trước',
		status: true,
		value: 50000,
		commission: 50000,
	},
	{
		stt: 4,
		time: '12/12/2022 | 09:09:09',
		code: 'S220531-70011',
		category: 'Sim số',
		collaborator: '01121245544',
		product: '0902123456',
		customer: '090XXXXXXX',
		type: 'Hoa hồng phát triển thuê bao trả trước',
		status: true,
		value: 50000,
		commission: 50000,
	},
	{
		stt: 5,
		time: '12/12/2022 | 09:09:09',
		code: 'S220531-70011',
		category: 'Sim số',
		collaborator: '01121245544',
		product: '0902123456',
		customer: '090XXXXXXX',
		type: 'Hoa hồng phát triển thuê bao trả trước',
		status: true,
		value: 50000,
		commission: 50000,
	},
	{
		stt: 6,
		time: '12/12/2022 | 09:09:09',
		code: 'S220531-70011',
		category: 'Sim số',
		collaborator: '01121245544',
		product: '0902123456',
		customer: '090XXXXXXX',
		type: 'Hoa hồng phát triển thuê bao trả trước',
		status: true,
		value: 50000,
		commission: 50000,
	},
];
