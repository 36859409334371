import { TableV2 } from 'components/common/TableV2';
import { useCallback } from 'react';
import { get } from 'lodash';
import { classNames } from '../../../utils/utils';
import { PATH_PAGE, TRANS_TYPE } from '../../../constant';
import { useNavigate } from 'react-router-dom';

export const TableHistory = ({ dataHistory }: any) => {
  const navigator = useNavigate();
  function getBalanceHisType(type: number) {
    let returnStr = "-"
    switch (type) {
      case TRANS_TYPE.HH_CAPDUOI_GOICUOC:
      case TRANS_TYPE.HHKD_GOICUOC:
        returnStr = "Gói cước" //"Gói cước"
        break
      case TRANS_TYPE.HH_CAPDUOI_THUEBAO:
      case TRANS_TYPE.HHKD_THUEBAO:
        returnStr = "Sim số" //"Sim số"
        break
      case TRANS_TYPE.RUT_TIEN:
        returnStr = "Rút tiền" //"Rút tiền"
        break
      case TRANS_TYPE.SU_KIEN:
        returnStr = "Sự kiện" //"Sự kiện"
        break
      case TRANS_TYPE.DIEU_CHINH:
        returnStr = "Điều chỉnh" //"Điều chỉnh"
        break
      case TRANS_TYPE.DUY_TRI:
        returnStr = "Duy trì" //"Duy trì"
        break
      case TRANS_TYPE.EDUCA:
      case TRANS_TYPE.EDUCA_GIAHAN:
      case TRANS_TYPE.HH_CAPDUOI_EDUCA:
        returnStr = "Educa" // Educa
        break
      default:
        break
    }
    return returnStr
  }


  const onRowClick = useCallback(
    (row: any) => {
      switch (row.transType) {
        case 1:
        case 2:
          return navigator(`${PATH_PAGE.STATISTIC_TRANSACTION}/info/${row.transCode}`);
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
          return navigator(
            `${PATH_PAGE.ACCOUNT_COLLABORATOR}/info-payment?transCode=${row.transCode}&transType=${row.transType}&stt=${row.stt}`
          );
        default:
          return;
      }
    },
    [navigator]
  );
  const renderRow = useCallback((row: any) => {
    return (
      <tr
        key={row?.stt}
        onClick={() => {
          onRowClick(row);
        }}
        className="cursor-fancy border-b bg-white hover:z-50 hover:shadow-[0px_0px_10px_rgba(209,209,209,0.2)] hover:drop-shadow-[0px_0px_8px_rgba(255,158,110,0.6)]"
      >
        <td className="rounded-l-[15px] p-4 text-center text-sm font-normal text-[#404040]">{row.genDateStr}</td>
        <td className="p-4 text-center text-sm font-medium text-[#404040]">{getBalanceHisType(row.transType)}</td>
        <td className="p-4 text-left text-sm font-extrabold text-[#404040]">
          <p className={'font-semibold'}>{row.title}</p>
          <p className={'font-normal'}>{row.transTypeStr}</p>
        </td>
        <td
          className={classNames(
            'p-4 text-center text-sm font-normal',
            get(row, 'amount') > 0 ? 'text-[#29CC6A]' : get(row, 'amount') < 0 ? 'text-[#FC5555]' : 'text-baseColor'
          )}
        >
          {get(row, 'amount') ? get(row, 'amount').toLocaleString('vi') : '-'}
        </td>
        <td className="p-4 text-center text-sm font-normal text-[#404040]">
          {get(row, 'currentAmount')?.toLocaleString('vi') || 0}
        </td>
        <td className="rounded-tr-[15px] p-4 text-center text-sm font-medium text-[#404040]">
          <div className={'flex flex-row items-center justify-center space-x-1'}>
            {row.status ? (
              <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.0546875 2.45313C0.0546875 1.34856 0.950118 0.453125 2.05469 0.453125H6.05469C7.15926 0.453125 8.05469 1.34856 8.05469 2.45312V8.45312H2.05469C0.950118 8.45312 0.0546875 7.55769 0.0546875 6.45312V2.45313Z"
                  fill="#29CC6A"
                />
              </svg>
            ) : (
              <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0.0546875 2.45312C0.0546875 1.34856 0.950118 0.453125 2.05469 0.453125H6.05469C7.15926 0.453125 8.05469 1.34856 8.05469 2.45312V8.45312H2.05469C0.950118 8.45312 0.0546875 7.55769 0.0546875 6.45312V2.45312Z"
                  fill="#D6D5D5"
                />
              </svg>
            )}
            <span>{row.statusStr}</span>
          </div>
        </td>
      </tr>
    );
  }, []);

  // console.log('aaaaa', dataHistory);

  return <TableV2 labelHeaders={labelHeader} dataRows={dataHistory} renderRow={renderRow} />
};

const labelHeader = [
  {
    className: 'w-[150px] text-[#818181] font-semibold text-[10px] leading-[12px] uppercase',
    label: 'Ngày',
  },
  {
    className: 'w-[155px] !text-center text-[#818181] font-semibold text-[10px] leading-[12px] uppercase',
    label: 'Loại giao dịch',
  },
  {
    className: '!text-left !px-4 text-[#818181] font-semibold text-[10px] leading-[12px] uppercase',
    label: 'Mô tả',
  },
  {
    className: 'w-[154px] text-[#818181] font-semibold text-[10px] leading-[12px] uppercase',
    label: 'Số tiền (VNĐ)',
  },
  {
    className: 'w-[154px] text-[#818181] font-semibold text-[10px] leading-[12px] uppercase',
    label: 'Số dư (VNĐ)',
  },
  {
    className: 'min-w-[155px] text-[#818181] font-semibold text-[10px] leading-[12px] uppercase',
    label: 'Trạng thái',
  },
];