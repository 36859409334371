import ScrollToTop from 'components/common/ScrollToTop';
import { FooterMobi } from 'components/footer/FooterMobi';
import { HeaderCMSCommon } from 'components/header/HeaderCMSCommon';
import useCheckDeviceMobi from 'hooks/useCheckDeviceMobi';
import { classNames } from 'utils/utils';

export const PaymentPolicy = () => {
  const isMobile = useCheckDeviceMobi();
  return (
    <>
      <ScrollToTop />
      <HeaderCMSCommon />

      <div className={classNames('w-full bg-[#F8F8F8] pb-10', isMobile ? 'pt-20' : 'pt-24')}>
        <div className={'mx-auto w-full max-w-[85rem] px-4'}>
          <div
            className={classNames(
              'mx-auto w-full max-w-[62rem] rounded-[4px] bg-white shadow-[0_0_20px_0_rgba(0,0,0,0.05)]',
              isMobile ? 'p-4' : 'p-8'
            )}
          >
            <h1 className={'text-center text-[32px] font-semibold leading-[40px] text-[#F15836]'}>
              CHÍNH SÁCH THANH TOÁN
            </h1>

            <div className={'mt-2'}>
              <section className="[&>*]:text-justify [&>*]:leading-[25px]">
                <p className={'break-words text-left font-semibold text-baseColor'}>
                  Vui lòng đọc trước quy định và hình thức thanh toán này. Khi đặt hàng, chúng tôi mặc định Cộng Tác
                  Viên đã đồng ý với quy định này. Các quy định có thể thay đổi để phù hợp với pháp luật TMĐT và dịch vụ
                  của Shop360.vn.
                </p>
              </section>

              <section className={classNames(isMobile ? 'mt-6' : 'mt-8', '[&>*]:text-justify [&>*]:leading-[25px]')}>
                <h1 className={'text-[16px] font-semibold  text-baseColor'}>1. Chính sách với Cộng Tác Viên:</h1>

                <section className={'mt-2 break-words text-left text-sm font-medium text-baseColor'}>
                  a. Hỗ trợ một hoặc nhiều phương thức thanh toán cho các Đơn Đặt Hàng trên , bao gồm nhưng không giới
                  hạn ở:
                  <ul className="grid gap-y-2">
                    <li>(i) Thanh toán tiền mặt;</li>

                    <li>(ii) Thanh toán qua đối tác được Shop360 chỉ định;</li>

                    <li>(iii) Ví điện tử;</li>

                    <li>(iv) Chuyển khoản ngân hàng;</li>

                    <li>(vii) Các hình thức thanh toán khác được hỗ trợ tại từng thời điểm.</li>
                  </ul>
                </section>

                <p className={'mt-2 break-words text-left text-sm font-medium text-baseColor'}>
                  b. Các hình thức thanh toán khả dụng có thể khác nhau tùy theo đối tượng người mua hàng, loại Sản
                  Phẩm, giá trị Đơn Đặt Hàng, khu vực giao hàng hoặc các trường hợp khác theo quy định pháp luật và
                  chính sách của Shop360 tại từng thời điểm.
                </p>
              </section>

              <section className={'mt-2 break-words text-left text-sm font-medium text-baseColor'}>
                <h1 className={'text-[16px] font-semibold  text-baseColor'}>
                  2. Vui lòng xem “Chính sách thanh toán và hoàn tiền” và thông tin thanh toán trên từng Đơn Đặt Hàng để
                  biết thêm thông tin chi tiết.
                </h1>
              </section>
            </div>
          </div>
        </div>
      </div>
      <FooterMobi />
    </>
  );
};
