import { useEffect, useRef } from 'react';
import { classNames } from '../../utils/utils';

export const TableV2 = ({
  labelHeaders,
  dataRows,
  renderRow,
  className,
  classNameHeader,
  onScroll,
  bodyMaxHeightClass,
  stickyHeader,
}: any) => {
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof onScroll !== 'undefined') {
      onScroll(tableRef.current);
    }
  }, [onScroll]);

  return (
    <div
      ref={tableRef}
      className={classNames(
        'relative -ml-1 w-[calc(100%+1rem)] overflow-x-auto',
        bodyMaxHeightClass ? `${bodyMaxHeightClass} overflow-auto` : ''
      )}
      onScroll={(e: any) => {
        if (typeof onScroll !== 'undefined' && e?.target) {
          onScroll(e);
        }
      }}
    >
      <table
        className={classNames(
          'w-full table-auto border-separate border-spacing-y-2.5 px-2 text-left shadow',
          className
        )}
      >
        <thead className={classNames('h-[40px] bg-white shadow-base', stickyHeader ? 'sticky top-0' : '')}>
          <tr className={''}>
            {labelHeaders.map((_header: any) => (
              <th
                key={_header.label}
                className={classNames(
                  `px-2 text-center text-[10px] !font-extrabold text-[#818181] first:rounded-bl-[15px] first:rounded-tl-[15px] last:rounded-tr-[15px] ${
                    classNameHeader || ''
                  }`,
                  _header.className
                )}
              >
                <span>{_header.label}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{dataRows.map((row: any, index: number) => renderRow(row, index))}</tbody>
      </table>
    </div>
  );
};
