import { MobiFoneIcon, ViettelIcon, VinaphoneIcon } from 'assets/images/supplier';
import { SUPPLIER } from '../constant';

export const getIconSupplier = (key: number) => {
  // console.log(key);
  switch (key) {
    case SUPPLIER.MOBIFONE:
      return <img className="h-[58px] w-[58px]" src={MobiFoneIcon} alt="mobifone" />;
    // case 'Viettel':
    //   return <img className="h-[58px] w-[58px]" src={ViettelIcon} alt="Viettel" />;
    // case 'Reddi':
    //   return <img className="h-[58px] w-[58px]" src={ReddiIcon} alt="Reddi" />;
    case SUPPLIER.VINAPHONE:
      return <img className="h-[58px] w-[58px]" src={VinaphoneIcon} alt="Vinaphone" />;
    case SUPPLIER.SAYMEE:
      return <span>Saymee</span>;
    default:
      return <></>;
  }
};
