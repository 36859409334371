import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import firebaseC from '../firebase.json';

// Initialize Firebase
const app = initializeApp(firebaseC);
const analytics = getAnalytics(app);

export const authenticationFirebase = getAuth(app);
