import { isArray } from 'lodash';

export const CheckBoxFilter = ({ name, label, value, onChange }: any) => {
  return (
    <div className={'flex w-full flex-row justify-between'}>
      <label className={'ml-1 cursor-pointer text-sm font-medium text-baseColor'} htmlFor={`${label}-${name}-checkbox`}>
        {label}
      </label>
      <input
        type={'checkbox'}
        className={
          'focus:ring-3 form-checkbox h-[20px] w-[20px] cursor-pointer rounded-t-[3px] rounded-bl-[3px] border border-[#A9A9A9] checked:h-[20px] checked:w-[20px] checked:border-[#F15836] checked:bg-[#FFC7BB] checked:fill-[#15836] hover:border-[#15836]'
        }
        checked={value && isArray(value) && (value.includes(name) || value.includes(-1) || value.includes('-1'))}
        onChange={(e) => {
          if (e.target.checked) {
            onChange([...value, name]);
          } else {
            onChange(value.filter((item: any) => item !== name));
          }
        }}
        id={`${label}-${name}-checkbox`}
      />
    </div>
  );
};
