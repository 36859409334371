import { SelectOptionEntity } from 'entity/SearchEntity';

export const typeProducts: Array<SelectOptionEntity> = [
  { id: 0, name: '', value: '' },
  // {
  //   id: 1,
  //   name: 'Sim số',
  //   value: 'SIMSO',
  // },
  { id: 2, name: 'Gói cước', value: 'GOICUOC' },
  // { id: 3, name: 'Vietlott', value: 'VIETLOTT' },
];
