import { sendGetRequest, sendPostRequest } from './index';
import { apiTransaction } from './api/transaction';

export const searchTransaction = (param: any) => sendPostRequest(apiTransaction.searchTrans, param);

export const exportExcelTransaction = (param: any) => sendGetRequest(apiTransaction.exportTrans, param);

export const getInfoTransaction = (tranCode: any) =>
  sendPostRequest(apiTransaction.detailTrans, {
    tranCode,
  });
