import { SpinCustom } from 'components/common/SpinCustom';
import { isEmpty } from 'lodash';
import { CarouselCard } from 'pages/main/Carousel/CarouselCard';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useMutation } from 'react-query';
import { getNewsList } from 'services/common';
// import { CardNew } from '../CardNew';

export const ListMobile = () => {
  const [indexDataCarousel, setIndexDataCarousel] = useState(1);
  const [keySearch, setKeySearch] = useState('');
  const [dataNews, setDataNews] = useState<any>([]);

  const mutationSearchSimMobile = useMutation(
    ({ _currentPage, _keySearch }: any) =>
      getNewsList({
        homenum: 1,
        page: _currentPage,
        sizeOfPage: 10,
        title: _keySearch?.trim(),
      }),
    {
      onSuccess: (response) => {
        if (!response?.data?.data?.items) {
          setDataNews([]);
          return;
        }
        setDataNews([...dataNews, ...response.data.data.items]);
      },
    }
  );

  useEffect(() => {
    mutationSearchSimMobile.mutate({ _currentpage: 1, _keySearch: '' });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (e: any) => {
    if (e.key === 'Enter') {
      setIndexDataCarousel(1);
      setKeySearch(e.target.value || '');
      mutationSearchSimMobile.mutate({ _currentPage: 1, _keySearch: e.target.value || '' });
    }
  };

  const onNextPageMobile = () => {
    setIndexDataCarousel(indexDataCarousel + 1);
    mutationSearchSimMobile.mutate({ _currentPage: indexDataCarousel + 1, _keySearch: keySearch });
  };

  return (
    <div className={'mx-auto w-full max-w-[62rem] py-6'}>
      <div className={'flex flex-row items-center justify-between'}>
        <h1 className={'text-lg font-bold text-baseColor'}>Tin tức & sự kiện</h1>
        <div className="relative h-[40px] w-1/2 rounded-l-[50px] rounded-r-[50px] border border-[#D6D5D5]">
          <input
            className={
              'h-full w-full bg-transparent indent-10 text-baseColor focus:outline-none' +
              ' text-sm font-medium placeholder:text-sm placeholder:font-medium placeholder:text-[#818181]'
            }
            placeholder={'Tìm kiếm tin tức'}
            onKeyDown={handleSearch}
          />
          <svg
            className={'pointer-events-none absolute left-4 top-3.5'}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.7897 12.7744C14.0701 13.0548 14.0701 13.5093 13.7897 13.7897C13.5093 14.0701 13.0548 14.0701 12.7744 13.7897L10.2616 11.2769C9.98119 10.9965 9.98119 10.5419 10.2616 10.2616C10.5419 9.98119 10.9965 9.98119 11.2769 10.2616L13.7897 12.7744ZM5.74359 11.4872C2.57149 11.4872 0 8.91569 0 5.74359C0 2.57149 2.57149 0 5.74359 0C8.91569 0 11.4872 2.57149 11.4872 5.74359C11.4872 8.91569 8.91569 11.4872 5.74359 11.4872ZM5.74359 10.0513C8.12266 10.0513 10.0513 8.12266 10.0513 5.74359C10.0513 3.36452 8.12266 1.4359 5.74359 1.4359C3.36452 1.4359 1.4359 3.36452 1.4359 5.74359C1.4359 8.12266 3.36452 10.0513 5.74359 10.0513Z"
              fill="#818181"
            />
          </svg>
        </div>
      </div>
      {mutationSearchSimMobile.isLoading && <SpinCustom />}
      <div className="flex h-full w-full flex-col space-y-2.5">
        <InfiniteScroll
          dataLength={dataNews.length + 15}
          next={() => onNextPageMobile()}
          hasMore={true}
          loader={<></>}
          endMessage={<></>}
          refreshFunction={() => console.log('refresh')}
          pullDownToRefresh
          pullDownToRefreshThreshold={50}
          pullDownToRefreshContent={<></>}
          releaseToRefreshContent={<></>}
          className={'grid grid-cols-1 gap-4'}
        >
          {dataNews.map((news: any, index: number) => (
            <CarouselCard key={index} item={news} />
          ))}
        </InfiniteScroll>
        {!mutationSearchSimMobile.isLoading && isEmpty(dataNews) && <span className="mt-8">Không có dữ liệu</span>}
      </div>
    </div>
  );
};
