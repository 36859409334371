import ScrollToTop from 'components/common/ScrollToTop';
import { FooterMobi } from 'components/footer/FooterMobi';
import useCheckDeviceMobi from 'hooks/useCheckDeviceMobi';
import { HeaderCMSCommon } from '../../components/header/HeaderCMSCommon';
import { classNames } from 'utils/utils';

export const QuestionsPage = () => {
  const isMobile = useCheckDeviceMobi();
  return (
    <>
      <ScrollToTop />
      <HeaderCMSCommon />
      <div className={'w-full bg-[#F8F8F8] pt-8'}>
        <div className={'mx-auto w-full max-w-[85rem] px-4'}>
          <div className={'mx-auto w-full max-w-[62rem] py-16'}>
            <div
              className={classNames(
                'mx-auto w-full max-w-[62rem] rounded-[4px] bg-white shadow-[0_0_20px_0_rgba(0,0,0,0.05)]',
                isMobile ? 'p-4' : 'p-8'
              )}
            >
              <h1 className={'text-center text-[32px]  font-extrabold text-[#F15836]'}>CÁC CÂU HỎI THƯỜNG GẶP</h1>
              <div className={'mt-6 grid grid-cols-1 gap-6 [&>*]:text-justify [&>*]:leading-[25px]'}>
                <h1 className={'text-[18px] font-extrabold  text-baseColor'}>I. Tìm hiểu chung về Shop360</h1>
                <h2 className={'text-[14px] font-extrabold  text-baseColor'}>Câu 1: Shop 360 là gì?</h2>
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Shop360 là ứng dụng kiếm tiền trên nền tảng Tiếp thị liên kết (Affiliate marketing), anh/chị có thể
                  gia tăng thu nhập bằng cách kinh doanh các sản phẩm cho người tiêu dùng trong danh mục có sẵn của
                  Shop360, anh chị sẽ được chi trả lại hoa hồng theo sản phẩm đã bán thành công.
                </p>
                <h2 className={'text-[14px] font-extrabold  text-baseColor'}>
                  Câu 2: Đối tượng tham gia Shop360 là ai?
                </h2>
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Bất kì ai có mong muốn gia tăng thêm thu nhập đều có thể tham gia Shop360, anh chị không cần bỏ vốn,
                  không cần nghỉ việc hoặc cam kết thời gian làm việc, Shop360 mang lại cho anh chị cơ hội gia tăng thu
                  nhập nhanh chóng và an toàn.
                </p>
                <h2 className={'text-[14px] font-extrabold  text-baseColor'}>
                  Câu 3: Shop360 kinh doanh những sản phẩm gì?
                </h2>
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Shop360 kinh doanh đa dạng các sản phẩm như Sim số, gói cước, sản phẩm tài chính, từ các thương hiệu
                  lớn như MobiFone, Saymee, Vietlott…
                </p>
                <h2 className={'text-[14px] font-extrabold  text-baseColor'}>
                  Câu 4: Sử dụng Shop360 để kiếm tiền có an toàn không?
                </h2>
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Shop360 an toàn 100% với các lý do sau: <br />
                  + Các nhà cung cấp sản phẩm, dịch vụ trên Shop360 là những công ty viễn thông hàng đầu Việt Nam như
                  MBF, Vietlott...
                  <br />+ CTV sẽ biết trước hoa hồng mình nhận được với mỗi sản phẩm bán. Shop360 thực hiện thanh toán
                  hoa hồng cho người dùng ngay trong ngày, không giam giữ, không thu phí quản lý...
                </p>
                <h2 className={'text-[14px] font-extrabold  text-baseColor'}>
                  Câu 5: Cách thức chi trả hoa hồng cho CTV như thế nào?
                </h2>
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  + Mỗi một giao dịch thành công anh chị sẽ được chi trả hoa hồng vào tài khoản của Shop360. Khi tài
                  khoản từ 200k trở lên, anh/chị có thể rút về tài khoản ngân hàng của mình (tối đa 1 lần/ngày).
                  <br />+ Để thực hiện thanh toán, Cộng tác viên phải liên kết đúng tài khoản ngân hàng và cập nhật đủ
                  thông tin cá nhân trước khi tạo yêu cầu thanh toán. Lưu ý: Họ tên trên tài khoản Cộng tác viên, trên
                  CMND/CCCD/Hộ chiếu và trên thông tin tài khoản ngân hàng phải trùng khớp.
                </p>
                <h1 className={'text-[18px] font-extrabold  text-baseColor'}>II. Đăng ký</h1>
                <h2 className={'text-[14px] font-extrabold  text-baseColor'}>
                  Câu 1: Để đăng ký làm CTV, tôi cần thực hiện như thế nào?
                </h2>
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Để đăng ký làm CTV, anh/chị vui lòng truy cập website, vào mục Đăng ký, nhập số điện thoại đăng ký,
                  sau đó nhập mật khẩu để đăng nhập vào ứng dụng. Khi hệ thống ghi nhận đăng ký thành công, người dùng
                  có thể nhấn nút Đăng nhập để thực hiện đăng nhập vào hệ thống Shop360 bằng tài khoản mới được đăng ký
                  thành công.
                  <br /> ***Anh/chị lưu ý Cung cấp chính xác thông tin cá nhân như CMND, email, số điện thoại.
                </p>
                <h2 className={'text-[14px] font-extrabold  text-baseColor'}>
                  Câu 2: Làm thế nào khi quên mật khẩu đăng nhập?
                </h2>
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Tại giao diện Đăng nhập, anh/chị nhấn nút Quên mật khẩu, hệ thống hiển thị màn hình Thiết lập lại mật
                  khẩu. Người dùng tiến hành nhập Số điện thoại đă đăng ký và tạo mật khẩu mới.
                </p>
                <h1 className={'text-[18px] font-extrabold  text-baseColor'}>III. Sử dụng</h1>
                <h2 className={'text-[14px] font-extrabold  text-baseColor'}>
                  Câu 1: Cộng tác viên kiểm tra được những thông tin gì trên hệ thống?
                </h2>
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Hiện tại hệ thống sẽ hỗ trợ anh/chị kinh doanh và kiểm soát hoa hồng nhận được từ việc bán các sản
                  phẩm thành công. Ngoài ra hệ thống sẽ hiển thị top 5 các sản phẩm kinh doanh hiệu quả nhất của CTV
                  trong khoảng thời gian đã lựa chọn hiển thị. Anh/chị có thể nắm bắt rõ được sản phẩm nào nhận được
                  nhiều doanh thu và hoa hồng nhiều nhất trong tất cả các sản phẩm mình kinh doanh.
                </p>
                <h2 className={'text-[14px] font-extrabold  text-baseColor'}>
                  Câu 2: Tại sao tôi không upload được ảnh CMT/CCCD trên hệ thống?
                </h2>
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Anh/chị vui lòng thao tác lại, sau khi chọn ảnh thì chờ một lúc cho ảnh load lên thành công rồi mới
                  bấm Lưu.
                </p>
                <h1 className={'text-[18px] font-extrabold  text-baseColor'}>IV. Hoa hồng và thanh toán</h1>
                <h2 className={'text-[14px] font-extrabold  text-baseColor'}>
                  Câu 1: Muốn biết hoa hồng nhận được khi bán hàng thành công thì làm thế nào?
                </h2>
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Anh/chị vui lòng xem ở màn hình Dashboard, phần menu Tài khoản. Trên trang web có công khai chính sách
                  hoa hồng với từng sản phẩm. Tại đây người dùng có thể quản lý, xem hoa hồng của mình thay đổi như thế
                  nào, từ giao dịch nào, từ yêu cầu thanh toán nào, và trạng thái như thế nào...
                </p>
                <h2 className={'text-[14px] font-extrabold  text-baseColor'}>
                  Câu 2: Hoa hồng tối thiểu và tối đa là bao nhiêu thì được rút về tài khoản?
                </h2>
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Hoa hồng tối thiểu 200,000đ thì anh/chị có thể đặt lệnh thanh toán và không giới hạn số tiền tối đa.
                  <br />
                  Lưu ý : CTV sẽ không thể tạo yêu cầu thanh toán nếu đang có yêu cầu thanh toán đang ở trạng thái Chờ
                  duyệt (xem trạng thái yêu cầu thanh toán ở màn hình Lịch sử thanh toán).
                </p>
                <h2 className={'text-[14px] font-extrabold  text-baseColor'}>
                  Câu 3: Vì sao tôi đã tạo yêu cầu thanh toán như hướng dẫn nhưng lại bị từ chối?
                </h2>
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Đối với khoản tạo yêu cầu bị từ chối, anh/chị vui lòng xem lại thông tin số tài khoản hoặc ngân hàng
                  thụ hưởng có đúng không? Ví dụ: Tên tài khoản ngân hàng phải trùng với thông tin tên tài khoản CTV.
                </p>
                <h2 className={'text-[14px] font-extrabold  text-baseColor'}>
                  Câu 4: Vì sao đã tạo yêu cầu thanh toán trên web thành công nhưng tôi vẫn chưa nhận được tiền như quy
                  định?
                </h2>
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Hiện nếu trạng thái là Đã hoàn tất nhưng anh/chị chưa nhận được tiền, anh/chị vui lòng đọc mã yêu cầu
                  rút tiền hoặc tên người tạo yêu cầu và kiểm tra phía ngân hàng, thời gian xử lý khoảng 3-5 ngày.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterMobi />
    </>
  );
};
