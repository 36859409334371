import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { PATH_PAGE } from '../../constant';
import Cookies from 'js-cookie';

const CheckAuth = () => {
  const token = Cookies.get('token');
  const location = useLocation();
  return token ? <Navigate to={PATH_PAGE.DASHBOARD} state={{ from: location }} replace /> : <Outlet />;
};

export default CheckAuth;
