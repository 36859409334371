// import { AppStoreIcon, GooglePlayIcon } from 'assets/images/common';
import { BannerBottom, Introduce1Icon, Introduce2Icon, Introduce3Icon, banner } from 'assets/images/main';
import useCheckDeviceMobi from 'hooks/useCheckDeviceMobi';
// import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { BackToTop } from '../../components/common/back-to-top';
import { ButtonCustom } from '../../components/common/ButtonCustom';
import { FooterMobi } from '../../components/footer/FooterMobi';
import { HeaderCMSCommon } from '../../components/header/HeaderCMSCommon';
import { PATH_PAGE } from '../../constant';
import { CardIntroduce } from './CardIntroduce';
import { Carousel } from './Carousel';
import { ImageBg } from './image';
import { default as GoiCuoc } from './GoiCuoc.png';
import { default as IconSim} from './IconSim.png';
import { default as Book } from './Book.png';
import { default as BaoHiem } from './BaoHiem.png';
import { default as Taichinh } from './Taichinh.png';
import { default as Vietlot } from './Vietlot1.png';


export const Main = () => {
  const navigate = useNavigate();
  const isMobile = useCheckDeviceMobi();
  return (
      // <>
      //   <div className="App">
      //     <Helmet>
      //       <title>App Title</title>
      //       <meta name="description" content="App Description"/>
      //       <meta name="theme-color" content="#008f68"/>
      //     </Helmet>
      //   </div>
        <>
          <HeaderCMSCommon/>
          <div className={'w-full overflow-x-hidden bg-[#F8F8F8] pt-[60px] md:overflow-x-auto'}>
            <div
                className={"bg-[url('assets/images/main/main-header-background.svg')] bg-cover bg-center bg-no-repeat md:h-[445px]" +
                    'h-[180px] rounded-bl-[30px] p-2 px-4 md:rounded-bl-[80px] md:p-8'}
            >
              <div className={'mx-auto flex w-full max-w-full flex-row pt-5 md:max-w-[85rem] md:justify-between'}>
                <div className={'flex w-3/5 flex-col justify-between pl-5 md:w-1/2 md:pl-12'}>
                  <div className="flex flex-col pt-4">
                                  <span className={'text-[20px] font-extrabold text-white  md:text-[45px]'}>
                                      Shop360 - Nền tảng tiếp thị liên kết các sản phẩm Viễn thông và Sản phẩm số hàng đầu Việt Nam
                                  </span>
                    <span
                        className={'text-[12px] font-normal leading-[16px] text-white md:text-[20px]' +
                            ' mr-4 md:mr-0 md:leading-[32px]'}
                    >
                                      Thu nhập không giới hạn khi thu hút khách hàng sử dụng các sản phẩm, dịch vụ trên nền tảng Shop360
                                  </span>
                  </div>
                  <ButtonCustom
                      label={'Đăng ký ngay'}
                      className={'mt-[15px] w-[150px] bg-gradient-to-r !from-[#FFB01F] !to-[#FFCF45] uppercase md:mt-[25px]' +
                          ' md:w-[200px]'}
                      type={'button'}
                      onClick={() => navigate(PATH_PAGE.REGISTER)}/>
                  <div className={'mt-[5.5rem] hidden space-x-4 md:flex md:flex-row'}>
                    {/* <img src={AppStoreIcon} alt={'AppStoreIcon'} className={'h-fit w-fit'} />
<img src={GooglePlayIcon} alt={'GooglePlayIcon'} className={'h-fit w-fit'} /> */}
                  </div>
                </div>
                <div className={'relative -ml-2 h-full w-2/5 md:ml-0 md:mr-16 md:h-[435px] md:w-[547px]'}>
                  <div
                      className={"absolute h-[120px] w-[150px] bg-[url('assets/images/main/banner.svg')] bg-cover md:h-[435px] md:w-[547px] "}
                  ></div>
                  <div className={'mt-[160px] flex flex-row space-x-1 md:hidden'}>
                    {/* <img src={AppStoreIcon} alt={'AppStoreIcon'} className={'h-[20px] w-[70px]'} />
<img src={GooglePlayIcon} alt={'GooglePlayIcon'} className={'h-[20px] w-[70px]'} /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className={'mx-auto mt-12 flex w-full flex-col items-center md:max-w-[85rem]'}>
              <div className={'w-full text-center md:w-[674px]'}>
                <p
                    className={'text-centert text-[22px] font-extrabold leading-[26px] text-[#404040] md:leading-[55px]' +
                        ' md:text-[45px]'}
                >
                  Chương trình tiếp thị liên kết
                  <p className={'text-[#F15836]'}> dành riêng cho bạn</p>
                </p>
                <p
                    className={'mt-2 px-2 text-[12px] font-normal leading-[16px] text-[#818181] md:px-0 md:text-[20px] md:leading-[32px]'}
                >
                  Không giới hạn đối tượng tham gia hợp tác, chỉ cần bạn đam mê kinh doanh và mong muốn có thêm một
                  nguồn thu nhập mới
                </p>
              </div>
              <div className={'mt-8 flex w-fit flex-row space-x-1.5 px-4 md:space-x-4 md:px-0'}>
                <CardIntroduce
                    label={'Hoa hồng hấp dẫn'}
                    description={'Hoa hồng top đầu trong các nền tảng Affiliate Marketing tại Việt Nam'}
                    icon={isMobile ? (
                        <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g filter="url(#filter0_d_1727_26994)">
                            <path
                                d="M8.9043 20.0039C8.9043 13.3765 14.2769 8.00391 20.9043 8.00391H36.9043C43.5317 8.00391 48.9043 13.3765 48.9043 20.0039V48.0039H20.9043C14.2769 48.0039 8.9043 42.6313 8.9043 36.0039V20.0039Z"
                                fill="#FFD35B"/>
                            <g clip-path="url(#clip0_1727_26994)">
                              <path
                                  d="M26.5294 27.5594H28.4583C29.5192 27.5594 30.5366 27.9809 31.2868 28.731C32.0369 29.4812 32.4583 30.4986 32.4583 31.5594H26.2352L26.2361 32.4483H33.3472V31.5594C33.3444 30.6136 33.0712 29.6883 32.5597 28.8928H35.125C35.9652 28.8925 36.7883 29.1305 37.4988 29.579C38.2093 30.0276 38.7781 30.6683 39.1392 31.427C37.037 34.2012 33.6334 36.0039 29.7917 36.0039C27.3374 36.0039 25.2583 35.4794 23.5694 34.5594V26.2892C24.6509 26.4441 25.6721 26.8824 26.5294 27.5594V27.5594ZM22.6806 34.2261C22.6806 34.4618 22.5869 34.6879 22.4202 34.8546C22.2535 35.0213 22.0274 35.115 21.7917 35.115H20.0139C19.7781 35.115 19.552 35.0213 19.3853 34.8546C19.2187 34.6879 19.125 34.4618 19.125 34.2261V26.2261C19.125 25.9904 19.2187 25.7643 19.3853 25.5976C19.552 25.4309 19.7781 25.3372 20.0139 25.3372H21.7917C22.0274 25.3372 22.2535 25.4309 22.4202 25.5976C22.5869 25.7643 22.6806 25.9904 22.6806 26.2261V34.2261ZM34.2361 21.7817C34.9434 21.7817 35.6216 22.0626 36.1217 22.5627C36.6218 23.0628 36.9028 23.7411 36.9028 24.4483C36.9028 25.1556 36.6218 25.8338 36.1217 26.3339C35.6216 26.834 34.9434 27.115 34.2361 27.115C33.5289 27.115 32.8506 26.834 32.3505 26.3339C31.8504 25.8338 31.5694 25.1556 31.5694 24.4483C31.5694 23.7411 31.8504 23.0628 32.3505 22.5627C32.8506 22.0626 33.5289 21.7817 34.2361 21.7817ZM28.0139 19.115C28.7211 19.115 29.3994 19.3959 29.8995 19.896C30.3996 20.3961 30.6806 21.0744 30.6806 21.7817C30.6806 22.4889 30.3996 23.1672 29.8995 23.6673C29.3994 24.1674 28.7211 24.4483 28.0139 24.4483C27.3066 24.4483 26.6284 24.1674 26.1283 23.6673C25.6282 23.1672 25.3472 22.4889 25.3472 21.7817C25.3472 21.0744 25.6282 20.3961 26.1283 19.896C26.6284 19.3959 27.3066 19.115 28.0139 19.115V19.115Z"
                                  fill="white"/>
                            </g>
                          </g>
                          <defs>
                            <filter
                                id="filter0_d_1727_26994"
                                x="0.904297"
                                y="0.00390625"
                                width="56"
                                height="56"
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                            >
                              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                              <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"/>
                              <feOffset/>
                              <feGaussianBlur stdDeviation="4"/>
                              <feComposite in2="hardAlpha" operator="out"/>
                              <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.78 0 0 0 0 0 0 0 0 0.2 0"/>
                              <feBlend mode="darken" in2="BackgroundImageFix" result="effect1_dropShadow_1727_26994"/>
                              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1727_26994"
                                       result="shape"/>
                            </filter>
                            <clipPath id="clip0_1727_26994">
                              <rect width="21.3333" height="21.3333" fill="white"
                                    transform="translate(18.2363 17.3372)"/>
                            </clipPath>
                          </defs>
                        </svg>
                    ) : (
                        <img
                            src={Introduce1Icon}
                            alt={''}
                            className={'h-[40px] w-[40px] object-fill md:h-[60px] md:w-[60px]'}/>
                    )}/>
                <CardIntroduce
                    label={'Thu nhập không giới hạn'}
                    description={'Thỏa sức kinh doanh với những sản phẩm dịch vụ được yêu thích'}
                    icon={isMobile ? (
                        <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g filter="url(#filter0_d_1727_27003)">
                            <path
                                d="M8.23242 20.0039C8.23242 13.3765 13.605 8.00391 20.2324 8.00391H36.2324C42.8598 8.00391 48.2324 13.3765 48.2324 20.0039V48.0039H20.2324C13.605 48.0039 8.23242 42.6313 8.23242 36.0039V20.0039Z"
                                fill="#FF8743"/>
                            <path
                                d="M31.0245 19.3311C30.9853 19.2997 30.9403 19.2764 30.8921 19.2624C30.8438 19.2485 30.7933 19.2442 30.7434 19.2499C30.6935 19.2556 30.6453 19.271 30.6014 19.2954C30.5575 19.3198 30.5189 19.3527 30.4877 19.3921L26.9831 23.8171H25.0409L29.2947 18.4464C29.4503 18.2498 29.6433 18.0859 29.8626 17.9641C30.0818 17.8423 30.3229 17.7651 30.5721 17.7368C30.8213 17.7085 31.0736 17.7297 31.3146 17.7992C31.5556 17.8687 31.7804 17.9852 31.9762 18.1419L35.895 21.281C36.2655 21.5779 36.5117 22.0026 36.5852 22.4716C36.6588 22.9407 36.5543 23.4203 36.2924 23.8164H34.3022L34.9928 23.0154C35.0262 22.9767 35.0515 22.9316 35.0671 22.8829C35.0827 22.8341 35.0883 22.7827 35.0836 22.7318C35.079 22.6808 35.064 22.6313 35.0398 22.5863C35.0155 22.5412 34.9824 22.5015 34.9425 22.4695L33.036 20.9422L30.7763 23.8171H28.8394L31.8468 19.9905L31.0245 19.3311ZM20.6181 21.533C20.0123 21.533 19.4313 21.7737 19.003 22.202C18.5746 22.6304 18.334 23.2114 18.334 23.8171V34.857C18.334 35.7657 18.695 36.6371 19.3375 37.2797C19.98 37.9222 20.8515 38.2832 21.7601 38.2832H34.7034C35.6121 38.2832 36.4836 37.9222 37.1261 37.2797C37.7686 36.6371 38.1296 35.7657 38.1296 34.857V28.0047C38.1296 27.096 37.7686 26.2245 37.1261 25.582C36.4836 24.9395 35.6121 24.5785 34.7034 24.5785H20.6181C20.4162 24.5785 20.2225 24.4983 20.0797 24.3555C19.9369 24.2127 19.8567 24.0191 19.8567 23.8171C19.8567 23.6152 19.9369 23.4216 20.0797 23.2788C20.2225 23.136 20.4162 23.0558 20.6181 23.0558H24.1052L25.3203 21.533H20.6181ZM32.0386 30.6695H34.3227C34.5247 30.6695 34.7183 30.7497 34.8611 30.8925C35.0039 31.0352 35.0841 31.2289 35.0841 31.4308C35.0841 31.6328 35.0039 31.8264 34.8611 31.9692C34.7183 32.112 34.5247 32.1922 34.3227 32.1922H32.0386C31.8367 32.1922 31.6431 32.112 31.5003 31.9692C31.3575 31.8264 31.2773 31.6328 31.2773 31.4308C31.2773 31.2289 31.3575 31.0352 31.5003 30.8925C31.6431 30.7497 31.8367 30.6695 32.0386 30.6695Z"
                                fill="white"/>
                          </g>
                          <defs>
                            <filter
                                id="filter0_d_1727_27003"
                                x="0.232422"
                                y="0.00390625"
                                width="56"
                                height="56"
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                            >
                              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                              <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"/>
                              <feOffset/>
                              <feGaussianBlur stdDeviation="4"/>
                              <feComposite in2="hardAlpha" operator="out"/>
                              <feColorMatrix type="matrix"
                                             values="0 0 0 0 1 0 0 0 0 0.555333 0 0 0 0 0.233333 0 0 0 0.2 0"/>
                              <feBlend mode="darken" in2="BackgroundImageFix" result="effect1_dropShadow_1727_27003"/>
                              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1727_27003"
                                       result="shape"/>
                            </filter>
                          </defs>
                        </svg>
                    ) : (
                        <img src={Introduce2Icon} alt={''} className={'h-[40px] w-[40px] md:h-[60px] md:w-[60px]'}/>
                    )}/>
                <CardIntroduce
                    label={'Đa dạng sản phẩm'}
                    description={'Sản phẩm từ nhiều lĩnh vực và từ các đối tác uy tín đầu ngành'}
                    icon={isMobile ? (
                        <svg width="57" height="57" viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g filter="url(#filter0_d_1727_27011)">
                            <path
                                d="M8.55273 20.0039C8.55273 13.3765 13.9253 8.00391 20.5527 8.00391H36.5527C43.1801 8.00391 48.5527 13.3765 48.5527 20.0039V48.0039H20.5527C13.9253 48.0039 8.55273 42.6313 8.55273 36.0039V20.0039Z"
                                fill="#FF5530"/>
                            <path
                                d="M30.0578 18.786C29.18 18.4301 28.1981 18.4301 27.3203 18.786L25.1928 19.6486L34.5109 23.2721L37.7886 22.0063C37.6542 21.8948 37.5035 21.8047 37.3417 21.7392L30.0578 18.786ZM38.4035 23.3304L29.4176 26.8023V37.4134C29.6352 37.3697 29.849 37.3046 30.0578 37.2201L37.3417 34.2669C37.6555 34.1398 37.9241 33.922 38.1133 33.6412C38.3024 33.3605 38.4035 33.0297 38.4035 32.6912V23.3314V23.3304ZM27.9605 37.4134V26.8023L18.9746 23.3304V32.6922C18.9748 33.0305 19.0759 33.3611 19.2651 33.6416C19.4542 33.9222 19.7228 34.1399 20.0364 34.2669L27.3203 37.2201C27.5291 37.3046 27.7429 37.3687 27.9605 37.4144V37.4134ZM19.5895 22.0063L28.6891 25.522L32.4942 24.0512L23.2237 20.4471L20.0364 21.7392C19.8713 21.8062 19.7216 21.8965 19.5895 22.0063Z"
                                fill="white"/>
                          </g>
                          <defs>
                            <filter
                                id="filter0_d_1727_27011"
                                x="0.552734"
                                y="0.00390625"
                                width="56"
                                height="56"
                                filterUnits="userSpaceOnUse"
                                colorInterpolationFilters="sRGB"
                            >
                              <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                              <feColorMatrix
                                  in="SourceAlpha"
                                  type="matrix"
                                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                  result="hardAlpha"/>
                              <feOffset/>
                              <feGaussianBlur stdDeviation="4"/>
                              <feComposite in2="hardAlpha" operator="out"/>
                              <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.36 0 0 0 0 0 0 0 0 0.2 0"/>
                              <feBlend mode="darken" in2="BackgroundImageFix" result="effect1_dropShadow_1727_27011"/>
                              <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1727_27011"
                                       result="shape"/>
                            </filter>
                          </defs>
                        </svg>
                    ) : (
                        <img src={Introduce3Icon} alt={''} className={'h-[40px] w-[40px] md:h-[60px] md:w-[60px]'}/>
                    )}/>
              </div>
              <div className={'mt-6 flex w-full flex-col md:hidden'}>
                <div className={'flex h-fit w-full flex-col px-10 text-center'}>
                  <p className={'text-center text-[22px] font-extrabold leading-[26px] text-[#404040]'}>
                    Làm thế nào
                    <span className={'text-[#F15836]'}> để tham gia </span>
                    cùng chúng tôi?
                  </p>
                  <p className={'mt-1 text-[12px] font-medium leading-[16px] text-[#818181]'}>
                    Đăng ký tham gia hệ thống chỉ với <span className={'text-[#F15836]'}> 04 bước </span>đơn giản
                  </p>
                </div>
                <div className={'mt-4 flex h-fit w-full flex-col space-y-3 px-4 pl-6'}>
                  <div
                      className={'flex h-[70px] w-full items-center justify-center rounded-t-[12px] rounded-bl-[12px] bg-[#FFD35B]' +
                          ' relative space-x-4 px-4'}
                  >
                    <div
                        className={'flex h-[40px] min-w-[40px] items-center justify-center rounded-t-[12px] rounded-bl-[12px] bg-white'}
                    >
                      <span className={'text-[24px] font-bold leading-[22px] text-[#FFD35B]'}>1</span>
                    </div>
                    <span className={'text-base font-medium text-white'}>
                                      Đăng ký tài khoản với số điện thoại dễ dàng chỉ trong 01 phút
                                  </span>
                    <div
                        className="absolute -left-[30px] top-[35px] z-0 h-[82px] w-4 rounded-tl-lg border-y !border-r-0 border-l border-dashed border-t-[#7A7A7A] border-l-[#7A7A7A]"></div>
                  </div>
                  <div
                      className={'flex h-[70px] w-full items-center justify-center rounded-t-[12px] rounded-bl-[12px] bg-[#FF8743]' +
                          ' relative space-x-4 px-4'}
                  >
                    <div
                        className={'flex h-[40px] min-w-[40px] items-center justify-center rounded-t-[12px] rounded-bl-[12px] bg-white'}
                    >
                      <span className={'text-[24px] font-bold leading-[22px] text-[#FF8743]'}>2</span>
                    </div>
                    <span className={'text-base font-medium text-white'}>Tìm kiếm sản phẩm và lấy link kinh doanh</span>
                    <div
                        className="absolute -left-[30px] top-[35px] z-0 h-[82px] w-4 border-y !border-r-0 border-l border-dashed border-y-[#7A7A7A] border-l-[#7A7A7A]"></div>
                  </div>
                  <div
                      className={'flex h-[70px] w-full items-center justify-center rounded-t-[12px] rounded-bl-[12px] bg-[#FF5530]' +
                          ' relative space-x-4 px-4'}
                  >
                    <div
                        className={'flex h-[40px] min-w-[40px] items-center justify-center rounded-t-[12px] rounded-bl-[12px] bg-white'}
                    >
                      <span className={'text-[24px] font-bold leading-[22px] text-[#FF5530]'}>3</span>
                    </div>
                    <span className={'text-base font-medium text-white'}>
                                      Chia sẻ link kinh doanh đến những nơi có khách hàng tiềm năng
                                  </span>
                    <div
                        className="absolute -left-[30px] top-[35px] z-0 h-[82px] w-4 rounded-bl-lg border-0 border-y !border-l border-dashed border-l-[#7A7A7A] border-b-[#7A7A7A]"></div>
                  </div>
                  <div
                      className={'flex h-[70px] w-full items-center justify-center rounded-t-[12px] rounded-bl-[12px] bg-[#FF6868]' +
                          ' space-x-4 px-4'}
                  >
                    <div
                        className={'flex h-[40px] min-w-[40px] items-center justify-center rounded-t-[12px] rounded-bl-[12px] bg-white'}
                    >
                      <span className={'text-[24px] font-bold leading-[22px] text-[#FF6868]'}>4</span>
                    </div>
                    <span className={'text-base font-medium text-white'}>
                                      Theo dõi hoa hồng và rút tiền về tài khoản ngân hàng
                                  </span>
                  </div>
                  <button
                      className={'h-[40px] w-[150px] rounded-t-[12px] bg-gradient-to-r from-[#F15836] to-[#FF924F]' +
                          ' mx-auto justify-center rounded-bl-[12px] text-center text-sm font-extrabold uppercase text-white' +
                          ' shadow-[0_0_20px_rgba(255,165,165,1)]'}
                      onClick={() => navigate(PATH_PAGE.REGISTER)}
                  >
                    Đăng ký ngay
                  </button>
                </div>
              </div>
              <div
                  className={'relative mt-20 mb-20 hidden h-[356px] w-[980px] flex-col items-center justify-center md:flex' +
                      ' rounded-xl border border-dashed border-[#7A7A7A]'}
              >
                <div
                    className={'absolute -top-10 left-20 flex h-[78px] w-[380px] flex-row rounded-t-[20px] rounded-bl-[20px] bg-[#FFD35B] p-2 hover:shadow-[#00d1ff]/30 hover:backdrop-blur-sm'}
                >
                  <div className={'relative flex h-full w-full items-center space-x-4 px-3'}>
                    <div className={'absolute -left-8 top-7 h-[10px] w-6 bg-[#F8F8F8]'}></div>
                    <div
                        className={' flex h-[40px] min-w-[40px] items-center justify-center rounded-t-[12px] rounded-bl-[12px] bg-white'}
                    >
                      <span className={' text-[24px] font-bold leading-[22px] text-[#FFD35B]'}>1</span>
                    </div>
                    <span className={'text-base font-normal text-white'}>
                                      Đăng ký tài khoản với số điện thoại dễ dàng chỉ trong 01 phút
                                  </span>
                  </div>
                </div>
                <div
                    className={'absolute -top-10 right-20 flex h-[78px] w-[380px] flex-row rounded-t-[20px] rounded-bl-[20px] bg-[#FF8743] p-2 hover:shadow-[#00d1ff]/30 hover:backdrop-blur-sm'}
                >
                  <div className={'relative flex h-full w-full items-center space-x-4 px-3'}>
                    <div className={'absolute -right-8 top-7 h-[10px] w-6 bg-[#F8F8F8]'}></div>
                    <div
                        className={'flex h-[40px] min-w-[40px] items-center justify-center rounded-t-[12px] rounded-bl-[12px] bg-white'}
                    >
                      <span className={' text-[24px] font-bold leading-[22px] text-[#FF8743]'}>2</span>
                    </div>
                    <span className={'text-base font-normal text-white'}>Tìm kiếm sản phẩm và lấy link kinh doanh</span>
                  </div>
                </div>
                <div
                    className={'absolute -bottom-10 left-20 flex h-[78px] w-[380px] flex-row rounded-t-[20px] rounded-bl-[20px] bg-[#FF6868] p-2 hover:shadow-[#00d1ff]/30 hover:backdrop-blur-sm'}
                >
                  <div className={'relative flex h-full w-full items-center space-x-4 px-3'}>
                    <div className={'absolute -left-8 top-7 h-[10px] w-6 bg-[#F8F8F8]'}></div>
                    <div
                        className={'flex h-[40px] min-w-[40px] items-center justify-center rounded-t-[12px] rounded-bl-[12px] bg-white'}
                    >
                      <span className={' text-[24px] font-bold leading-[22px] text-[#FF6868]'}>4</span>
                    </div>
                    <span className={'text-base font-normal text-white'}>
                                      Theo dõi hoa hồng và rút tiền về TK ngân hàng
                                  </span>
                  </div>
                </div>
                <div
                    className={'absolute -bottom-10 right-20 flex h-[78px] w-[380px] flex-row rounded-t-[20px] rounded-bl-[20px] bg-[#FF5530] p-2 hover:shadow-[#00d1ff]/30 hover:backdrop-blur-sm'}
                >
                  <div className={'relative flex h-full w-full items-center space-x-4 px-3'}>
                    <div className={'absolute -right-8 top-7 h-[10px] w-6 bg-[#F8F8F8]'}></div>
                    <div
                        className={'flex h-[40px] min-w-[40px] items-center justify-center rounded-t-[12px] rounded-bl-[12px] bg-white'}
                    >
                      <span className={' text-[24px] font-bold leading-[22px] text-[#FF5530]'}>3</span>
                    </div>
                    <span className={'text-base font-normal text-white'}>
                                      Chia sẻ link kinh doanh đến những nơi có khách hàng tiềm năng
                                  </span>
                  </div>
                </div>
                <div className={'flex h-fit w-[500px] flex-col text-center'}>
                  <p className={'text-center  text-[45px] font-extrabold leading-[55px] text-[#404040]'}>
                    Làm thế nào
                    <span className={'text-[#F15836]'}> để tham gia </span>
                    cùng chúng tôi?
                  </p>
                  <p className={'mt-1 text-[20px] font-medium leading-[32px] text-[#818181]'}>
                    Đăng ký tham gia hệ thống chỉ với <span className={'text-[#F15836]'}> 04 bước </span>đơn giản
                  </p>
                </div>
                <ButtonCustom
                    label={'ĐĂNG KÝ NGAY'}
                    className={'mt-4 h-[45px] w-[200px] shadow-[#FFA5A5]'}
                    onClick={() => navigate(PATH_PAGE.REGISTER)}/>
              </div>
            </div>
            <div
                className={"flex flex-col items-center justify-start bg-[url('assets/images/main/banner-middle.svg')] px-12" +
                    ' relative mt-8 h-[144px] w-full rounded-bl-3xl bg-auto bg-center bg-no-repeat md:mt-0 md:h-[246px]'}
            >
              <p
                  className={'mt-6 text-[22px] font-extrabold leading-[26px] text-white md:mt-12 md:text-[45px] md:leading-[55px]'}
              >
                Hãy chọn <span className={'text-[#FFD35B]'}>Shop360!</span>
              </p>
              <p className={'mt-4 text-center text-[12px] leading-[16px] text-white md:text-[20px] md:leading-[32px]'}>
                Được tin tưởng bởi các đối tác với 9,000+ sản phẩm trong nhiều lĩnh vực khác nhau
              </p>
              {isMobile && (
                  <div className="absolute top-[114px] h-fit w-full px-2">
                    <ImageBg/>
                  </div>
              )}
            </div>

            <>
              {!isMobile && (
                  <div className={'flex w-full flex-row items-center justify-center'}>
                    <div className={'relative h-[45px] w-[396px] md:h-5 md:w-[980px]'}>
                      <img
                          src={banner}
                          alt={'banner-telco'}
                          className={'absolute -top-[5rem] rounded-t-[12px] rounded-bl-[12px] md:-top-24'}/>
                    </div>
                  </div>
              )}
            </>
            <section className={'mx-auto h-fit max-w-[61rem] my-5'}>
              <div className={'mt-8 mb-6 font-bold text-[26px] text-black-500 text-center'}>
                <p>CÁC SẢN PHẨM CỦA SHOP360</p>
              </div>
              <div className={'pt-8'}>
                <div className="justify-center grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6">
                  <div className="flex flex-col my-0 mx-2 py-4 px-2 bg-white rounded-l-2xl rounded-t-xl drop-shadow-lg
                  hover:scale-110 transform backdrop-blur-sm transition duration-500">
                    <div className="flex justify-center mb-4">
                      <img className="rounded-full w-[80px]" src={GoiCuoc} alt="icon" />
                    </div>
                    <span className={'font-bold text-center text-[22px]'}>Gói cước</span>
                    <p className={'text-black-400 text-[14px] text-center'}>
                      Chọn lọc các gói cước được ưu đãi nhất của các nhà Mạng hàng đầu đầu Việt Nam
                    </p>
                  </div>
                  <div className="flex flex-col my-0 mx-2 py-4 px-2 bg-white rounded-l-2xl rounded-t-xl drop-shadow-lg
                  hover:scale-110 transform backdrop-blur-sm transition duration-500">
                    <div className="flex justify-center mb-4">
                      <img className="rounded-full w-[80px]" src={IconSim}  alt="icon"/>
                    </div>
                    <span className={'font-bold text-center text-[22px]'}>Sim số</span>
                    <p className={'text-black-400 text-[14px] text-center'}>
                      Kho sim số của các nhà Mạng hàng đầu Việt Nam
                    </p>
                  </div>
                  <div className="flex flex-col my-0 mx-2 py-4 px-2 bg-white rounded-l-2xl rounded-t-xl drop-shadow-lg
                  hover:scale-110 transform backdrop-blur-sm transition duration-500">
                    <div className="flex justify-center mb-4">
                      <img className="rounded-full grayscale w-[80px]" src={Book}  alt="icon"/>
                    </div>
                    <span className={'font-bold text-center text-[22px]'}>Sách</span>
                    <p className={'text-center'}>
                      Coming soon</p>
                  </div>
                  <div className="flex flex-col my-0 mx-2 py-4 px-2 bg-white rounded-l-2xl rounded-t-xl drop-shadow-lg
                  hover:scale-110 transform backdrop-blur-sm transition duration-500">
                    <div className="flex justify-center mb-4">
                      <img className="rounded-full grayscale w-[80px]" src={Vietlot}  alt="icon"/>
                    </div>
                    <span className={'font-bold text-center text-[22px]'}>Vietlott</span>
                    <p className={'text-center'}>
                      Coming soon</p>
                  </div>
                  <div className="flex flex-col my-0 mx-2 py-4 px-2 bg-white rounded-l-2xl rounded-t-xl drop-shadow-lg
                  hover:scale-110 transform backdrop-blur-sm transition duration-500">
                    <div className="flex justify-center mb-4">
                      <img className="rounded-full grayscale w-[80px]" src={BaoHiem}  alt="icon"/>
                    </div>
                    <span className={'font-bold text-center text-[22px]'}>Bảo hiểm</span>
                    <p className={'text-center'}>
                      Coming soon</p>
                  </div>
                  <div className="flex flex-col my-0 mx-2 py-4 px-2 bg-white rounded-l-2xl rounded-t-xl drop-shadow-lg
                  hover:scale-110 transform backdrop-blur-sm transition duration-500">
                    <div className="flex justify-center mb-4">
                      <img className="rounded-full grayscale w-[80px]" src={Taichinh}  alt="icon"/>
                    </div>
                    <span className={'font-bold text-center text-[22px]'}>Tài chính</span>
                    <p className={'text-center'}>
                      Coming soon</p>
                  </div>
                </div>
              </div>
            </section>
            <div
                className={'mx-auto mb-3 -mt-8 flex h-fit max-w-[85rem] flex-col items-center justify-center pb-4 md:mt-1' +
                    ' pt-20 md:pt-12'}
            >
              <p className={'text-[22px] font-extrabold leading-[26px] text-[#404040] md:text-[45px] md:leading-[55px]'}>
                Tin tức<span className={'text-[#F15836]'}> & Sự kiện</span>
              </p>
            </div>
            <Carousel/>

            <div className={'mx-auto mb-12 h-fit w-full md:max-w-[61rem]'}>
              <div className={'mt-12 flex h-fit flex-row-reverse items-center justify-center px-4'}>
                <img src={BannerBottom} alt={''} className={'h-[172px] w-[180px] md:h-[372px] md:w-[381px]'}/>
                <div className={'mr-2 w-full md:mr-8'}>
                  <p
                      className={'text-[22px] font-extrabold leading-[26px] text-baseColor md:text-[45px] md:leading-[55px]'}
                  >
                    Đăng ký trở thành
                  </p>
                  <p
                      className={'text-[22px] font-extrabold leading-[26px] text-baseColor md:text-[45px] md:leading-[55px]'}
                  >
                    Cộng tác viên Shop360
                  </p>
                  <p
                      className={'mb-4 text-[22px] font-extrabold leading-[26px] text-[#F15836] md:text-[45px] md:leading-[55px]'}
                  >
                    ngay hôm nay!
                  </p>
                  <p className={'text-[12px] font-normal leading-[16px] text-[#818181] md:text-[20px] md:leading-[32px]'}>
                    Tham gia dễ dàng, hoàn toàn miễn phí, hoa hồng hấp dẫn, sản phẩm đến từ các thương hiệu uy tín, đội
                    ngũ
                    nhân viên hỗ trợ thân thiện nhiệt tình. Việc của bạn là kinh doanh hiệu quả, những việc còn lại cứ
                    để
                    chúng tôi lo!
                  </p>
                  <p>
                    <button
                        className={'bg-gradient-to-r from-[#F15836] to-[#FF924F] md:h-[45px] md:w-[200px] md:rounded-t-[15px]' +
                            ' mt-4 font-black uppercase shadow-[0_0_20px_rgba(255,165,165,1)] md:rounded-bl-[15px] md:text-base' +
                            ' mb-12 h-[40px] w-[150px] rounded-t-[12px] rounded-bl-[12px] text-sm text-white'}
                        onClick={() => navigate(PATH_PAGE.REGISTER)}
                    >
                      Đăng ký ngay
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <BackToTop/>
          </div>
          <FooterMobi/>
        </>
      // </>
  );
};
