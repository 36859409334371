import { HeaderCMSCommon } from 'components/header/HeaderCMSCommon';
import { default as Image1 } from './image1.png';
import { default as Image2 } from './image2.png';
import { default as Image3 } from './image3.png';
import { default as Image5 } from './image5.png';
import { default as Image4 } from './image4.png';
import { default as Image6 } from './image6.png';
import { default as Image7 } from './image7.png';
import { FooterMobi } from 'components/footer/FooterMobi';
import useCheckDeviceMobi from 'hooks/useCheckDeviceMobi';
import { classNames } from 'utils/utils';

const UsageInstructions = () => {
  const isMobile = useCheckDeviceMobi();

  return (
    <>
      <HeaderCMSCommon />
      <div className={'w-full bg-[#F8F8F8] pt-5'}>
        <div className={'mx-auto w-full max-w-[85rem] px-4'}>
          <div className={'mx-auto w-full max-w-[62rem] py-16'}>
            <div
              className={classNames(
                'mx-auto w-full max-w-[62rem] rounded-[4px] bg-white shadow-[0_0_20px_0_rgba(0,0,0,0.05)]',
                isMobile ? 'p-4' : 'p-8'
              )}
            >
              <h1 className={'mb-6 text-center text-[32px] font-extrabold leading-[26px] text-[#F15836]'}>
                HƯỚNG DẪN SỬ DỤNG SHOP360{' '}
              </h1>
              <h2 className={'my-6 text-center text-[32px] font-extrabold leading-[26px] text-[#F15836]'}>
                OSP GLOBAL
              </h2>
              <h2 className={'mb-4 mt-6 text-[18px] font-bold leading-[28px] text-[#F15836]'}>
                I. Đăng nhập tài khoản
              </h2>
              <ul className="ml-4 list-disc space-y-2">
                <li>
                  <h2>
                    <strong>Bước 1: Tải app trên CH Play (Android) và App Store (IOS) và truy cập app.</strong>
                  </h2>
                </li>

                <li className="mt-2">
                  <h2>
                    <strong>Bước 2: Điền thông tin tài khoản và mật khẩu</strong>
                  </h2>
                  <div className="my-4 flex justify-center">
                    <img src={Image1} alt="image1" />
                  </div>
                </li>
                <li className="mt-2">
                  <h2>
                    <strong>Bước 3: Nhấn đăng nhập</strong>
                  </h2>
                </li>
              </ul>
              <h2 className={'mb-4 mt-6 text-[18px] font-bold leading-[28px] text-[#F15836]'}>II. Xóa tài khoản</h2>
              <p className="mt-2 font-medium text-baseColor">
                Tất cả các dữ liệu của bạn được lưu trữ trên nền tảng sẽ bị xoá khỏi hệ thống. Bạn sẽ mất quyền truy cập
                vào tất cả các dữ liệu bao gồm nhưng không giới hạn: các thông tin kinh doanh, đơn hàng của bạn, cộng
                tác viên cấp dưới, thông tin hoa hồng, số dư tài khoản Cộng tác viên và thông tin tài khoản nhận thanh
                toán.
              </p>
              <p className="mt-2 font-medium text-baseColor">
                Đội ngũ chăm sóc khách hàng sẽ không thể hỗ trợ bạn khôi phục tài khoản cũng như các dữ liệu theo tài
                khoản đã xoá của bạn.
              </p>
              <p className="mt-2 font-medium text-baseColor">
                Sau khi xoá tài khoản, bạn có thể sử dụng số điện thoại hiện tại để đăng ký tài khoản mới. Lúc này, các
                dữ liệu cũ đã bị xoá khỏi hệ thống sẽ không có liên kết gì với tài khoản mới tạo của bạn.
              </p>

              <h3 className={'mb-4 mt-6 text-[18px] font-bold leading-[28px] text-[#F15836]'}>
                Cách 1: Truy cập app để xóa tài khoản và dữ liệu
              </h3>

              <ul className="ml-4 list-disc space-y-2">
                <li>
                  <h2>
                    <strong>Bước 1: Đăng nhập tài khoản.</strong>
                  </h2>
                </li>

                <li className="mt-2">
                  <h2>
                    <strong>Bước 2: Truy cập mục "Thông tin tài khoản"</strong>
                  </h2>
                  <div className={`my-4 flex justify-center gap-2 ${isMobile ? 'flex-col' : 'flex-row'}`}>
                    <img src={Image2} alt="image2" />
                    <img src={Image3} alt="image3" />
                  </div>
                </li>
                <li className="mt-2">
                  <h2>
                    <strong>Bước 3: Xóa tài khoản</strong>
                  </h2>
                  <div className={`my-4 flex justify-center gap-2 ${isMobile ? 'flex-col' : 'flex-row'}`}>
                    <img src={Image4} alt="image4" />
                    <img src={Image5} alt="image5" />
                  </div>
                  <div className={`my-4 flex justify-center gap-2 ${isMobile ? 'flex-col' : 'flex-row'}`}>
                    <img src={Image6} alt="image6" />
                    <img src={Image7} alt="image7" />
                  </div>
                </li>
              </ul>

              <h3 className={'mb-4 mt-6 text-[18px] font-bold leading-[28px] text-[#F15836]'}>
                Cách 2: Liên hệ hotline <strong>0896.686.686</strong> hoặc email <strong>shop360.cskh@osp.vn</strong> để
                được hỗ trợ.
              </h3>
            </div>
          </div>
        </div>
      </div>
      <FooterMobi />
    </>
  );
};

export default UsageInstructions;
