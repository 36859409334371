import ScrollToTop from 'components/common/ScrollToTop';
import { FooterMobi } from 'components/footer/FooterMobi';
import useCheckDeviceMobi from 'hooks/useCheckDeviceMobi';
// import { useState } from 'react';
import { HeaderCMSCommon } from '../../components/header/HeaderCMSCommon';
import { NewList } from './list/index';
import { ListMobile } from './list/ListMobile';
// import { default as BannerNews1 } from './list/BannerNews1.png';
// import { default as BannerNews2 } from './list/BannerNews2.png';
import { default as BannerNews3 } from './list/BannerNews3.png';
import { default as BannerNews4 } from './list/BannerNews4.png';
import { default as BannerNews5 } from './list/BannerNews5.png';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

export const NewsPage = () => {
  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '500px',
    duration: 5000,
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    scale: 0.4,
    arrows: true,
  };

  const divPhoneStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '150px',
  };

  const isMobile = useCheckDeviceMobi();
  // const [indexDataCarousel, setIndexDataCarousel] = useState(0);
  const arrImg = [BannerNews3, BannerNews4, BannerNews5];

  return (
    <>
      <ScrollToTop />
      <HeaderCMSCommon />
      <div className={'w-full bg-[#F8F8F8] pt-16 md:pt-24'}>
        <div className={'mx-auto w-full max-w-[85rem] px-4'}>
          <Slide>
            {arrImg.map((slideImage, index) => (
              <div key={index}>
                <div style={{ ...(isMobile ? divPhoneStyle : divStyle) }}>
                  <img src={slideImage} alt="" />
                </div>
              </div>
            ))}
          </Slide>
          {/* <div className={'relative h-52 w-full rounded-[16px] bg-[#D9D9D9] md:h-[450px]'}>
           
            <div className={'absolute bottom-0 mx-auto hidden w-full flex-row justify-center space-x-4 pb-4 md:flex'}>
              {Array.from({ length: 5 }).map((item, index) => {
                if (index === indexDataCarousel) {
                  return (
                    <svg
                      key={index}
                      className={'cursor-pointer'}
                      width="38"
                      height="16"
                      viewBox="0 0 38 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect x="0.828125" y="0.163574" width="36.3507" height="15" rx="7.5" fill="#F15836" />
                    </svg>
                  );
                }
                return (
                  <svg
                    key={index}
                    onClick={() => setIndexDataCarousel(index)}
                    className={'cursor-pointer'}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="8" cy="8" r="8" fill="#818181" />
                  </svg>
                );
              })}
            </div>
          </div> */}
          {isMobile ? <ListMobile /> : <NewList />}
        </div>
      </div>
      <FooterMobi />
    </>
  );
};
