import { createContext, Dispatch, SetStateAction, useEffect, useLayoutEffect, useState } from 'react';
import Cookies from 'js-cookie';

interface IAuthContext {
  token?: string;
  setToken: Dispatch<SetStateAction<string | undefined>>;
  persist: boolean;
  setPersist: Dispatch<SetStateAction<boolean>>;
  userLogin: any;
  setUserLogin: Dispatch<SetStateAction<any>>;
}

const AuthContext = createContext<IAuthContext>(null as any);

export const AuthProvider = ({ children }: any) => {
  const [token, setToken] = useState<string>();
  const [userLogin, setUserLogin] = useState<any>();
  const [persist, setPersist] = useState<boolean>(false);

  useEffect(() => {
    const _token = Cookies.get('token');
    if (_token) {
      setToken(_token);
    }
  }, []);

  useEffect(() => {
    const reportFavouriteInStorage = localStorage.getItem('persist');
    if (!!reportFavouriteInStorage) {
      setPersist(JSON.parse(reportFavouriteInStorage));
    }
  }, []);

  return (
    <AuthContext.Provider value={{ token, setToken, persist, setPersist, setUserLogin, userLogin }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
