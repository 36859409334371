import { createContext, Dispatch, SetStateAction } from 'react';

type CategoryContextType = {
  packTypeChoice: Array<number>;
  numExpiredChoice: Array<number>;
  traSau: number;
  traTruoc: number;
  amount: Array<number>;
  setPackTypeChoice: Dispatch<SetStateAction<Array<number>>>;
  setTraTruoc: Dispatch<SetStateAction<number>>;
  setAmount: Dispatch<SetStateAction<Array<number>>>;
  setTraSau: Dispatch<SetStateAction<number>>;
  setNumExpiredChoice: Dispatch<SetStateAction<Array<number>>>;
};

export const CategoryContext = createContext<CategoryContextType>({} as CategoryContextType);
