import { classNames } from '../../utils/utils';
import { NavLink } from 'react-router-dom';

export const PageHeader = ({ label, isSelected, href }: any) => {
  return (
    <NavLink to={href} className={({ isActive }) => `flex flex-col`}>
      {({ isActive }) => (
        <>
          <span className={classNames(`flex text-center justify-center py-2 px-2 text-[14px] font-bold uppercase text-[#F15836]`)}>
            {label}
          </span>
          <div
            className={classNames('h-[4px] w-full rounded-t-[10px] rounded-bl-[10px]', isActive ? 'bg-[#FFCFB4]' : 'hidden')}
          />
        </>
      )}
    </NavLink>
  );
};
