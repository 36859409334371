import { NotFoundIcon } from 'assets/images/common';
import { ButtonCustom } from 'components/common/ButtonCustom';
import { TextForm } from 'components/common/form-item/TextForm';
import { SpinCustom } from 'components/common/SpinCustom';
import { TableV2 } from 'components/common/TableV2';
import { isEmpty } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getSuggestPlansV2, searchServicePlansInfinite } from 'services/service-plan';
import { AccountAuthen } from '../../../components/common/AccountAuthen';
import { FilterItem } from '../../../components/common/FilterItem';
import { PaginationAuto } from '../../../components/common/pagination/PaginationAuto';
import {
  MAX_AMOUNT,
  NUM_EXPIRED_CHOICE,
  PACK_TYPE_CHOICE,
  PATH_PAGE,
  PER_PAGE_DEFAULT,
  QUERY_KEY,
  SUPPLIER,
} from '../../../constant';
import useCheckDeviceMobi from '../../../hooks/useCheckDeviceMobi';
import { Products } from '../index';
import { PopupFilter } from './PopupFilter';
import { RowMobileServicePlan } from './RowMobileServicePlan';
import { RowTableServicePlan } from './RowTableServicePlan';
import { ServicePlanContext } from './ServicePlanContext';

import Cookies from 'js-cookie';
// import Vinaphone from '../../../assets/images/vinaphone.svg';
import { useDebounce } from 'hooks/useDebounce';
import Mobifone from '../../../assets/images/mbf_logo.png';

// interface LocationState {
//   suggest?: any[];
// }

const pattern = /^[0-9]{10}$/;

export const ProductsServicePlan = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  // const state = location.state as LocationState;
  const [dataTable, setDataTable] = useState<{
    items: Array<any>;
    numberPerPage: number;
    pageNumber: number;
  }>({
    items: [],
    numberPerPage: 0,
    pageNumber: 0,
  });

  const [searchParams, setSearchParams] = useState<any>({
    page: 1,
    typeSearch: 0,
    sizeOfPage: PER_PAGE_DEFAULT,
    hotPackage: 2,
    supplier: [SUPPLIER.MOBIFONE],
  });

  // const { userLogin } = useContext(AuthContext);
  const isMobile = useCheckDeviceMobi();
  // const [phoneData, setPhoneData] = useState<any[]>([]);

  const [isShowNotification, setIsShowNotification] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(PER_PAGE_DEFAULT);
  const [totalPage, setTotalPage] = useState<number>(1);

  // check param
  const [params] = useSearchParams();
  const isSuggesting = params.get('suggest') === 'true';
  const phoneNumber = params.get('phoneNumber');
  const urlPackType = params.get('packType') || 'mobifone';

  // end check param
  const [searchString, setSearchString] = useState<string>('');
  const [packTypeChoice, setPackTypeChoice] = useState<Array<number>>([]);
  const [numExpiredChoice, setNumExpiredChoice] = useState<Array<number>>([]);
  const [traSau, setTraSau] = useState<number>(0);
  const [traTruoc, setTraTruoc] = useState<number>(0);
  const [amount, setAmount] = useState<Array<number>>([0, MAX_AMOUNT]);
  const [supplier, setSupplier] = useState<Array<any>>([SUPPLIER.MOBIFONE]);
  // const [urlPackType, setPackType] = useState<string>();
  const [dataPack, setDataPack] = useState<number>(0);

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const isSearchingPhone = useMemo(() => {
    return pattern.test(searchParams?.searchString);
  }, [searchParams?.searchString]);

  // const { data: dataCommonOption } = useQuery(QUERY_KEY.GET_COMMON_OPTION, () => getOptionSearch());

  // const { data: dataCheckReqPayment } = useQuery(QUERY_KEY.REQ_PAYMENT, () => checkReqPayment());

  // const listTypePackage = useMemo(() => {
  //   return dataCommonOption?.data?.data?.LOAIGOICUOC || [];
  // }, [dataCommonOption]);

  // const listCycle = useMemo(() => {
  //   return dataCommonOption?.data?.data?.CHUKY || [];
  // }, [dataCommonOption]);

  // const listTelco = useMemo(() => {
  //   return dataCommonOption?.data?.data?.TELCO || [];
  // }, [dataCommonOption]);

  // const maxAmount = useMemo(() => {
  //   let maxNum = Number(dataCommonOption?.data?.data?.limitAmountPck?.find((i: any) => i.name === 'Max')?.value) || 0;
  //   setAmount([0, maxNum]);
  //   return maxNum;
  // }, [dataCommonOption]);

  // const isSearchingPhone = useMemo(() => {
  //   if (urlPackType === 'vinaphone') {
  //     let packCode = searchString?.trim() || '';
  //     if (/^\d+$/.test(packCode)) {
  //       // searchString là số điện thoại
  //       if (
  //         (['9', '8', '7', '5', '3'].includes((+packCode + '').at(0) + '') && packCode.length === 10) ||
  //         (['1'].includes((+packCode + '').at(0) + '') &&
  //           ['2'].includes((+packCode + '').at(1) + '') &&
  //           packCode.length === 11)
  //       ) {
  //         // sđt đúng định dạng
  //         return true;
  //       }
  //     }
  //   }
  //   return false;
  // }, [searchString, urlPackType]);

  // // 1 = du dk
  // // 161 =  tài khoản chưa xác thực
  // // 162 =  số dư chưa đủ
  // // 163 =  giới hạn 1 lần 1 ngày
  // // 164 =  thiếu thông tin ngân hàng
  // const statusReqPayment = useMemo(() => {
  //   return dataCheckReqPayment?.data?.data?.code;
  // }, [dataCheckReqPayment]);

  // // Sort khi tìm kiếm theo sđt
  // const filterVNPPhone = (data: any, param: any) => {
  //   if (!data || !data?.length) {
  //     return [];
  //   }

  //   let filteredData = data.filter((item: any) => {
  //     let isValid = true;
  //     if (typeof param?.fromAmount === 'number' && item.amount < parseInt(param?.fromAmount)) {
  //       isValid = false;
  //     }
  //     if (typeof param?.toAmount === 'number' && item.amount > parseInt(param?.toAmount)) {
  //       isValid = false;
  //     }
  //     if (typeof param?.packageType === 'number' && item.packType !== param?.packageType) {
  //       isValid = false;
  //     }
  //     if (param?.numExpiredChoice && !checkNumExpired(param.numExpiredChoice.split(','), item.numExpired)) {
  //       isValid = false;
  //     }
  //     return isValid;
  //   });

  //   return filteredData;
  // };

  const {
    data: packageData,
    isFetching,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: [QUERY_KEY.SIM_LIST, searchParams],
    queryFn: searchServicePlansInfinite,
    enabled: !!Cookies.get('token') && !isSearchingPhone && !isSuggesting,
    retry: 0,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage) => {
      return lastPage.data?.data?.items?.length < perPage ? undefined : lastPage?.data?.data?.page + 1;
    },
  });

  const { data: suggestData, isFetching: isFetchingSuggest } = useQuery({
    // queryKey: [QUERY_KEY.SIM_SUGGEST, searchParams?.searchString],
    queryKey: [
      QUERY_KEY.SIM_SUGGEST,
      { ...searchParams, msisdn: phoneNumber, typeSearch: 0, fromAmount: 0, toAmount: 10000000 },
    ],
    queryFn: getSuggestPlansV2,
    enabled: !!Cookies.get('token') && (isSearchingPhone || isSuggesting) && phoneNumber !== null,
    retry: 0,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (packageData?.pages?.length && !isSearchingPhone && !isSuggesting) {
      setDataTable({
        // items: searchSimQuery?.data?.data?.data?.items,
        items: packageData?.pages?.flatMap((item) => item?.data?.data?.items ?? []) ?? [],
        numberPerPage: searchParams?.sizeOfPage || PER_PAGE_DEFAULT,
        pageNumber: 0,
      });
    } else if (suggestData?.data?.data?.items?.length && (isSearchingPhone || isSuggesting)) {
      let availableSuppliers = supplier?.includes(SUPPLIER.MOBIFONE)
        ? [SUPPLIER.MOBIFONE, SUPPLIER.SAYMEE]
        : [SUPPLIER.VINAPHONE];

      setDataTable({
        items: suggestData?.data?.data?.items?.filter((item: any) => availableSuppliers?.includes(item?.supplier)),
        numberPerPage: searchParams?.sizeOfPage || PER_PAGE_DEFAULT,
        pageNumber: 0,
      });
    } else {
      setDataTable({ items: [], numberPerPage: 0, pageNumber: 0 });
    }
  }, [isSearchingPhone, isSuggesting, packageData?.pages, searchParams?.sizeOfPage, suggestData, supplier]);

  const fetchItems = useCallback(
    (ref?: HTMLDivElement | null) => {
      if (ref) {
        const { scrollHeight, scrollTop, clientHeight } = ref;

        if (scrollHeight - scrollTop - clientHeight < 300 && !isFetching && !isSearchingPhone) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, isSearchingPhone]
  );

  const onSearchPackage = useCallback(
    (_param?: { perPage?: number; currentPage?: number; data?: any; packType?: number }) => {
      if (!_param?.currentPage) {
        setCurrentPage(1);
      }
      const newSearchParams = {
        ...searchParams,
        ..._param?.data,
        page: _param?.currentPage || 1,
        typeSearch: 0,
        sizeOfPage: _param?.perPage || searchParams.sizeOfPage,
        hotPackage: 2,
        supplier: (typeof _param?.packType !== 'undefined' ? [_param?.packType] : _param?.data?.supplier) || supplier,
        packCode: _param?.data?.packCode || searchString,
        fromAmount: (_param?.data?.fromAmount !== undefined ? _param?.data?.fromAmount : amount[0]) || '',
        toAmount: (_param?.data?.toAmount !== undefined ? _param?.data?.toAmount : amount[1]) || '',
      };
      setSearchParams(newSearchParams);
    },
    [amount, searchParams, searchString, supplier]
  );

  // useEffect(() => {
  //   if (isSuggesting && phoneNumber !== null) {
  //     setSearchString(phoneNumber);
  //     mutationSearchPackage.mutate({
  //       page: 1,
  //       typeSearch: 0,
  //       sizeOfPage: PER_PAGE_DEFAULT,
  //       packCode: phoneNumber,
  //       hotPackage: 2,
  //     });

  //     if (!state?.suggest?.length) {
  //       // Nếu không có suggest trong location state
  //       mutationSearchVNPPhonePackage.mutate({
  //         msisdn: phoneNumber,
  //       });
  //     } else {
  //       // Nếu có suggest
  //       setPhoneData(state.suggest);
  //       let newData = state.suggest;
  //       if (isSuggesting) {
  //         // Nếu đang gợi ý thì lấy ngẫu nhiên 5 bản ghi
  //         newData = randomSubArrays(state.suggest, 5);
  //       }
  //       newData = newData
  //         .filter((item: any, index: number) => index >= 0 && index < 5)
  //         ?.map((item: any) => ({
  //           ...item,
  //           // supplier: 'Vinaphone',
  //         }));
  //       setVNPPhoneDataTable({
  //         items: newData.filter((item: any, index: number) => index >= 0 && index < 5) || [],
  //         numberPerPage: 5,
  //         pageNumber: 1,
  //       });

  //       setVnpTotalPage(0);
  //     }

  //     if (document.querySelector('#table-div')?.parentElement?.parentElement) {
  //       document.querySelector('#table-div')!.parentElement!.parentElement!.scrollTo({ top: 0, behavior: 'smooth' });
  //     }
  //   } else {
  //     mutationSearchPackage.mutate({
  //       page: 1,
  //       typeSearch: 0,
  //       sizeOfPage: PER_PAGE_DEFAULT,
  //       hotPackage: 2,
  //       supplier: [SUPPLIER.MOBIFONE],
  //     });
  //     mutationSearchVNPPackage.mutate({
  //       page: 1,
  //       typeSearch: 0,
  //       sizeOfPage: PER_PAGE_DEFAULT,
  //       hotPackage: 2,
  //       supplier: [SUPPLIER.VINAPHONE],
  //     });
  //   }
  // }, []);

  const clearSearch = () => {
    setPackTypeChoice([]);
    setSupplier([SUPPLIER.MOBIFONE]);
    setNumExpiredChoice([]);
    setTraTruoc(0);
    setTraSau(0);
    setSearchString('');
    setAmount([0, MAX_AMOUNT]);
    setDataPack(0);

    const keySearch: any = {
      packTypeChoice: [],
      numExpiredChoice: '',
      traSau: 0,
      traTruoc: 0,
      dataPack: 0,
      fromAmount: '',
      toAmount: '',
      supplier: [SUPPLIER.MOBIFONE],
      typeSearch: 0,
      packCode: '',
    };

    onSearchPackage({ data: keySearch });
  };

  const onPackTypeChange = (e: any) => {
    // navigate("/products/service-plan?packType=" + e.target.value);
    // setPackType(e.target.value);
    // onSearchPackage({ packType: e.target.value });
    setSupplier([Number(e.target.value)]);
    onSearchPackage({ packType: Number(e.target.value) });
  };

  const onDataChange = useCallback(
    (name: string, value: any) => {
      const keySearch: any = {
        packType: packTypeChoice || [],
        numExpiredChoice: isEmpty(numExpiredChoice) ? '' : numExpiredChoice.join(','),
        traSau,
        traTruoc,
        dataPack,
        fromAmount: amount[0] || '',
        toAmount: amount[1] || '',
        supplier: supplier,
        typeSearch: 0,
        packCode: searchString?.trim(),
      };

      if (name === 'amount') {
        keySearch.fromAmount = value[0] || 0;
        keySearch.toAmount = value[1] || MAX_AMOUNT;
        setAmount(value);
      } else if (name === 'numExpiredChoice') {
        keySearch.numExpiredChoice = isEmpty(value) ? '' : value.join(',');
      } else if (name === 'searchString') {
        keySearch.searchString = value.trim();
        keySearch.packCode = value.trim();
      } else {
        keySearch[name] = value;
      }

      setCurrentPage(1);
      // setVNPCurrentPage(1);
      onSearchPackage({ data: keySearch, currentPage: 1 });
    },
    [amount, dataPack, numExpiredChoice, onSearchPackage, packTypeChoice, searchString, supplier, traSau, traTruoc]
  );

  const debounceSearch = useDebounce(() => {
    onDataChange('searchString', searchString);
  }, 500);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
    debounceSearch();
  };

  const showButtonClear =
    // !isEmpty(supplier) ||
    !isEmpty(packTypeChoice) ||
    !isEmpty(numExpiredChoice) ||
    traTruoc !== 0 ||
    traSau !== 0 ||
    amount.toString() !== [0, MAX_AMOUNT].toString() ||
    dataPack !== 0;

  return (
    <>
      {(isFetching || isFetchingSuggest) && <SpinCustom />}
      <ServicePlanContext.Provider
        value={{
          packTypeChoice,
          numExpiredChoice,
          traSau,
          traTruoc,
          amount,
          dataPack,
          setPackTypeChoice,
          setTraTruoc,
          setAmount,
          setTraSau,
          setNumExpiredChoice,
          supplier,
          setSupplier,
          setDataPack,
        }}
      >
        <Products name={PATH_PAGE.PRODUCTS_SERVICE_PLAN}>
          <div className={'mb-2 flex flex-col rounded-[15px] bg-white py-4 sm:px-2 md:mb-6 md:px-6'}>
            {!isSuggesting ? (
              <>
                <div className={'flex flex-row border-b-[1px] border-[#EFEFEF] pb-4'}>
                  <input
                    type={'radio'}
                    value={SUPPLIER.MOBIFONE}
                    id={'mobifone'}
                    checked={supplier.includes(SUPPLIER.MOBIFONE)}
                    onChange={onPackTypeChange}
                    className={
                      'form-radio mx-4 mr-2 h-[20px] w-[20px] cursor-pointer border border-[#A9A9A9] checked:h-[20px] checked:w-[20px] checked:bg-transparent hover:border-[#15836]'
                    }
                  />
                  <label htmlFor="mobifone" className={'flex flex-row items-center justify-center'}>
                    <img src={Mobifone} alt="mobifone" className="h-[11px]" />
                  </label>
                  {/* <input
                    type={'radio'}
                    value={SUPPLIER.VINAPHONE}
                    id={'vinaphone'}
                    checked={supplier.includes(SUPPLIER.VINAPHONE)}
                    onChange={onPackTypeChange}
                    className={
                      'form-radio mx-4 mr-2 h-[20px] w-[20px] cursor-pointer border border-[#A9A9A9] checked:h-[20px] checked:w-[20px] checked:bg-transparent hover:border-[#15836]'
                    }
                  />
                  <label htmlFor="vinaphone" className={'flex flex-row'}>
                    <img src={Vinaphone} alt="vinaphone" />
                  </label> */}
                </div>
                <div className={'relative mb-6 mt-4 flex flex-row space-x-2 px-2'}>
                  <div className={'relative h-full w-full'}>
                    <TextForm
                      maxLength={50}
                      placeholder={
                        urlPackType === 'mobifone' ? 'Nhập để tìm kiếm' : 'Nhập số điện thoại hoặc tên gói cước'
                      }
                      className={'h-[40px] rounded-[10px] sm:pl-2 md:pl-6'}
                      value={searchString}
                      onChange={(e) => {
                        handleInputChange(e);
                      }}
                      onKeyDown={(e) => {
                        if (e.code === 'Enter') {
                          onSearchPackage();
                        }
                      }}
                    />
                  </div>
                  <ButtonCustom
                    onClick={() => {
                      setCurrentPage(1);
                      onSearchPackage({ data: searchParams });
                    }}
                    label={
                      <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M13.4688 12.7812L17.5938 16.9062"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8.3125 14.1562C11.9196 14.1562 14.8438 11.2321 14.8438 7.625C14.8438 4.01789 11.9196 1.09375 8.3125 1.09375C4.70539 1.09375 1.78125 4.01789 1.78125 7.625C1.78125 11.2321 4.70539 14.1562 8.3125 14.1562Z"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                    className={'h-[40px] w-[40px] px-2.5'}
                  />
                  <ButtonCustom
                    label={
                      <div className={'flex flex-row items-center justify-center space-x-0.5'}>
                        {!isEmpty(packTypeChoice) || traTruoc || traSau || !isEmpty(numExpiredChoice) ? (
                          <svg
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_573_26031)">
                              <path
                                d="M10.9518 20.5827H15.1185V18.416H10.9518V20.5827ZM6.78516 14.0827H19.2852V11.916H6.78516V14.0827ZM3.66016 5.41602V7.58268H22.4102V5.41602H3.66016Z"
                                fill="#F15836"
                              />
                              <circle cx="20.12" cy="5.41492" r="4.91296" fill="#FFE1D0" />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M20.1182 0C17.1276 0 14.7031 2.42448 14.7031 5.41509C14.7031 8.40569 17.1276 10.8302 20.1182 10.8302C23.1088 10.8302 25.5333 8.40569 25.5333 5.41509C25.5333 2.42448 23.1088 0 20.1182 0ZM22.4654 4.49945C22.5086 4.45004 22.5415 4.39249 22.5622 4.33018C22.5828 4.26788 22.5908 4.20207 22.5857 4.13663C22.5805 4.07119 22.5623 4.00744 22.5321 3.94914C22.502 3.89084 22.4605 3.83915 22.41 3.79713C22.3596 3.75511 22.3013 3.72359 22.2385 3.70444C22.1757 3.68529 22.1097 3.67889 22.0445 3.68562C21.9792 3.69235 21.9159 3.71207 21.8583 3.74361C21.8007 3.77516 21.7501 3.81791 21.7093 3.86933L19.5925 6.409L18.4971 5.31319C18.4043 5.22351 18.2799 5.17389 18.1509 5.17502C18.0218 5.17614 17.8983 5.22791 17.807 5.31918C17.7158 5.41046 17.664 5.53393 17.6629 5.663C17.6618 5.79207 17.7114 5.91643 17.801 6.00927L19.2779 7.48611C19.3263 7.53445 19.3842 7.57219 19.4479 7.59691C19.5117 7.62163 19.5799 7.63279 19.6482 7.62969C19.7165 7.62658 19.7835 7.60928 19.8447 7.57888C19.906 7.54848 19.9602 7.50566 20.004 7.45313L22.4654 4.49945Z"
                                fill="#F15836"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_573_26031">
                                <rect width="25" height="26" fill="white" transform="translate(0.535156)" />
                              </clipPath>
                            </defs>
                          </svg>
                        ) : (
                          <svg
                            width="26"
                            height="26"
                            viewBox="0 0 26 26"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M11.1628 20.5827H15.3294V18.416H11.1628V20.5827ZM6.99609 14.0827H19.4961V11.916H6.99609V14.0827ZM3.87109 5.41602V7.58268H22.6211V5.41602H3.87109Z"
                              fill="#F15836"
                            />
                          </svg>
                        )}
                        <span className={'font-sm !font-medium text-[#404040]'}>Bộ lọc</span>
                      </div>
                    }
                    className={'h-[40px] min-w-[100px] md:min-w-[120px]'}
                    background={'bg-[#FFECD6]'}
                    onClick={() => setIsOpenFilter(true)}
                  />
                  {isOpenFilter && (
                    <PopupFilter
                      currentSupplier={urlPackType}
                      isOpen={isOpenFilter}
                      setIsOpen={setIsOpenFilter}
                      onSearch={(_data: any) => {
                        setIsOpenFilter(false);
                        setCurrentPage(1);
                        const _dataSearch = {
                          ..._data,
                          typeSearch: 0,
                          packCode: searchString?.trim(),
                          page: 1,
                          sizeOfPage: perPage,
                          supplier: supplier,
                        };
                        // Tìm kiếm mobifone
                        setCurrentPage(1);
                        onSearchPackage({ data: _dataSearch, currentPage: 1 });
                        // mutationSearchVNPPackage.mutate(vnpDataSearch);
                      }}
                      listTypePackage={PACK_TYPE_CHOICE}
                      listCycle={NUM_EXPIRED_CHOICE}
                      // listTelco={listTelco}
                      maxAmount={+MAX_AMOUNT}
                    />
                  )}
                </div>

                <div className={'flex h-full w-full flex-wrap gap-x-4 gap-y-2'}>
                  {/* {supplier?.map((_item, index) => (
                    <FilterItem
                      label={getLabelTypeNumber(_item, listTelco)}
                      key={`${index}-supplier`}
                      onClear={() => setSupplier((v) => v.filter((item) => item !== _item))}
                    />
                  ))} */}
                  {/* {packTypeChoice?.map((packType, index) => (
                    <FilterItem
                      label={getLabelTypeNumber(packType, listTypePackage)}
                      key={`${index}-packTypeChoice`}
                      onClear={() => {
                        const currentPackTypeChoice = [...packTypeChoice];
                        setPackTypeChoice((v) => v.filter((item) => item !== packType));
                        onDataChange(
                          'packTypeChoice',
                          currentPackTypeChoice.filter((item) => item !== packType)
                        );
                      }}
                    />
                  ))} */}
                  {packTypeChoice?.map((packType, index) => (
                    <FilterItem
                      label={PACK_TYPE_CHOICE.find((item) => item.value === packType)?.name}
                      key={`${index}-packTypeChoice`}
                      onClear={() => {
                        const currentPackTypeChoice = [...packTypeChoice];
                        setPackTypeChoice((v) => v.filter((item) => item !== packType));
                        onDataChange(
                          'packType',
                          currentPackTypeChoice.filter((item) => item !== packType)
                        );
                      }}
                    />
                  ))}
                  {traTruoc === 1 && (
                    <FilterItem
                      label={'Trả trước'}
                      onClear={() => {
                        setTraTruoc(0);
                        onDataChange('traTruoc', 0);
                      }}
                    />
                  )}
                  {traSau === 1 && (
                    <FilterItem
                      label={'Trả sau'}
                      onClear={() => {
                        setTraSau(0);
                        onDataChange('traSau', 0);
                      }}
                    />
                  )}
                  {/* {dataPack === 1 && urlPackType === 'vinaphone' && (
                    <FilterItem
                      label={'Data'}
                      onClear={() => {
                        setDataPack(0);
                        onDataChange('dataPack', 0);
                      }}
                    />
                  )} */}
                  {/* {!isEmpty(amount) && amount.some((i: number) => i !== 0) && ( */}
                  {!isEmpty(amount) && !(amount[0] === 0 && amount[1] === MAX_AMOUNT) && (
                    <FilterItem
                      label={`Từ ${amount[0]?.toLocaleString('vi') || 0} VNĐ - ${
                        amount[1]?.toLocaleString('vi') || 0
                      } VNĐ`}
                      onClear={() => {
                        setAmount([0, MAX_AMOUNT]);
                        onDataChange('amount', [0, MAX_AMOUNT]);
                      }}
                    />
                  )}
                  {/* {numExpiredChoice?.map((packType, index) => (
                    <FilterItem
                      label={getLabelTypeNumber(packType, listCycle)}
                      key={`${index}-packTypeChoice`}
                      onClear={() => {
                        let submitPackType = [...numExpiredChoice];
                        setNumExpiredChoice((v) => v.filter((item) => item !== packType));
                        onDataChange(
                          'numExpiredChoice',
                          submitPackType.filter((item) => item !== packType)
                        );
                      }}
                    />
                  ))} */}

                  {numExpiredChoice?.map((packType, index) => (
                    <FilterItem
                      label={NUM_EXPIRED_CHOICE.find((item) => item.value === packType)?.name || '-'}
                      key={`${index}-packTypeChoice`}
                      onClear={() => {
                        let submitPackType = [...numExpiredChoice];
                        setNumExpiredChoice((v) => v.filter((item) => item !== packType));
                        onDataChange('numExpiredChoice', submitPackType?.filter((item) => item !== packType) || []);
                      }}
                    />
                  ))}

                  {showButtonClear && (
                    <button className={'text-sm font-normal text-[#F15836]'} onClick={() => clearSearch()}>
                      Xóa điều kiện
                    </button>
                  )}
                </div>
              </>
            ) : (
              <>
                <div className={'relative flex items-center justify-between space-x-2'}>
                  <div>
                    Gợi ý gói cước cho thuê bao: <span className={'ml-2 text-[#FF924F]'}>{phoneNumber || '-'}</span>
                  </div>
                  <button
                    className={
                      'rounded-t-[8px] rounded-bl-[8px] border border-black py-2 text-sm font-extrabold ' +
                      'flex items-center space-x-4 whitespace-nowrap px-6'
                    }
                    onClick={() => navigate(-1)}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-5 w-5">
                      <path
                        fillRule="evenodd"
                        d="M17 10a.75.75 0 01-.75.75H5.612l4.158 3.96a.75.75 0 11-1.04 1.08l-5.5-5.25a.75.75 0 010-1.08l5.5-5.25a.75.75 0 111.04 1.08L5.612 9.25H16.25A.75.75 0 0117 10z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span>Quay lại</span>
                  </button>
                </div>
              </>
            )}
          </div>

          {!dataTable.items.length && !isFetching && !isFetchingSuggest && currentPage === 1 ? (
            <div className={'mt-4 flex h-fit w-full flex-col items-center justify-center'}>
              <img src={NotFoundIcon} alt={'not-found'} />
              <span className={'mt-2 text-[18px] font-bold leading-[28px] text-[#A1A1AA]'}>Không tìm thấy dữ liệu</span>
            </div>
          ) : (
            <></>
          )}
          {dataTable.items.length > 0 ? (
            <div className="flex flex-1 flex-col">
              <div className={'hidden w-full md:contents'} id="table-div">
                <TableV2
                  bodyMaxHeightClass={'max-h-[480px]'}
                  labelHeaders={labelHeader}
                  dataRows={dataTable.items}
                  renderRow={(item: any, index: number) => (
                    <RowTableServicePlan
                      key={index}
                      packType={supplier}
                      row={item}
                      index={index}
                      currentPage={currentPage}
                      perPage={perPage}
                    />
                  )}
                  onScroll={(e: any) => {
                    if (!isSearchingPhone && !isSuggesting) {
                      fetchItems(e.target as HTMLDivElement);
                    }
                  }}
                  stickyHeader={true}
                />
              </div>

              {!isSearchingPhone && !isSuggesting ? (
                <div className={'mt-6 hidden md:contents'}>
                  <div className="pt-4">
                    <PaginationAuto
                      totalPage={totalPage}
                      currentPage={currentPage || 1}
                      setCurrentPage={(_page: number) => {
                        setCurrentPage(_page);
                        onSearchPackage({ currentPage: _page });
                      }}
                      perPage={perPage}
                      setPerPage={(_perPage: number) => {
                        setPerPage(_perPage);
                        setCurrentPage(1);
                        onSearchPackage({ perPage: _perPage });
                      }}
                      showPages={false}
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
          {isMobile && (
            <div className={'flex h-full w-full flex-col space-y-2.5 md:hidden'}>
              <InfiniteScroll
                dataLength={dataTable.items.length + 15}
                next={() => fetchNextPage()}
                hasMore={true}
                loader={<></>}
                endMessage={<></>}
                refreshFunction={() => console.log('refresh')}
                pullDownToRefresh
                pullDownToRefreshThreshold={50}
                pullDownToRefreshContent={<></>}
                releaseToRefreshContent={<></>}
                className={'flex flex-col space-y-2.5'}
              >
                {/* {generateMobileRows()} */}
                {dataTable.items.map((item, index) => (
                  <RowMobileServicePlan item={item} key={index} packType={urlPackType} />
                ))}
              </InfiniteScroll>
            </div>
          )}
          {/* <>
            {!mutationSearchPackage?.isLoading && (dataTable.items.length > 0 || currentPage !== 1) && (
              <div className={'mt-6 hidden md:contents'}>
                <PaginationCustom
                  currentPage={currentPage || 1}
                  setCurrentPage={(_page: number) => {
                    setCurrentPage(_page);
                    onSearchPackage({ currentPage: _page });
                  }}
                  totalItem={mutationSearchPackage?.data?.data?.data?.data?.rowCount || 0}
                  perPage={perPage}
                  setPerPage={(_perPage: number) => {
                    setPerPage(_perPage);
                    setCurrentPage(1);
                    onSearchPackage({ currentPage: 1, perPage: _perPage });
                  }}
                />
              </div>
            )}
          </> */}
          {isShowNotification && <AccountAuthen setIsShowNotification={setIsShowNotification} />}
        </Products>
      </ServicePlanContext.Provider>
    </>
  );
};

const labelHeader = [
  {
    label: 'STT',
    className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase w-[65px]',
  },
  // {
  //   label: 'Nhà mạng',
  //   className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase w-[65px]',
  // },
  {
    label: 'Gói cước',
    className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase w-[120px]',
  },
  {
    label: 'Loại gói cước',
    className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase w-[130px]',
  },
  {
    label: 'Giá cước (VNĐ)',
    className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase w-fit w-[130px]',
  },
  {
    label: 'Chu kỳ',
    className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase w-[120px]',
  },
  {
    label: 'Giới thiệu',
    className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase min-w-[165px]',
  },
  {
    label: 'Hoa hồng (chưa VAT)',
    className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase w-[120px]',
  },
  {
    label: 'Thao tác',
    className: 'text-[#818181] font-semibold text-[10px] leading-[12px] uppercase w-[272px]',
  },
];
