import { PaymentCardInfo } from '../../../components/common/PaymentCardInfo';
import { useMutation, useQuery } from 'react-query';
import { PATH_PAGE, PER_PAGE_DEFAULT, QUERY_KEY } from '../../../constant';
import { getUserInfo } from '../../../services/account';
import { BankCardInfoMobi } from 'components/common/BankCardInfoMobi';
import { TextForm } from '../../../components/common/form-item/TextForm';
import { SelectForm } from '../../../components/common/form-item/SelectForm';
import { ButtonCustom } from '../../../components/common/ButtonCustom';
import { useCallback, useEffect, useState } from 'react';
import { SelectOptionEntity } from '../../../entity/SearchEntity';
import { getHistoryPayment } from '../../../services/payment';
import { TimeFormMobi } from '../../../components/common/form-item/TimeFormMobi';
import InfiniteScroll from 'react-infinite-scroll-component';
import { classNames } from '../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { HeaderAdmin } from 'components/header/HeaderAdmin';

const typeTrans: Array<SelectOptionEntity> = [
  { id: 0, name: '', value: '' },
  { id: 6, name: 'Hoa hồng kinh doanh', value: 1 },
  { id: 3, name: 'Yêu cầu rút tiền', value: 3 },
  { id: 4, name: 'Giải thưởng sự kiện', value: 4 },
  { id: 5, name: 'Điều chỉnh bới OSP', value: 5 },
];

export const AccountTypeMobi = () => {
  const { data } = useQuery(QUERY_KEY.USER_INFO, () => getUserInfo());
  const [dataTable, setDataTable] = useState<any>([]);
  const navigator = useNavigate();
  const mutationGetHistoryScroll = useMutation((param: any) => getHistoryPayment(param), {
    onSuccess: (response) => {
      if (response?.data?.data) {
        setDataTable([...dataTable, ...response.data.data.items]);
        return;
      }
    },
    onError: (error) => {
      setDataTable([]);
    },
  });

  const mutationGetHistory = useMutation((param: any) => getHistoryPayment(param), {
    onSuccess: (response) => {
      if (response?.data?.data) {
        setDataTable(response.data.data.items || []);
        return;
      }
    },
    onError: (error) => {
      setDataTable([]);
    },
  });

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [textSearch, setTextSearch] = useState<string>('');
  const [typeTransSelect, setTypeTransSelect] = useState<any>(null);
  const [startDay, setStartDay] = useState<Date | null>(null);
  const [endDay, setEndDay] = useState<Date | null>(null);

  const onSearchHistory = (_page: number) => {
    mutationGetHistory.mutate({
      username: '',
      transTypes: typeTransSelect?.value ? [typeTransSelect.value] : [],
      status: '',
      level: '',
      fromDate: startDay ? startDay.toISOString() : '',
      toDate: endDay ? endDay.toISOString() : '',
      page: _page || 1,
      numberPerPage: PER_PAGE_DEFAULT,
    });
  };
  const onSearchHistoryScroll = (_page: number) => {
    mutationGetHistoryScroll.mutate({
      username: '',
      transTypes: typeTransSelect?.value ? [typeTransSelect.value] : [],
      status: '',
      level: '',
      fromDate: startDay ? startDay.toISOString() : '',
      toDate: endDay ? endDay.toISOString() : '',
      page: _page || 1,
      numberPerPage: PER_PAGE_DEFAULT,
    });
  };

  useEffect(() => {
    onSearchHistory(1);
  }, []);
  const onRowClick = useCallback(
    (row: any) => {
      switch (row.transType) {
        case 1:
        case 2:
          return navigator(`${PATH_PAGE.STATISTIC_TRANSACTION}/info/${row.transCode}`);
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        case 8:
          return navigator(
            `${PATH_PAGE.ACCOUNT_COLLABORATOR}/info-payment?transCode=${row.transCode}&transType=${row.transType}&stt=${row.stt}`
          );
        default:
          return;
      }
    },
    [navigator]
  );

  return (
    <HeaderAdmin>
      <div className={'h-fit w-full bg-[#F8F8F8] px-4 pt-16'}>
        <div
          className={
            ' mb-2 flex cursor-pointer flex-row space-x-2 text-[12px] font-medium leading-[16px] text-[#71717A]'
          }
        >
          <a href={PATH_PAGE.DASHBOARD}>Trang chủ</a>
          <span>|</span>
          <span>Báo cáo thống kê</span>
          <span>|</span>
          <span>Danh sách giao dịch</span>
        </div>
        <div className={'grid grid-cols-1 gap-4'}>
          <span className={'text-lg font-bold text-baseColor'}>Tài khoản cộng tác viên</span>
          <PaymentCardInfo accBalance={data?.data?.data?.accBalance || 0} isLeftFuncRequest />
          <BankCardInfoMobi data={data} />
          <h3 className="mt-1 mb-1 text-lg font-bold text-baseColor">Lịch sử biến động số dư</h3>
          <div className={'flex flex-col items-center justify-center'}>
            <div className={'grid-cols-1-1 grid w-full gap-4'}>
              <TextForm
                label={'Số thuê bao'}
                placeholder={'Tìm kiếm số thuê bao'}
                value={textSearch}
                setValue={setTextSearch}
              />
              <SelectForm
                label={'Loại giao dịch'}
                options={typeTrans}
                value={typeTransSelect}
                onChange={(v) => (v ? setTypeTransSelect(v) : setTypeTransSelect(''))}
                classIcon={'text-gray-400'}
              />
              <TimeFormMobi
                label={'Thời gian'}
                startDay={startDay}
                setStartDay={setStartDay}
                endDay={endDay}
                setEndDay={setEndDay}
                isDisabledSelectToday={true}
              />
            </div>
            <ButtonCustom
              label={'Tìm kiếm'}
              className={'mt-4 h-[40px] w-full bg-gray-600 px-2 !text-sm !font-extrabold'}
              onClick={() => {
                setCurrentPage(1);
                setDataTable([]);
                onSearchHistory(1);
              }}
            />
          </div>
        </div>
        <div className={'h-fit w-full pb-10'}>
          <InfiniteScroll
            dataLength={dataTable.length + 15}
            next={() => {
              setCurrentPage(currentPage + 1);
              onSearchHistoryScroll(currentPage + 1);
            }}
            hasMore={true}
            loader={<></>}
            endMessage={<></>}
            refreshFunction={() => console.log('refresh')}
            pullDownToRefresh
            pullDownToRefreshThreshold={50}
            pullDownToRefreshContent={<></>}
            releaseToRefreshContent={<></>}
            className={'grid grid-cols-1 gap-4'}
          >
            {dataTable.map((item: any, index: number) => (
              <div
                key={index}
                className={'grid w-full grid-cols-1 rounded-t-[16px] rounded-bl-[16px] bg-white p-4'}
                onClick={() => onRowClick(item)}
              >
                <div className={'grid grid-cols-3'}>
                  <div className={'col-span-2 flex flex-col'}>
                    <span className={'text-base font-bold text-baseColor'}>{item.levelStr}</span>
                    <span className={'text-sm font-normal text-baseColor'}>{item.transTypeStr}</span>
                    <p className={'text-sm text-[#818181]'}>{item.genDateStr}</p>
                  </div>
                  <div className={'col-span-1 ml-auto flex flex-col items-end justify-center'}>
                    <span
                      className={classNames(
                        'left-auto text-base font-bold',
                        item.amount > 0 ? 'text-[#18AA1F]' : item.amount < 0 ? 'text-[#FC5555]' : 'text-baseColor'
                      )}
                    >
                      {item.amount > 0 ? '+' : ''}
                      {item.amount ? item.amount.toLocaleString('vi') : '-'}
                    </span>
                    <div className={'left-auto flex flex-row items-center text-sm font-semibold text-[#FF9900]'}>
                      <svg
                        className={'mr-1'}
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.907 7.23268C13.9644 7.04908 13.9958 6.85834 14.0003 6.66602V3.99935C14.0003 2.09935 11.1337 0.666016 7.33366 0.666016C3.53366 0.666016 0.666992 2.09935 0.666992 3.99935V6.66602C0.703742 7.11015 0.854326 7.53733 1.10422 7.90633C1.35411 8.27533 1.69488 8.57371 2.09366 8.77268C2.03245 8.95307 2.00093 9.14219 2.00033 9.33268V11.9993C2.00033 13.8993 4.86699 15.3327 8.66699 15.3327C12.467 15.3327 15.3337 13.8993 15.3337 11.9993V9.33268C15.2977 8.88917 15.1474 8.46256 14.8973 8.0945C14.6473 7.72644 14.3061 7.4295 13.907 7.23268ZM7.33366 9.99935C9.90032 9.99935 12.0403 9.33268 13.1603 8.33268C13.6937 8.65268 14.0003 8.99935 14.0003 9.33268C14.0003 10.146 11.9203 11.3327 8.66699 11.3327C5.41366 11.3327 3.39366 10.1793 3.33366 9.36602C4.62069 9.80541 5.97384 10.0197 7.33366 9.99935ZM7.33366 1.99935C10.587 1.99935 12.667 3.18602 12.667 3.99935C12.667 4.81268 10.587 5.99935 7.33366 5.99935C4.08033 5.99935 2.00033 4.81268 2.00033 3.99935C2.00033 3.18602 4.08033 1.99935 7.33366 1.99935ZM2.00033 6.03935C3.62178 6.96173 5.46981 7.40987 7.33366 7.33268C9.19751 7.40987 11.0455 6.96173 12.667 6.03935V6.66602C12.667 7.47935 10.587 8.66602 7.33366 8.66602C4.08033 8.66602 2.00033 7.47935 2.00033 6.66602V6.03935ZM8.66699 13.9993C5.41366 13.9993 3.33366 12.8127 3.33366 11.9993V11.3727C4.95511 12.2951 6.80314 12.7432 8.66699 12.666C10.5308 12.7432 12.3789 12.2951 14.0003 11.3727V11.9993C14.0003 12.8127 11.9203 13.9993 8.66699 13.9993Z"
                          fill="#FF9900"
                        />
                      </svg>
                      {(item.currentAmount || 0).toLocaleString('vi')}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </HeaderAdmin>
  );
};
