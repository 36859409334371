import { InputCustom } from '../../../../components/common/InputCustom';
import { classNames, onKeyDownNumber } from '../../../../utils/utils';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { getOtpRegisterServicePlan, getOtpRegisterVNPServicePlan } from '../../../../services/service-plan';
import { useContext, useState } from 'react';
import { ConfirmPackagePopupContext } from './ConfirmPackagePopupContext';
import { SpinCustom } from 'components/common/SpinCustom';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { SUPPLIER } from 'constant';

interface errorMessage {
  message?: string;
  suggest?: any[];
}

export const ConfirmPhoneBody = ({ onSuccess, setPhoneNumber }: any) => {
  const { setActiveStepIndex, item, setDataForm } = useContext(ConfirmPackagePopupContext);
  const [isFocus, setIsFocus] = useState<boolean>(false);
  const [messageErrorOTP, setMessageErrorOTP] = useState<errorMessage>({ message: '', suggest: [] });
  const mutation = useMutation((param: any) => getOtpRegisterServicePlan(param), {
    onSuccess: (response: any) => {
      if (response.data.success) {
        onSuccess(response.data.data);
        setActiveStepIndex(1);
        return;
      }

      toast.error(response?.data?.message || 'Đăng ký không thành công, vui lòng thử lại sau!');
    },
  });

  const vnpMutation = useMutation((param: any) => getOtpRegisterVNPServicePlan(param), {
    onSuccess: (response: any) => {
      if (response.data.success) {
        onSuccess(response.data.data);
        setActiveStepIndex(1);
        return;
      }

      setMessageErrorOTP({
        message: response?.data?.error || 'Đăng ký không thành công, vui lòng thử lại sau!',
        suggest: response?.data?.suggest || [],
      });
      // toast.error(response?.data?.error || 'Đăng ký không thành công, vui lòng thử lại sau!');
    },
    onError: (error: any) => {
      toast.error('Đăng ký không thành công, vui lòng thử lại sau!');
    },
  });

  const {
    trigger,
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    setFocus,
    formState: { errors },
  } = useForm<{ phoneNumber: Number | undefined }>({
    defaultValues: {},
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const onSubmit = (data: any) => {
    if (!['9', '8', '7', '5', '3'].includes((+data.phoneNumber + '').at(0) + '')) {
      return;
    }
    if (item.supplier === SUPPLIER.VINAPHONE) {
      setDataForm({
        supplier: item.supplier,
        phoneNumber: `0${+data.phoneNumber}`,
        packCode: item.packCode,
        amount: item.amount,
        type: item.type,
        numExpired: item.numExpired,
        packNumber: item.packNumber,
        srcReq: 'CTVOSP',
        typeSource: 'CTV',
        promoCode: '',
      });
      vnpMutation.mutate({
        phoneNumber: `0${+data.phoneNumber}`,
        packCode: item.packCode,
        amount: item.amount,
        type: item.type,
        numExpired: item.numExpired,
        packNumber: item.packNumber,
        srcReq: 'CTVOSP',
        typeSource: 'CTV',
        promoCode: '',
      });
    } else {
      setDataForm({
        msisdn: `0${+data.phoneNumber}`,
        pckCode: item?.packCode,
        amount: item.amount,
        type: item.type,
        numExpired: item.numExpired,
        packNumber: item.packNumber,
        srcReq: 'CTVOSP',
        typeSource: 'CTV',
      });
      mutation.mutate({
        msisdn: `0${+data.phoneNumber}`,
        pckCode: item?.packCode,
        amount: item.amount,
        type: item.type,
        numExpired: item.numExpired,
        packNumber: item.packNumber,
        srcReq: 'CTVOSP',
        typeSource: 'CTV',
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={'mt-4 w-full'}>
        <span className={'text-sm font-bold text-[#585858]'}>
          Số điện thoại đăng ký <label className={'text-[#F15836]'}>*</label>
        </span>
        <div className="relative mt-2 w-full">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-3 ">
            <p className={'text-sm text-[#A9A9A9]'}>+84</p>
          </div>
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center justify-center pl-12 ">
            <div className={'leading-0 mt-[-4px] text-xl font-light text-[#A9A9A9]'}>|</div>
          </div>
          <InputCustom
            onPaste={(event) => {
              event.preventDefault();
              let value = parseInt(event?.clipboardData?.getData('text')?.trim());
              setValue('phoneNumber', isNaN(value) ? undefined : +value);
              trigger('phoneNumber');
            }}
            placeholder={'Số điện thoại'}
            type={'tel'}
            autoFocus
            maxLength={10}
            className={classNames('w-full pl-14 pr-2.5', errors.phoneNumber ? 'border border-[#FC5555]' : '')}
            register={register}
            label={'phoneNumber'}
            control={control}
            required={{
              required: 'Vui lòng nhập số điện thoại',
              maxLength: {
                value: 11,
                message: 'Số điện thoại không hợp lệ',
              },
              validate: (value: any) => {
                if (!['9', '8', '7', '5', '3'].includes((+value + '').at(0) + '')) {
                  return 'Số điện thoại không đúng định dạng!';
                }
                setMessageErrorOTP({ message: '', suggest: [] });
                setValue('phoneNumber', +value);
              },
            }}
            setFocus={setIsFocus}
            onKeyDown={onKeyDownNumber}
          />
          {!!watch('phoneNumber') && isFocus && (
            <div
              className="absolute inset-y-0 right-2 flex cursor-pointer items-center justify-center"
              onClick={() => {
                setValue('phoneNumber', undefined);
                setFocus('phoneNumber', { shouldSelect: true });
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11.9999 21.6004C14.546 21.6004 16.9878 20.589 18.7881 18.7886C20.5885 16.9883 21.5999 14.5465 21.5999 12.0004C21.5999 9.45431 20.5885 7.01252 18.7881 5.21217C16.9878 3.41182 14.546 2.40039 11.9999 2.40039C9.45382 2.40039 7.01203 3.41182 5.21168 5.21217C3.41133 7.01252 2.3999 9.45431 2.3999 12.0004C2.3999 14.5465 3.41133 16.9883 5.21168 18.7886C7.01203 20.589 9.45382 21.6004 11.9999 21.6004ZM10.4483 8.75199C10.222 8.5334 9.91886 8.41245 9.60422 8.41518C9.28959 8.41792 8.98861 8.54412 8.76612 8.76661C8.54363 8.9891 8.41743 9.29007 8.41469 9.60471C8.41196 9.91935 8.53291 10.2225 8.7515 10.4488L10.3031 12.0004L8.7515 13.552C8.63689 13.6627 8.54547 13.7951 8.48258 13.9415C8.41969 14.0879 8.38659 14.2454 8.3852 14.4047C8.38382 14.564 8.41418 14.7221 8.47452 14.8695C8.53485 15.017 8.62396 15.151 8.73663 15.2637C8.8493 15.3763 8.98328 15.4654 9.13076 15.5258C9.27823 15.5861 9.43625 15.6165 9.59558 15.6151C9.75492 15.6137 9.91238 15.5806 10.0588 15.5177C10.2052 15.4548 10.3376 15.3634 10.4483 15.2488L11.9999 13.6972L13.5515 15.2488C13.7778 15.4674 14.0809 15.5883 14.3956 15.5856C14.7102 15.5829 15.0112 15.4567 15.2337 15.2342C15.4562 15.0117 15.5824 14.7107 15.5851 14.3961C15.5878 14.0814 15.4669 13.7783 15.2483 13.552L13.6967 12.0004L15.2483 10.4488C15.4669 10.2225 15.5878 9.91935 15.5851 9.60471C15.5824 9.29007 15.4562 8.9891 15.2337 8.76661C15.0112 8.54412 14.7102 8.41792 14.3956 8.41518C14.0809 8.41245 13.7778 8.5334 13.5515 8.75199L11.9999 10.3036L10.4483 8.75199Z"
                  fill="#A1A1AA"
                />
              </svg>
            </div>
          )}
        </div>
        {errors.phoneNumber?.message && (
          <span className={'mt-2 text-sm font-normal leading-[18px] text-[#F15836]'}>{errors.phoneNumber.message}</span>
        )}
        {messageErrorOTP?.message && !errors.phoneNumber?.message ? (
          <div className="mt-2">
            <div className={'mb-[10px] mt-[6px] flex w-full flex-col justify-center'}>
              <p className="text-sm font-medium text-[#FF7575]">{messageErrorOTP?.message}</p>
              {/* {messageErrorOTP?.suggest?.length ? */}
              <p className="text-sm font-medium text-[#EE0033]">
                <span>Vui lòng thử lại hoặc xem Gợi ý gói cước</span>
                <Link
                  state={{ ...(messageErrorOTP?.suggest?.length ? { suggest: messageErrorOTP?.suggest } : {}) }}
                  className={'ml-1 text-[#0099ff] underline'}
                  to={
                    '/products/service-plan?suggest=true&packType=vinaphone&phoneNumber=' +
                    ('0' + watch('phoneNumber') || '')
                  }
                >
                  Tại đây
                </Link>
              </p>
              {/* : <></>} */}
            </div>
          </div>
        ) : (
          <></>
        )}
        <button
          className={classNames(
            'w-full rounded-t-[8px] rounded-bl-[8px] bg-gradient-to-l from-[#F15836] to-[#FF924F] p-2' +
              ' mt-6 text-sm font-extrabold text-white',
            watch('phoneNumber') &&
              !errors.phoneNumber &&
              (watch('phoneNumber') + '').length >= 9 &&
              (watch('phoneNumber') + '').length <= 10
              ? ''
              : 'opacity-40'
          )}
          disabled={mutation.isLoading || vnpMutation.isLoading || (watch('phoneNumber') + '').length < 9}
        >
          Lấy mã OTP
        </button>
      </form>
      {(mutation.isLoading || vnpMutation.isLoading) && <SpinCustom />}
    </>
  );
};
