import { Calendar } from '../calendar/Calendar';
import { useCallback, useEffect, useState } from 'react';
import { endOfDay, isBefore } from 'date-fns';

interface ITimeForm {
  label?: string;
  startDay: Date | null;
  endDay: Date | null;
  setStartDay: (date: Date | null) => void;
  setEndDay: (date: Date | null) => void;
  backgroundColor?: boolean;
  placeholder?: string;
  isDisabledSelectToday?: boolean;
}

export const TimeForm = ({
  label,
  startDay,
  endDay,
  setEndDay,
  setStartDay,
  backgroundColor,
  placeholder,
  isDisabledSelectToday,
}: ITimeForm) => {
  const [isSelectStartDay, setIsSelectStartDay] = useState<boolean>(false);

  const onChangeDate = useCallback((newDay: Date | null, otherDay: Date | null) => {
    setIsSelectStartDay((v) => !v);
    if (!newDay) {
      setStartDay(null);
      setEndDay(null);
      return;
    } else if (!otherDay) {
      setStartDay(endOfDay(newDay));
      // setEndDay(newDay);
      return;
    }

    if (isBefore(newDay, otherDay)) {
      setStartDay(newDay);
      setEndDay(endOfDay(otherDay));
    } else {
      setStartDay(otherDay);
      setEndDay(endOfDay(newDay));
    }
  }, []);

  useEffect(() => {
    return () => {
      setIsSelectStartDay(false);
    };
  }, []);

  return (
    <div className={'flex flex-col space-y-2'}>
      {label && <label className={'text-sm font-bold text-[#5B5B5B]'}>{label}</label>}
      <div className="flex items-center">
        <Calendar
          placeholder={placeholder || 'Từ ngày - Đến ngày'}
          selectedDay={startDay}
          onChange={(day: Date | null, count?: number) => {
            if (!day) {
              setStartDay(null);
              setEndDay(null);
              return;
            }
            if (!count || count < 2) {
              setStartDay(day);
              setEndDay(null);
            } else if (startDay) {
              if (isBefore(day, startDay)) {
                setStartDay(day);
                setEndDay(endOfDay(startDay));
              } else {
                setStartDay(startDay);
                setEndDay(endOfDay(day));
              }
            }
          }}
          setTodayToDate={(date: Date) => onChangeDate(date, date)}
          otherDay={endDay}
          backgroundColor={backgroundColor}
          isDisabledSelectToday={isDisabledSelectToday}
        />
      </div>
    </div>
  );
};
