import ScrollToTop from 'components/common/ScrollToTop';
import { FooterMobi } from 'components/footer/FooterMobi';
import { HeaderCMSCommon } from 'components/header/HeaderCMSCommon';
import useCheckDeviceMobi from 'hooks/useCheckDeviceMobi';
import { classNames } from 'utils/utils';

export const TransactionTerms = () => {
  const isMobile = useCheckDeviceMobi();

  return (
    <>
      <ScrollToTop />
      <HeaderCMSCommon />
      <div className={classNames('w-full bg-[#F8F8F8] pb-10', isMobile ? 'pt-20' : 'pt-24')}>
        <div className={'mx-auto w-full max-w-[85rem] px-4'}>
          <div
            className={classNames(
              'mx-auto w-full max-w-[62rem] rounded-[4px] bg-white shadow-[0_0_20px_0_rgba(0,0,0,0.05)]',
              isMobile ? 'p-4' : 'p-8'
            )}
          >
            <h1 className={'text-center text-[32px] font-semibold leading-[40px] text-[#F15836]'}>
              ĐIỀU KIỆN GIAO DỊCH CHUNG
            </h1>

            <div className={'mt-2'}>
              <section className="[&>*]:text-justify [&>*]:leading-[25px]">
                <p className={'break-words text-left text-sm font-medium text-baseColor'}>
                  Điều kiện này được áp dụng cho khách hàng/cộng tác viên khi tiếp thị, mua Sản Phẩm và sử dụng Dịch vụ
                  của Shop360 thông qua website Shop360.vn
                </p>
              </section>

              <section className={classNames(isMobile ? 'mt-6' : 'mt-8', '[&>*]:text-justify [&>*]:leading-[25px]')}>
                <h1 className={classNames('text-[16px] font-semibold  text-baseColor', !isMobile ? 'uppercase' : '')}>
                  I. PHẠM VI ÁP DỤNG
                </h1>

                <section className={'mt-2 break-words text-left text-sm font-medium text-baseColor'}>
                  Điều kiện chung áp dụng cho:
                  <ul className="list-disc pl-8">
                    <li>
                      Các tổ chức, cá nhân là người hợp tác với Shop360 nhằm bán hàng, giới thiệu sản phẩm được cung
                      cấp, quản lý và vận hành bởi Shop360 đến khách hàng của họ, thay mặt khách hàng đặt hàng trên Nền
                      Tảng Shop360 (sau đây gọi tắt là “Cộng Tác Viên").
                    </li>

                    <li>
                      Khách Hàng là người cuối cùng (end-user) nhận hàng hóa/dịch vụ và thanh toán giá trị hàng hóa/dịch
                      vụ cho Shop360 (sau đây gọi tắt là “Khách hàng")
                    </li>
                  </ul>
                </section>
              </section>

              <section className="[&>*]:text-justify [&>*]:leading-[25px]">
                <h1
                  className={classNames(
                    'text-[16px]  font-semibold text-baseColor',
                    isMobile ? 'mt-6' : 'mt-8 uppercase'
                  )}
                >
                  II. CHÍNH SÁCH HOÀN TRẢ
                </h1>

                <section className={'mt-2 break-words text-left text-sm font-medium text-baseColor'}>
                  <ul className="list-disc pl-8">
                    <li>
                      Shop360 không áp dụng chính sách hoàn trả và hoàn tiền lại cho Khách hàng đối với các sản phẩm đã
                      khởi tạo đơn hàng thành công.
                    </li>

                    <li>
                      Các trường hợp được hoàn trả và hoàn tiền sẽ theo quyết định của Shop360 tại từng thời điểm (nếu
                      có).
                    </li>

                    <li>
                      Khách Hàng có trách nhiệm bảo mật các thông tin thanh toán/hoàn tiền của mình, Shop360 không chịu
                      trách nhiệm về những thiệt hại/rủi ro có thể xảy ra trong trường hợp khách hàng để lộ/trao
                      đổi/cung cấp thông tin thanh toán/hoàn tiền của mình cho người khác.
                    </li>

                    <li>
                      Shop360 không chịu trách nhiệm và không có nghĩa vụ đối với bất kỳ thiệt hại nào phát sinh trong
                      trường hợp khách hàng nhập sai thông tin thanh toán/thông tin chuyển khoản khi tiến hành thanh
                      toán/hoàn tiền. Những trường hợp sai sót về thông tin thanh toán/thông tin chuyển khoản, vui lòng
                      liên hệ hotline Ngân hàng/ Nền tảng Ví điện tử để được hỗ trợ.
                    </li>

                    <li>
                      Shop360 và các đơn vị hợp tác có quyền kiểm tra tính hợp pháp việc sử dụng phương thức thanh toán,
                      thông tin tài khoản nhận hoàn tiền của Khách Hàng và quyền đình chỉ giao dịch cho đến khi xác nhận
                      được tính hợp pháp.
                    </li>
                  </ul>
                </section>
              </section>

              <section className="[&>*]:text-justify [&>*]:leading-[25px]">
                <h1
                  className={classNames(
                    'text-[16px]  font-semibold text-baseColor',
                    isMobile ? 'mt-6' : 'mt-8 uppercase'
                  )}
                >
                  III. QUY TRÌNH GIAO DỊCH CHO CỘNG TÁC VIÊN
                </h1>

                <section className={'mt-2 break-words text-left text-sm font-medium text-baseColor'}>
                  Để xác lập Đơn Đặt Hàng, Cộng Tác Viên thực hiện các bước sau:
                  <ul className="list-disc pl-8">
                    <li>
                      <strong>Bước 1:</strong> Cộng Tác Viên tìm kiếm Sản Phẩm (hàng hóa, dịch vụ, chương trình khuyến
                      mại) theo nhu cầu trong các phân vùng dịch vụ tương ứng.
                    </li>

                    <li>
                      <strong>Bước 2:</strong> Cộng Tác Viên xem kỹ các thông tin liên quan và lựa chọn Sản Phẩm (số
                      lượng, chủng loại,…) để đăng ký dịch vụ. Trước khi đăng kí, trong trường hợp Cộng Tác Viên muốn có
                      thêm thông tin liên quan và phù hợp về Sản Phẩm và thông tin về Sản phẩm, Cộng Tác Viên vui lòng
                      liên hệ Shop360 để được cung cấp thêm thông tin. Việc Cộng Tác Viên đăng ký dịch vụ được hiểu là
                      Cộng Tác Viên đã nhận thấy mình được cung cấp đủ thông tin chi tiết về Sản Phẩm.
                    </li>

                    <li>
                      <strong>Bước 3:</strong> Cộng Tác Viên sẽ được thanh toán hoa hồng cho mỗi giao dịch thành công
                      theo Chính sách Hoa hồng tương ứng với sản phẩm.
                    </li>
                  </ul>
                </section>
              </section>

              <section className="[&>*]:text-justify [&>*]:leading-[25px]">
                <h1
                  className={classNames(
                    'text-[16px]  font-semibold text-baseColor',
                    isMobile ? 'mt-6' : 'mt-8 uppercase'
                  )}
                >
                  IV. QUYỀN VÀ NGHĨA VỤ CỦA CÁC BÊN
                </h1>
              </section>

              <section className="mt-4 grid gap-y-4 [&>*]:text-justify [&>*]:leading-[25px]">
                <h2 className={'text-[15px] font-semibold  text-baseColor'}>1. Quyền và nghĩa vụ của Shop360</h2>

                <h2 className={'text-[14px] font-semibold  text-baseColor'}>1.1. Quyền của Shop360</h2>

                <section className={'mt-2 break-words text-left text-sm font-medium text-baseColor'}>
                  <ul className="list-disc pl-8">
                    <li>
                      Shop360 sẽ tiến hành cung cấp các dịch vụ cho các Cộng Tác Viên tham gia sau khi đã hoàn thành các
                      thủ tục và các điều kiện bắt buộc được quy định.
                    </li>

                    <li>
                      Yêu cầu các Cộng Tác Viên cung cấp thông tin chính xác, trung thực và cập nhật khi thực hiện giao
                      dịch trên Nền Tảng.
                    </li>

                    <li>
                      Shop360, theo quyết định của mình mà không cần có sự đồng ý của Cộng Tác Viên, có quyền đưa ra các
                      cảnh cáo, hạn chế, từ chối, tạm ngừng hoặc chấm dứt có thời hạn và/hoặc vĩnh viễn ngay lập tức và
                      quyền truy cập/sử dụng tài khoản của Cộng Tác Viên để sử dụng một hoặc tất cả các dịch vụ trên tại
                      bất cứ thời điểm nào mà không cần thông báo trước tới Khách Hàng nếu Shop360 nhận định rằng
                      <ul className="grid gap-y-2">
                        <li>
                          <strong>(i)</strong> Việc tiếp tục cho phép Cộng Tác Viên giao dịch sẽ gây thiệt hại hoặc có
                          khả năng sẽ gây thiệt hại cho và/hoặc Khách Hàng/Cộng Tác Viên khác ; và/hoặc
                        </li>

                        <li>
                          <strong>(ii)</strong> Cộng Tác Viên vi phạm Điều kiện chung này, các Điều kiện và điều khoản
                          của các tính năng tương ứng và các chính sách của Shop360 tại từng thời điểm; và/hoặc
                        </li>

                        <li>
                          <strong>(iii)</strong> Cộng Tác Viên có những hoạt động lừa đảo, giả mạo, gây rối loạn thị
                          trường, gây mất đoàn kết đối với các Cộng Tác Viên khác của , hoạt động vi phạm pháp luật hiện
                          hành của Việt Nam.
                        </li>
                      </ul>
                    </li>

                    <li>
                      Shop360 có quyền xem xét việc chấm dứt quyền sử dụng dịch vụ và quyền Cộng Tác Viên nếu Cộng Tác
                      Viên không tham gia hoạt động giao dịch và trao đổi thông tin trên liên tục trong thời hạn do
                      Shop360 quyết định tại từng thời điểm. Nếu muốn tiếp tục trở thành Cộng Tác Viên và được cấp lại
                      quyền sử dụng dịch vụ thì phải đăng ký lại theo thủ tục của Shop360 tại từng thời điểm. Trong
                      trường hợp chấm dứt quyền Cộng Tác Viên và quyền sử dụng dịch vụ thì tất cả các chứng nhận, các
                      quyền của Cộng Tác Viên được cấp sẽ mặc nhiên hết giá trị và bị chấm dứt.
                    </li>

                    <li>
                      Shop360 bảo lưu quyền sở hữu trí tuệ đối với mọi tài sản trí tuệ của Shop360 thể hiện trên theo
                      pháp luật về sở hữu trí tuệ. Để làm rõ thêm, tài sản trí tuệ bao gồm nhưng không giới hạn thông
                      tin, thiết kế, tài liệu, đồ họa, phần mềm, hình ảnh, video, âm nhạc, âm thanh, mã nguồn liên quan
                      và các tài sản trí tuệ khác liên quan đến , không phụ thuộc vào việc các quyền sở hữu trí tuệ đó
                      đã được đăng ký hay chưa (“Tài Sản Trí Tuệ”).
                    </li>

                    <li>
                      Các Cộng Tác Viên sẽ (i) không được nhận chuyển nhượng bất cứ quyền sở hữu trí tuệ nào hoặc bất cứ
                      quyền nào khác liên quan đến các Tài Sản Trí Tuệ và (ii) không được sử dụng, biên tập, công bố, mô
                      phỏng, dịch, thực hiện các sản phẩm phái sinh, phân phát, hoặc dưới bất cứ hình thức nào sử dụng,
                      tái sử dụng, sao chép, sửa đổi, đăng ký hoặc công bố các Tài Sản Trí Tuệ mà không được Shop360
                      chấp thuận trước bằng văn bản.
                    </li>

                    <li>
                      Shop360 có quyền tiến hành các hoạt động xúc tiến, quảng bá ra thị trường nước ngoài trong phạm vi
                      và điều kiện cho phép, góp phần mở rộng, kết nối đáp ứng các nhu cầu tìm kiếm bạn hàng và phát
                      triển thị trường nước ngoài.
                    </li>

                    <li>
                      Các quyền khác theo quy định tại Điều kiện chung này, các Điều kiện và điều khoản tương ứng với
                      từng tính năng, các hợp đồng với các Cộng Tác Viên và các chính sách liên quan đến việc thực hiện
                      giao dịch trên tại từng thời điểm.
                    </li>
                  </ul>
                </section>

                <h2 className={'text-[14px] font-semibold  text-baseColor'}>1.2. Nghĩa vụ của Shop360</h2>

                <section className={'mt-2 break-words text-left text-sm font-medium text-baseColor'}>
                  <ul className="list-disc pl-8">
                    <li>
                      Lưu trữ thông tin đăng ký của các Cộng Tác Viên tham gia vào hệ thống và thường xuyên cập nhật
                      những thông tin thay đổi, bổ sung có liên quan.
                    </li>

                    <li>
                      Shop360 có nghĩa vụ tiếp nhận những phản ánh từ các Cộng Tác Viên và là đầu mối tiếp nhận và giải
                      quyết các khiếu nại của người tiêu dùng trong trường hợp một giao dịch thực hiện trên có nhiều hơn
                      02 Cộng Tác Viên tham gia.
                    </li>

                    <li>Lưu trữ thông tin về các giao dịch được thực hiện trên theo hệ thống.</li>

                    <li>
                      Chịu trách nhiệm cập nhật các sản phẩm mới, đăng tải thông tin chính xác các sản phẩm/ dịch vụ.
                    </li>

                    <li>
                      Chịu trách nhiệm cung cấp công cụ thống kê trực tuyến, số liệu quảng cáo, doanh thu, doanh số qua
                      nền tảng liên quan đến dịch vụ hợp tác cho các Cộng Tác Viên.
                    </li>

                    <li>
                      Đảm bảo chất lượng và duy trì hoạt động ổn định của hệ thống để đáp ứng nhu cầu sử dụng của các
                      Cộng Tác Viên.
                    </li>

                    <li>
                      Shop360 được các Cộng Tác Viên ủy quyền chịu trách nhiệm thu hộ hoa hồng khi thực hiện sản
                      phẩm/dịch vụ thành công.
                    </li>

                    <li>
                      Mọi trách nhiệm liên quan đến dịch vụ/quảng cáo bị chỉnh sửa nằm ngoài hệ thống Shop360 mà do các
                      Cộng Tác Viên quản lý thì các Cộng Tác Viên phải tự chịu trách nhiệm với các bên liên quan.
                    </li>

                    <li>
                      Shop360 chịu trách nhiệm cung cấp, hỗ trợ về mặt kỹ thuật cho các Cộng Tác Viên và Khách hàng.
                      Cung cấp và hướng dẫn cho Cộng Tác Viên các tài liệu và cách sử dụng dịch vụ đảm bảo việc cung cấp
                      dịch vụ được đúng đắn, kịp thời và thông suốt.
                    </li>

                    <li>
                      Chịu trách nhiệm đối soát, xác nhận số liệu và thanh toán cho Cộng Tác Viên phần doanh thu Cộng
                      Tác Viên được hưởng.
                    </li>

                    <li>
                      Các nghĩa vụ khác theo quy định tại Điều kiện chung này, các Điều kiện và điều khoản tương ứng với
                      từng tính năng, các hợp đồng với các Cộng Tác Viên và các chính sách liên quan đến việc thực hiện
                      giao dịch trên tại từng thời điểm.
                    </li>
                  </ul>
                </section>

                <h2 className={'text-[14px] font-semibold  text-baseColor'}>1.3. Giới hạn trách nhiệm của Shop360</h2>

                <section className={'mt-2 break-words text-left text-sm font-medium text-baseColor'}>
                  <ul className="list-disc pl-8">
                    <li>
                      Shop360 sẽ cố gắng đến mức cao nhất trong phạm vi và điều kiện có thể để được duy trì trong tình
                      trạng chạy ổn định, an toàn và tuân thủ các quy định pháp luật liên quan đến việc cung ứng các
                      dịch vụ thương mại điện tử và thực hiện các giao dịch trực tuyến. Tuy nhiên, Shop360 không chịu
                      trách nhiệm trước những thiệt hại, tổn thất của Cộng Tác Viên phát sinh trong quá trình giao dịch,
                      trừ phi những thiệt hại, tổn thất này là do lỗi cố ý của Shop360.
                    </li>

                    <li>
                      Trong trường hợp mà Cộng Tác Viên bị thiệt hại do lỗi của Shop360, trách nhiệm của Shop360 đối với
                      Cộng Tác Viên chỉ giới hạn ở khoản lợi ích thực tế (không bao gồm các khoản thu hộ, tạm giữ hộ)
                      trong giao dịch bị thiệt hại do lỗi của Shop360 trên.
                    </li>
                  </ul>
                </section>
              </section>

              <section className="mt-4 grid gap-y-4 [&>*]:text-justify [&>*]:leading-[25px]">
                <h2 className={'text-[15px] font-semibold  text-baseColor'}>2. Quyền và nghĩa vụ của Cộng Tác Viên</h2>

                <h2 className={'text-[14px] font-semibold  text-baseColor'}>
                  2.1. Điều kiện để trở thành Cộng Tác Viên
                </h2>

                <section className={'mt-2 break-words text-left text-sm font-medium text-baseColor'}>
                  <ul className="list-disc pl-8">
                    <li>Ký kết Hợp đồng Cộng Tác Viên với Shop360 (Hợp đồng điện tử).</li>

                    <li>Tiến hành xác thực thông tin giấy tờ tùy thân. Các loại giấy tờ được chấp nhận: CMND, CCCD</li>

                    <li>
                      Cộng Tác Viên cần nhập các thông tin như: Họ và tên trên giấy tờ, Số trên giấy tờ, Ngày cấp, Ngày
                      sinh, Giới tính, Địa chỉ thường trú, Hình ảnh chụp hai mặt của giấy tờ.
                    </li>

                    <li>
                      Shop360 sẽ sử dụng trí tuệ nhân tạo để đọc thông tin từ hình ảnh chụp hai mặt giấy tờ mà Cộng Tác
                      Viên gửi lên. Nếu thông tin trùng khớp, Shop360 sẽ ghi nhận thông tin đăng ký hợp lệ. Nếu thông
                      tin không trùng khớp, Shop360 sẽ yêu cầu Cộng Tác Viên xem và sửa lại thông tin, và kiểm tra lại
                      lần nữa. Nếu vẫn chưa trùng khớp, Cộng Tác Viên sẽ đọc thông tin bằng mắt thường để kiểm tra.
                    </li>

                    <li>
                      Một số trường hợp sẽ không được xác thực: Hình ảnh giấy tờ không rõ nét, Giấy tờ bị rách, mất góc,
                      không nhìn được đủ thông tin, Giấy tờ bị quá hạn sử dụng.
                    </li>

                    <li>
                      Shop360 sẽ kiểm tra lại Hợp đồng Cộng tác viên. Nếu thông tin đầy đủ và hợp lệ, Đại diện Shop360
                      sẽ ký kết Hợp đồng bằng chữ ký điện tử và hệ thống xác thực tài khoản tạo lập Cộng Tác Viên thành
                      công.
                    </li>
                  </ul>
                </section>

                <h2 className={'text-[14px] font-semibold  text-baseColor'}>2.2. Quyền của Cộng Tác Viên</h2>

                <section className={'mt-2 break-words text-left text-sm font-medium text-baseColor'}>
                  <ul className="list-disc pl-8">
                    <li>
                      Được Shop360 cấp tài khoản sử dụng dịch vụ tại Nền Tảng. Mỗi tài khoản đều được sở hữu bởi một
                      Cộng Tác Viên với đầy đủ thông tin Họ tên, Số Chứng minh thư nhân dân/Căn cước công dân, Địa chỉ
                      hộp thư điện tử và số điện thoại di động hợp pháp và các thông tin khác cần được cung cấp khi đăng
                      ký tạo tài khoản với Shop360. Cộng Tác Viên phải chịu trách nhiệm toàn bộ về tính chính xác của
                      các thông tin đã đăng ký hoặc sửa đổi trên hệ thống của Shop360.
                    </li>

                    <li>
                      Thông qua thông tin đã đăng ký với Shop360, Cộng Tác Viên có quyền nhận tiền hoa hồng sau khi đã
                      trừ các khoản chi phí theo quy định.
                    </li>
                  </ul>
                </section>

                <h2 className={'text-[14px] font-semibold  text-baseColor'}>2.3. Nghĩa vụ của Cộng Tác Viên</h2>

                <section className={'mt-2 break-words text-left text-sm font-medium text-baseColor'}>
                  <ul className="list-disc pl-8">
                    <li>Cung cấp thông tin xác thực nhận dạng cá nhân/ tổ chức cho Shop360 theo quy định.</li>

                    <li>
                      Cung cấp các thông tin thanh toán chính xác (tài khoản, ngân hàng …) được sử dụng để Shop360 thanh
                      toán cho Cộng Tác Viên. Cộng Tác Viên phải chịu hoàn toàn trách nhiệm về những rủi ro xảy ra do
                      việc cung cấp thông tin thanh toán không chính xác gây ra.
                    </li>

                    <li>
                      Mọi trách nhiệm liên quan đến việc Dịch vụ bị chỉnh sửa/thay đổi nằm ngoài hệ thống của Shop360 mà
                      do Cộng Tác Viên quản lý thì Cộng Tác Viên phải chịu các trách nhiệm liên quan.
                    </li>

                    <li>
                      Chủ động quảng bá, giới thiệu thông tin Dịch vụ đến khách hàng hoặc các đối tác phân phối một cách
                      rõ ràng và minh bạch (Ví dụ: thông tin Dịch vụ đầy đủ; giá bán được niêm yết rõ ràng,…);
                    </li>

                    <li>
                      Đảm bảo các điều kiện khai thác, sử dụng Dịch vụ cho khách hàng trong phạm vi khả năng và trách
                      nhiệm của Cộng Tác Viên; phát hiện ra các khiếm khuyết, sai sót của Dịch vụ từ bất kỳ nguồn nào và
                      thông báo để Shop360 phối hợp sửa chữa;
                    </li>

                    <li>Phối hợp cùng Shop360 thực hiện việc đối soát thanh toán doanh thu theo chu kỳ.</li>
                  </ul>
                </section>
              </section>

              <section className="[&>*]:text-justify [&>*]:leading-[25px]">
                <h1
                  className={classNames(
                    'text-[16px]  font-semibold text-baseColor',
                    isMobile ? 'mt-6' : 'mt-8 uppercase'
                  )}
                >
                  V. Các hành vi nghiêm cấm
                </h1>

                <section className={'mt-2 break-words text-left text-sm font-medium text-baseColor'}>
                  <ul className="list-disc pl-8">
                    <li>
                      Nghiêm cấm việc tiến hành quảng bá, kinh doanh, phân phối Dịch vụ bằng hình thức SPAM, gian lận
                      như Cheating, hacking,…
                    </li>

                    <li>
                      Nghiêm cấm quảng bá Dịch vụ trên các kênh vi phạm pháp luật, trái với thuần phong mỹ tục, kênh bị
                      tranh chấp và/hoặc các hình thức không được pháp luật cho phép hoặc chưa quy định rõ ràng …. Nếu
                      phát hiện vi phạm, Cộng Tác Viên cũng phải chịu số tiền phạt theo quy định của Shop360.vn tùy từng
                      thời điểm. Đồng thời Shop360.vn không có trách nhiệm nào về kinh tế và pháp lý đối với các vi phạm
                      ở trên do Cộng Tác Viên gây ra.
                    </li>

                    <li>
                      Nghiêm cấm Cộng Tác Viên sử dụng link affiliate hoặc các thông tin của sản phẩm dịch vụ của
                      Shop360.vn để thực hiện việc quảng cáo trên các diễn đàn, group, cộng đồng, Facebook giữa các đơn
                      vị đối thủ của Shop360.vn và các đối thủ có cùng dịch vụ với sản phẩm mà Shop360.vn cung cấp.
                    </li>

                    <li>
                      Trường hợp Người nộp đơn vận hành trang mạng thuộc một trong các trường hợp sau thì Shop360.vn có
                      khả năng từ chối đơn đăng ký. Thêm vào đó, nếu sau khi được chấp thuận mà Shop360.vn phát hiện ra
                      trang mạng nêu trên cũng thuộc một trong các trường hợp sau thì Shop360.vn có thể hủy bỏ việc đăng
                      ký dựa theo các quy định của Thỏa thuận:
                      <ul className="grid gap-y-2">
                        <li>a) Trang mạng chứa đựng nội dung tục tĩu, khiêu dâm;</li>

                        <li>
                          b) Trang mạng chứa đựng nội dung vi phạm Bản quyền tác giả, Bản quyền thương mại, nội dung từ
                          các tên miền được biết đến rộng rãi hay các quyền sở hữu tài sản trí tuệ;
                        </li>

                        <li>
                          c) Trang mạng chứa đựng nội dung có tính phỉ báng, bôi nhọ, cản trở việc kinh doanh, vi phạm
                          danh dự, quyền riêng tư, quyền hình ảnh hay các hình thức tương tự;
                        </li>

                        <li>
                          d) Trang mạng có liên quan đến các hoạt động đầu tư lừa đảo, bán hàng đa cấp hay các hình thức
                          tương tự hoặc tham gia giới thiệu các hoạt động trên;
                        </li>

                        <li>
                          e) Trang mạng có liên quan đến cờ bạc, cá cược (ngoại trừ các trò chơi do Nhà nước vận hành,
                          sổ xố hay các trò chơi hợp pháp);
                        </li>

                        <li>
                          f) Trang mạng chứa đựng nội dung vi phạm pháp luật hiện hành, các sắc lệnh, các quy định trong
                          ngành, v.v, hay các nội dung trái luật và chống đối xã hội;
                        </li>

                        <li>g) Trang mạng chứa đựng nội dung công kích lại trật tự và đạo đức cộng đồng;</li>

                        <li>h) Trang mạng chứa đựng nội dung về tôn giáo;</li>

                        <li>i) Trang mạng chỉ dành cho người thân, bạn bè, các cá nhân cụ thể sử dụng;</li>

                        <li>
                          j) Trang mạng công khai cho công chúng không theo cách thức, ví dụ như, yêu cầu có tên người
                          dùng và mật mã để truy cập;
                        </li>

                        <li>k) Trang mạng không có sự đầu tư về nội dung hay chứa các nội dung gây khó hiểu;</li>

                        <li>l) Trang mạng chứa đựng số lượng lớn các đường dẫn tới các trang mạng khác;</li>

                        <li>m) Các trường hợp khác mà xét thấy không phù hợp.</li>
                      </ul>
                    </li>

                    <li className="my-2">
                      Không chạy từ khóa thương hiệu, nghiêm cấm sử dụng quảng cáo tìm kiếm đối với các từ khóa thương
                      hiệu của nhà quảng cáo.
                    </li>

                    <li>
                      Không sử dụng từ khóa cố tình viết sai tên thương hiệu để gây nhầm lẫn với khách hàng. Cụ thể:
                      <ul className="grid gap-y-2">
                        <li>
                          <strong>+</strong> Không sử dụng các từ khóa thương hiệu (Brand Key) của nhà quảng cáo như:
                          Shopee, Tiki, FPTshop, Yes24,…
                        </li>

                        <li>
                          <strong>+</strong> Các từ khóa liên quan hoặc cố tình viết sai tên thương hiệu gây hiểu nhầm.
                        </li>

                        <li>
                          <strong>+</strong> Không được đại diện – mạo danh thương hiệu của sản phẩm: Nghiêm cấm Cộng
                          Tác Viên tạo dựng các trang web, page mạo danh. Nghiêm cấm Cộng Tác Viên liên hệ tư vấn trực
                          tiếp cho khách hàng với tư cách là đại diện MobiFone trên trang web của MobiFone.
                        </li>

                        <li>
                          <strong>+</strong> Cộng Tác Viên không được sử dụng hoạt động đầu cơ mua đi bán lại (reseller)
                          các sản phẩm. Đây được hiểu là hình thức mua sản phẩm để nhận được hoa hồng từ nhà quảng cáo
                          sau đó lấy sản phẩm đó đi bán lại.
                        </li>

                        <li>
                          <strong>+</strong> Toàn bộ các hành vi gian lận khác vô tình hoặc cố ý gây ra thiệt hại về
                          thương hiệu, hoạt động kinh doanh của MobiFone hoặc Shop360.vn mà không được phép.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </section>
              </section>

              <section className="[&>*]:text-justify [&>*]:leading-[25px]">
                <h1
                  className={classNames(
                    'text-[16px]  font-semibold text-baseColor',
                    isMobile ? 'mt-6' : 'mt-8 uppercase'
                  )}
                >
                  VI. CHÍNH SÁCH BẢO ĐẢM AN TOÀN GIAO DỊCH
                </h1>
              </section>

              <section className="mt-4 grid gap-y-4 [&>*]:text-justify [&>*]:leading-[25px]">
                <h2 className={'text-[15px] font-semibold  text-baseColor'}>
                  1. Hệ thống thanh toán trực tuyến trên Shop360 được cung cấp bởi các đối tác cung cấp dịch vụ thanh
                  toán, dịch vụ trung gian thanh toán đã được cấp phép hoạt động hợp pháp tại Việt Nam (“Đối Tác Thanh
                  Toán”).
                </h2>

                <h2 className={'text-[15px] font-semibold  text-baseColor'}>
                  2. Cộng Tác Viên không đưa thông tin chi tiết về việc thanh toán, thông tin tài khoản/thẻ, mật khẩu và
                  các thông tin xác thực khác với bất kỳ bên thứ ba nào bằng bất kỳ hình thức liên lạc nào. Shop360
                  không chịu trách nhiệm về những thiệt hại hay rủi ro Cộng Tác Viên có thể gánh chịu trong việc để lộ
                  các thông tin thanh toán.
                </h2>

                <h2 className={'text-[15px] font-semibold  text-baseColor'}>
                  3. Cộng Tác Viên tuyệt đối không được trực tiếp hay gián tiếp sử dụng, phát tán bất kỳ chương trình,
                  mã, công cụ hay hình thức nào khác để xâm nhập, can thiệp trái phép vào hệ thống hay làm thay đổi cấu
                  trúc dữ liệu của Shop360 và các Cộng Tác Viên khác. Mọi vi phạm sẽ bị xử lý theo Điều kiện chung này
                  và quy định của pháp luật.
                </h2>
              </section>
            </div>
          </div>
        </div>
      </div>
      <FooterMobi />
    </>
  );
};
