import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { Fragment, useContext, useLayoutEffect, useState } from 'react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import Scrollbars from 'react-custom-scrollbars';
import { ButtonGradient } from '../../../components/common/button/ButtonGradient';
import { ButtonCustom } from '../../../components/common/ButtonCustom';
import { CheckBoxFilter } from '../../../components/common/checkbox/CheckBoxFilter';
import { RadioFilter } from '../../../components/common/radio/RadioFilter';
import { RangeSlider } from '../../../components/common/slider/RangeSlider';
import { Controller, useForm } from 'react-hook-form';
import { FilterItem } from '../../../components/common/FilterItem';
import { isEmpty } from 'lodash';
import { ServicePlanContext } from './ServicePlanContext';
import { getLabelTypeNumber } from '../../../utils/utils';
import useCheckDeviceMobi from '../../../hooks/useCheckDeviceMobi';
import { NUM_EXPIRED_CHOICE, PACK_TYPE_CHOICE } from 'constant';

interface FormConfigPaymentProps {
  packTypeChoice: Array<number>;
  numExpiredChoice: Array<number>;
  traSau: number | string;
  traTruoc: number | string;
  amount: Array<number>;
  srcReq: 'CMSCTV';
  supplier: Array<any>;
  dataPack: number | string;
}

export const PopupFilter = ({
  isOpen,
  setIsOpen,
  onSearch,
  listTypePackage,
  listCycle,
  // listTelco,
  maxAmount,
  currentSupplier,
}: any) => {
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const isMobile = useCheckDeviceMobi();

  const {
    packTypeChoice,
    numExpiredChoice,
    traSau,
    traTruoc,
    amount,
    dataPack,
    setPackTypeChoice,
    setNumExpiredChoice,
    setTraTruoc,
    setTraSau,
    setAmount,
    supplier,
    setSupplier,
    setDataPack,
  } = useContext(ServicePlanContext);
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormConfigPaymentProps>({
    defaultValues: {
      packTypeChoice,
      numExpiredChoice,
      traSau,
      traTruoc,
      amount,
      supplier,
      dataPack,
    },
    mode: 'all',
  });

  const onSubmit = (data: FormConfigPaymentProps) => {
    setPackTypeChoice(data.packTypeChoice);
    setNumExpiredChoice(data.numExpiredChoice);
    setAmount(data.amount);
    setTraTruoc(data.traTruoc);
    setTraSau(data.traSau);
    setSupplier(data.supplier);
    setDataPack(data.dataPack);

    let submitData: any = {
      packType: data.packTypeChoice || [],
      numExpiredChoice: isEmpty(data.numExpiredChoice) ? '' : data.numExpiredChoice.join(','),
      traSau: data.traSau || '',
      traTruoc: data.traTruoc || '',
      fromAmount: data.amount[0] || (typeof data.amount[0] === 'number' ? 0 : ''),
      toAmount: data.amount[1] || (typeof data.amount[1] === 'number' ? 0 : ''),
      supplier: data.supplier,
    };

    if (currentSupplier === 'vinaphone') {
      let submitPackageType = data.traSau ? 2 : data.traTruoc ? 1 : data.dataPack ? 3 : -1;
      if (submitPackageType !== -1) {
        submitData.packageType = submitPackageType;
      }
    }

    onSearch(submitData);
    setIsDirty(true);
  };

  const onClear = () => {
    setValue('packTypeChoice', []);
    setValue('numExpiredChoice', []);
    setValue('supplier', []);
    setValue('amount', [0, maxAmount]);
    setValue('traSau', 0);
    setValue('traTruoc', 0);
    setValue('dataPack', '');
    setIsDirty(false);
  };

  useLayoutEffect(() => {
    setValue('packTypeChoice', packTypeChoice);
  }, [packTypeChoice, setValue]);

  useLayoutEffect(() => {
    setValue('numExpiredChoice', numExpiredChoice);
  }, [numExpiredChoice, setValue]);

  useLayoutEffect(() => {
    setValue('amount', amount);
  }, [amount, setValue]);

  useLayoutEffect(() => {
    setValue('traTruoc', traTruoc);
  }, [setValue, traTruoc]);

  useLayoutEffect(() => {
    setValue('traSau', traSau);
  }, [setValue, traSau]);

  useLayoutEffect(() => {
    setValue('supplier', supplier);
  }, [setValue, supplier]);

  const showButtonClear =
    !isEmpty(watch('packTypeChoice')) ||
    !isEmpty(watch('supplier')) ||
    watch('traTruoc') ||
    watch('traSau') ||
    watch('dataPack') ||
    !isEmpty(watch('numExpiredChoice')) ||
    watch('amount').toString() !== [0, maxAmount].toString();

  return (
    <Transition
      show={isOpen}
      as={Fragment}
      enter="transition-transform duration-700"
      enterFrom={isMobile ? 'translate-x-0' : 'translate-x-[450px]'}
      enterTo="translate-x-0"
      leave="transition-transform duration-700"
      leaveFrom="translate-x-0"
      leaveTo={isMobile ? 'translate-x-0' : 'translate-x-[450px]'}
      appear={true}
    >
      <Dialog
        open={isOpen}
        onClose={() => {
          onClear();
          setIsOpen(false);
        }}
        static
        className="fixed inset-0 z-50 flex h-screen w-screen flex-row items-start justify-end bg-[#000000]/60 md:-left-[450px] md:w-[calc(100vw_+_450px)] md:items-center"
      >
        <Dialog.Panel
          className={
            'h-[calc(100vh-100px)] w-full rounded-bl-[16px] border border-gray-200 bg-[#F8F8F8] shadow-base md:w-[450px]' +
            ' duration-300 md:h-screen'
          }
        >
          <form onSubmit={handleSubmit(onSubmit)} className={'h-full w-full'}>
            <Dialog.Title
              className={'flex h-[64px] flex-row items-center justify-between border-b-[1.5px] border-[#D6D5D5] px-6'}
            >
              <div></div>
              <h2 className={'text-[22px] font-extrabold leading-[26px] text-[#404040]'}>Bộ lọc tìm kiếm</h2>
              <button onClick={() => setIsOpen(false)}>
                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_573_26413)">
                    <path
                      d="M0.314708 0.701427C0.414214 0.601669 0.532424 0.522521 0.662566 0.468518C0.792707 0.414515 0.932225 0.386719 1.07313 0.386719C1.21403 0.386719 1.35354 0.414515 1.48369 0.468518C1.61383 0.522521 1.73204 0.601669 1.83154 0.701427L7.5004 6.37242L13.1692 0.701427C13.2688 0.60183 13.3871 0.522825 13.5172 0.468924C13.6473 0.415022 13.7868 0.38728 13.9277 0.38728C14.0685 0.38728 14.208 0.415022 14.3381 0.468924C14.4682 0.522825 14.5865 0.60183 14.6861 0.701427C14.7857 0.801024 14.8647 0.919262 14.9186 1.04939C14.9725 1.17952 15.0002 1.31899 15.0002 1.45984C15.0002 1.6007 14.9725 1.74017 14.9186 1.8703C14.8647 2.00043 14.7857 2.11867 14.6861 2.21826L9.01509 7.88711L14.6861 13.556C14.7857 13.6556 14.8647 13.7738 14.9186 13.9039C14.9725 14.0341 15.0002 14.1735 15.0002 14.3144C15.0002 14.4552 14.9725 14.5947 14.9186 14.7248C14.8647 14.855 14.7857 14.9732 14.6861 15.0728C14.5865 15.1724 14.4682 15.2514 14.3381 15.3053C14.208 15.3592 14.0685 15.3869 13.9277 15.3869C13.7868 15.3869 13.6473 15.3592 13.5172 15.3053C13.3871 15.2514 13.2688 15.1724 13.1692 15.0728L7.5004 9.40181L1.83154 15.0728C1.73195 15.1724 1.61371 15.2514 1.48358 15.3053C1.35345 15.3592 1.21398 15.3869 1.07313 15.3869C0.932275 15.3869 0.792802 15.3592 0.662673 15.3053C0.532543 15.2514 0.414305 15.1724 0.314708 15.0728C0.215111 14.9732 0.136106 14.855 0.0822051 14.7248C0.0283036 14.5947 0.000560851 14.4552 0.000560851 14.3144C0.000560851 14.1735 0.0283036 14.0341 0.0822051 13.9039C0.136106 13.7738 0.215111 13.6556 0.314708 13.556L5.9857 7.88711L0.314708 2.21826C0.21495 2.11876 0.135802 2.00055 0.0817995 1.87041C0.0277966 1.74026 0 1.60075 0 1.45984C0 1.31894 0.0277966 1.17943 0.0817995 1.04928C0.135802 0.919142 0.21495 0.800933 0.314708 0.701427Z"
                      fill="#A9A9A9"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_573_26413">
                      <rect width="15" height="15" fill="white" transform="translate(0 0.386719)" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </Dialog.Title>
            <Dialog.Description className={'flex h-full flex-col '}>
              <div className={'mt-4 flex flex-col space-y-2 border-b border-[#D6D5D5] px-6 pb-4'}>
                <span className={'text-[18px] text-base font-bold leading-[28px]'}>Bộ lọc áp dụng</span>
                <div className={'min-h-[60px] rounded-t-[12px] rounded-bl-[12px] border border-[#D6D5D5] bg-white p-2'}>
                  <Scrollbars className={'h-full w-full'}>
                    <div className={'flex h-full w-full flex-wrap gap-2 space-x-2'}>
                      {/* {watch('packTypeChoice')?.map((packType, index) => (
                        <FilterItem
                          label={PACK_TYPE_CHOICE.find((item) => item.value === packType)?.name || ''}
                          key={`${index}-packTypeChoice`}
                          onClear={() =>
                            setValue(
                              'packTypeChoice',
                              watch('packTypeChoice').filter((v) => v !== packType)
                            )
                          }
                        />
                      ))} */}
                      {watch('packTypeChoice')?.[0] > 0 ? (
                        <FilterItem
                          label={
                            PACK_TYPE_CHOICE.find((item) => item.value === watch('packTypeChoice')?.[0])?.name || ''
                          }
                          onClear={() => setValue('traTruoc', 0)}
                        />
                      ) : (
                        <></>
                      )}
                      {/* {watch('supplier')?.map((_item, index) => (
                        <FilterItem
                          label={getLabelTypeNumber(_item, listTelco)}
                          key={`${index}-supplier`}
                          onClear={() =>
                            setValue(
                              'supplier',
                              watch('supplier').filter((v) => v !== _item)
                            )
                          }
                        />
                      ))} */}
                      {watch('traTruoc') === 1 && (
                        <FilterItem label={'Trả trước'} onClear={() => setValue('traTruoc', 0)} />
                      )}
                      {watch('dataPack') === 1 && <FilterItem label={'Data'} onClear={() => setValue('dataPack', 0)} />}
                      {watch('traSau') === 1 && <FilterItem label={'Trả sau'} onClear={() => setValue('traSau', 0)} />}
                      {/* {!isEmpty(watch('amount')) && watch('amount').some((i: number) => i !== 0) && ( */}
                      {!isEmpty(watch('amount')) &&
                        !(watch('amount')?.[0] === 0 && watch('amount')?.[1] === maxAmount) && (
                          <FilterItem
                            label={`Từ ${watch('amount')?.[0]?.toLocaleString('vi') || 0} VNĐ - ${
                              watch('amount')?.[1]?.toLocaleString('vi') || 0
                            } VNĐ`}
                            onClear={() => setValue('amount', [0, maxAmount])}
                          />
                        )}
                      {watch('numExpiredChoice')?.map((packType, index) => (
                        <FilterItem
                          label={NUM_EXPIRED_CHOICE.find((item) => item.value === packType)?.name || '-'}
                          key={`${index}-numExpiredChoice`}
                          onClear={() =>
                            setValue(
                              'numExpiredChoice',
                              watch('numExpiredChoice').filter((v) => v !== packType)
                            )
                          }
                        />
                      ))}
                      {!showButtonClear && <span>Chưa có bộ lọc áp dụng</span>}
                    </div>
                  </Scrollbars>
                </div>
              </div>
              <div className={'h-[calc(100%-280px)]'}>
                <Scrollbars className={'h-full min-h-[200px] w-full'}>
                  <div className={'mb-4 overflow-x-hidden px-6'}>
                    {currentSupplier === 'mobifone' ? (
                      <Disclosure as={'div'} defaultOpen>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="mt-2 flex w-full justify-between py-2 text-left text-[18px] text-base font-bold leading-[28px]">
                              <span>Loại gói cước</span>
                              <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-base`} />
                            </Disclosure.Button>
                            <Disclosure.Panel className="space-y-4 pb-2">
                              <Controller
                                control={control}
                                name="packTypeChoice"
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    {PACK_TYPE_CHOICE?.map((packType: { name: string; value: any }) => (
                                      <RadioFilter
                                        name={packType.value}
                                        key={packType.value}
                                        label={packType.name}
                                        value={value?.[0] === packType.value}
                                        onChange={() => {
                                          onChange([packType.value]);
                                        }}
                                        group="loai-goi-cuoc"
                                      />
                                    ))}
                                  </>
                                )}
                              />
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ) : (
                      <></>
                    )}
                    {/* <Disclosure as={'div'} defaultOpen>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="mt-2 flex w-full justify-between py-2 text-left text-[18px] text-base font-bold leading-[28px]">
                            <span>{currentSupplier === 'mobifone' ? 'Loại thuê bao áp dụng' : 'Nhóm gói cước'}</span>
                            <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-base`} />
                          </Disclosure.Button>
                          <Disclosure.Panel className="space-y-4 pb-2">
                            <Controller
                              control={control}
                              name="traTruoc"
                              render={({ field: { onChange, value } }) => (
                                <RadioFilter
                                  name={'tra-truoc'}
                                  label={'Trả trước'}
                                  group={'loai-thue-bao'}
                                  value={value === 1}
                                  onChange={(isChecked: boolean) => {
                                    if (isChecked) {
                                      onChange(1);
                                      setValue('traSau', 0);

                                      if (currentSupplier === 'vinaphone') {
                                        setValue('dataPack', 0);
                                      }
                                    }
                                  }}
                                />
                              )}
                            />
                            <Controller
                              control={control}
                              name="traSau"
                              render={({ field: { onChange, value } }) => (
                                <RadioFilter
                                  name={'tra-sau'}
                                  label={'Trả sau'}
                                  group={'loai-thue-bao'}
                                  value={value === 1}
                                  onChange={(isChecked: boolean) => {
                                    if (isChecked) {
                                      onChange(1);
                                      setValue('traTruoc', 0);

                                      if (currentSupplier === 'vinaphone') {
                                        setValue('dataPack', 0);
                                      }
                                    }
                                  }}
                                />
                              )}
                            />

                            {currentSupplier === 'vinaphone' ? (
                              <Controller
                                control={control}
                                name="dataPack"
                                render={({ field: { onChange, value } }) => (
                                  <RadioFilter
                                    name={'data-pack'}
                                    label={'Data'}
                                    group={'loai-thue-bao'}
                                    value={value === 1}
                                    onChange={(isChecked: boolean) => {
                                      if (isChecked) {
                                        onChange(1);
                                        setValue('traTruoc', 0);
                                        setValue('traSau', 0);
                                      }
                                    }}
                                  />
                                )}
                              />
                            ) : (
                              <></>
                            )}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure> */}
                    <Disclosure as={'div'} defaultOpen>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="mt-2 flex w-full justify-between py-2 text-left text-[18px] text-base font-bold leading-[28px]">
                            <span>Khoảng giá</span>
                            <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-base`} />
                          </Disclosure.Button>
                          <Disclosure.Panel className="pb-6">
                            <Controller
                              control={control}
                              name="amount"
                              render={({ field: { onChange, value } }) => (
                                <RangeSlider
                                  value={
                                    isDirty || value[0] || value[1] || (typeof value[1] === 'number' && !value[1])
                                      ? value
                                      : [0, maxAmount]
                                  }
                                  setValue={(_value: Array<number>) => onChange(_value)}
                                  maxAmount={maxAmount}
                                />
                              )}
                            />
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                    {/* <Disclosure as={'div'} defaultOpen>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="mt-2 flex w-full justify-between py-2 text-left text-[18px] text-base font-bold leading-[28px]">
                            <span>Nhà mạng</span>
                            <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-base`} />
                          </Disclosure.Button>
                          <Disclosure.Panel className="space-y-4 pb-2">
                            <Controller
                              control={control}
                              name="supplier"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  {listTelco.map((item: any) => (
                                    <CheckBoxFilter
                                      key={`${item.value}-${item.name}`}
                                      name={item.value}
                                      label={item.name}
                                      value={value}
                                      onChange={onChange}
                                    />
                                  ))}
                                </>
                              )}
                            />
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure> */}
                    <Disclosure as={'div'} defaultOpen>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="mt-2 flex w-full justify-between py-2 text-left text-[18px] text-base font-bold leading-[28px]">
                            <span>Chu kỳ</span>
                            <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-base`} />
                          </Disclosure.Button>
                          <Disclosure.Panel className="space-y-4 pb-2">
                            <Controller
                              control={control}
                              name="numExpiredChoice"
                              render={({ field: { onChange, value } }) => (
                                <>
                                  {NUM_EXPIRED_CHOICE.map((numExpired: any) => (
                                    <CheckBoxFilter
                                      key={numExpired.value}
                                      name={numExpired.value}
                                      label={numExpired.name}
                                      value={value}
                                      onChange={onChange}
                                    />
                                  ))}
                                </>
                              )}
                            />
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </Scrollbars>
              </div>
              <div className={'grid h-[90px] w-full grid-cols-2 items-center justify-end gap-2 px-6 py-2'}>
                <ButtonGradient className={'h-[30px]'} background={'bg-white'} type={'button'} onClick={onClear}>
                  <span className={'!text-sm font-semibold text-[#F15836]'}>Xóa điều kiện</span>
                </ButtonGradient>
                <ButtonCustom
                  label={'Áp dụng'}
                  type={'submit'}
                  className={'h-[30px] !rounded-l-[10px] bg-gray-600 !text-sm !font-semibold'}
                />
              </div>
            </Dialog.Description>
          </form>
        </Dialog.Panel>
      </Dialog>
    </Transition>
  );
};
