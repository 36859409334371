import { sendPostRequest } from './index';
import { apiPayment } from './api/payment';

export const getHistoryPayment = (param: any) => {
  return sendPostRequest(apiPayment.getHistoryPayment, param);
};

export const checkReqPayment = () => {
  return sendPostRequest(apiPayment.checkReqPayment);
};

export const createPayment = (param: any) => {
  return sendPostRequest(apiPayment.createPayment, param);
};

export const getInfoPayment = (param: any) => {
  return sendPostRequest(apiPayment.getInfoPayment, param);
};
