import { format, startOfToday } from 'date-fns';
import { useMemo, useRef, useState } from 'react';
import { CalendarIcon, CalendarIconMobi } from 'assets/images';
import { UseCheckOutsideElement } from '../../../hooks/useCheckOutsideElement';
import { PopupSelectDay } from './PopupSelectDay';
import { classNames } from '../../../utils/utils';

const MONTH_FORMAT = 'MM/yyyy';

export const Calendar = ({
  placeholder,
  selectedDay,
  onChange,
  otherDay,
  backgroundColor,
  className,
  isDisabledSelectToday,
  setTodayToDate,
}: any) => {
  const today = startOfToday();
  const [currentMonth, setCurrentMonth] = useState<string>(format(today, MONTH_FORMAT));
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const calendarPopupRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  UseCheckOutsideElement([calendarPopupRef, inputRef], () => setIsOpen(false));

  const getValueInput = useMemo(() => {
    if (!selectedDay) return '';
    if (!otherDay) return format(selectedDay, 'dd/MM/yyyy');
    return `${format(selectedDay, 'dd/MM/yyyy')} - ${format(otherDay, 'dd/MM/yyyy')}`;
  }, [selectedDay, otherDay]);

  return (
    <div className={'relative w-full'}>
      <div className="relative">
        {backgroundColor ? (
          <div className="pointer-events-none absolute inset-y-0 flex items-center !fill-white pl-[calc(100%_-_30px)] md:fill-[#404040]">
            <img src={CalendarIcon} alt={'calendar-icon'} className={'fill-[#A9A9A9]'} />
          </div>
        ) : (
          <>
            <div className="pointer-events-none absolute inset-y-0 hidden items-center !fill-white pl-[calc(100%_-_30px)] md:flex md:fill-[#404040]">
              <img src={CalendarIcon} alt={'calendar-icon'} className={''} />
            </div>
            <div className="pointer-events-none absolute inset-y-0 flex items-center !fill-white pl-[calc(100%_-_30px)] md:hidden md:fill-[#404040]">
              <img src={CalendarIconMobi} alt={'calendar-icon'} className={''} />
            </div>
          </>
        )}

        <input
          ref={inputRef}
          name="start"
          type="text"
          className={classNames(
            ' datepicker-input block w-full rounded-t-[10px] rounded-bl-[10px] border' +
              ' border-[#EFEFEF]/20 bg-white/20 py-2 pl-2 pr-10 text-sm font-normal text-white shadow-base' +
              ' placeholder:text-white focus:outline-none md:border-[#EFEFEF] md:bg-white md:text-baseColor' +
              ' md:placeholder:text-gray-400',
            className,
            backgroundColor ? '!border-[#EFEFEF] !bg-white !text-baseColor placeholder:!text-baseColor' : ''
          )}
          placeholder={placeholder}
          autoComplete={'off'}
          value={getValueInput}
          onChange={() => {}}
          readOnly
          onKeyDown={(e) => {
            if (e.key === 'Backspace' || e.key === 'Delete') {
              setIsOpen(false);
              onChange(null);
            }
          }}
          onClick={() => !isOpen && setIsOpen(true)}
        />
        {isOpen && (
          <PopupSelectDay
            calendarPopupRef={calendarPopupRef}
            selectedDay={selectedDay}
            otherDay={otherDay}
            onChange={onChange}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            currentMonth={currentMonth}
            setCurrentMonth={setCurrentMonth}
            today={today}
            setTodayToDate={setTodayToDate}
            isDisabledSelectToday={isDisabledSelectToday}
          />
        )}
      </div>
    </div>
  );
};
