import { Footer } from '../footer';
import { HeaderCMSCommon } from './HeaderCMSCommon';

export const HeaderCMS = ({ children }: any) => {
  return (
    <div className={'md:bg-gradient-to-r md:from-[#FFF] md:to-[#FFF]'}>
      <HeaderCMSCommon />
      <div className={'w-full bg-[#F8F8F8] pt-[60px]'}>
        <div
          className={
            "bg-cover bg-center md:min-h-[600px] md:bg-[url('assets/images/main/main-header-background.svg')]" +
            ' bg-no-repeat'
          }
        >
          <section
            className={
              'mx-auto flex min-h-[600px] w-full max-w-[85rem] items-center justify-center md:h-[calc(100vh_-_400px)] md:px-4'
            }
          >
            {children}
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};
