import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { classNames } from '../../utils/utils';

interface IButtonCustom extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  label: string | any;
  background?: string;
}

export const ButtonCustom = (props: IButtonCustom) => {
  const { label, className, background, ...otherProps } = props;
  return (
    <button
      className={classNames(
        'flex items-center justify-center rounded-t-[8px] rounded-bl-[8px] py-2.5 text-sm font-extrabold leading-[21px] text-white shadow-base',
        className,
        background || 'bg-gradient-to-r from-[#F15836] to-[#FF924F] '
      )}
      {...otherProps}
    >
      {label}
    </button>
  );
};
