import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { ButtonGradient } from 'components/common/button/ButtonGradient';
import { ButtonCustom } from 'components/common/ButtonCustom';
import { CheckBoxFilter } from 'components/common/checkbox/CheckBoxFilter';
import { FilterItem } from 'components/common/FilterItem';
import { RadioFilter } from 'components/common/radio/RadioFilter';
import { format } from 'date-fns';
import { useWindowSize } from 'hooks/useWindowSize';
import { get, isEmpty } from 'lodash';
import { Fragment, useContext, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { getOptionSearch } from 'services/common';
import { arrayStringToArrayNumber, getLabelTypeNumber } from 'utils/utils';
import { TimeForm } from '../../../../components/common/form-item/TimeForm';
import { RangeSlider } from '../../../../components/common/slider/RangeSlider';
import { PER_PAGE_DEFAULT, QUERY_KEY } from '../../../../constant';
import { SelectOptionEntity } from '../../../../entity/SearchEntity';
import { PopupChooseBonus } from './PopupChooseBonus';
import { TransMobileContext } from './TransMobileContext';

interface FormConfigPaymentProps {
  typeProduct: SelectOptionEntity;
  typeTrans: SelectOptionEntity;
  itemName: Array<string>;
  tranCode: string;
  msisdnContact: Array<string>;
  startDay: Date | null;
  endDay: Date | null;
  // visible filter by sim or package
  isFilterBySim: boolean;
  isFilterByPackage: boolean;
  // sim
  typeMsisdn: Array<any>;
  telco: Array<any>;
  typeSim: Array<any>;
  // package
  typePck: Array<any>;
  cyclePck: Array<any>;
  amount: Array<number>;
  numExpiredChoice: Array<any>;
  agent: Array<number>;
}

export const PopupFilterTrans = ({ isOpen, setIsOpen, onClearData }: any) => {
  const [width, height] = useWindowSize();
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [isShowMore, setIsShowMore] = useState(false);
  const {
    typeProduct,
    typeTrans,
    itemName,
    tranCode,
    msisdnContact,
    startDay,
    endDay,
    isFilterBySim,
    isFilterByPackage,
    typeMsisdn,
    telco,
    typeSim,
    typePck,
    cyclePck,
    amount,
    setIsFilterBySim,
    setIsFilterByPackage,
    setTypeProduct,
    setTypeTrans,
    setItemName,
    setTranCode,
    setMsisdnContact,
    setStartDay,
    setEndDay,

    setTypeMsisdn,
    setTelco,
    setTypeSim,
    setAmount,
    numExpiredChoice,
    setNumExpiredChoice,
    setTypePck,
    setCyclePck,
  } = useContext(TransMobileContext);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormConfigPaymentProps>({
    defaultValues: {
      typeProduct,
      typeTrans,
      itemName,
      tranCode,
      msisdnContact,
      startDay,
      endDay,
      isFilterBySim,
      isFilterByPackage,
      typeMsisdn,
      telco,
      typeSim,
      typePck,
      cyclePck,
      amount: [0, 0],
    },
    mode: 'all',
  });

  const { data: dataCommonOption } = useQuery(QUERY_KEY.GET_COMMON_OPTION, () => getOptionSearch());

  const listGroupMsisdnOptions = useMemo(() => {
    return dataCommonOption?.data?.data?.listGroupMsisdn || [];
  }, [dataCommonOption]);

  const listTelco = useMemo(() => {
    return dataCommonOption?.data?.data?.TELCO || [];
  }, [dataCommonOption]);

  const listTypePackage = useMemo(() => {
    return dataCommonOption?.data?.data?.LOAIGOICUOC || [];
  }, [dataCommonOption]);

  const listTypeSubscriber = useMemo(() => {
    return dataCommonOption?.data?.data?.LOAITHUEBAO || [];
  }, [dataCommonOption]);

  const listCycle = useMemo(() => {
    return dataCommonOption?.data?.data?.CHUKY || [];
  }, [dataCommonOption]);

  const listPckPrice = useMemo(() => {
    return dataCommonOption?.data?.data?.pckPrice || [];
  }, [dataCommonOption]);

  const listTransStatuses = useMemo(() => {
    return dataCommonOption?.data?.data?.transStatuses || [];
  }, [dataCommonOption]);

  const maxAmount = useMemo(() => {
    return dataCommonOption?.data?.data?.limitAmountPck?.find((i: any) => i.name === 'Max')?.value || 0;
  }, [dataCommonOption]);

  const [listGroupMsisdnOptionsFirst, listGroupMsisdnOptionsLast] = useMemo(() => {
    const _listGroupMsisdnOptions = [...listGroupMsisdnOptions];
    const _listGroupMsisdnOptionsFirst = _listGroupMsisdnOptions.splice(0, 5);
    return [_listGroupMsisdnOptionsFirst, _listGroupMsisdnOptions];
  }, [listGroupMsisdnOptions]);

  const onSubmit = (data: FormConfigPaymentProps) => {
    setIsFilterBySim(data.isFilterBySim);
    setIsFilterByPackage(data.isFilterByPackage);
    setTypeProduct(
      data.isFilterBySim
        ? { id: 1, name: 'SimSố', value: 'SIMSO' }
        : data.isFilterByPackage
        ? { id: 2, name: 'Gói Cước', value: 'GOICUOC' }
        : { id: 3, name: '', value: '' }
    );
    setTypeTrans(data.typeTrans);
    setItemName(data.itemName);
    setTranCode(data.tranCode);
    setMsisdnContact(data.msisdnContact);
    setStartDay(data.startDay);
    setEndDay(data.endDay);

    setTypeMsisdn(data.typeMsisdn);
    setTelco(data.telco);
    setTypeSim(data.typeSim);
    setAmount(data.amount);
    setTypePck(data.typePck);
    setCyclePck(data.cyclePck);
    setIsOpen(false);
    setIsDirty(true);

    let param: any = {
      tranCode: data.tranCode?.trim() || undefined,
      msisdnContact: isEmpty(data.msisdnContact) ? undefined : data.msisdnContact.map((item) => item?.trim()),
      itemName: isEmpty(data.itemName) ? undefined : data.itemName.map((item) => item?.trim()),
      itemType: data.isFilterBySim ? 'SIMSO' : data.isFilterByPackage ? 'GOICUOC' : '',
      status: data.typeTrans.value ? +data.typeTrans.value : undefined,
      fromDate: data.startDay ? data.startDay.toISOString() : undefined,
      toDate: data.endDay ? data.endDay.toISOString() : undefined,
      page: 1,
      numberPerPage: PER_PAGE_DEFAULT,
    };

    if (data.isFilterBySim) {
      param = {
        ...param,
        agent: arrayStringToArrayNumber(data.telco),
        typeMsisdn: arrayStringToArrayNumber(data.typeMsisdn),
        groupMsisdn: arrayStringToArrayNumber(data.typeSim),
      };
    } else if (data.isFilterByPackage) {
      param = {
        ...param,
        agent: arrayStringToArrayNumber(data.telco),
        typePck: arrayStringToArrayNumber(data.typePck),
        cyclePck: arrayStringToArrayNumber(data.cyclePck),
        fromAmount: data.amount[0] || undefined,
        toAmount: data.amount[1] || undefined,
      };
    }
    onClearData(param);
  };

  const onClear = () => {
    setValue('isFilterByPackage', false);
    setValue('isFilterBySim', false);

    setValue('typeTrans', { id: 0, name: '', value: '' });

    setValue('telco', []);
    setValue('typePck', []);
    setValue('typeMsisdn', []);
    setValue('amount', [0, 0]);
    setValue('cyclePck', []);
    setValue('typeSim', []);
    setValue('startDay', null);
    setValue('endDay', null);
    setStartDay(null);
    setEndDay(null);
    setIsDirty(false);
  };

  useLayoutEffect(() => {
    setValue('typeProduct', typeProduct);
  }, [typeProduct]);

  useLayoutEffect(() => {
    setValue('typeTrans', typeTrans);
  }, [typeTrans]);

  useLayoutEffect(() => {
    setValue('itemName', itemName);
  }, [itemName]);

  useLayoutEffect(() => {
    setValue('tranCode', tranCode);
  }, [tranCode]);

  useLayoutEffect(() => {
    setValue('msisdnContact', msisdnContact);
  }, [msisdnContact]);

  useLayoutEffect(() => {
    setValue('startDay', startDay);
  }, [startDay]);

  useLayoutEffect(() => {
    setValue('endDay', endDay);
  }, [endDay]);

  useLayoutEffect(() => {
    setValue('isFilterBySim', isFilterBySim);
  }, [isFilterBySim]);

  useLayoutEffect(() => {
    setValue('isFilterByPackage', isFilterByPackage);
  }, [isFilterByPackage]);

  useLayoutEffect(() => {
    setValue('typeMsisdn', typeMsisdn);
  }, [typeMsisdn]);

  useLayoutEffect(() => {
    setValue('telco', telco);
  }, [telco]);

  useLayoutEffect(() => {
    setValue('typeSim', typeSim);
  }, [typeSim]);

  useLayoutEffect(() => {
    setValue('typePck', typePck);
  }, [typePck]);

  useLayoutEffect(() => {
    setValue('cyclePck', cyclePck);
  }, [cyclePck]);

  useLayoutEffect(() => {
    setValue('amount', amount);
  }, [amount]);

  return (
    <>
      <Transition show={isOpen} as={Fragment}>
        <Dialog
          open={isOpen}
          onClose={() => {
            onClear();
            setIsOpen(false);
          }}
          style={{ height: `${height}px` }}
          className="fixed top-0 left-0 z-50 flex w-screen flex-row items-start justify-center bg-[#000000]/60"
        >
          <Dialog.Panel
            style={{ height: `${height}px` }}
            className={'w-full border border-gray-200 bg-[#F8F8F8] shadow-base'}
          >
            <form onSubmit={handleSubmit(onSubmit)} className={'h-full w-full'}>
              <Dialog.Title
                className={'flex h-[64px] flex-row items-center justify-between border-b-[1.5px] border-[#D6D5D5] px-6'}
              >
                <div></div>
                <h2 className={'text-[18px] font-extrabold leading-[28px] text-[#404040]'}>Bộ lọc tìm kiếm</h2>
                <button onClick={() => setIsOpen(false)} className={'focus:outline-none'}>
                  <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_573_26413)">
                      <path
                        d="M0.314708 0.701427C0.414214 0.601669 0.532424 0.522521 0.662566 0.468518C0.792707 0.414515 0.932225 0.386719 1.07313 0.386719C1.21403 0.386719 1.35354 0.414515 1.48369 0.468518C1.61383 0.522521 1.73204 0.601669 1.83154 0.701427L7.5004 6.37242L13.1692 0.701427C13.2688 0.60183 13.3871 0.522825 13.5172 0.468924C13.6473 0.415022 13.7868 0.38728 13.9277 0.38728C14.0685 0.38728 14.208 0.415022 14.3381 0.468924C14.4682 0.522825 14.5865 0.60183 14.6861 0.701427C14.7857 0.801024 14.8647 0.919262 14.9186 1.04939C14.9725 1.17952 15.0002 1.31899 15.0002 1.45984C15.0002 1.6007 14.9725 1.74017 14.9186 1.8703C14.8647 2.00043 14.7857 2.11867 14.6861 2.21826L9.01509 7.88711L14.6861 13.556C14.7857 13.6556 14.8647 13.7738 14.9186 13.9039C14.9725 14.0341 15.0002 14.1735 15.0002 14.3144C15.0002 14.4552 14.9725 14.5947 14.9186 14.7248C14.8647 14.855 14.7857 14.9732 14.6861 15.0728C14.5865 15.1724 14.4682 15.2514 14.3381 15.3053C14.208 15.3592 14.0685 15.3869 13.9277 15.3869C13.7868 15.3869 13.6473 15.3592 13.5172 15.3053C13.3871 15.2514 13.2688 15.1724 13.1692 15.0728L7.5004 9.40181L1.83154 15.0728C1.73195 15.1724 1.61371 15.2514 1.48358 15.3053C1.35345 15.3592 1.21398 15.3869 1.07313 15.3869C0.932275 15.3869 0.792802 15.3592 0.662673 15.3053C0.532543 15.2514 0.414305 15.1724 0.314708 15.0728C0.215111 14.9732 0.136106 14.855 0.0822051 14.7248C0.0283036 14.5947 0.000560851 14.4552 0.000560851 14.3144C0.000560851 14.1735 0.0283036 14.0341 0.0822051 13.9039C0.136106 13.7738 0.215111 13.6556 0.314708 13.556L5.9857 7.88711L0.314708 2.21826C0.21495 2.11876 0.135802 2.00055 0.0817995 1.87041C0.0277966 1.74026 0 1.60075 0 1.45984C0 1.31894 0.0277966 1.17943 0.0817995 1.04928C0.135802 0.919142 0.21495 0.800933 0.314708 0.701427Z"
                        fill="#A9A9A9"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_573_26413">
                        <rect width="15" height="15" fill="white" transform="translate(0 0.386719)" />
                      </clipPath>
                    </defs>
                  </svg>
                </button>
              </Dialog.Title>
              <Dialog.Description className={'flex h-full flex-col '}>
                <div className={'mt-4 flex flex-col space-y-2 border-b border-[#D6D5D5] px-6 pb-4'}>
                  <span className={'text-base text-[18px] font-bold leading-[28px]'}>Bộ lọc áp dụng</span>
                  <div
                    className={'min-h-[60px] rounded-t-[12px] rounded-bl-[12px] border border-[#D6D5D5] bg-white p-2'}
                  >
                    <Scrollbars className={'h-full w-full'}>
                      <div className={'flex h-full w-full flex-wrap gap-2 space-x-2'}>
                        {/*TODO:*/}
                        {watch('isFilterByPackage') && (
                          <FilterItem label={'Gói cước'} onClear={() => setValue('isFilterByPackage', false)} />
                        )}
                        {watch('isFilterBySim') && (
                          <FilterItem label={'Sim số'} onClear={() => setValue('isFilterBySim', false)} />
                        )}
                        {watch('typeTrans') && !isEmpty(watch('typeTrans').value) && (
                          <FilterItem
                            label={watch('typeTrans').name}
                            onClear={() => setValue('typeTrans', { id: 0, name: '', value: '' })}
                          />
                        )}
                        {watch('typeMsisdn')?.map((_item, index) => (
                          <FilterItem
                            label={getLabelTypeNumber(_item, listTypeSubscriber)}
                            key={`${index}-typeMsisdn`}
                            onClear={() =>
                              setValue(
                                'typeMsisdn',
                                watch('typeMsisdn').filter((v) => v !== _item)
                              )
                            }
                          />
                        ))}
                        {watch('telco')?.map((_item, index) => (
                          <FilterItem
                            label={getLabelTypeNumber(_item, listTelco)}
                            key={`${index}-telco`}
                            onClear={() =>
                              setValue(
                                'telco',
                                watch('telco').filter((v) => v !== _item)
                              )
                            }
                          />
                        ))}
                        {/* {watch('typeSim')?.map((_item, index) => (
                          <FilterItem
                            label={getLabelTypeNumber(_item, [
                              ...listGroupMsisdnOptionsFirst,
                              ...listGroupMsisdnOptionsLast,
                            ])}
                            key={`${index}-typeSim`}
                            onClear={() =>
                              setValue(
                                'typeSim',
                                watch('typeSim').filter((v) => v !== _item)
                              )
                            }
                          />
                        ))} */}
                        {watch('typeSim')?.map((_item, index) => (
                          <FilterItem
                            label={getLabelTypeNumber(_item, [
                              ...listGroupMsisdnOptionsFirst,
                              ...listGroupMsisdnOptionsLast,
                            ])}
                            key={`${index}-typeSim`}
                            onClear={() =>
                              setValue(
                                'typeSim',
                                watch('typeSim').filter((v) => v !== _item)
                              )
                            }
                          />
                        ))}
                        {watch('typePck')?.map((_item, index) => (
                          <FilterItem
                            label={getLabelTypeNumber(_item, listTypePackage)}
                            key={`${index}-typePck`}
                            onClear={() =>
                              setValue(
                                'typePck',
                                watch('typePck').filter((v) => v !== _item)
                              )
                            }
                          />
                        ))}{' '}
                        {!isEmpty(watch('amount')) && watch('amount').some((i: number) => i !== 0) && (
                          <FilterItem
                            label={`Từ ${watch('amount')?.[0]?.toLocaleString('vi') || 0} VNĐ - ${
                              watch('amount')?.[1]?.toLocaleString('vi') || 0
                            } VNĐ`}
                            onClear={() => setValue('amount', [0, 0])}
                          />
                        )}
                        {watch('cyclePck')?.map((_item, index) => (
                          <FilterItem
                            label={getLabelTypeNumber(_item, listCycle)}
                            key={`${index}-cyclePck`}
                            onClear={() =>
                              setValue(
                                'cyclePck',
                                watch('cyclePck').filter((v) => v !== _item)
                              )
                            }
                          />
                        ))}
                        {watch('startDay') && watch('endDay') && (
                          <FilterItem
                            label={`${format(watch('startDay') || new Date(), 'dd/MM/yyyy')} - ${format(
                              watch('endDay') || new Date(),
                              'dd/MM/yyyy'
                            )}`}
                            onClear={() => {
                              setValue('startDay', null);
                              setValue('endDay', null);
                            }}
                          />
                        )}
                      </div>
                    </Scrollbars>
                  </div>
                </div>
                <div className={'h-[calc(100%_-_280px)]'}>
                  <Scrollbars className={'h-full min-h-[200px] w-full'}>
                    <div className={'mb-4 px-6 overflow-x-hidden'}>
                      <Disclosure as={'div'} defaultOpen>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="mt-2 flex w-full justify-between py-2 text-left text-[18px] text-base font-bold leading-[28px]">
                              <span>Danh mục sản phẩm</span>
                              <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-base`} />
                            </Disclosure.Button>
                            <Disclosure.Panel className="space-y-4 pb-2">
                              <Controller
                                control={control}
                                name="isFilterByPackage"
                                render={({ field: { onChange, value } }) => (
                                  <RadioFilter
                                    name={'GOI_CUOC'}
                                    label={'Gói cước'}
                                    // group={'loai-thue-bao'}
                                    value={value}
                                    onChange={(isChecked: boolean) => {
                                      onChange(isChecked);
                                      setValue('isFilterBySim', false);
                                      if (isChecked) {
                                        setValue('typeMsisdn', []);
                                        setValue('telco', []);
                                        setValue('typeSim', []);
                                      }
                                    }}
                                  />
                                )}
                              />
                              <Controller
                                control={control}
                                name="isFilterBySim"
                                render={({ field: { onChange, value } }) => (
                                  <RadioFilter
                                    name={'SIM_SO'}
                                    label={'Sim số'}
                                    // group={'loai-thue-bao'}
                                    value={value}
                                    onChange={(isChecked: boolean) => {
                                      onChange(isChecked);
                                      setValue('isFilterByPackage', false);

                                      if (isChecked) {
                                        setValue('telco', []);
                                        setValue('typePck', []);
                                        setValue('typeMsisdn', []);
                                        setValue('amount', [0, 0]);
                                        setValue('cyclePck', []);
                                      }
                                    }}
                                  />
                                )}
                              />
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                      <Disclosure as={'div'} defaultOpen>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="mt-2 flex w-full justify-between py-2 text-left text-[18px] text-base font-bold leading-[28px]">
                              <span>Trạng thái</span>
                              <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-base`} />
                            </Disclosure.Button>
                            <Disclosure.Panel className="space-y-4 pb-2">
                              <Controller
                                control={control}
                                name="typeTrans"
                                render={({ field: { onChange, value } }) => (
                                  <>
                                    {listTransStatuses.map((item: any) => (
                                      <RadioFilter
                                        name={item.value}
                                        label={item.name}
                                        value={value.value === item.value}
                                        onChange={(isChecked: boolean) => {
                                          if (isChecked) {
                                            onChange(item);
                                          }
                                        }}
                                      />
                                    ))}
                                  </>
                                )}
                              />
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>

                      <Disclosure as={'div'} defaultOpen className={'relative'}>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="mt-2 flex w-full justify-between py-2 text-left text-[18px] text-base font-bold leading-[28px]">
                              <span>Thời gian</span>
                              <ChevronUpIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-base`} />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" space-y-4 pb-2">
                              <TimeForm
                                placeholder={'Thời gian giao dịch'}
                                startDay={startDay}
                                setStartDay={setStartDay}
                                endDay={endDay}
                                setEndDay={setEndDay}
                                backgroundColor={true}
                              />
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                      {/*TODO: filter theo sim */}
                      {watch('isFilterBySim') && (
                        <>
                          <Disclosure as={'div'} defaultOpen>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="mt-2 flex w-full justify-between py-2 text-left text-[18px] text-base font-bold leading-[28px]">
                                  <span>Loại thuê bao</span>
                                  <ChevronUpIcon
                                    className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-base`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className="space-y-4 pb-2">
                                  <Controller
                                    control={control}
                                    name="typeMsisdn"
                                    render={({ field: { onChange, value } }) => (
                                      <>
                                        {listTypeSubscriber.map((packType: { name: string; value: any }) => (
                                          <CheckBoxFilter
                                            name={packType.value}
                                            key={packType.value}
                                            label={packType.name}
                                            value={value}
                                            onChange={onChange}
                                          />
                                        ))}
                                      </>
                                    )}
                                  />
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                          <Disclosure as={'div'} defaultOpen>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="mt-2 flex w-full justify-between py-2 text-left text-[18px] text-base font-bold leading-[28px]">
                                  <span>Nhà mạng</span>
                                  <ChevronUpIcon
                                    className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-base`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className="space-y-4 pb-2">
                                  <Controller
                                    control={control}
                                    name="telco"
                                    render={({ field: { onChange, value } }) => (
                                      <>
                                        {listTelco.map((item: any) => (
                                          <CheckBoxFilter
                                            key={`${item.value}-${item.name}`}
                                            name={item.value}
                                            label={item.name}
                                            value={value}
                                            onChange={onChange}
                                          />
                                        ))}
                                      </>
                                    )}
                                  />
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                          <Disclosure as={'div'} defaultOpen>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="mt-2 flex w-full justify-between py-2 text-left text-[18px] text-base font-bold leading-[28px]">
                                  <span>Loại số</span>
                                  <ChevronUpIcon
                                    className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-base`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className="space-y-4 pb-2">
                                  <Controller
                                    control={control}
                                    name="typeSim"
                                    render={({ field: { onChange, value } }) => (
                                      <>
                                        {listGroupMsisdnOptionsFirst.map((_item: { name: string; value: any }) => (
                                          <CheckBoxFilter
                                            key={`${_item.value}-${_item.name}`}
                                            name={_item.value}
                                            label={_item.name}
                                            value={value}
                                            onChange={onChange}
                                          />
                                        ))}
                                        {!isShowMore && !isEmpty(listGroupMsisdnOptionsLast) && (
                                          <div
                                            className={
                                              'flex cursor-pointer items-center text-sm font-medium' + ' text-[#F15836]'
                                            }
                                            onClick={() => setIsShowMore(true)}
                                          >
                                            Xem toàn bộ
                                            <svg
                                              className={'ml-1'}
                                              width="13"
                                              height="8"
                                              viewBox="0 0 13 8"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M11.3633 1.59375L6.36328 6.59375L1.36328 1.59375"
                                                stroke="#F15836"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          </div>
                                        )}
                                        {isShowMore && (
                                          <>
                                            {listGroupMsisdnOptionsLast.map((_item: { name: string; value: any }) => (
                                              <CheckBoxFilter
                                                key={`${_item.value}-${_item.name}`}
                                                name={_item.value}
                                                label={_item.name}
                                                value={value}
                                                onChange={onChange}
                                              />
                                            ))}
                                          </>
                                        )}
                                      </>
                                    )}
                                  />
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        </>
                      )}
                      {/* TODO: filter theo goi cuoc */}
                      {watch('isFilterByPackage') && (
                        <>
                          <Disclosure as={'div'} defaultOpen>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="mt-2 flex w-full justify-between py-2 text-left text-[18px] text-base font-bold leading-[28px]">
                                  <span>Loại gói cước</span>
                                  <ChevronUpIcon
                                    className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-base`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className="space-y-4 pb-2">
                                  <Controller
                                    control={control}
                                    name="typePck"
                                    render={({ field: { onChange, value } }) => (
                                      <>
                                        {listTypePackage.map((packType: { name: string; value: any }) => (
                                          <CheckBoxFilter
                                            name={packType.value}
                                            key={packType.value}
                                            label={packType.name}
                                            value={value}
                                            onChange={onChange}
                                          />
                                        ))}
                                      </>
                                    )}
                                  />
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                          <Disclosure as={'div'} defaultOpen>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="mt-2 flex w-full justify-between py-2 text-left text-[18px] text-base font-bold leading-[28px]">
                                  <span>Loại thuê bao</span>
                                  <ChevronUpIcon
                                    className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-base`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className="space-y-4 pb-2">
                                  <Controller
                                    control={control}
                                    name="typeMsisdn"
                                    render={({ field: { onChange, value } }) => (
                                      <>
                                        {listTypeSubscriber.map((packType: { name: string; value: any }) => (
                                          <CheckBoxFilter
                                            name={packType.value}
                                            key={packType.value}
                                            label={packType.name}
                                            value={value}
                                            onChange={onChange}
                                          />
                                        ))}
                                      </>
                                    )}
                                  />
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                          <Disclosure as={'div'} defaultOpen>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="mt-2 flex w-full justify-between py-2 text-left text-[18px] text-base font-bold leading-[28px]">
                                  <span>Nhà mạng</span>
                                  <ChevronUpIcon
                                    className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-base`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className="space-y-4 pb-2">
                                  <Controller
                                    control={control}
                                    name="telco"
                                    render={({ field: { onChange, value } }) => (
                                      <>
                                        {listTelco.map((item: any) => (
                                          <CheckBoxFilter
                                            key={`${item.value}-${item.name}`}
                                            name={item.value}
                                            label={item.name}
                                            value={value}
                                            onChange={onChange}
                                          />
                                        ))}
                                      </>
                                    )}
                                  />
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                          <Disclosure as={'div'} defaultOpen>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="mt-2 flex w-full justify-between py-2 text-left text-[18px] text-base font-bold leading-[28px]">
                                  <span>Khoảng giá</span>
                                  <ChevronUpIcon
                                    className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-base`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className="pb-6">
                                  <Controller
                                    control={control}
                                    name="amount"
                                    render={({ field: { onChange, value } }) => (
                                      <RangeSlider
                                        value={
                                          isDirty || watch('amount')[0] || watch('amount')[1]
                                            ? watch('amount')
                                            : [0, maxAmount]
                                        }
                                        setValue={(_value: Array<number>) => onChange(_value)}
                                        maxAmount={maxAmount}
                                      />
                                    )}
                                  />
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                          <Disclosure as={'div'} defaultOpen>
                            {({ open }) => (
                              <>
                                <Disclosure.Button className="mt-2 flex w-full justify-between py-2 text-left text-[18px] text-base font-bold leading-[28px]">
                                  <span>Chu kỳ</span>
                                  <ChevronUpIcon
                                    className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-base`}
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel className="space-y-4 pb-2">
                                  <Controller
                                    control={control}
                                    name="cyclePck"
                                    render={({ field: { onChange, value } }) => (
                                      <>
                                        {listCycle.map((numExpired: any) => (
                                          <CheckBoxFilter
                                            name={numExpired.value}
                                            label={numExpired.name}
                                            value={value}
                                            onChange={onChange}
                                          />
                                        ))}
                                      </>
                                    )}
                                  />
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        </>
                      )}
                    </div>
                  </Scrollbars>
                </div>
                <div className={'grid h-[90px] w-full grid-cols-2 items-center justify-center gap-2 px-6 py-2'}>
                  <ButtonGradient className={'h-[30px]'} background={'bg-white'} type={'button'} onClick={onClear}>
                    <span className={'text-sm font-semibold text-[#F15836]'}>Xóa điều kiện</span>
                  </ButtonGradient>
                  <ButtonCustom
                    label={'Áp dụng'}
                    type={'submit'}
                    className={'h-[30px] !rounded-l-[10px] bg-gray-600 !text-sm !font-semibold'}
                  />
                </div>
              </Dialog.Description>
            </form>
          </Dialog.Panel>
        </Dialog>
      </Transition>
    </>
  );
};
