import { createContext, Dispatch, SetStateAction } from 'react';
import { SelectOptionEntity } from '../../../../entity/SearchEntity';

type TransContextType = {
  typeProduct: SelectOptionEntity;
  typeTrans: SelectOptionEntity;
  itemName: Array<string>;
  tranCode: string;
  msisdnContact: Array<string>;
  startDay: Date | null;
  endDay: Date | null;
  // visible filter by sim or package
  isFilterBySim: boolean;
  isFilterByPackage: boolean;

  // sim
  typeMsisdn: Array<any>;
  telco: Array<any>;
  typeSim: Array<any>;
  // package
  typePck: Array<any>;
  cyclePck: Array<any>;
  numExpiredChoice: Array<any>;

  setIsFilterBySim: Dispatch<SetStateAction<boolean>>;
  setIsFilterByPackage: Dispatch<SetStateAction<boolean>>;
  setTypeProduct: Dispatch<SetStateAction<SelectOptionEntity>>;
  setTypeTrans: Dispatch<SetStateAction<SelectOptionEntity>>;

  setItemName: Dispatch<SetStateAction<Array<string>>>;
  setTranCode: Dispatch<SetStateAction<string>>;
  setMsisdnContact: Dispatch<SetStateAction<Array<string>>>;

  setStartDay: Dispatch<SetStateAction<Date | null>>;
  setEndDay: Dispatch<SetStateAction<Date | null>>;

  setTypeMsisdn: Dispatch<SetStateAction<Array<any>>>;
  setTelco: Dispatch<SetStateAction<Array<any>>>;
  setTypeSim: Dispatch<SetStateAction<Array<any>>>;

  setTypePck: Dispatch<SetStateAction<Array<any>>>;
  setCyclePck: Dispatch<SetStateAction<Array<any>>>;
  setNumExpiredChoice: Dispatch<SetStateAction<Array<any>>>;

  amount: Array<any>;
  setAmount: Dispatch<SetStateAction<Array<any>>>;
};

export const TransMobileContext = createContext<TransContextType>({} as TransContextType);
