import { classNames, minTwoDigits, secondsToHms } from '../../../../utils/utils';
import { CalendarBackIcon } from '../../../../assets/images';
import { useContext, useEffect, useState } from 'react';
import VerifyOTP from '../../../../components/common/otp/VerifyOTP';
import {
  SUPPLIER,
  TIME_COUNTDOWN_RESEND,
  TIME_COUNTDOWN_VERIFY,
  TIME_COUNTDOWN_VERIFY_VINAPHONE,
} from '../../../../constant';
import { useMutation } from 'react-query';
import {
  confirmOtpRegisterServicePlan,
  confirmOtpRegisterVNPServicePlan,
  getOtpRegisterServicePlan,
  getOtpRegisterVNPServicePlan,
} from '../../../../services/service-plan';
import { ConfirmPackagePopupContext } from './ConfirmPackagePopupContext';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import toast from 'react-hot-toast';
import { SpinCustom } from 'components/common/SpinCustom';

export const ConfirmOTPBody = ({ responseGetOTP, setSuccessTransCode, setOtpError }: any) => {
  const { setActiveStepIndex, item, dataForm } = useContext(ConfirmPackagePopupContext);

  const [otp, setOtp] = useState(new Array(6).fill(''));
  const [countdown, setCountdown] = useState<number>(TIME_COUNTDOWN_RESEND);
  const [countdownVerify, setCountdownVerify] = useState<number>(
    item.supplier === 'Vinaphone' ? TIME_COUNTDOWN_VERIFY_VINAPHONE : TIME_COUNTDOWN_VERIFY
  );
  const [isErrorOTP, setIsErrorOTP] = useState<boolean>(false);
  const [messageErrorOTP, setMessageErrorOTP] = useState<string>('');

  const mutation = useMutation((param: any) => confirmOtpRegisterServicePlan(param), {
    onSuccess: (response: AxiosResponse<any>) => {
      // const dataResponse = response.data;
      // if (+dataResponse.respStatus === 1) {
      //   setActiveStepIndex(2);
      //   return;
      // } else if (+dataResponse.respStatus === 2) {
      //   setMessageErrorOTP('Mã xác minh không đúng hoặc hết hạn');
      //   return;
      // } else if (+dataResponse.respStatus === 3 || +dataResponse.respStatus === 0) {
      //   setActiveStepIndex(3);
      //   return;
      // }
      // if (response?.status !== 400) {
      //   toast.error('Đăng ký không thành công, vui lòng báo Admin và thử lại sau!');
      //   return;
      // }

      if (response?.data?.success) {
        setActiveStepIndex(2);
        return;
      } else {
        setMessageErrorOTP(response?.data?.message || 'Mã xác minh không đúng hoặc hết hạn');
        return;
      }
    },
    onError: (error: any) => {
      if (error?.status !== 400) {
        toast.error('Đăng ký không thành công, vui lòng báo Admin và thử lại sau!');
      }
    },
  });

  const mutationVnp = useMutation((param: any) => confirmOtpRegisterVNPServicePlan(param), {
    onSuccess: (response: AxiosResponse<any>) => {
      if (response.data?.success) {
        setSuccessTransCode(response.data?.data?.transCode || '');
        setActiveStepIndex(2);
        return;
      }
      if (!response.data?.success) {
        if (response.data?.error && typeof response.data.error === 'string') {
          setMessageErrorOTP(response.data.error);
          return;
        }

        if (response.data?.data?.error) {
          setActiveStepIndex(3);
          setOtpError(response.data?.message || '');
          // toast.error(response.data?.error?.msg || 'Đăng ký không thành công, vui lòng báo Admin và thử lại sau!')
          return;
        }
      }
      toast.error('Đăng ký không thành công, vui lòng báo Admin và thử lại sau!');
    },
    onError: (error: any) => {
      toast.error('Đăng ký không thành công, vui lòng báo Admin và thử lại sau!');
    },
  });

  const mutationGetOtp = useMutation((param: any) => getOtpRegisterServicePlan(param), {
    onSuccess: (response: any) => {},
  });

  const mutationGetOtpVnp = useMutation((param: any) => getOtpRegisterVNPServicePlan(param), {
    onSuccess: (response: any) => {},
  });

  useEffect(() => {
    if (!countdown) {
      return;
    }
    const intervalId = setInterval(() => {
      setCountdown(countdown - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [countdown, setCountdown]);

  useEffect(() => {
    if (!countdownVerify) {
      return;
    }
    const intervalId = setInterval(() => {
      setCountdownVerify(countdownVerify - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [countdownVerify, setCountdownVerify]);

  const onSubmit = () => {
    if (item.supplier === SUPPLIER.VINAPHONE) {
      mutationVnp.mutate({
        phoneNumber: dataForm.phoneNumber,
        otp: otp.join(''),
        pckCode: item.packCode,
        srcReq: 'CTV',
      });
    } else {
      mutation.mutate({
        msisdn: responseGetOTP?.msisdn,
        otp: otp.join(''),
        pckCode: item?.packCode,
        tranCode: responseGetOTP.tranCode,
        srcReq: 'CTV',
      });
    }
  };

  const handleChange = (_value: any, index: number) => {
    setOtp([...otp.map((_item: any, idx: number) => (idx === index ? _value : _item))]);
    isErrorOTP && setIsErrorOTP(false);
    messageErrorOTP && setMessageErrorOTP('');
  };

  const resendOTP = () => {
    setOtp(new Array(6).fill(''));
    setCountdownVerify(TIME_COUNTDOWN_VERIFY);
    setCountdown(TIME_COUNTDOWN_RESEND);
    setMessageErrorOTP('');
    setIsErrorOTP(false);
    if (item.supplier === SUPPLIER.VINAPHONE) {
      mutationGetOtpVnp.mutate(dataForm);
    } else {
      mutationGetOtp.mutate(dataForm);
    }
  };

  return (
    <>
      <div className={'mt-4 w-full'}>
        <div className={'flex flex-row items-center space-x-2'}>
          <span className={'mr-[1px] cursor-pointer'} onClick={() => setActiveStepIndex(0)}>
            <img className="h-[14px] w-[14px]" src={CalendarBackIcon} alt="Logo" />
          </span>
          <span className={'text-base font-extrabold text-baseColor'}>Mã xác minh</span>
        </div>
        <span className={'text-sm font-medium text-baseColor'}>
          Mã xác thực đã được gửi qua tin nhắn SMS đến số điện thoại{' '}
          <span className={'text-[#F15836]'}>{responseGetOTP?.data?.msisdn}</span>
        </span>
        <div className={'mt-4 w-full'}>
          <VerifyOTP handleChange={handleChange} isErrorOTP={isErrorOTP} isConfirmRegister otp={otp} />
          <div className="mt-2">
            {messageErrorOTP ? (
              <div className={'mb-[10px] mt-[6px] flex w-full flex-col items-center'}>
                <p className="text-sm font-medium text-[#FF7575]">
                  {messageErrorOTP || 'Mã xác minh không đúng hoặc hết hạn'}
                </p>
              </div>
            ) : (
              <>
                {isErrorOTP ? (
                  <div className={'mb-[10px] mt-[6px] flex w-full flex-col items-center'}>
                    <p className="text-sm font-medium text-[#FF7575]">Mã xác minh không đúng hoặc hết hạn</p>
                  </div>
                ) : (
                  <div className={'mt-[16px] flex w-full flex-col items-center'}>
                    <p
                      className={classNames(
                        'text-sm font-medium leading-[20px]',
                        countdownVerify === 0 ? 'text-[#FF7575]' : 'text-gray-400'
                      )}
                    >
                      {countdownVerify === 0 ? (
                        'Mã xác minh không đúng hoặc hết hạn'
                      ) : (
                        <>
                          Mã xác minh hết hạn sau{' '}
                          <span className="text-[#F15836]">{secondsToHms(countdownVerify)}</span>
                        </>
                      )}
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
          <button
            disabled={otp.join('').length !== 6}
            className={classNames(
              'w-full rounded-t-[8px] rounded-bl-[8px] bg-gradient-to-l from-[#F15836] to-[#FF924F] p-2' +
                ' mt-6 text-sm font-extrabold text-white',
              otp.join('').length === 6 ? '' : 'opacity-40'
            )}
            onClick={() => onSubmit()}
          >
            Đăng ký
          </button>
          <div className={'mt-6 flex w-full flex-col items-center'}>
            <div id={'recaptcha-container'} />
            <p className={'text-sm font-medium leading-[20px] text-gray-400'}>
              Bạn chưa nhận được mã?{' '}
              <button
                className={'h-fit w-fit !border-0 !bg-transparent text-gray-600'}
                aria-disabled={countdown > 0}
                disabled={countdown > 0}
                onClick={() => !countdown && resendOTP()}
              >
                <span className="font-bold text-[#F15836]">
                  GỬI LẠI{' '}
                  {countdown ? <span className="font-medium text-[#F15836]">({minTwoDigits(countdown)}s)</span> : ''}
                </span>
              </button>
            </p>
          </div>
        </div>
      </div>
      {(mutation.isLoading || mutationVnp.isLoading || mutationGetOtp.isLoading || mutationGetOtpVnp.isLoading) && (
        <SpinCustom />
      )}
    </>
  );
};
