import { createContext, Dispatch, SetStateAction } from 'react';
import { SelectOptionEntity } from '../../../../entity/SearchEntity';

type TransContextType = {
  typeProduct: SelectOptionEntity;
  typeTrans: SelectOptionEntity;
  itemName: string;
  tranCode: string;
  msisdnContact: string;
  startDay: Date | null;
  endDay: Date | null;
  // visible filter by sim or package
  isFilterBySim: boolean;
  isFilterByPackage: boolean;

  // sim
  typeMsisdn: SelectOptionEntity;
  telco: SelectOptionEntity;
  typeSim: SelectOptionEntity;
  // package
  typePck: SelectOptionEntity;
  cyclePck: SelectOptionEntity;

  setIsFilterBySim: Dispatch<SetStateAction<boolean>>;
  setIsFilterByPackage: Dispatch<SetStateAction<boolean>>;
  setTypeProduct: Dispatch<SetStateAction<SelectOptionEntity>>;
  setTypeTrans: Dispatch<SetStateAction<SelectOptionEntity>>;
  setItemName: Dispatch<SetStateAction<string>>;
  setTranCode: Dispatch<SetStateAction<string>>;
  setMsisdnContact: Dispatch<SetStateAction<string>>;
  setStartDay: Dispatch<SetStateAction<Date | null>>;
  setEndDay: Dispatch<SetStateAction<Date | null>>;

  setTypeMsisdn: Dispatch<SetStateAction<SelectOptionEntity>>;
  setTelco: Dispatch<SetStateAction<SelectOptionEntity>>;
  setTypeSim: Dispatch<SetStateAction<SelectOptionEntity>>;

  setTypePck: Dispatch<SetStateAction<SelectOptionEntity>>;
  setCyclePck: Dispatch<SetStateAction<SelectOptionEntity>>;
};

export const TransContext = createContext<TransContextType>({} as TransContextType);
