import { Listbox, Transition } from '@headlessui/react';
import { Fragment, useMemo } from 'react';
import { PAGINATION_OPTION } from '../../../constant';
import { classNames, minTwoDigits } from '../../../utils/utils';

const dataGenerator = [-2, -1, 0, 1, 2];

export const PaginationAuto = ({
  currentPage,
  setCurrentPage,
  perPage,
  setPerPage,
  isHiddenPerPage,
  totalPage,
  showPages = true,
}: any) => {
  const generatePagination = useMemo(() => {
    if (totalPage > 5) {
      if (currentPage < 3) {
        return dataGenerator.filter((item: number) => item + 3 <= totalPage).map((item: number) => item + 3);
      }
      if (currentPage > totalPage - 2) {
        return dataGenerator
          .filter((item: number) => item + totalPage - 2 <= totalPage)
          .map((item: number) => item + totalPage - 2);
      }
      // console.log(
      //   '3',
      //   dataGenerator
      //     .filter((item: number) => item + currentPage <= totalPage)
      //     .map((item: number) => item + currentPage)
      // );
      return dataGenerator
        .filter((item: number) => item + currentPage <= totalPage)
        .map((item: number) => item + currentPage);
    }

    return Array.from({ length: totalPage }, (_, i) => i + 1);
  }, [currentPage, totalPage]);

  return (
    <div className={'flex flex-row items-center justify-between'}>
      <div className={'h-1 w-1'}></div>
      {showPages && (
        <nav className="flex items-center space-x-2">
          <button
            onClick={() => setCurrentPage(1)}
            className={
              'inline-flex h-[34px] w-[34px] cursor-pointer items-center justify-center border border-transparent text-sm text-[#404040] hover:rounded-t-[8px] hover:rounded-bl-[8px] hover:border-[#F15836] ' +
              (currentPage > 1 ? 'visible' : 'invisible')
            }
          >
            <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.77121 2.66797L3.66406 6.77511L7.77121 10.8823"
                stroke="#404040"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.8064 2.66797L8.69922 6.77511L12.8064 10.8823"
                stroke="#404040"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button
            onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
            className={
              'inline-flex h-[34px] w-[34px] cursor-pointer items-center justify-center border border-transparent text-sm text-[#404040] hover:rounded-t-[8px] hover:rounded-bl-[8px] hover:border-[#F15836] ' +
              (currentPage > 1 ? 'visible' : 'invisible')
            }
          >
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.80636 2.66797L3.69922 6.77511L7.80636 10.8823"
                stroke="#404040"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          {generatePagination.map((page: number) => (
            <span
              key={page}
              onClick={() => setCurrentPage(page)}
              className={classNames(
                'inline-flex h-[34px] w-[34px] cursor-pointer items-center justify-center rounded-t-[8px] rounded-bl-[8px] border p-2 p-4 text-sm hover:border-[#F15836]',
                page === currentPage ? 'border-[#F15836] text-[#F15836]' : 'border-transparent text-baseColor'
              )}
            >
              {page}
            </span>
          ))}
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            className={
              'inline-flex h-[34px] w-[34px] cursor-pointer items-center justify-center border border-transparent text-sm text-[#404040] hover:rounded-t-[8px] hover:rounded-bl-[8px] hover:border-[#F15836] ' +
              (currentPage < totalPage ? 'visible' : 'invisible')
            }
          >
            <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.20926 2.66797L9.31641 6.77511L5.20926 10.8823"
                stroke="#404040"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
          <button
            onClick={() => setCurrentPage(totalPage)}
            className={
              'inline-flex h-[34px] w-[34px] cursor-pointer items-center justify-center ' +
              'border border-transparent text-sm text-[#404040] hover:rounded-t-[8px] hover:rounded-bl-[8px] hover:border-[#F15836] ' +
              (currentPage < totalPage ? 'visible' : 'invisible')
            }
          >
            <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M4.7808 1.5L8.88794 5.60714L4.7808 9.71429"
                stroke="#404040"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.780797 1.5L4.88794 5.60714L0.780796 9.71429"
                stroke="#404040"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </nav>
      )}
      <div
        className={'flex flex-row items-center justify-center space-x-2 rounded-t-[8px] rounded-bl-[8px] shadow-base'}
      >
        {' '}
        {!isHiddenPerPage && (
          <>
            <span className={'p-2 text-sm font-normal text-[#A9A9A9]'}>Hiển thị</span>
            <Listbox value={perPage} onChange={setPerPage}>
              <div className="relative rounded-tr-[8px] bg-[#FFF0EA]">
                <Listbox.Button className="relative w-full cursor-pointer py-2 pl-3 pr-10 text-left">
                  <span className="block truncate font-bold text-[#404040]">{perPage}</span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                    <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M11.082 5.18778L6.97489 9.29492L2.86774 5.18778"
                        stroke="#404040"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {PAGINATION_OPTION.map((_item, index) => (
                      <Listbox.Option
                        key={`${index}-${_item}`}
                        className={({ active }) =>
                          `relative cursor-pointer select-none px-4 py-2 ${
                            active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                          }`
                        }
                        value={_item}
                      >
                        {({ selected }) => (
                          <span
                            className={`block truncate text-sm text-[#404040] ${
                              selected ? 'font-bold' : 'font-normal'
                            }`}
                          >
                            {minTwoDigits(_item)}
                          </span>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </>
        )}
      </div>
    </div>
  );
};
