import { NotFoundIcon } from 'assets/images/common';
import { ButtonCustom } from 'components/common/ButtonCustom';
import { SelectForm } from 'components/common/form-item/SelectForm';
import { TimeForm } from 'components/common/form-item/TimeForm';
import { PaginationCustom } from 'components/common/pagination/Pagination';
import { PaymentCardInfo } from 'components/common/PaymentCardInfo';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { BankCardInfo } from '../../../components/common/BankCardInfo';
import { PER_PAGE_DEFAULT, QUERY_KEY } from '../../../constant';
import { SelectOptionEntity } from '../../../entity/SearchEntity';
import { getUserInfo } from '../../../services/account';
import { getHistoryPayment } from '../../../services/payment';
import { TableHistory } from './TableHistory';

const typeTrans: Array<SelectOptionEntity> = [
  { id: 0, name: '', value: [] },
  { id: 6, name: 'Hoa hồng kinh doanh', value: [1, 2, 10] },
  { id: 8, name: 'Hoa hồng cấp dưới', value: [85, 86, 90] },
  { id: 3, name: 'Yêu cầu rút tiền', value: [3] },
  { id: 4, name: 'Giải thưởng sự kiện', value: [4] },
  { id: 5, name: 'Điều chỉnh bởi OSP', value: [5] },
  { id: 7, name: 'Duy trì', value: [7] },
];

export const AccountTypeDesktop = () => {
  const mutationGetHistory = useMutation((param: any) => getHistoryPayment(param));
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(PER_PAGE_DEFAULT);
  const { data } = useQuery(QUERY_KEY.USER_INFO, () => getUserInfo());

  const [textSearch, setTextSearch] = useState<string>('');
  const [typeTransSelect, setTypeTransSelect] = useState<any>(null);
  const [startDay, setStartDay] = useState<Date | null>(null);
  const [endDay, setEndDay] = useState<Date | null>(null);

  const onSearchHistory = (_param?: { perPage?: number; currentPage?: number }) => {
    const typeTransSelectAll = typeTrans.flatMap(item => Array.isArray(item.value) ? item.value : [item.value]);

    mutationGetHistory.mutate({
      username: '',
      guestMsidn: textSearch,
      transTypes: (typeTransSelect?.value?.length) ? [...typeTransSelect?.value] : typeTransSelectAll,
      status: '',
      level: '',
      fromDate: startDay ? startDay.toISOString() : '',
      toDate: endDay ? endDay.toISOString() : '',
      page: _param?.currentPage || currentPage,
      numberPerPage: _param?.perPage || perPage,
    });
  };

  useEffect(() => {
    onSearchHistory();
  }, []);

  const dataHistory = useMemo(() => {
    if (mutationGetHistory?.data?.data?.data?.items) {
      return mutationGetHistory.data.data.data.items;
    }
    return [];
  }, [mutationGetHistory]);

  return (
    <>
      <div className={'mb-5 flex flex-row justify-between'}>
        <h2 className={'text-2xl font-semibold uppercase text-gray-700'}>TÀI KHOẢN CỘNG TÁC VIÊN</h2>
      </div>
      <div className={'grid-cols-1-1 grid gap-4 lg:grid-cols-2'}>
        <PaymentCardInfo accBalance={data?.data?.data?.accBalance || 0} />
        <BankCardInfo data={data} />
      </div>
      <h3 className="mt-6 mb-5 text-[18px] font-bold leading-[28px] text-[#404040]">Lịch sử biến động số dư</h3>
      <div className={'flex flex-col items-center justify-center space-y-6'}>
        <div className={'grid-cols-1-1 grid w-full gap-4 md:grid-cols-2 lg:grid-cols-2'}>
          {/* <TextForm
            label={'Số thuê bao'}
            placeholder={'Tìm kiếm số thuê bao'}
            value={textSearch}
            setValue={setTextSearch}
          /> */}
          <SelectForm
            label={'Loại giao dịch'}
            options={typeTrans}
            value={typeTransSelect}
            onChange={(v) => (v ? setTypeTransSelect(v) : setTypeTransSelect(''))}
            classIcon={'text-gray-400'}
          />
          <TimeForm
            label={'Thời gian'}
            startDay={startDay}
            setStartDay={setStartDay}
            endDay={endDay}
            setEndDay={setEndDay}
            isDisabledSelectToday={true}
          />
        </div>
        <ButtonCustom
          label={'Tìm kiếm'}
          className={'h-[36px] w-[91px] bg-gray-600 px-2 !text-sm !font-semibold'}
          onClick={() => onSearchHistory()}
        />
      </div>
      <div className={'mt-6'}>
        {dataHistory?.length > 0 ? <TableHistory dataHistory={dataHistory} /> :
          <div className={'mt-4 flex h-fit w-full flex-col items-center justify-center'}>
            <img src={NotFoundIcon} alt={'not-found'} />
            <span className={'mt-2 text-[18px] font-bold leading-[28px] text-[#A1A1AA]'}>
              Không tìm thấy dữ liệu
            </span>
          </div>
        }
      </div>
      <div className={'mt-6'}>
        {dataHistory?.length > 0 && <PaginationCustom
          currentPage={currentPage || 1}
          setCurrentPage={(_page: number) => {
            setCurrentPage(_page);
            onSearchHistory({ currentPage: _page });
          }}
          totalItem={mutationGetHistory?.data?.data?.data?.total || 0}
          perPage={perPage}
          setPerPage={(_perPage: number) => {
            setPerPage(_perPage);
            setCurrentPage(1);
            onSearchHistory({ currentPage: 1, perPage: _perPage });
          }}
        />}
      </div>
    </>
  );
};
