import { useContext } from 'react';
import { StepVerifyOTP } from './StepVerifyOTP';
import { StepResetPassword } from './StepResetPassword';
import { StepSuccess } from './StepSuccess';
import { RegisterContext } from 'pages/resigter';
import { StepError } from './StepError';

export const Step = () => {
  const { activeStepIndex } = useContext(RegisterContext);
  switch (activeStepIndex) {
    case 0:
      return <StepVerifyOTP />;
    case 1:
      return <StepResetPassword />;
    case 2:
      return <StepSuccess />;
    case 3:
      return <StepError />;
    default:
      break;
  }

  return <></>;
};
