import { PATH_PAGE } from 'constant';
import { useNavigate } from 'react-router-dom';

export const CardNew = ({ data }: any) => {
  const navigator = useNavigate();
  return (
    <div
      className={
        'rounded-t-[16px] border border-[#D6D5D5] shadow-[0_0_15px_rgba(41,45,50,0.15)]' +
        ' max-h-[287px] transform cursor-pointer rounded-bl-[16px] transition duration-500 hover:scale-105'
      }
      onClick={() => navigator(`${PATH_PAGE.NEWS}/${data?.formatUrl}/${data?.id}`)}
    >
      <img src={data?.imageUrl} alt="" className={'h-[175px] w-full rounded-t-[16px] object-cover'} />
      <div className={'h-[112] w-fit px-6 py-2'}>
        <p className={'mt-4 text-sm font-bold text-baseColor line-clamp-2'}>{data?.title}</p>
        <p className={'mt-2 flex flex-row items-center space-x-2 text-sm font-medium text-[#818181]'}>
          <svg
            className={'mr-2'}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.9987 15.1667C4.04536 15.1667 0.832031 11.9533 0.832031 8C0.832031 4.04666 4.04536 0.833328 7.9987 0.833328C11.952 0.833328 15.1654 4.04666 15.1654 8C15.1654 11.9533 11.952 15.1667 7.9987 15.1667ZM7.9987 1.83333C4.5987 1.83333 1.83203 4.6 1.83203 8C1.83203 11.4 4.5987 14.1667 7.9987 14.1667C11.3987 14.1667 14.1654 11.4 14.1654 8C14.1654 4.6 11.3987 1.83333 7.9987 1.83333Z"
              fill="#818181"
            />
            <path
              d="M10.4751 10.62C10.3884 10.62 10.3017 10.6 10.2217 10.5467L8.15505 9.31333C7.64172 9.00667 7.26172 8.33333 7.26172 7.74V5.00667C7.26172 4.73333 7.48839 4.50667 7.76172 4.50667C8.03505 4.50667 8.26172 4.73333 8.26172 5.00667V7.74C8.26172 7.98 8.46172 8.33333 8.66839 8.45333L10.7351 9.68667C10.9751 9.82667 11.0484 10.1333 10.9084 10.3733C10.8084 10.5333 10.6417 10.62 10.4751 10.62Z"
              fill="#818181"
            />
          </svg>
          {data?.genDateStr?.split(' ')?.join(' | ')}
        </p>
      </div>
    </div>
  );
};
