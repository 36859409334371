import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { PATH_PAGE } from '../../../constant';
import { useNavigate } from 'react-router-dom';

export const AccountAuthen = ({ setIsShowNotification }: any) => {
  const navigate = useNavigate();
  return (
    <Transition show={true} as={Fragment}>
      <Dialog
        open={true}
        onClose={() => {}}
        className="fixed inset-0 z-50 flex h-full min-h-screen w-screen flex-row items-center justify-center overflow-y-auto bg-[#000000]/60"
      >
        <Dialog.Panel
          className={
            'h-[324px] min-h-[324px] w-[400px] rounded-t-[16px] rounded-bl-[16px] border border-gray-200' +
            ' bg-[#F8F8F8] shadow-base'
          }
        >
          <Dialog.Title className={' flex h-fit flex-col items-start px-6 pt-6'}>
            <div className={'flex h-fit w-full flex-row justify-end'}>
              <button type={'button'} className={'outline-none'} onClick={() => setIsShowNotification(false)}>
                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clipPath="url(#clip0_573_26413)">
                    <path
                      d="M0.314708 0.701427C0.414214 0.601669 0.532424 0.522521 0.662566 0.468518C0.792707 0.414515 0.932225 0.386719 1.07313 0.386719C1.21403 0.386719 1.35354 0.414515 1.48369 0.468518C1.61383 0.522521 1.73204 0.601669 1.83154 0.701427L7.5004 6.37242L13.1692 0.701427C13.2688 0.60183 13.3871 0.522825 13.5172 0.468924C13.6473 0.415022 13.7868 0.38728 13.9277 0.38728C14.0685 0.38728 14.208 0.415022 14.3381 0.468924C14.4682 0.522825 14.5865 0.60183 14.6861 0.701427C14.7857 0.801024 14.8647 0.919262 14.9186 1.04939C14.9725 1.17952 15.0002 1.31899 15.0002 1.45984C15.0002 1.6007 14.9725 1.74017 14.9186 1.8703C14.8647 2.00043 14.7857 2.11867 14.6861 2.21826L9.01509 7.88711L14.6861 13.556C14.7857 13.6556 14.8647 13.7738 14.9186 13.9039C14.9725 14.0341 15.0002 14.1735 15.0002 14.3144C15.0002 14.4552 14.9725 14.5947 14.9186 14.7248C14.8647 14.855 14.7857 14.9732 14.6861 15.0728C14.5865 15.1724 14.4682 15.2514 14.3381 15.3053C14.208 15.3592 14.0685 15.3869 13.9277 15.3869C13.7868 15.3869 13.6473 15.3592 13.5172 15.3053C13.3871 15.2514 13.2688 15.1724 13.1692 15.0728L7.5004 9.40181L1.83154 15.0728C1.73195 15.1724 1.61371 15.2514 1.48358 15.3053C1.35345 15.3592 1.21398 15.3869 1.07313 15.3869C0.932275 15.3869 0.792802 15.3592 0.662673 15.3053C0.532543 15.2514 0.414305 15.1724 0.314708 15.0728C0.215111 14.9732 0.136106 14.855 0.0822051 14.7248C0.0283036 14.5947 0.000560851 14.4552 0.000560851 14.3144C0.000560851 14.1735 0.0283036 14.0341 0.0822051 13.9039C0.136106 13.7738 0.215111 13.6556 0.314708 13.556L5.9857 7.88711L0.314708 2.21826C0.21495 2.11876 0.135802 2.00055 0.0817995 1.87041C0.0277966 1.74026 0 1.60075 0 1.45984C0 1.31894 0.0277966 1.17943 0.0817995 1.04928C0.135802 0.919142 0.21495 0.800933 0.314708 0.701427Z"
                      fill="#A9A9A9"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_573_26413">
                      <rect width="15" height="15" fill="white" transform="translate(0 0.386719)" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
            <div className={'mb-4 flex h-fit w-full flex-1 items-center justify-center'}>
              <svg width="72" height="71" viewBox="0 0 72 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="35.9993" cy="35.4134" r="25.6321" fill="white" />
                <path
                  d="M35.9966 6.22461C19.8848 6.22461 6.80859 19.3008 6.80859 35.4126C6.80859 51.5244 19.8848 64.6006 35.9966 64.6006C52.1084 64.6006 65.1846 51.5244 65.1846 35.4126C65.1846 19.3008 52.1084 6.22461 35.9966 6.22461ZM35.9966 17.8998C41.6299 17.8998 46.2124 22.4823 46.2124 28.1156C46.2124 33.7489 41.6299 38.3314 35.9966 38.3314C30.3633 38.3314 25.7808 33.7489 25.7808 28.1156C25.7808 22.4823 30.3633 17.8998 35.9966 17.8998ZM35.9966 58.763C30.0714 58.763 23.0663 56.3696 18.0752 50.3569C23.1877 46.3456 29.4982 44.1655 35.9966 44.1655C42.495 44.1655 48.8054 46.3456 53.918 50.3569C48.9269 56.3696 41.9218 58.763 35.9966 58.763Z"
                  fill="url(#paint0_linear_474_13700)"
                />
                <circle cx="56.7553" cy="54.8706" r="9.41547" fill="white" />
                <path
                  d="M56.7532 43.5195C59.7637 43.5195 62.6508 44.7154 64.7795 46.8441C66.9082 48.9728 68.1041 51.86 68.1041 54.8704C68.1041 57.8809 66.9082 60.768 64.7795 62.8967C62.6508 65.0254 59.7637 66.2213 56.7532 66.2213C53.7428 66.2213 50.8556 65.0254 48.7269 62.8967C46.5982 60.768 45.4023 57.8809 45.4023 54.8704C45.4023 51.86 46.5982 48.9728 48.7269 46.8441C50.8556 44.7154 53.7428 43.5195 56.7532 43.5195ZM56.7532 48.3842C56.5474 48.384 56.3437 48.4268 56.1553 48.5098C55.9669 48.5927 55.7979 48.7141 55.659 48.8661C55.5201 49.0181 55.4145 49.1973 55.3488 49.3925C55.2832 49.5876 55.2589 49.7942 55.2776 49.9993L55.8695 56.4952C55.8924 56.7136 55.9954 56.9158 56.1586 57.0627C56.3218 57.2097 56.5336 57.291 56.7532 57.291C56.9728 57.291 57.1847 57.2097 57.3478 57.0627C57.511 56.9158 57.614 56.7136 57.637 56.4952L58.2272 49.9993C58.2459 49.7944 58.2217 49.5879 58.1562 49.3928C58.0906 49.1978 57.9851 49.0186 57.8464 48.8667C57.7077 48.7147 57.5389 48.5933 57.3507 48.5102C57.1624 48.4272 56.959 48.3842 56.7532 48.3842ZM56.7532 61.3566C57.0973 61.3566 57.4272 61.22 57.6705 60.9767C57.9138 60.7334 58.0505 60.4035 58.0505 60.0594C58.0505 59.7154 57.9138 59.3854 57.6705 59.1421C57.4272 58.8988 57.0973 58.7622 56.7532 58.7622C56.4092 58.7622 56.0792 58.8988 55.8359 59.1421C55.5927 59.3854 55.456 59.7154 55.456 60.0594C55.456 60.4035 55.5927 60.7334 55.8359 60.9767C56.0792 61.22 56.4092 61.3566 56.7532 61.3566Z"
                  fill="url(#paint1_linear_474_13700)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_474_13700"
                    x1="35.9966"
                    y1="6.22461"
                    x2="35.9966"
                    y2="64.6006"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#818181" />
                    <stop offset="1" stopColor="#9E9E9E" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_474_13700"
                    x1="45.4023"
                    y1="54.8704"
                    x2="68.1041"
                    y2="54.8704"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F15836" />
                    <stop offset="1" stopColor="#FF924F" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </Dialog.Title>
          <Dialog.Description className={'flex h-full w-full flex-col items-center px-6 pb-6'}>
            <h2 className={'mb-4 text-[22px] font-extrabold leading-[26px] text-baseColor'}>Tài khoản chưa xác thực</h2>
            <p className={'text-center text-sm font-medium text-[#818181]'}>
              Vui lòng bổ sung thông tin xác thực tài khoản để bắt đầu kinh doanh trên nền tảng Shop360!
            </p>
            <button
              className={
                'h-[40px] w-full rounded-t-[8px] rounded-bl-[8px] bg-gradient-to-l from-[#F15836]' +
                ' mt-4 w-full rounded-t-[6px] rounded-bl-[6px] bg-white to-[#FF924F] p-[2px]'
              }
              onClick={() => {
                navigate(PATH_PAGE.PROFILE);
                setIsShowNotification(false);
              }}
            >
              <span className={' text-sm font-extrabold text-white'}>Xác thực tài khoản</span>
            </button>
          </Dialog.Description>
        </Dialog.Panel>
      </Dialog>
    </Transition>
  );
};
