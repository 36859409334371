import { PaginationCustom } from 'components/common/pagination/Pagination';
import { SpinCustom } from 'components/common/SpinCustom';
import { PATH_PAGE } from 'constant';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { getNewsList } from 'services/common';
import { CardNew } from '../CardNew';

export const NewList = () => {
  const [indexDataCarousel, setIndexDataCarousel] = useState(1);
  const [keySearch, setKeySearch] = useState('');
  const navigator = useNavigate();

  const { data: response, isLoading } = useQuery([PATH_PAGE.NEWS, indexDataCarousel, keySearch], () =>
    getNewsList({
      homenum: 1,
      page: indexDataCarousel,
      sizeOfPage: 7,
      title: keySearch?.trim(),
    })
  );

  const [dataNews, newFirst, totalItems] = useMemo(() => {
    if (!response?.data?.data?.items) {
      return [[], null, 0];
    }
    const dataList = [...response.data.data.items];
    const dataFirst = dataList.shift();
    return [dataList, dataFirst, response.data.data.rowCount];
  }, [response]);

  const handleSearch = (e: any) => {
    if (e.key === 'Enter') {
      setIndexDataCarousel(1);
      setKeySearch(e.target.value || '');
    }
  };

  return (
    <div className={'mx-auto w-full max-w-[62rem] py-16'}>
      <div className={'flex flex-row justify-between'}>
        <h1 className={'text-[22px] font-extrabold leading-[26px] text-baseColor'}>TIN TỨC</h1>
        <div className="relative h-[40px] w-[313px] rounded-l-[50px] rounded-r-[50px] border border-[#D6D5D5]">
          <input
            className={
              'h-full w-full bg-transparent indent-10 text-baseColor focus:outline-none' +
              ' text-sm font-medium placeholder:text-sm placeholder:font-medium placeholder:text-[#818181]'
            }
            placeholder={'Tìm kiếm tin tức'}
            onKeyDown={handleSearch}
          />
          <svg
            className={'pointer-events-none absolute top-3.5 left-4'}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.7897 12.7744C14.0701 13.0548 14.0701 13.5093 13.7897 13.7897C13.5093 14.0701 13.0548 14.0701 12.7744 13.7897L10.2616 11.2769C9.98119 10.9965 9.98119 10.5419 10.2616 10.2616C10.5419 9.98119 10.9965 9.98119 11.2769 10.2616L13.7897 12.7744ZM5.74359 11.4872C2.57149 11.4872 0 8.91569 0 5.74359C0 2.57149 2.57149 0 5.74359 0C8.91569 0 11.4872 2.57149 11.4872 5.74359C11.4872 8.91569 8.91569 11.4872 5.74359 11.4872ZM5.74359 10.0513C8.12266 10.0513 10.0513 8.12266 10.0513 5.74359C10.0513 3.36452 8.12266 1.4359 5.74359 1.4359C3.36452 1.4359 1.4359 3.36452 1.4359 5.74359C1.4359 8.12266 3.36452 10.0513 5.74359 10.0513Z"
              fill="#818181"
            />
          </svg>
        </div>
      </div>
      {isLoading ? (
        <SpinCustom />
      ) : (
        <>
          {newFirst && (
            <div
              className={
                'mt-12 flex h-[248px] w-full transform cursor-pointer flex-row rounded-[16px] border border-[#D6D5D5] shadow-base transition duration-500 hover:scale-105'
              }
              onClick={() => navigator(`${PATH_PAGE.NEWS}/${newFirst?.formatUrl}/${newFirst?.id}`)}
            >
              <img src={newFirst?.imageUrl} alt="" className={'h-[248px] w-[320px] rounded-l-[16px] object-cover'} />
              <div className={'h-full w-full p-6'}>
                <h2 className={'text-[18px] font-bold leading-[28px] text-baseColor'}>{newFirst?.title}</h2>
                <p className={'mt-4 flex flex-row items-center space-x-2 text-sm font-medium text-[#818181]'}>
                  <svg
                    className={'mr-2'}
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.9987 15.1667C4.04536 15.1667 0.832031 11.9533 0.832031 8C0.832031 4.04666 4.04536 0.833328 7.9987 0.833328C11.952 0.833328 15.1654 4.04666 15.1654 8C15.1654 11.9533 11.952 15.1667 7.9987 15.1667ZM7.9987 1.83333C4.5987 1.83333 1.83203 4.6 1.83203 8C1.83203 11.4 4.5987 14.1667 7.9987 14.1667C11.3987 14.1667 14.1654 11.4 14.1654 8C14.1654 4.6 11.3987 1.83333 7.9987 1.83333Z"
                      fill="#818181"
                    />
                    <path
                      d="M10.4751 10.62C10.3884 10.62 10.3017 10.6 10.2217 10.5467L8.15505 9.31333C7.64172 9.00667 7.26172 8.33333 7.26172 7.74V5.00667C7.26172 4.73333 7.48839 4.50667 7.76172 4.50667C8.03505 4.50667 8.26172 4.73333 8.26172 5.00667V7.74C8.26172 7.98 8.46172 8.33333 8.66839 8.45333L10.7351 9.68667C10.9751 9.82667 11.0484 10.1333 10.9084 10.3733C10.8084 10.5333 10.6417 10.62 10.4751 10.62Z"
                      fill="#818181"
                    />
                  </svg>
                  {newFirst?.genDateStr?.split(' ')?.join(' | ')}
                </p>
                <p className={'mt-4 text-justify text-sm font-medium text-baseColor'}>{newFirst?.shortContent}</p>
              </div>
            </div>
          )}
          <div className={'mt-6 grid grid-cols-3 gap-4'}>
            {dataNews.map((news: any, index: number) => (
              <CardNew key={index} data={news} />
            ))}
          </div>
        </>
      )}
      <div className={'mt-14'}>
        {!isLoading && totalItems > 0 && (
          <PaginationCustom
            currentPage={indexDataCarousel}
            setCurrentPage={setIndexDataCarousel}
            totalItem={totalItems}
            perPage={7}
            isBackgroundOrange
            isHiddenPerPage
          />
        )}
        {!isLoading && totalItems === 0 && <>Không có dữ liệu</>}
      </div>
    </div>
  );
};
